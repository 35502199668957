import React from 'react'
import { CustomFacets, ExternalChannels } from '..'

export const SettingsBigcommerce = ({ routeProps }) => {
  const { match: { params: { instance } } } = routeProps

  return (
    <>
      <CustomFacets slug='bigcommerce' instance={instance} />
      <ExternalChannels name='Bigcommerce' href='https://login.bigcommerce.com/login' />
    </>
  )
}
