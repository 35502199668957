import React from 'react'
import { BarChart, Bar, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { withComma } from './utils'

const SDBarChart = ({ data, name, mLeft }) => {
  const CustomTooltip = (props) => {
    const { active, payload, label } = props
    return (
      active &&
        <div className='custom-tooltip'>
          {payload && payload[0] && <p className='value-0'>{`${payload[0].payload.legend}: ${withComma(payload[0].value)}`}</p>}
          {payload && payload[1] && <p className='label'>{`${payload[1].payload.legend1}: ${withComma(payload[1].value)}`}</p>}
          <p className='label text-muted'>{`Date: ${label}`}</p>
        </div>
    )
  }

  return (
    <div style={{ width: '100%', height: 300 }}>
      <ResponsiveContainer>
        <BarChart
          data={data}
          margin={{ left: mLeft, right: 5, top: 5 }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='label' />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar name={name} dataKey='value' fill='#336db0' />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export { SDBarChart }
