import React from 'react'
import { Alert } from 'reactstrap'
import { FaInfo as Info } from 'react-icons/all'

export const InfoAlert = ({ children }) => {
  return (
    <Alert
      color='primary'
      className='has-icon d-flex align-items-center my-3'
    >
      <div className='alert-icon'><Info className='m-0' size={16} /></div>
      <span style={{ color: '#363642' }}>
        {children}
      </span>
    </Alert>
  )
}
