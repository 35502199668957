import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { User } from 'suredone-common'
import { ResourceList, ResourceListItem } from '../../components/SearchableLists'
import { Loadable, Page } from '../../components'
import { UserSummary2 } from './UserSummary2'
import Debug from 'debug'

const debug = Debug('sd:UserManager')

export const GET_ALL_USERS = gql`{
  AllUsers {
    ... on User {
      id
      username
      primaryEmail
      roles
      scopes
      organizationId
      legacyUserId
      legacyAccountId
      legacyAccountName
      legacyPrimary
      firstName
      lastName
    }
  }
}`

export function UserManager () {
  const { loading, error, data } = useQuery(GET_ALL_USERS)
  const response = (data && data.AllUsers) ? data.AllUsers : []
  const items = response.map(({
    id,
    username,
    primaryEmail,
    roles,
    scopes,
    organizationId,
    legacyUserId,
    legacyAccountId,
    legacyAccountName,
    legacyPrimary,
    firstName,
    lastName
  }) => new User(
    id,
    username,
    primaryEmail,
    roles,
    scopes,
    organizationId,
    legacyUserId,
    legacyAccountId,
    legacyAccountName,
    '',
    legacyPrimary,
    firstName,
    lastName
  ))

  return (
    <Page
      title='Users'
      description='All users'
    >
      <Loadable
        loading={loading}
        error={error}
        content={
          <ResourceList
            items={items}
            itemPlural='users'
            itemComponent={
              ({ item: user, searchTerms }) =>
                <ResourceListItem to={`users/${user.id}`}>
                  <UserSummary2 isListItem searchTerms={searchTerms} user={user} />
                </ResourceListItem>
            }
            searchableStrings={u => [
              u.primaryEmail,
              u.firstName,
              u.lastName,
              [u.firstName, u.lastName].join(' '), // full name
              u.legacyUserId,
              u.legacyAccountId,
              u.legacyAccountName
            ]}
            searchPlaceholder='Search users'
            className='user-list'
          />
        }
      />
    </Page>
  )
}

debug('loaded')
