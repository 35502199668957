import React from 'react'
import { FaSpinner } from 'react-icons/fa'
import FormText from 'reactstrap/lib/FormText'
import './Loading.css'

export const Loading = ({ loadingText = false }) => {
  return (
    <div className='loader-outer'>
      <div className='loader-inner d-flex justify-content-center align-items-center flex-wrap text-center'>
        <FaSpinner style={{ color: '#16689F', fontSize: '72px' }} className='fa-pulse' />
        {loadingText &&
          <FormText style={{ fontSize: '100%' }}>
            <p className='m-0'>{loadingText}</p>
          </FormText>}
      </div>
    </div>
  )
}

export const LoadingInline = ({ loadingText = false }) => {
  return (
    <div style={{ textAlign: 'center' }}>
      <FaSpinner style={{ color: '#16689F' }} className='fa-pulse' />
      {loadingText &&
        <FormText style={{ fontSize: '100%' }}>
          <p className='m-0'>{loadingText}</p>
        </FormText>}
    </div>
  )
}
