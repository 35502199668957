/**
 * <EmptyState />
 *
 * Empty states are used when a list, table, or chart has no items or data to show.
 * This is an opportunity to provide explanation or guidance.
 *
 * Usage:
 *  <EmptyState
 *    isEmpty     = Boolean
 *    heading     = String
 *    content     = String
 *    customImage = SVG
 *    action      = object -> { content: 'Learn more', url: '/example-url', handleAction: 'doSomething()' }
 *  >
 *    {children}. It's used when {isEmpty} is false.
 *  </EmptyState>
 */

import React from 'react'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { people } from '../img/illustrations'

export default function EmptyState ({ isEmpty, heading, content, customImage, action, children }) {
  const { emptyImage } = people

  if (isEmpty) {
    return (
      <div className='row justify-content-center py-3 is-empty'>
        <div className='empty-state-container'>
          <div className='state-figure'>{customImage || emptyImage}</div>
          <div className='mt-2'>
            {heading && <h4 className='state-header mb-3'> {heading} </h4>}
            {content && <h6 className='text-muted font-weight-normal'>{content}</h6>}
            {action &&
              <div className='state-action state-action mt-4 mb-0'>
                {action.url
                  ? <Button color='primary' tag={Link} to={action.url}>{action.content}</Button>
                  : <Button color='primary' onClick={action.handleAction}>{action.content}</Button>}
              </div>}
          </div>
        </div>
      </div>
    )
  } else return children
}
export { EmptyState }
