import gql from 'graphql-tag'

export const GET_LOGS = gql`
  query UserLogs ($userId: Int!, $filter: JSONObject!, $targetOrganizationId: String, $offset: Int, $limit: Int, $orderBy: LogOrderByInput) {
    UserLogs(userId: $userId, filter: $filter, targetOrganizationId: $targetOrganizationId, offset: $offset, limit: $limit, orderBy: $orderBy) {
      count
      hasMore
      logs {
        id
        createdAt
        s3Path
        userId
        accountId
        context
        instance
        jobId
        integration
        requestId
        type
        identifier
        channelId
        action
        method
        operation
        result
        fieldData
        message
        warnings
        request
        response
        retry
        release
        releaseType
        role
        serverName
        truncatedFields
        errors
      }
    }
  }
`

export const GET_LOG_DETAIL = gql`
  query UserLogs ($userId: Int!, $filter: JSONObject!, $targetOrganizationId: String, $offset: Int, $limit: Int, $orderBy: LogOrderByInput) {
    UserLogs(userId: $userId, filter: $filter, targetOrganizationId: $targetOrganizationId, offset: $offset, limit: $limit, orderBy: $orderBy) {
      count
      logs {
        id
        createdAt
        s3Path
        userId
        accountId
        context
        instance
        jobId
        integration
        requestId
        type
        identifier
        channelId
        action
        method
        operation
        result
        fieldData
        message
        warnings
        request
        response
        retry
        release
        releaseType
        role
        truncatedFields
        errors
      }
    }
  }
`
