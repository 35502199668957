import React from 'react'
import auth01 from './auth01.png'
import auth02 from './auth02.png'
import auth03 from './auth03.png'
import authGroupPermissions from './authGroupPermissions.png'
import provisionStorePermission from './provisionStorePermission.png'

export const customAppStepInstructions = [
  {
    title: 'Authentication & Permissions',
    description: (
      <>
        1. Log in to your Miva admin page. <br />
        2. Go to <b>Settings {'>'} User Management {'>'} API Tokens</b>. <br />
        3. Click <b>Add API Token</b>.
      </>
    ),
    imgSrc: auth01,
    imgAlt: 'Login with Miva and click Add API Token'
  },
  {
    title: 'Add API Token',
    description: (
      <>
        1. Set <b>Name:</b> <code>SureDone Connection</code><br />
        2. Set <b>Allowed IP Address(es):</b> <code>52.207.122.19/32, 35.175.182.89/32</code><br />
        3. Write down the following values to use them in the following step: <br />
        - <b>Endpoint URL</b><br />
        - <b>Access Token</b><br />
        - <b>Signature</b><br />
        4. Click <b>Save</b>.
      </>
    ),
    imgSrc: auth02,
    imgAlt: 'Add API Token'
  },
  {
    title: 'Assign Token Permissions',
    description: (
      <>
        1. Click on the <b>Groups</b> tab next to <b>API Tokens</b><br />
        2. Ensure you have API Permissions groups covering product and order management<br />
        3. For each relevant group, click the checkbox to the left, then the box with <code>...</code> above, then <b>API Tokens</b> in the dropdown<br />
        4. Use the switch in the modal to assign the group to the API Token you just created<br />
        5. Click <b>Close</b>.
      </>
    ),
    imgSrc: authGroupPermissions,
    imgAlt: 'Assign API Token Permissions'
  },
  {
    title: 'Add Provision Store Permission',
    description: (
      <>
        1. Click on the <b>API Tokens</b> tab<br />
        2. Click on the empty check box to the left of the SureDone Connection token, then click on the "Functions" button that appears once that is selected<br />
        3. Click "Add Function", select your store in the dropdown under 'Store', and in the text box that says "Function", type "Provision_Store"<br />
        4. Save Changes<br />
        5. Click <b>Close</b>.
      </>
    ),
    imgSrc: provisionStorePermission,
    imgAlt: 'Add Provision Store Permission'
  },
  {
    title: 'Connect to SureDone',
    description: (
      <>
        1. Use the information from the previous step to complete the form below with: <br />
        - <b>Endpoint URL</b><br />
        - <b>Access Token</b><br />
        - <b>Signature</b><br />
        - <b>Store Code</b> (you can find this in Settings {'>'} Store Settings under Store Details {'>'} Identification)<br />
        2. Click on <b>Authorize Miva</b>
      </>
    ),
    imgSrc: auth03,
    imgAlt: 'Connect to SureDone'
  }
]
