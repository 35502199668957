import React from 'react'
import { Col, Label, FormGroup, CustomInput } from 'reactstrap'
import { Field } from 'react-final-form'

const checker = val => {
  const posibleTrueValues = ['on', 'true']
  return posibleTrueValues.includes(val?.toString())
}

const ToggleType = ({ data }) => {
  const { label, fieldName, disabled, description } = data
  return (
    <>
      <Col sm={7}>
        <Label tag={`${description && 'legend'}`} className={`${description && 'mb-0'}`} for={fieldName}>{label}</Label>
        {description && <p>{description}</p>}
      </Col>
      <Col sm={5}>
        <Field
          name={fieldName}
          format={val => checker(val)}
          parse={val => val ? 'on' : 'off'}
        >
          {({ input, meta }) => {
            return (
              <FormGroup>
                <CustomInput
                  {...input}
                  id={fieldName}
                  color='primary'
                  checked={input.value}
                  placeholder=''
                  type='switch'
                  disabled={disabled}
                />
                {meta.invalid && <div style={{ fontSize: '12px', color: '#dc3545', marginTop: '2px' }}>{label} is required</div>}
              </FormGroup>
            )
          }}
        </Field>
      </Col>
    </>
  )
}

export { ToggleType }
