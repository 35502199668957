import React, { useState } from 'react'
import Select from 'react-select'
import { Row, Col, Input } from 'reactstrap'
import { SearchPreview } from './SearchPreview'
import { ActionButton } from '../../../components'
import { connectorOptions, keyOptions, operatorOptions, initialFilter, forceOr, formatInput, formatOperator } from './utils'

export const SearchBy = ({ typeOfSearch }) => {
  const [currentFilters, setCurrentFilters] = useState({ 0: initialFilter })
  const [finalQueryValue, setFinalQueryValue] = useState({})

  const previousConnectorIsOR = idx => finalQueryValue?.[idx - 1]?.connector === 'OR'
  const generateNullFilters = idx => {
    const amountOfFilters = Object.keys(currentFilters)?.length
    const extraFilters = amountOfFilters - idx
    return (new Array(extraFilters).fill(0)).reduce((acc, curr, localindex) => {
      acc[idx + localindex + 2] = null
      return acc // { n: null, [n+1]: null, ... }
    }, {})
  }

  const handleFiltersChange = (type, idx, e) => {
    let value
    if (type === 'input') {
      value = e.target.value || '' // handles text entries
    } else {
      value = e // handles select entries
    }
    setCurrentFilters(p => ({ ...p, [idx]: { ...p[idx], [type]: value } }))
  }

  const handleSubmitFilter = (idx, e) => {
    e.preventDefault()
    const { connector, input, key, operator } = currentFilters[idx] || {}

    const handleConnector = () => {
      if (idx === 0) return ''
      if (previousConnectorIsOR(idx)) return 'OR'
      return connector.value
    }

    // Adds an empty row for filtering and clean the following filters if they exist
    setCurrentFilters(p => ({ ...p, [idx + 1]: initialFilter, ...generateNullFilters(idx) }))

    setFinalQueryValue(p => ({
      ...p,
      [idx]: {
        key: key.value,
        input: formatInput(input, operator.value),
        connector: handleConnector(),
        operator: formatOperator(operator.value)
      },
      ...generateNullFilters(idx - 1)
    }))
  }

  return (
    <>
      {Object.keys(currentFilters)?.map((i, idx) => {
        if (!currentFilters?.[idx]) return null

        return (
          <form key={idx} onSubmit={e => handleSubmitFilter(idx, e)} className='mb-1'>
            <Row className='filter-by'>
              <Col xs={12} lg={1} className='my-1'>
                {idx === 0
                  ? <strong className='d-flex h-100 align-items-center'>Where:</strong>
                  : (
                    <Select
                      clearable={false}
                      options={connectorOptions}
                      placeholder='+ Add filter by'
                      disabled={previousConnectorIsOR(idx)}
                      onChange={e => handleFiltersChange('connector', idx, e)}
                      value={previousConnectorIsOR(idx) ? forceOr() : currentFilters?.[idx]?.connector}
                    />
                    )}
              </Col>
              <Col xs={6} lg={3} className='my-1'>
                <Select
                  autoFocus
                  clearable={false}
                  placeholder='+ Add filter by'
                  value={currentFilters?.[idx]?.key}
                  options={keyOptions[typeOfSearch]}
                  onChange={e => handleFiltersChange('key', idx, e)}
                />
              </Col>
              <Col xs={6} lg={3} className='my-1'>
                <Select
                  clearable={false}
                  options={operatorOptions}
                  placeholder='+ Add operator'
                  value={currentFilters?.[idx]?.operator}
                  onChange={e => handleFiltersChange('operator', idx, e)}
                />
              </Col>

              <Col xs={6} lg={3} className='my-1'>
                <Input
                  placeholder='sd_123'
                  value={currentFilters?.[idx]?.input}
                  onChange={e => handleFiltersChange('input', idx, e)}
                />
              </Col>
              <Col xs={6} lg={2} className='my-1'>
                <ActionButton
                  outline
                  block
                  color='primary'
                  disabled={!(currentFilters?.[idx]?.key && currentFilters?.[idx]?.operator)}
                >
                  Add filter
                </ActionButton>
              </Col>
            </Row>
            <hr className='d-lg-none' />
          </form>
        )
      })}

      <SearchPreview finalQueryValue={finalQueryValue} typeOfSearch={typeOfSearch} />
    </>
  )
}
