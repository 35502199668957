import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ActionButton, ErrorAlert } from '../../components'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import './AddChannelModal.scss'
import { useMutation } from '@apollo/react-hooks'
import { CHANGE_CHANNEL_STATUS } from './commonQueries'
import debug from 'debug'

export const AddChannelModal = ({ slug, channelName, newChannelLoading, addNewChannel, channelInstances, parentCallback }) => {
  const [modal, setModal] = useState(false)
  const history = useHistory()
  const toggle = () => setModal(prev => !prev)

  const [changeChannelStatus, { loading, error }] = useMutation(CHANGE_CHANNEL_STATUS, {
    onError: (error) => {
      debug(error)
    },
    onCompleted: (data) => {
      history.push(`/channels/${slug}/${instanceNumber}/authorization`)
    }
  })

  const instancesOfThisChannels = channelInstances?.filter(({ channel }) => channel.slug === slug)
  const lastInstance = instancesOfThisChannels.pop()
  const { instance: instanceNumber } = lastInstance

  const handleClick = () => {
    parentCallback()
    toggle()
  }

  const handleAuthorizeChannel = () => {
    if (window.analytics) {
      const currentEvent = `Unauth channel: Auth existing instance of ${channelName}${instanceNumber > 0 ? instanceNumber : ''}`
      const currentHistory = window?.analyticsEventHistory
      const newHistory = [currentEvent, ...currentHistory].slice(0, 10)
      window.analyticsEventHistory = newHistory
      const fullFilled = {
        action: `User clicks authorize ${channelName}${instanceNumber > 0 ? instanceNumber : ''} on the popup modal`,
        instanceNumber,
        channel: channelName,
        slug: `${slug}${instanceNumber > 0 ? instanceNumber : ''}`,
        pathHistory: window?.analyticsPathHistory,
        priorPath: window?.analyticsPathHistory?.[0],
        eventHistory: newHistory,
        priorEvent: newHistory?.[1]
      }
      window.analytics.track(currentEvent, fullFilled)
    }
    slug === 'ebay' ? changeChannelStatus({ variables: { slug, instanceNumber, changeTo: true } }) : history.push(`/channels/${slug}/${instanceNumber}/authorization`)
  }

  const handleAddNewInstance = () => {
    if (window.analytics) {
      const currentEvent = `Unauth channel: Add new instance of ${channelName}${instanceNumber > 0 ? instanceNumber : ''}`
      const currentHistory = window?.analyticsEventHistory
      const newHistory = [currentEvent, ...currentHistory].slice(0, 10)
      window.analyticsEventHistory = newHistory
      const fullFilled = {
        action: `User clicks add a new channel instance of ${channelName}${instanceNumber > 0 ? instanceNumber : ''} on the popup modal`,
        instanceNumber,
        channel: channelName,
        slug: `${slug}${instanceNumber > 0 ? instanceNumber : ''}`,
        pathHistory: window?.analyticsPathHistory,
        priorPath: window?.analyticsPathHistory?.[0],
        eventHistory: newHistory,
        priorEvent: newHistory?.[1]
      }
      window.analytics.track(currentEvent, fullFilled)
    }
    addNewChannel()
  }

  return (
    <>
      <ActionButton
        color='success'
        loading={newChannelLoading}
        onClick={() => handleClick()}
      >
        Add Channel
      </ActionButton>

      <Modal isOpen={modal} centered toggle={toggle} className='add-channel-modal'>
        <ModalHeader toggle={toggle}>
          You have unauthorized {channelName} channels
        </ModalHeader>
        <ModalBody>
          <p className='m-0'>What do you want to do?</p>
          <div className='d-flex justify-content-start mt-3 flex-wrap'>
            <ActionButton
              className='btn btn-primary mr-sm-3 mb-2'
              color='primary'
              onClick={() => handleAuthorizeChannel()}
              loading={loading}
            >
              Authorize {channelName} {instanceNumber > 0 && instanceNumber}
            </ActionButton>
            <ActionButton
              className='mb-3'
              color='secondary'
              loading={newChannelLoading}
              onClick={() => handleAddNewInstance()}
            >
              Add a new {channelName} instance
            </ActionButton>
            {error && <ErrorAlert error={error} />}
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}
