import React, { useState } from 'react'
import { SearchBy } from '.'
import { Card, CardBody, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'

const availableTabs = ['Products', 'Orders']

export const Builder = () => {
  const [activeTab, setActiveTab] = useState('1')

  const toggleActiveTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return (
    <Card>
      <CardBody>
        <h5 className='mb-3'>Search Builder</h5>
        <Card className='mb-0'>
          <Nav tabs>
            {availableTabs.map((i, idx) => (
              <NavItem key={`${i}-${idx}`}>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  onClick={() => toggleActiveTab(`${idx + 1}`)}
                  className={`${activeTab === `${idx + 1}` ? 'active' : ''}`}
                >
                  {i}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <TabContent activeTab={activeTab} className='p-3'>
            {availableTabs.map((item, idx) => (
              <TabPane key={item + idx} tabId={`${idx + 1}`}>
                <SearchBy typeOfSearch={item} />
              </TabPane>
            ))}
          </TabContent>
        </Card>
      </CardBody>
    </Card>
  )
}
