import React, { useState } from 'react'
import Debug from 'debug'

import { Label, FormFeedback, FormGroup, Input } from 'reactstrap'
import { Field, Form } from 'react-final-form'
import { useMFA } from '../../hooks'
import { ActionButton } from '../ActionButton'
import ErrorAlert from '../ErrorAlert'
import QRCode from 'qrcode.react'
import { SaveButton } from '../SaveButton'
import { useStores } from '../../stores/RootStore'
import { useMutation } from '@apollo/react-hooks'
import { SET_MFA_SETTING } from './commonQueries'
import icon from './suredone-icon.png'
import { DIGITS } from 'suredone-common'
const debug = Debug('sd:MFAEnableButton')

export const MFAIntroText = () => (
  <p>
    To log in with two-factor auth enabled, you will need a separate app like
    <a href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US&gl=US'>Google Authenticator</a> or
    <a href='https://authy.com/download/'>Authy</a> or a password manager
    like <a href='https://1password.com/'>1Password</a> supplied by your organization.
  </p>
)

const MfaCodeForm = ({ handleSubmit, dirtySinceLastSubmit, customError, verifying, success, pristine, valid }) => {
  return (
    <form onSubmit={handleSubmit}>
      <Label for='code'>Enter the 6-digit code from your app here</Label>
      <Field
        name='code'
        validate={(code) => {
          if (!code) return 'Required'
          if (!DIGITS.test(code) || code.length !== 6) return 'Password must contain only 6 digits.'
        }}
      >
        {({ input, meta }) => {
          return (
            <FormGroup>
              <Input
                {...input}
                type='text'
                invalid={meta.touched && meta.invalid}
                className='w-50 m-auto'
              />
              {meta.error && meta.touched && (
                <FormFeedback>{meta.error}</FormFeedback>
              )}
            </FormGroup>
          )
        }}
      </Field>
      <SaveButton
        color='primary'
        type='submit'
        loading={verifying}
        disabled={verifying || pristine || !valid}
        // The following sintax {state ? 'true' : false} is to avoid console warnings
        success={success ? 'true' : null}
        dirtysincelastsubmit={dirtySinceLastSubmit ? 'true' : null}
      >
        {verifying ? 'Verifying' : 'Verify Code'}
      </SaveButton>
      {customError && !verifying && <ErrorAlert className='w-100' error={customError} />}
    </form>
  )
}

const MfaVerifyCode = () => {
  const {
    verifySoftwareToken,
    updatingMFA,
    loading: { verifying, updating },
    errors: { verifyingError, updatingError },
    success: { updatingSuccess, verifyingSuccess }
  } = useMFA()
  const [setMFASetting, { error, loading }] = useMutation(SET_MFA_SETTING,
    {
      onCompleted: (e) => {
        auth.refresh()
      },
      onError: (e) => {
        debug(e)
      }
    })
  const { stores: { auth } } = useStores()
  const onHandleSubmit = async (values) => {
    const resultVerify = await verifySoftwareToken(values.code)
    if (resultVerify === 'SUCCESS') {
      const resultUpdate = await updatingMFA(true)
      if (resultUpdate === 'SUCCESS') setMFASetting({ variables: { mfaEnabled: true } })
    }
  }
  return (
    <Form
      onSubmit={onHandleSubmit}
      verifying={verifying || updating || loading}
      customError={(updatingError && updatingError.message) || (verifyingError && verifyingError.message) || error}
      success={updatingSuccess && verifyingSuccess}
    >
      {props => <MfaCodeForm {...props} />}

    </Form>
  )
}

const MFAEnableButton = () => {
  const { associateSoftwareToken, loading: { associating }, errors: { associatingError }, success: { associatingSuccess } } = useMFA()
  const { stores: { auth } } = useStores()
  const { user } = auth || {}
  const { id } = user || {}
  const [secretCode, setSecretCode] = useState()
  const onHandleSubmit = async () => {
    const SecretCode = await associateSoftwareToken()
    setSecretCode(`otpauth://totp/Suredone:${id}?secret=${SecretCode}&issuer=Suredone`)
  }

  return (
    <>
      {!(associatingSuccess && secretCode)
        ? (
          <div>
            <MFAIntroText />
            <ActionButton
              color='primary'
              loading={associating}
              disabled={associating || associatingSuccess}
              onClick={onHandleSubmit}
            >
              Set up authenticator app
            </ActionButton>
          </div>)
        : null}
      {associatingError && <ErrorAlert error={associatingError.message} />}
      {(associatingSuccess && secretCode)
        ? (
          <>
            <p>
              <Label for='mfa-qr'>Scan this QR code with your authenticator app:</Label>
            </p>
            <div id='mfa-qr' className='w-100 text-center'>
              <QRCode size={256} value={secretCode} imageSettings={{ src: icon, height: 40, width: 40, excavate: true }} />
              <MfaVerifyCode />
            </div>
          </>)
        : null}
    </>
  )
}

export { MFAEnableButton }

debug('loaded')
