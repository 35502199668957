import auth01 from './auth01.png'
import auth02 from './auth02.png'
import auth03 from './auth03.png'

export const stepInstructions = {
  Permissions: {
    instructions: {
      stepInstructions: [
        {
          title: 'Login with Google & Verify Business User',
          description: 'Click "Authorize Google" below to start the authorization process. Verify that the user authorizing is connected to the Google Merchant Center account that will be associated with the SureDone product catalog.',
          imgSrc: auth01,
          imgAlt: 'Login with Google and Verify Business User'
        },
        {
          title: 'Review & Grant Permissions',
          description: 'SureDone requires permissions to create & manage product catalogs and process orders for a stable business integration. All requested permissions must be allowed for the SureDone Google integration to operate reliably. Note that if you change your password on Google you may have to re-authorize your connection inside SureDone.',
          imgSrc: auth02,
          imgAlt: 'Review and Grant Permissions'
        },
        {
          title: 'Apply User Roles',
          description: 'Once you have authorized, check to make sure your user has the "Order Manager" role applied to enable import of Google orders. We suggest adding "Admin" and "Payments analyst" roles to allow SureDone to create a service connection and to generate reports.',
          imgSrc: auth03,
          imgAlt: 'Apply User Roles'
        }
      ]
    }
  }
}
