import * as ebayConfigData from '../../channels/ebay-config.json'
import * as amazonConfigData from '../../channels/amzn-config.json'
import * as bigcommerceConfigData from '../../channels/bigcommerce-config.json'
import * as bigcommerceEditorData from '../../channels/bigcommerce-editor.json'
import * as etsyConfigData from '../../channels/etsy-config.json'
import * as etsyEditorData from '../../channels/etsy-editor.json'
import * as facebookConfigData from '../../channels/facebook-config.json'
import * as facebookEditorData from '../../channels/facebook-editor.json'
import * as googleConfigData from '../../channels/google-config.json'
import * as googleEditorData from '../../channels/google-editor.json'
import * as magentoConfigData from '../../channels/magento-config.json'
import * as magentoEditorData from '../../channels/magento-editor.json'
import * as magentotwoConfigData from '../../channels/magentotwo-config.json'
import * as magentotwoEditorData from '../../channels/magentotwo-editor.json'
import * as mivaConfigData from '../../channels/miva-config.json'
import * as mivaEditorData from '../../channels/miva-editor.json'
// import * as shipstationConfigData from '../../channels/shipstation-config.json'
// import * as shipstationEditorData from '../../channels/shipstation-editor.json'
import * as shopifyConfigData from '../../channels/shopify-config.json'
import * as shopifyEditorData from '../../channels/shopify-editor.json'
import * as skuvaultConfigData from '../../channels/skuvault-config.json'
import * as skuvaultEditorData from '../../channels/skuvault-editor.json'
import * as walmartConfigData from '../../channels/walmart-config.json'
import * as walmartEditorData from '../../channels/walmart-editor.json'

export const amazonLegacy = 'amazon'
export const amazonNew = 'amzn'
export const amazonSlugs = [amazonLegacy, amazonNew]

/**
 * For 'amazon' and 'amzn' returns true
 * @param {String} slug
 */
export const isAmazon = slug => amazonSlugs.includes(slug)

// Place channel slugs here in the order they should appear in the API
export const slugSortOrder = [
  ...amazonSlugs,
  'ebay',
  'etsy',
  'facebook',
  'google',
  'walmart',
  'bigcommerce',
  'shopify',
  'magento',
  'magentotwo',
  'miva',
  'skuvault'
].reverse()

export function channelSortCompare ({ slug: a }, { slug: b }) {
  const ia = slugSortOrder.indexOf(a)
  const ib = slugSortOrder.indexOf(b)
  return ib - ia
}

export const channelsToFilterByOnReports = [
  'ebay',
  'amzn',
  'amazon',
  'shopify',
  'bigcommerce',
  'etsy',
  'magento',
  'magentotwo',
  'walmart',
  'facebook',
  'google',
  'miva'
]

export const channelKeyToFilterOnReports = {
  ebay: 'id',
  amzn: 'sku',
  amazon: 'sku',
  shopify: 'sku',
  walmart: 'sku',
  facebook: 'sku',
  google: 'sku',
  magento: 'sku',
  magentotwo: 'sku',
  bigcommerce: 'listing_id',
  etsy: 'listing_id',
  miva: 'product_id'
}

export const channelsAndTables = [
  // slug*sd_plugin{n}*n based on sd_items_base ER Diagram
  'ebay*sd_plugin0*0',
  'amzn*sd_plugin0*0',
  'amazon*sd_plugin0*0',
  'bigcommerce*sd_plugin1*1',
  'etsy*sd_plugin1*1',
  'magento*sd_plugin1*1',
  'magentotwo*sd_plugin1*1',
  'walmart*sd_plugin2*2',
  'miva*sd_plugin2*2',
  'shopify*sd_plugin3*3',
  'google*sd_plugin3*3',
  'facebook*sd_plugin3*3'
]

export const configData = {
  bigcommerce: bigcommerceConfigData,
  etsy: etsyConfigData,
  facebook: facebookConfigData,
  google: googleConfigData,
  magento: magentoConfigData,
  magentotwo: magentotwoConfigData,
  shopify: shopifyConfigData,
  skuvault: skuvaultConfigData,
  walmart: walmartConfigData,
  amazon: amazonConfigData,
  amzn: amazonConfigData,
  ebay: ebayConfigData,
  miva: mivaConfigData
}

export const editorData = {
  bigcommerce: bigcommerceEditorData,
  etsy: etsyEditorData,
  facebook: facebookEditorData,
  google: googleEditorData,
  magento: magentoEditorData,
  magentotwo: magentotwoEditorData,
  shopify: shopifyEditorData,
  skuvault: skuvaultEditorData,
  walmart: walmartEditorData,
  miva: mivaEditorData
}

// This array should be updated each time a new channel is migrated to the new UI
export const channelsWithNewUI = ['facebook', 'google', 'bigcommerce', 'shopify', 'ebay', 'walmart', 'etsy', 'miva', ...amazonSlugs]

// Validates if the String it receives is a valid URL
export const isValidURL = (str) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator
  return !!pattern.test(str)
}

export const correctSettingValue = (type, value) => {
  if (!value) return ''
  if (type === 'SELECT_MULTI' || type === 'MULTI_SELECT') {
    return ((Array.isArray(value) && value.length === 0) ? [''] : value)
  }
  if (type === 'MAPPING') {
    if (value[0] === '') {
      return ''
    } else {
      try {
        return JSON.parse(value[0])
      } catch (error) {
        throw new Error('Error parsing your Default Ship Mapping values')
      }
    }
  }
  return value[0]
}
