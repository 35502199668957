import React from 'react'
import { Link } from 'react-router-dom'
import { UncontrolledAlert } from 'reactstrap'

export const Notification = ({ message, action, extraMessage, color = 'warning', showBullet = false, children }) => {
  return (
    <UncontrolledAlert color={color} className='notification text-center my-2 d-flex flex-wrap align-items-baseline w-100 justify-content-center'>
      {children || (
        <>
          <p>
            {message}
            {action && <Link to={action.link} className='ml-1'>{action.title}</Link>}
          </p>

          {showBullet && <p className='bullet'> • </p>}

          <p className='extra-info'>
            {extraMessage}
          </p>
        </>)}

    </UncontrolledAlert>
  )
}
