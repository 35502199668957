import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { ActionButton } from '../../../components'
import { GET_ACTION_URL } from '../commonQueries'
import { useStores } from '../../../stores/RootStore'

export const ActionType = ({ data }) => {
  const { stores: { auth } } = useStores()
  const isPlatformUser = auth?.user?.sudoer

  const { item, instanceNumber, slug } = data || {}
  const { name: actionName, label: actionLabel } = item || {}

  const { loading, error, data: actionData } = useQuery(GET_ACTION_URL, { variables: { slug, instanceNumber, actionName } })
  const { ChannelInstance } = actionData || {}
  const { actionUrl } = ChannelInstance || {}

  const message = () => {
    if (loading || actionUrl) return actionLabel
    if (error || !actionUrl) return 'No action available'
  }

  return (
    <>
      <ActionButton
        tag='a'
        download
        color='link'
        href={actionUrl}
        loading={loading}
        className='pl-0 text-left'
      >
        {message()}
      </ActionButton>
      {isPlatformUser && <small className='d-block w-100 text-muted'>Please use the legacy sudo UI to download the file.</small>}
    </>
  )
}
