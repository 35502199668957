import React, { useState } from 'react'
import { Card, CardBody } from 'reactstrap'
import { SearchTipsModal } from '../../../components'
import { FaExternalLinkAlt as ExternalLink } from 'react-icons/all'

export const ReadTheDocs = () => {
  const [searchTipsOpen, setSearchTipsOpen] = useState(false)
  const toggleSearchTips = () => setSearchTipsOpen(prev => !prev)

  return (
    <>
      <Card style={{ minHeight: '217px' }}>
        <CardBody>
          <h5 className='mb-3'>Useful Links</h5>
          <ul className='pl-3'>
            <li className='mb-1' onClick={toggleSearchTips}>
              <span style={{ color: '#346CB0', cursor: 'pointer' }}>
                Search tips
              </span>
            </li>
            <li className='mb-1'>
              <a href='https://app.suredone.com/search-instructions' target='_blank' rel='noopener noreferrer'>
                Read the docs <ExternalLink size={10} />
              </a>
            </li>
            <li className='mb-1'>
              <a href='https://support.suredone.com/support/solutions/articles/1000297038-searching-suredone' target='_blank' rel='noopener noreferrer'>
                Searching SureDone <ExternalLink size={10} />
              </a>
            </li>
          </ul>
        </CardBody>
      </Card>
      <SearchTipsModal toggleSearchTips={toggleSearchTips} searchTipsOpen={searchTipsOpen} />
    </>
  )
}
