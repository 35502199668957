import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { useStores } from '../../stores/RootStore'
import { FaTrash as DeleteIcon } from 'react-icons/fa'
import { DELETE_CHANNEL_INSTANCE } from './commonQueries'
import { ActionButton, DisclaimerModal } from '../../components'
import { Alert, Spinner } from 'reactstrap'

export const DeleteChannel = ({ slug, instanceNumber }) => {
  const history = useHistory()
  const { stores: { auth } } = useStores()
  const { user } = auth
  const [modal, setModal] = useState(false)
  const toggleModal = () => setModal(!modal)

  const [deleteChannel, { loading, error }] = useMutation(DELETE_CHANNEL_INSTANCE, {
    onCompleted: () => {
      auth.refresh()
      history.push('/dashboard')
    }
  })

  const isPlatformUser = user?.sudoer
  const channelInstance = `${slug.charAt(0).toUpperCase() + slug.slice(1)} ${instanceNumber || ''}`
  const modalContent = (
    <>
      {isPlatformUser && <p>As a sudo user ({user?.sudoer?.email}) <b className='mark'>you are about to delete {channelInstance} from {user?.username}'s account</b>.</p>}
      <p>Are you sure? <b>This action cannot be undone.</b> All values related to this channel will be deleted.</p>
    </>
  )

  const handleDelete = () => {
    deleteChannel({ variables: { slug, instanceNumber } })
  }

  // Channel deletion does not work for eBay main (instance 0) and storefront
  if (slug === 'storefront' || (slug === 'ebay' && instanceNumber === 0)) {
    return null
  }

  return (
    <>
      {error && <Alert color='danger' className='mt-3'>{error?.message}</Alert>}

      <ActionButton
        outline
        color='danger'
        disabled={loading}
        onClick={() => toggleModal()}
        icon={loading ? <Spinner size='sm' /> : <DeleteIcon className='m-0' />}
      >
        <span className='ml-2'>Delete {channelInstance}</span>
      </ActionButton>

      <DisclaimerModal
        isOpen={modal}
        color='danger'
        content={modalContent}
        onAccept={handleDelete}
        toggleModal={toggleModal}
        actionLabel='Delete channel'
        header={`${channelInstance} Deletion`}
      />
    </>
  )
}
