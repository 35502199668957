import React from 'react'
import { ActionButton } from '../../../components'

export const TypeFilters = ({ typeFilter, setTypeFilter }) => {
  return (
    <>
      <ActionButton
        color='link'
        onClick={() => setTypeFilter('all')}
        className={typeFilter === 'all' ? 'focus' : ''}
      >
        All results
      </ActionButton>
      <ActionButton
        color='link'
        onClick={() => setTypeFilter('error')}
        className={typeFilter === 'error' ? 'focus' : ''}
      >
        Only Errors
      </ActionButton>
      <ActionButton
        color='link'
        onClick={() => setTypeFilter('warning')}
        className={typeFilter === 'warning' ? 'focus' : ''}
      >
        Only Warnings
      </ActionButton>
    </>
  )
}
