import React, { useState } from 'react'
import Debug from 'debug'
import { useHistory } from 'react-router-dom'
import { SaveButton } from '../../components'
import { Form, Field } from 'react-final-form'
import { useQualifications } from '../../hooks'
import { useMutation } from '@apollo/react-hooks'
import { CalendlyEventListener } from 'react-calendly'
import { LoggedOutPage } from '../../components/LoggedOutPage'
import { Card, CardBody, FormGroup, FormFeedback, Label, Input, Alert } from 'reactstrap'
import { SET_NEXT_HOUR_CALL, UPDATE_QUALIFICATIONS } from '../Qualifications/commonQueries'
const debug = Debug('sd:Talk')

const scrollToTop = () => window.scrollTo(0, 0)

export const Talk = () => {
  const { storedValue, setValue, cleanCurrentChannels, cleanWantedChannels } = useQualifications()
  const { email, firstName, lastName, phoneNumber } = storedValue
  const history = useHistory()
  const [showCalendly, setShowCalendly] = useState(false)

  const baseUrl = 'https://calendly.com/suredone/demo-call'
  const calendlyParams = `${email ? `&email=${email.replace('+', '%2B')}` : ''}${firstName ? `&first_name=${firstName}` : ''}${lastName ? `&last_name=${lastName}` : ''}${phoneNumber ? `&a2=${phoneNumber}` : ''}&a3=Demonstration%20Call`

  const [setNextHourCall, { data, loading, called }] = useMutation(SET_NEXT_HOUR_CALL, {
    onCompleted: () => setShowCalendly(true),
    onError: error => debug({ error })
  })

  const [updateQualifications] = useMutation(UPDATE_QUALIFICATIONS, {
    onCompleted: () => history.push('/register'),
    onError: error => debug({ error })
  })

  const handleSubmit = values => {
    const { phone, email } = values
    setValue(storedValue => ({
      ...storedValue,
      email,
      phoneNumber: phone
    }))
    const qualifications = {
      ...storedValue,
      currentChannels: cleanCurrentChannels(storedValue),
      wantedChannels: cleanWantedChannels(storedValue),
      email,
      phoneNumber: phone
    }
    setNextHourCall({ variables: { qualifications } })
  }

  // Executed when the demo schedule was confirmed
  const handleEventScheduled = e => {
    const { data } = e || {}
    const { payload } = data || {}
    scrollToTop()
    // Saves the data on mongo
    updateQualifications({
      variables: {
        qualifications: {
          email,
          calendlyData: payload
        }
      }
    })
  }

  const formContent = ({ handleSubmit, hasValidationErrors }) => {
    return (
      <form className='form' onSubmit={handleSubmit}>
        <p>
          Please provide us with the following information and we will contact you as soon as possible.
        </p>

        <FormGroup>
          <div className='form-label-group'>
            <Field
              name='email'
              validate={e => !e && 'Required'}
            >
              {({ input, meta }) => (
                <>
                  <Input {...input} id='email' type='text' xvalid={meta.valid} invalid={!meta.pristine && meta.touched && meta.invalid} placeholder='Email' />
                  <Label for='name'>Email</Label>
                  {meta.error && meta.touched && <FormFeedback className='d-block mb-3'>{meta.error}</FormFeedback>}
                </>
              )}
            </Field>
          </div>
        </FormGroup>

        <FormGroup>
          <div className='form-label-group'>
            <Field
              name='phone'
              validate={e => !e && 'Required'}
            >
              {({ input, meta }) => (
                <>
                  <Input {...input} id='phone' type='text' xvalid={meta.valid} invalid={!meta.pristine && meta.touched && meta.invalid} placeholder='Phone' />
                  <Label for='phone'>Phone</Label>
                  {meta.error && meta.touched && <FormFeedback className='d-block mb-3'>{meta.error}</FormFeedback>}
                </>
              )}
            </Field>
          </div>
        </FormGroup>

        <SaveButton
          type='submit'
          color='primary'
          loading={loading}
          success={called && data}
          disabled={hasValidationErrors || loading || called}
        />
      </form>
    )
  }

  return (
    <LoggedOutPage
      title="Let's talk"
    >
      <Card className='mb-5'>
        <CardBody>
          {!showCalendly &&
            <Form
              render={formContent}
              onSubmit={handleSubmit}
              initialValues={{ email }}
            />}

          {showCalendly && (
            <>
              <Alert color='success'>
                <h4 className='alert-heading'>
                  Your data was saved!
                </h4>
                <p className='mb-1'>
                  We're going to call you in the next hour, but just in case we don't connect please schedule a convenient time for a demonstration.
                </p>
              </Alert>
              <CalendlyEventListener onEventScheduled={e => handleEventScheduled(e)}>
                <iframe
                  width='100%'
                  height='885px'
                  frameBorder='0'
                  title='Calendly'
                  src={`${baseUrl}?embed_domain=''&embed_type=Inline${calendlyParams}`}
                />
              </CalendlyEventListener>
            </>
          )}
        </CardBody>
      </Card>
    </LoggedOutPage>
  )
}
