import React, { useMemo, useState } from 'react'
import { DetailedLogRow } from './'
import { ActionButton } from '../../components'
import { useStores } from '../../stores/RootStore'
import { usePagination, useColumnOrder, useTable, useExpanded, useSortBy } from 'react-table'
import { allColumnsIds, columnTableOrder, initialHiddenColumns, columnsToDelete, cellFormatter, initialColumnsShown } from './utils'
import { Button, Col, Input, Label, Card, CardBody, Pagination, PaginationItem, PaginationLink, Row, Table as RTable } from 'reactstrap'
import { FaCog as Settings, FaAngleLeft as Prev, FaAngleRight as Next, FaAngleDoubleLeft as DPrev, FaAngleDoubleRight as DNext, FaCaretUp as CaretUp, FaCaretDown as CaretDown, FaSyncAlt as Refresh } from 'react-icons/fa'

export const LogsTable = ({ logs, refreshLogs, logsCount, hasMore, targetOrganizationId, activeColumns, history, handleAddFilter }) => {
  const { stores: { auth } } = useStores()
  const isPlatformUser = auth.permissions.hasPlatformRole()
  const actualSearch = history.location.search
  const activeColumnsAsArray = activeColumns?.split(',')
  const initialColumnsFromURL = allColumnsIds?.filter(i => !activeColumnsAsArray?.includes(i))
  const splittedColumns = actualSearch?.split('columns=')[1]
  const alreadyHasColumns = !!splittedColumns
  const decodedURL = actualSearch && decodeURIComponent(actualSearch)
  const parsedFilters = decodedURL && JSON.parse(decodedURL.split('?filters=')[1].split('&columns=')[0])

  // State to show, or not, the checkboxes to hide columns
  const [showTableSettings, setShowTableSettings] = useState(false)

  // States to handle lazy pagination
  const [pageSize, setPageSize] = useState(parsedFilters?.size || 20)
  const [pageIndex, setPageIndex] = useState(parsedFilters?.page || 0)

  const addColumnToURL = (id, actualSearch, alreadyHasColumns) => {
    if (alreadyHasColumns) return `${actualSearch},${id}`
    return `${actualSearch}&columns=${id}`
  }

  const removeColumnFromURL = (id, actualSearch, alreadyHasColumns) => {
    const filteredColumns = activeColumnsAsArray?.filter(i => i !== id)
    const onlyFilters = actualSearch?.split('&columns=')[0]
    return `${onlyFilters}&columns=${filteredColumns}`
  }

  const handleClickHideColumn = (id, checked) => {
    let newSearch
    if (checked) newSearch = addColumnToURL(id, actualSearch, alreadyHasColumns)
    if (!checked) newSearch = removeColumnFromURL(id, actualSearch)
    history.push({ search: newSearch })
  }

  /**
   * Update the page or size on the URL to make a new query
   * @param {'size'|'page'} type
   * @param {number} val
   */
  const updatePageOrSizeOnURL = (type, val) => {
    parsedFilters[type] = val
    const stringifiedFilters = JSON.stringify(parsedFilters)
    const defaultColumns = initialColumnsShown?.join(',')
    const newSearch = `filters=${stringifiedFilters}&columns=${splittedColumns || defaultColumns}`
    history.push({ search: newSearch })
  }

  const updatePageSize = s => {
    setPageSize(s)
    updatePageOrSizeOnURL('size', s)
    // Goes to page 0 when update page size
    setPageIndex(0)
    updatePageOrSizeOnURL('page', 0)
  }

  const updatePageIndex = p => {
    setPageIndex(p)
    updatePageOrSizeOnURL('page', p)
  }

  // This function constructs the table settings, the table and pagination
  const Table = ({ tableInfo, handleAddFilter }) => {
    const {
      page,
      prepareRow,
      headerGroups,
      getTableProps,
      visibleColumns,
      getTableBodyProps
      // setColumnOrder, TODO: reoder the columns with this fuction and state.columnOrder
      // state: { columnOrder }
    } = tableInfo

    // Helpers for lazy pagination
    const pageCount = Math.ceil(logsCount / pageSize) || 1 // Amount of pages
    const canPreviousPage = pageIndex > 0
    const canNextPage = (pageIndex + 1) < pageCount
    const gotoPage = p => updatePageIndex(p)

    return (
      <>
        {/* Table settings */}
        <Row className='justify-content-between mt-1 mb-3'>
          <Col xs='auto' className='d-flex align-items-center'>
            <span className='mr-2'>Page <strong>{pageIndex + 1} of {pageCount}{hasMore ? '+' : ''}</strong></span>
          </Col>
          {parsedFilters && (
            <Col xs='auto' className='d-flex'>
              <Input
                type='select'
                value={pageSize}
                className='mr-2'
                onChange={e => { updatePageSize(Number(e.target.value)) }}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </Input>
              <ActionButton
                outline
                color='primary'
                className='mr-2'
                onClick={() => refreshLogs()}
                icon={<Refresh style={{ margin: '-2px 0 0' }} />}
              />
              <ActionButton
                outline
                color='primary'
                icon={<Settings style={{ margin: '-2px 0 0' }} />}
                onClick={() => setShowTableSettings(prev => !prev)}
              />
            </Col>
          )}
        </Row>

        {/* Table */}
        <RTable
          striped
          bordered
          responsive
          {...getTableProps()}
          style={{ borderCollapse: 'collapse', border: '1px solid #ecedf1' }}
          className='table dt-responsive nowrap w-100 dataTable dtr-inline'
        >

          {/* Headers */}
          <thead>
            {headerGroups.map((headerGroup, idx) => (
              <tr key={idx} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, idx) => (
                  <th key={idx} {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span>{column.isSorted ? (column.isSortedDesc ? <CaretDown /> : <CaretUp />) : ''}</span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {/* Content */}
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <React.Fragment key={`rt-${i}`}>
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => cellFormatter({ cell, handleAddFilter, targetOrganizationId }))}
                  </tr>
                  {row.isExpanded
                    ? (
                      <tr>
                        <td colSpan={visibleColumns.length}>
                          <DetailedLogRow row={row} />
                        </td>
                      </tr>)
                    : null}
                </React.Fragment>
              )
            })}
          </tbody>
        </RTable>

        {/* Pagination */}
        {pageCount > 1 &&
          <Pagination className='row justify-content-center'>
            {pageCount > 3 && (
              <>
                <PaginationItem disabled={!canPreviousPage}><PaginationLink onClick={() => gotoPage(0)}><DPrev className='m-0' /></PaginationLink></PaginationItem>
                <PaginationItem disabled={!canPreviousPage}><PaginationLink onClick={() => gotoPage(pageIndex - 1)}><Prev className='m-0' /></PaginationLink></PaginationItem>
              </>
            )}
            {[-2, -1, 0, 1, 2]
              .filter(index => pageIndex + 1 + index > 0 && pageIndex + 1 + index <= pageCount)
              .map(index =>
                <PaginationItem key={pageIndex + 1 + index} active={index === 0}>
                  <PaginationLink onClick={() => gotoPage(pageIndex + index)}>{pageIndex + 1 + index}</PaginationLink>
                </PaginationItem>
              )}
            {pageCount > 3 && (
              <>
                <PaginationItem disabled={!canNextPage}><PaginationLink onClick={() => gotoPage(pageIndex + 1)}><Next className='m-0' /></PaginationLink></PaginationItem>
                <PaginationItem disabled={!canNextPage}><PaginationLink onClick={() => gotoPage(pageCount - 1)}><DNext className='m-0' /></PaginationLink></PaginationItem>
              </>
            )}
          </Pagination>}
      </>
    )
  }

  const aux = (val) => {
    return typeof val === 'object' ? <pre className='mb-0'>{`${JSON.stringify(val || {}, null, 2)}`}</pre> : val
  }

  // The data to show inside react-table
  const data = useMemo(
    () => logs?.map(i => Object.fromEntries(Object.keys(i).map(key => ([key, aux(i[key])])))) || [],
    [logs]
  )

  // The columns for react-table
  const columns = useMemo(
    () => [
      {
        // Make an expander cell
        Header: () => null, // No header
        id: 'expander', // It needs an ID
        width: 30,
        Cell: ({ row }) => (
          <Button className='btn-sm' id='expander' {...row.getToggleRowExpandedProps()}>
            {row.isExpanded
              ? <Next size={16} className='m-0' style={{ transform: 'rotate(90deg)' }} />
              : <Next size={16} className='m-0' />}
          </Button>
        )
      },
      ...logs?.map(i => Object.keys(i)?.map(j => ({ Header: j, accessor: j })))[0].filter(i => !columnsToDelete(isPlatformUser).includes(i.accessor)) || []
    ],
    // eslint-disable-next-line
    [logs]
  )

  // Creates a table instance with columns, data and initialState
  const { allColumns, ...rest } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        columnOrder: columnTableOrder,
        hiddenColumns: activeColumnsAsArray ? initialColumnsFromURL : initialHiddenColumns,
        pageSize
      }
    },
    useSortBy,
    useExpanded,
    usePagination,
    useColumnOrder
  )

  return (
    <>

      {/* Checkboxes to hide columns */}
      {showTableSettings && (
        <Card>
          <CardBody className='m-0'>
            <h6 className='mb-2'>Columns to show</h6>
            <div className='d-flex flex-wrap'>
              {allColumns.map(column => (
                <div key={column.id} className={column.id}>
                  <Label check for={column.id} className='custom-control custom-checkbox mb-0 mr-3' style={{ cursor: 'pointer' }}>
                    <Input
                      id={column.id}
                      type='checkbox'
                      className='custom-control-input'
                      {...column.getToggleHiddenProps()}
                      onClick={e => handleClickHideColumn(e.target.id, e.target.checked)}
                    />
                    <span className='custom-control-label d-flex text-left'>
                      {column.id}
                    </span>
                  </Label>
                </div>
              ))}
            </div>
          </CardBody>
        </Card>
      )}

      {/* The table */}
      <Card>
        <CardBody className='m-0'>
          <Table tableInfo={rest} handleAddFilter={handleAddFilter} />
        </CardBody>
      </Card>
    </>
  )
}
