import React from 'react'
import { Row, Col, Alert } from 'reactstrap'
import { getLink, isNewUIHandlingAddChannel } from './utils'
import { ActionButton, ErrorAlert } from '../../components'
import ReactMarkdown from 'react-markdown'
import {
  FaPlus as Plus
} from 'react-icons/fa'

const ChannelContent = ({ slug, errorMutation, readme, readmeImages }) => {
  return (
    isNewUIHandlingAddChannel(slug)
      ? (
        <>
          <Row>
            <Col className='mb-3' />
          </Row>
          {errorMutation && <ErrorAlert error='An error ocurred. Please try again later.' />}
          <Row>
            <Col>
              <ReactMarkdown>
                {readme}
              </ReactMarkdown>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='images-carousel mb-3'>
                {readmeImages && readmeImages.map(({ src, alt }) =>
                  <img src={src} alt={alt} key={src} />
                )}
              </div>
            </Col>
          </Row>
        </>)
      : (
        <Alert
          color='info'
          className='d-flex justify-content-between'
        >
          <span>
            This channel is handled by legacy app. Please go there to add it.
          </span>
          <a href={getLink(slug, 0)}>
            <ActionButton
              color='primary'
              icon={<Plus />}
            >
              Take me there
            </ActionButton>
          </a>
        </Alert>)
  )
}

export { ChannelContent }
