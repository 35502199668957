import React, { useState } from 'react'
import { LoggedOutPage } from '../../components/LoggedOutPage'
import { Button, Card, CardHeader, CardText, UncontrolledCollapse, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col } from 'reactstrap'
import { news, finalInfo } from './News'
import './ProductUpdates.css'

const ProductUpdates = () => {
  const [data, setData] = useState(news)
  const [currentOrder, setCurrentOrder] = useState('Newest')

  const changeOrder = (order) => {
    const sorted = data.sort(function (a, b) {
      return order === 'Newest'
        ? new Date(b.date) - new Date(a.date)
        : new Date(a.date) - new Date(b.date)
    })
    setData(sorted)
    setCurrentOrder(order)
  }

  return (
    <LoggedOutPage
      title='Product Updates'
      className='product-updates'
    >
      <UncontrolledDropdown className='mb-3'>
        <DropdownToggle caret>{currentOrder}</DropdownToggle>
        <DropdownMenu className='dropdown-menu-left stop-propagation'>
          <div className='dropdown-arrow' />
          <DropdownItem onClick={() => changeOrder('Newest')}>Newest</DropdownItem>
          <DropdownItem onClick={() => changeOrder('Oldest')}>Oldest</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      {data && data.map(({ id, date, title, content }) =>
        <Card key={id}>
          <CardHeader id={id.replace('.', '-')} className='has-arrow'>
            {title}
            <CardText className='text-muted' style={{ fontWeight: 'normal' }}>
              <span className='text-muted'>{id}</span> | {date}
            </CardText>
          </CardHeader>
          <UncontrolledCollapse toggler={id.replace('.', '-')}>
            <div className='p-4'>
              {content}
            </div>
          </UncontrolledCollapse>
        </Card>
      )}
      {finalInfo &&
        <Row>
          <Col className='text-center'>
            <Button size='lg' color='link' href={finalInfo.link} tag='a'>{finalInfo.title}</Button>
          </Col>
        </Row>}
    </LoggedOutPage>
  )
}

export { ProductUpdates }
