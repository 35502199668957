import gql from 'graphql-tag'

const fullLogs =
  `
  id
  type
  role
  retry
  jobId
  result
  action
  userId
  method
  s3Path
  release
  message
  warnings
  context
  request
  instance
  response
  timestamp
  createdAt
  fieldData
  accountId
  channelId
  operation
  requestId
  serverName
  identifier
  integration
  releaseType
  truncatedFields
  errors
`

export const GET_STATUSES = gql`
  query getLogStatusesByFilter($filter: logStatusesFilters, $targetOrganizationId: String) {
    getLogStatusesByFilter(filter: $filter, targetOrganizationId: $targetOrganizationId) {
      count
      statuses {
      id
      expiresAt
      lastUpdated
      _id {
        uid
        type
        context
        instance
        identifier
      }
      lastError {
        _id
        type
        result
        context
        instance
        created_at
      }
      lastLog {
        _id
        type
        result
        context
        instance
        created_at
      }
      lastErrorFullLog {
        ${fullLogs}
      }
      lastStatusFullLog {
        ${fullLogs}
      }
      }
    }
  }
`
