import gql from 'graphql-tag'

export const GET_SCHEDULED_EVENT = gql`
  query GetScheduledEvent($eventId: String!, $inviteeId: String!) {
    GetScheduledEvent(eventId: $eventId, inviteeId: $inviteeId){
      id
      eventData
      inviteeData
    }
  }
`
