/* eslint-disable camelcase */
import { useEffect, useCallback } from 'react'
import { useRouteMatch } from 'react-router'
import { useQueryParams, useLocalStorage, useCookies } from '.'
import { filterObject, isAllowedQualificationKeys } from '../components/utils'

/**
 * Returns the localStorage for qualifications, the setter and a cleaner
 * Priority of source of truth is:
 * 1. localStorage
 * 2. route params (ex: suredone.com/bigcommerce/register?...) source = bigcommerce
 * 2. query params
 * 3. cookies
 *
 * Usage :
 *   const { storedValue, setValue, clearStorage } = useQualifications()
 *   setValue((storedValue) => ({ ...storedValue, utm_source: google }))
 * @returns { storedValue: {
 * email: String
 * firstName: String
 * lastName: String
 * userId: String
 * utm_source: String
 * utm_medium: String
 * utm_campaign: String
 * utm_campaignid: String
 * utm_adgroupid: String
 * utm_keyword: String
 * gclid: String
 * MatchType: String
 * Distribution: String
 * AdID: String
 * Placement: String
 * adp: String
 * affid: String,
 * isAFit: Boolean,
 * currentChannels: [String],
 * wantedChannels: [String],
 * actionsToDo: [String] ,
 * skuCount: String,
 * ordersPerMonth: String,
 * gmvPerMonth: String,
 * primaryCategory: String,
 * source: String,
 * signed_payload: String,
 * plan: String,
 * inPersonDemo: Boolean,
 * totalPoints: Number,
 * company
 * }, setValue, clearStorage }
 */

// initializationCallback to avoid issues with initial useEffect race condition
const useQualifications = (initializationCallback) => {
  const paramObject = useQueryParams()
  const { params } = useRouteMatch()
  const { source } = params
  const {
    email,
    firstName,
    lastName,
    utm_source,
    utm_medium,
    utm_campaign,
    utm_campaignid,
    utm_adgroupid,
    utm_keyword,
    gclid,
    MatchType,
    Distribution,
    AdID,
    Placement,
    adp,
    affid,
    company,
    plan,
    signed_payload
  } = paramObject
  const [cookies] = useCookies()
  const [storedValue, setValue, clearStorageValue] = useLocalStorage('qualifications', {
    ...filterObject(cookies, isAllowedQualificationKeys),
    ...filterObject(paramObject, isAllowedQualificationKeys),
    ...filterObject(params, isAllowedQualificationKeys)
  })

  const clearStorage = useCallback(
    () => {
      clearStorageValue('qualifications')
    },
    [clearStorageValue]
  )

  const trackEmail = location => {
    if (window.analytics) {
      const currentEvent = 'Capture Email'
      const currentHistory = window?.analyticsEventHistory || []
      const newHistory = [currentEvent, ...currentHistory].slice(0, 10)
      window.analyticsEventHistory = newHistory
      const fullFilled = {
        location,
        pathHistory: window?.analyticsPathHistory,
        priorPath: window?.analyticsPathHistory?.[0],
        eventHistory: newHistory,
        priorEvent: newHistory?.[1]
      }
      window.analytics.track(currentEvent, fullFilled)
    }
  }

  const cleanCurrentChannels = storedValue => storedValue?.currentChannels?.map(i => i?.name)
  const cleanWantedChannels = storedValue => storedValue?.wantedChannels?.map(i => i?.name)

  useEffect(() => {
    // For each variable check if is already on the localStorage
    // If is there, then do nothing
    // If isn't, check query params.
    // If it's there set it on localStorage
    // If it isn't check cookies. If it's there set it on localStorage
    const newStoredValue = {}
    if (!storedValue.email) {
      if (email) {
        trackEmail('Email in query params')
        newStoredValue.email = email
      }
      if (!email && cookies.email) {
        trackEmail('Email inside cookies')
        newStoredValue.email = cookies.email
      }
    }
    if (!storedValue.firstName) {
      if (firstName) newStoredValue.firstName = firstName
      if (!firstName && cookies.firstName) newStoredValue.firstName = cookies.firstName
    }
    if (!storedValue.lastName) {
      if (lastName) newStoredValue.lastName = lastName
      if (!lastName && cookies.lastName) newStoredValue.lastName = cookies.lastName
    }
    if (!storedValue.utm_source) {
      if (utm_source) newStoredValue.utm_source = utm_source
      if (!utm_source && cookies.utm_source) newStoredValue.utm_source = cookies.utm_source
    }
    if (!storedValue.utm_medium) {
      if (utm_medium) newStoredValue.utm_medium = utm_medium
      if (!utm_medium && cookies.utm_medium) newStoredValue.utm_medium = cookies.utm_medium
    }
    if (!storedValue.utm_campaign) {
      if (utm_campaign) newStoredValue.utm_campaign = utm_campaign
      if (!utm_campaign && cookies.utm_campaign) newStoredValue.utm_campaign = cookies.utm_campaign
    }
    if (!storedValue.utm_campaignid) {
      if (utm_campaignid) newStoredValue.utm_campaignid = utm_campaignid
      if (!utm_campaignid && cookies.utm_campaignid) newStoredValue.utm_campaignid = cookies.utm_campaignid
    }
    if (!storedValue.utm_adgroupid) {
      if (utm_adgroupid) newStoredValue.utm_adgroupid = utm_adgroupid
      if (!utm_adgroupid && cookies.utm_adgroupid) newStoredValue.utm_adgroupid = cookies.utm_adgroupid
    }
    if (!storedValue.utm_keyword) {
      if (utm_keyword) newStoredValue.utm_keyword = utm_keyword
      if (!utm_keyword && cookies.utm_keyword) newStoredValue.utm_keyword = cookies.utm_keyword
    }
    if (!storedValue.gclid) {
      if (gclid) newStoredValue.gclid = gclid
      if (!gclid && cookies.gclid) newStoredValue.gclid = cookies.gclid
    }
    if (!storedValue.MatchType) {
      if (MatchType) newStoredValue.MatchType = MatchType
      if (!MatchType && cookies.MatchType) newStoredValue.MatchType = cookies.MatchType
    }
    if (!storedValue.Distribution) {
      if (Distribution) newStoredValue.Distribution = Distribution
      if (!Distribution && cookies.Distribution) newStoredValue.Distribution = cookies.Distribution
    }
    if (!storedValue.AdID) {
      if (AdID) newStoredValue.AdID = AdID
      if (!AdID && cookies.AdID) newStoredValue.AdID = cookies.AdID
    }
    if (!storedValue.Placement) {
      if (Placement) newStoredValue.Placement = Placement
      if (!Placement && cookies.Placement) newStoredValue.Placement = cookies.Placement
    }
    if (!storedValue.adp) {
      if (adp) newStoredValue.adp = adp
      if (!adp && cookies.adp) newStoredValue.adp = cookies.adp
    }
    if (!storedValue.affid) {
      if (affid) newStoredValue.affid = affid
      if (!affid && cookies.affid) newStoredValue.affid = cookies.affid
    }
    if (!storedValue.company) {
      if (company) newStoredValue.company = company
      if (!company && cookies.company) newStoredValue.company = cookies.company
    }
    if (signed_payload) {
      const signedArray = paramObject.signed_payload.split('.')
      // eslint-disable-next-line
      const decoded = signedArray.map(encoded => atob(decodeURIComponent(encoded)))
      const { user } = (decoded && JSON.parse(decoded[0])) || {}
      if (user && user.email) {
        trackEmail('Email in signed payload')
        newStoredValue.email = user.email
      }
    }
    if (!storedValue.source) {
      if (source) newStoredValue.source = source
      if (!source && cookies.source) newStoredValue.source = cookies.source
    }
    if (!storedValue.plan) {
      if (plan) newStoredValue.plan = plan
      if (!plan && cookies.plan) newStoredValue.plan = cookies.plan
    }
    if (!storedValue.currentChannels) { newStoredValue.currentChannels = [] }
    if (!storedValue.wantedChannels) { newStoredValue.wantedChannels = [] }
    if (!storedValue.actionsToDo) { newStoredValue.actionsToDo = [] }
    if (!storedValue.isAFit) { newStoredValue.isAFit = false }

    if (!(Object.keys(newStoredValue).length === 0 && newStoredValue.constructor === Object)) {
      setValue((storedValue) => ({ ...storedValue, ...newStoredValue }))
    }
    if (initializationCallback) {
      initializationCallback({ ...storedValue, ...newStoredValue })
    }
    // eslint-disable-next-line
  }, [])

  return { storedValue, setValue, clearStorage, cleanCurrentChannels, cleanWantedChannels }
}

export default useQualifications
