import React, { useEffect } from 'react'
import * as Sentry from '@sentry/react'
import ReactDOMServer from 'react-dom/server'
import { ActionButton } from './ActionButton'
import { UncontrolledAlert } from 'reactstrap'
import { useStores } from '../stores/RootStore'

const debug = require('debug')('sd:ErrorAlert')

export const ANNOYING_HEADERS = [
  'Error: ',
  'GraphQL error: ',
  'Network error: ',
  'Network error: Response not successful: Received status code 500',
  'Network error: Unexpected token E in JSON at position 0'
]

export const REPLACE = [
  '',
  '',
  '',
  'Something went wrong. Please, refresh the page and try again.',
  'Server Error. Please, refresh the page and try again.'
]

// Function that removes all occurrences that are in ANNOYING_HEADERS
// To delete a new string from the error messages just add it to ANNOYING_HEADERS and add the replacement text at the same position
// eslint-disable-next-line
String.prototype.replaceArray = function (find, replace) {
  let replaceString = this
  for (let i = 0; i < find.length; i++) {
    replaceString = replaceString.replace(find[i], replace[i])
  }
  return replaceString
}

const parseError = e => {
  const error = e && e.toString()
  if (error) {
    if (error.includes('is not valid JSON')) {
      return error.split('"')?.[1] || 'Something went wrong. Please try again.'
    }
    return error.replaceArray(ANNOYING_HEADERS, REPLACE)
  }
  return e
}

export default function ErrorAlert (props) {
  const { error, children } = props
  const { stores: { auth } } = useStores()
  const { user } = auth || {}
  const { firstName, lastName, primaryEmail: email } = user || {}
  const name = `${firstName || ''} ${lastName || ''}`

  useEffect(() => {
    const errorText = parseError(error)
    const currentEvent = 'UI Error'
    const eventHistory = window?.analyticsEventHistory
    const newHistory = [currentEvent, ...eventHistory].slice(0, 10)
    window.analyticsEventHistory = newHistory
    if (window.analytics) {
      window.analytics.track(currentEvent, {
        error: errorText,
        children: children && ReactDOMServer.renderToString(children),
        pathHistory: window?.analyticsPathHistory,
        priorPath: window?.analyticsPathHistory?.[1], // special case using [1] instead of [0]
        eventHistory: newHistory,
        priorEvent: newHistory?.[1]
      })
    }
    Sentry.captureMessage(errorText)
  }, [error, children])

  if (!props.error && !props.children) return null
  debug('ErrorAlert error:', JSON.stringify(error, null, 4))
  const newProps = Object.assign({ color: 'danger' }, props)
  delete newProps.children
  delete newProps.error
  return (
    <UncontrolledAlert {...newProps}>
      {/* Use 'error' prop OR a children, not both */}
      {error
        ? parseError(error)
        : children}

      <ActionButton
        color='link'
        className='text-primary mt-n1 px-2'
        onClick={() => Sentry.showReportDialog({
          user: {
            name,
            email
          }
        })}
      >
        Give us feedback.
      </ActionButton>
    </UncontrolledAlert>
  )
}

export { ErrorAlert }
debug('loaded')
