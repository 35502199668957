import React, { useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useHotkeys } from 'react-hotkeys-hook'
import { useLocalStorage } from '../../hooks'
import { LAST_SEARCHES_KEY } from '../../scenes/Search/SearchBuilder'
import { Pill, makeSearch, maxHeightModifiers, searchboxSuggestions } from '..'
import { InputGroupAddon, InputGroupText, Input, Dropdown, DropdownMenu, DropdownItem } from 'reactstrap'
import { AiOutlineInfoCircle as Info, FaExternalLinkAlt as ExternalLink, FaSearch as Magnifier } from 'react-icons/all'

export const Searchbox = ({ handlers }) => {
  useHotkeys('/', () => inputRef.current.focus()) // Opens the searchbox when you type /
  const [lastSearches, setLastSearches] = useLocalStorage(LAST_SEARCHES_KEY) // To include the search to the localstorage

  const inputRef = useRef(null)
  const { searchboxOpen, typeOfSearch, setSearchboxOpen, toggleSearchbox, toggleSearchTips } = handlers

  const [searchState, setSearchState] = useState({
    userInput: '',
    activeOption: -1,
    filteredOptions: searchboxSuggestions[typeOfSearch]
  })

  const handleInputFocus = (e) => {
    setSearchboxOpen(true)

    if (!searchState.userInput) {
      setSearchState(prev => ({
        ...prev,
        filteredOptions: searchboxSuggestions[typeOfSearch]
      }))
    }
  }

  const handleInputChange = (e) => {
    const userInput = e.target.value

    if (userInput === '/') {
      setSearchState(prev => ({
        ...prev,
        userInput: '',
        activeOption: -1
      }))
      return
    }

    const filteredOptions = searchboxSuggestions[typeOfSearch]?.filter(i => i?.key?.toLowerCase()?.includes(userInput.toLowerCase()))

    if (userInput) {
      if (filteredOptions?.length) {
        setSearchboxOpen(true)
      } else {
        setSearchState(prev => ({
          ...prev,
          userInput,
          activeOption: 0,
          filteredOptions: [{ key: userInput, example: 'Show all results' }]
        }))
        return
      }
    }

    setSearchState(prev => ({
      ...prev,
      userInput,
      filteredOptions,
      activeOption: -1
    }))
  }

  const handleInputKeyDown = (e) => {
    const { activeOption, filteredOptions } = searchState || {}

    if (e.key === 'Enter') {
      const typedByUser = e.target.value
      const usingArrows = searchboxSuggestions[typeOfSearch]?.[searchState?.activeOption]?.key
      const userInput = typedByUser || usingArrows
      const filteredOptions = searchboxSuggestions[typeOfSearch]?.filter(i => i?.key?.toLowerCase()?.includes(userInput.toLowerCase()))

      setSearchState(prev => ({
        ...prev,
        activeOption: 0,
        filteredOptions,
        userInput: searchState.filteredOptions[activeOption]?.key
      }))

      setSearchboxOpen(false)
      addToLastSearches({ type: typeOfSearch, query: searchState.userInput })

      if (!filteredOptions?.length || (searchState.userInput && searchState.activeOption === 0)) {
        makeSearch({ type: typeOfSearch, query: searchState.userInput })
        setSearchState(prev => ({
          ...prev,
          userInput,
          activeOption: 0,
          filteredOptions: [{ key: userInput, example: 'Show all results' }]
        }))
      }
    } else if (e.key === 'ArrowUp') {
      if (activeOption === 0) { return }

      setSearchState(prev => ({
        ...prev,
        activeOption: prev.activeOption - 1
      }))
    } else if (e.key === 'ArrowDown') {
      if (activeOption === filteredOptions.length - 1) { return }

      setSearchState(prev => ({
        ...prev,
        activeOption: prev.activeOption + 1
      }))
    }
  }

  const handleSuggestionClick = (e, suggestion) => {
    inputRef?.current?.focus()
    setSearchState(prev => ({
      ...prev,
      activeOption: 0,
      filteredOptions: [],
      userInput: suggestion
    }))
  }

  const addToLastSearches = (searchToSave) => {
    if (!lastSearches) {
      setLastSearches([searchToSave])
    } else {
      setLastSearches(storedValue => ([searchToSave, ...storedValue]))
    }
  }

  return (
    <Dropdown inNavbar className='w-100' isOpen={searchboxOpen} toggle={toggleSearchbox}>
      <div className='top-bar-search'>
        <div className='input-group input-group-search'>
          <InputGroupAddon addonType='prepend' onClick={() => inputRef?.current?.focus()}>
            <InputGroupText>
              <Magnifier className='mr-0' />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            type='text'
            aria-label='Search'
            innerRef={inputRef}
            className='form-control'
            onFocus={handleInputFocus}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
            value={searchState?.userInput}
            placeholder={`Searching ${typeOfSearch}`}
          />
          <InputGroupAddon addonType='apend' onClick={() => inputRef?.current?.focus()}>
            <InputGroupText className='pl-0 pr-2'>
              <span className='content px-2'>/</span>
            </InputGroupText>
          </InputGroupAddon>
        </div>
        <DropdownMenu modifiers={maxHeightModifiers}>
          <DropdownItem header className='pb-3'>Search Suggestions</DropdownItem>
          <div className='options-wrapper'>
            {searchState?.filteredOptions?.map((item, idx) => {
              const { key, hint, example, explanation } = item

              return (
                <DropdownItem key={key} onClick={(e) => handleSuggestionClick(e, key)} className={idx === searchState?.activeOption ? 'active' : ''}>
                  <span className='d-flex align-items-baseline'><Pill>{key}</Pill> <span className='text-muted pl-1'>{hint}</span></span>
                  <div className='d-flex flex-wrap text-right pl-2'>
                    <span className='text-muted w-100'>{example}</span>
                    <small className='text-muted w-100 text-right'>{explanation}</small>
                  </div>
                </DropdownItem>
              )
            })}
          </div>

          <div className='options-footer'>
            <span onClick={toggleSearchTips}>
              <Info className='mr-0' size={15} /> Search tips
            </span>
            <Link to='/search-instructions' target='_blank' rel='noopener noreferrer'>
              Read the docs <ExternalLink size={10} />
            </Link>
          </div>
        </DropdownMenu>
      </div>
    </Dropdown>
  )
}
