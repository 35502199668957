import React, { useState } from 'react'
import Select from 'react-select'
import Highlighter from 'react-highlight-words'
import { AiOutlineInfoCircle as Info } from 'react-icons/ai'
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap'
import 'react-select/dist/react-select.css'

const customStyles = {
  fontWeight: 600,
  letterSpacing: '.3px'
}

const listAffectedCategories = ({ categories }) => categories?.join(', ')
const generateId = label => label.replace('@Unit of Measure', '').replaceAll(' ', '-')

export const WalmartFieldSummary = ({ field, searchTerms, handleChangedValue }) => {
  const { label, value, values: optionsToSelect } = field
  const { channelFieldName } = value || {}

  const [selectedOption, setSelectedOption] = useState({
    value: (channelFieldName || null),
    label: <strong style={customStyles}>{channelFieldName || null}</strong>
  })

  const options = (optionsToSelect || []).map(item => ({
    value: item,
    label: <strong style={customStyles}>{item}</strong>
  }))

  const handleChange = (event) => {
    setSelectedOption(event)
    handleChangedValue(event, field)
  }

  return (
    <div className='d-flex w-100 justify-content-between align-items-center flex-wrap'>
      <div className='col-12 col-sm-7 col-lg-8 px-0 pr-sm-3 pb-2 pb-sm-0'>
        <strong style={customStyles}>
          <Highlighter
            autoEscape
            className=''
            highlightTag='mark'
            searchWords={searchTerms || []}
            textToHighlight={label.replace('@Unit of Measure', '') || 'Unnamed field'}
          />
        </strong>

        <span id={generateId(label)}>
          <Info className='ml-1' size={15} />
        </span>

        <UncontrolledPopover placement='right' trigger='hover' target={generateId(label)}>
          <PopoverHeader>Editing this unit will affect all these categories:</PopoverHeader>
          <PopoverBody>{listAffectedCategories(field)}</PopoverBody>
        </UncontrolledPopover>
      </div>

      <div className='col-12 col-sm-5 col-lg-4 px-0'>
        <Select
          placeholder=''
          options={options}
          clearable={false}
          value={selectedOption}
          onChange={handleChange}
        />
      </div>
    </div>
  )
}
