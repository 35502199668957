/**
 * <Pill />
 *
 * This component is can be used in 4 different ways:
 * 1 Display a pill -> without functionality. <Pill>{content}</Pill>
 * 2 Clickeable     -> do something when click the pill. <Pill onClick={...} >
 * 3 Addable        -> will render a '+' and will do something when click the '+'. <Pill onAdd={...} >
 * 4 Closable       -> will render a '×' and will do something when click the 'x'. <Pill onClose={...} >
 *
 *
 * Usage:
 *  <Pill
 *    name              = String
 *    size              = String - sm || lg
 *    color             = String - bootstrap colors
 *    onAdd             = Function
 *    onClose           = Function
 *    onClick           = Function
 *    rounded           = Boolean
 *    disabled          = Boolean
 *    addable           = Boolean - true to render a '+'
 *    closable          = Boolean - true to render a '×'
 *    className         = String
 *  >
 *    {children}
 *  </Pill>
 */

import React from 'react'
import { Badge, Button } from 'reactstrap'
import './Pill.scss'

export const Pill = ({
  name,
  onAdd,
  onClose,
  onClick,
  rounded,
  disabled,
  children,
  className,
  size = 'sm',
  color = 'light',
  closable, addable
}) => {
  return (
    <Badge
      pill
      color={color}
      style={{
        cursor: onClick ? 'pointer' : 'auto',
        fontSize: size === 'lg' ? '100%' : '85%',
        borderRadius: rounded ? '100px' : '4px'
      }}
      className={`sd-pill p-0 ${className || ''} ${disabled ? 'text-muted' : ''}`}
    >
      <span
        onClick={onClick} style={{
          display: 'inline-block',
          verticalAlign: 'middle',
          padding: size === 'lg' ? '6px 6px 6px 8px' : '4px 4px 4px 6px'
        }}
      >
        {children}
      </span>

      {closable &&
        <Button
          name={name}
          color='link'
          disabled={disabled}
          className='close-btn'
          onClick={e => onClose(e.target.name)}
        >
          ×
        </Button>}

      {addable &&
        <Button
          name={name}
          color='link'
          disabled={disabled}
          className='add-btn'
          onClick={e => onAdd(e.target.name)}
        >
          +
        </Button>}
    </Badge>
  )
}
