import React from 'react'
import { StepInstructionsInfo } from '..'
import { Form, Field } from 'react-final-form'
import { AuthLinkButton } from '../../AuthLinkButton'
import { customAppStepInstructions } from './StepInstructions'
import { fieldModifiers } from '../../ChannelContent/modifiers'
import { FaExclamationTriangle as Warning } from 'react-icons/fa'
import { AuthorizedShopifyStores } from './AuthorizedShopifyStores'
import { Row, Col, FormGroup, Label, FormFeedback, Input, FormText, Alert } from 'reactstrap'

export const ShopifyCustomAuth = ({ props }) => {
  const { slug, instanceNumber, userInputNeeded } = props

  const renderFormContent = props => {
    const { handleSubmit, errors, values } = props
    const { shop, accessToken } = values || {}
    const shouldBeEnabled = Object.keys(errors).length === 0 && errors.constructor === Object

    const handleModifiers = (e, modifiers) => {
      const value = e.target.value
      const modifiedValue = (modifiers || []).map(i => fieldModifiers[i](value))
      return (modifiedValue || [])[0]
    }

    return (
      <Row className='mt-4'>
        <form className='max-80 w-100' onSubmit={handleSubmit}>
          {(userInputNeeded || []).map(item => {
            const { name, label, required, modifiers, hint } = item
            return (
              <FormGroup key={name}>
                <div className='form-label-group'>
                  <Field
                    name={name}
                    validate={required ? e => e ? undefined : `${label} is required` : null}
                  >
                    {({ input, meta }) => (
                      <>
                        <Input
                          id={name}
                          {...input}
                          type='text'
                          onChange={e => (modifiers || []).length > 0
                            ? input.onChange(handleModifiers(e, modifiers))
                            : input.onChange(e)}
                          placeholder={label}
                          invalid={meta.touched && meta.invalid}
                        />
                        <Label for={name}>{label}</Label>
                        {meta.error && meta.touched && <FormFeedback>{meta.error}</FormFeedback>}
                      </>
                    )}
                  </Field>
                  {hint && <FormText>{hint}</FormText>}
                </div>
              </FormGroup>
            )
          })}

          <AuthorizedShopifyStores values={values} />

          <div className='d-flex justify-content-end mt-4'>
            <AuthLinkButton
              text='Authorize Shopify'
              disabled={!shouldBeEnabled}
              href={`/auth/${slug}/${instanceNumber}?shop=${shop}&accessToken=${accessToken}`}
            />
          </div>
        </form>
      </Row>
    )
  }

  return (
    <>
      <Row>
        <Col>
          <Alert color='info' className='has-icon mt-3 mb-2'>
            <div className='alert-icon'><Warning className='m-0' size={18} /></div>
            <span>
              After enabling this connection you may receive emails from Shopify about upgrades and changes to their API. Please be aware that <b>SureDone</b> receives these notices and <b>will ensure any needed changes are made prior to deadlines set by Shopify</b>.
            </span>
          </Alert>
        </Col>
      </Row>
      <StepInstructionsInfo stepInstructions={customAppStepInstructions} />
      <Form onSubmit={() => {}} render={renderFormContent} />
    </>
  )
}
