import React from 'react'
import { InfoAlert } from '../InfoAlert'
import { FitmentFAQ } from '../FitmentFAQ'
import { Card, CardBody } from 'reactstrap'
import { FitmentSummary } from '../FitmentSummary'
import { ShopifyAddFYV } from './AddFitYourVehicle'
import { ShopifySearchPage } from './ShopifySearchPage'
import { ShopifyEditThemeFile } from './EditThemeFiles'
import { FitmentCustomFacets } from '../FitmentCustomFacets'
import { ShopifyCategorySubFilter } from './CategorySubFilter'
import { ShopifyHideMyVehicleButton } from './HideMyVehicleButton'
import './ShopifyAInstructions.scss'

export const ShopifyAInstructions = () => {
  return (
    <>
      <InfoAlert>
        An{' '}
        <a href='https://shopify.dev/docs/themes/os20' target='_blank' rel='noopener noreferrer'>
          <b>Online Store 2.0</b>
        </a>{' '}
        is required to use the SureFit plugin.<br />
        For basic installation instructions please go to your Shopify store and check <b>Apps {'>'} SureFit Year Make Model Search</b>.
      </InfoAlert>
      <Card>
        <CardBody>
          <FitmentFAQ />
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <FitmentSummary />
          <ShopifyEditThemeFile />
          <FitmentCustomFacets />
          <ShopifyHideMyVehicleButton />
          <ShopifyCategorySubFilter />
          <ShopifyAddFYV />
          <ShopifySearchPage />
        </CardBody>
      </Card>
    </>
  )
}
