import React from 'react'
import { useStores } from '../../stores/RootStore'
import { Card, CardBody, Row, Col } from 'reactstrap'
import { FilterBy, ChannelsFilter, DownloadLogs, FilterSuggestions } from './index'

export const Filters = ({ logs, activeFilters, handleAddFilter, handleRemoveFilter, suggestions, showChannelsSuggestion, downloadButtonText }) => {
  const { stores: { auth } } = useStores()
  const isPlatformUser = auth.permissions.hasPlatformRole()
  const areLogsToBeDownloaded = logs?.length > 0

  return (
    <Card style={{ zIndex: 2 }}>
      <CardBody>
        <h6 className='mb-2'>Filter by:</h6>

        {showChannelsSuggestion && <ChannelsFilter handleAddFilter={handleAddFilter} activeFilters={activeFilters} />}

        {suggestions && <FilterSuggestions handleAddFilter={handleAddFilter} activeFilters={activeFilters} suggestions={suggestions} />}

        <FilterBy handleAddFilter={handleAddFilter} handleRemoveFilter={handleRemoveFilter} isPlatformUser={isPlatformUser} activeFilters={activeFilters} />

        {/* Download buttons */}
        {areLogsToBeDownloaded && (
          <Row className='mt-3'>
            <Col>
              <DownloadLogs
                logs={logs}
                isPlatformUser={isPlatformUser}
                downloadButtonText={downloadButtonText}
              />
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  )
}
