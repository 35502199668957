/**
 * <SaveButton />
 *
 * This component is used to save data. It render a button that has 3 states:
 * 1 Initial -> disabled, because the form which call this component isn't dirty
 * 2 Clickeable -> the form was dirtied so you can click the <SaveButton />
 * 3 Saved -> disabled again, the data was saved and you don't click the button again until you dirty the form
 *
 * If you want to show an Icon in your button, maybe you should use <ActionButton /> which receives the custom button icon
 *
 * Usage:
 *  <SaveButton
 *    reactsStrapProps      = All props reactraps Button can receive
 *    loading               = Boolean. If it's true, the spinner icon (from ActionButton) is displayed
 *    disabled              = Boolean. For the initial and saved states
 *    success               = Boolean. To disable the button on success
 *    dirtysincelastsubmit  = Boolean. To enable the button if the form is dirtied again
 *  >
 *    {optional children}   = String. If you don't set a children, the button text will be 'Save changes'
 *  </SaveButton>
 */

import React from 'react'
import { ActionButton } from './ActionButton'
import { FiCheckCircle as Check } from 'react-icons/fi'

const SaveButton = (props) => {
  const { successtext } = props
  const children = props.children || 'Save changes'
  const showCheckedIcon = !!((props.success && !props.dirtysincelastsubmit && !props.loading))
  return (
    <ActionButton {...props}>
      {showCheckedIcon ? <><Check /> {successtext || 'Saved'}</> : children}
    </ActionButton>
  )
}

export { SaveButton }
