/* eslint-disable camelcase */
import React, { useEffect } from 'react'
import { useQueryParams } from '../../../hooks'
import { Alert, Card, CardBody } from 'reactstrap'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { GET_AUTH_STATUS, GET_CHANNEL_AUTH_URL } from '../'
import { UPDATE_ONBOARD_DATA } from '../../Dashboard/commonQueries'
import { Steps, Step, Loadable, ErrorAlert } from '../../../components'
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom'
import Debug from 'debug'
import { StepFormContent, StepRedirectContent, StepCompleteContent, StepOnlyInfoContent, StepFormSubmitContent } from './ChannelContent'
const debug = Debug('sd:ChannelWizard')

const humanizeError = e => {
  if (e.includes('action requires merchant approval')) {
    const splitted = e?.split('merchant approval for ')[1]
    const [missedScope] = splitted?.split(' scope')
    return `${missedScope} scope is missing. Please update the app to provide all required scopes.`
  }
  if (e.includes('[API]')) {
    const [, humanized] = e?.split('[API]')
    return humanized
  }
  return e
}

/**
 * The step action could be REDIRECT or FORM. This means that the CURRENT step have a redirection button or a form
 * @param {{slug:String, stepNumber: Int, step: {name: String,action: string, instructions: String,...rest}}} props
 */
const StepContent = (props) => {
  const { slug, step, instanceNumber, channelName, auth_data, auth_loading, auth_error, availableChannels } = props
  const { action, form, userInputNeeded, nextStep } = step || {}
  const paramObject = useQueryParams()
  const renderSwitch = (action) => {
    switch (action) {
      case 'REDIRECT':
        return (
          <StepRedirectContent
            slug={slug}
            channelName={channelName}
            step={step}
            instanceNumber={instanceNumber}
            auth_data={auth_data}
            auth_loading={auth_loading}
            auth_error={auth_error}
          />
        )
      case 'FORM':
        return (
          <StepFormContent
            userInputNeeded={userInputNeeded}
            slug={slug}
            form={form}
            channelName={channelName}
            step={step}
            instanceNumber={instanceNumber}
            auth_data={auth_data}
            auth_loading={auth_loading}
            auth_error={auth_error}
            paramObject={paramObject}
          />
        )
      case 'EBAYFORMSUBMIT':
        return (
          <StepFormSubmitContent
            channelName={channelName}
            instanceNumber={instanceNumber}
            slug={slug}
            nextStep={nextStep}
          />
        )
      case 'ONLY_INFO':
        return (
          <StepOnlyInfoContent step={step} auth_loading={auth_loading} auth_error={auth_error} slug={slug} />
        )
      default:
        return (
          <StepCompleteContent slug={slug} step={step} instanceNumber={instanceNumber} availableChannels={availableChannels} />
        )
    }
  }

  return (
    <>
      <Card>
        <CardBody>
          {(paramObject && paramObject.error) && <ErrorAlert error={humanizeError(paramObject.error)} />}
          {renderSwitch(action)}
        </CardBody>
      </Card>
    </>
  )
}

/**
 *
 * @param {String} baseUrl:String Url for step routing
 * @param {[{name: String,stepUrl: string,...rest}]} stepsData array of steps data
 */
const StepGenerator = (baseUrl, stepsData) => {
  return (
    <Steps>
      {
        stepsData?.map((step, idx) =>
          <Step
            key={`${step.name}${idx + 1}`}
            to={`${baseUrl}/${step.stepUrl}`}
            desktopPrefix={`Step ${idx + 1}: `}
            name={step.name}
            enableClick={false}
          />
        )
      }
    </Steps>
  )
}

/**
 *
 * @param {String} baseUrl
 * @param {String} redirectUrl
 * @param {String} slug Represents the channel name
 * @param {number} instanceNumber represents the instance number of the channel
 * @param {[{name: String,url: string,...rest}]} stepsData
 */
const RouteGenerator = (baseUrl, redirectUrl, slug, instanceNumber, stepsData, channelName, auth_data, auth_loading, auth_error, availableChannels, nextStep) => {
  return (
    <Switch>
      {
        stepsData?.map((step, idx) =>
          <Route
            key={`${step.name}${idx + 1}`}
            path={`${baseUrl}/${step.stepUrl}`}
            name={step.name}
            render={() => <StepContent slug={slug} channelName={channelName} stepNumber={idx + 1} step={step} instanceNumber={instanceNumber} auth_data={auth_data} auth_loading={auth_loading} auth_error={auth_error} availableChannels={availableChannels} nextStep={nextStep} />}
          />
        )
      }
      <Redirect to={`${redirectUrl}`} />
    </Switch>
  )
}

export const ChannelWizard = (props) => {
  // enabled=true because only ebay really needs the validation
  const { slug, instanceNumber, authSteps, channelName, enabled = true, availableChannels } = props
  const { url, path } = useRouteMatch()
  const paramObject = useQueryParams()
  const { loading, error, data } = useQuery(GET_AUTH_STATUS, { variables: { slug, instanceNumber } })
  // Note: as this isn't working bc is a known error on this '@apollo/react-hooks' version
  // We let here as is and should work as expected when update that package
  const { data: auth_data, loading: auth_loading, error: auth_error } = useQuery(GET_CHANNEL_AUTH_URL, {
    variables: { slug, instanceNumber },
    skip: slug === 'walmart', // if the channel is walmart we don't want to query auth URL
    onError: (error) => {
      debug({ error })
    }
  })

  const { ChannelInstance } = data || {}
  const { authStatus: step } = ChannelInstance || {}
  const redirectUrl = (paramObject && paramObject.error) ? `${path}/${step}?error=${paramObject.error}` : `${path}/${step}`
  // Update onboard data
  const [updateOnboardData] = useMutation(UPDATE_ONBOARD_DATA)

  useEffect(() => {
    const authStatus = step
    if (authStatus === 'complete') updateOnboardData({ variables: { input: { lastAuthorizedChannel: `${slug}-${instanceNumber}` } } })
    // eslint-disable-next-line
  }, [step])

  return (
    <Loadable
      loading={loading || auth_loading}
      inline
      error={error || auth_error}
      style={{ textAlign: 'center' }}
      content={
        !enabled
          ? <Alert color='info'>To authorize this channel you must first enable it (use the switch at the top right).</Alert>
          : (
            <>
              {StepGenerator(url, authSteps)}
              {RouteGenerator(url, redirectUrl, slug, instanceNumber, authSteps, channelName, auth_data, auth_loading, auth_error, availableChannels)}
            </>)
      }
    />
  )
}
