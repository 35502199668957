import { useState, useEffect, useRef } from 'react'
/**
 * Debounce setting a value
 * @param value
 * @param delay
 * @returns {[string, fn, fn]}
 */
export default function useDebounce (value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)
  const handler = useRef(null)
  const canceller = () => {
    clearTimeout(handler.current)
  }
  useEffect(() => {
    handler.current = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)
    return canceller
  }, [value, delay])
  return [debouncedValue, canceller, setDebouncedValue]
}
