import React from 'react'
import { Col, Row } from 'reactstrap'
import { ImportField } from './ImportField'
import { VisualPicker } from '../../../components'
import { ResourceList } from '../../../components/SearchableLists'

const importTypes = [
  {
    id: 'overwrite',
    label: 'Overwrite',
    description: 'Use this option for new products that are not already in your catalog.'
  },
  {
    id: 'merge',
    label: 'Merge',
    description: 'Use this option if your products are already in your catalog. Only new fields that do not already exist will be imported.'
  }
]

export const TypeOfImports = ({ tableHeaders, typeOfImport, setTypeOfImport, skipUpdateFields, setSkipUpdateFields }) => {
  const mergeWasSelected = typeOfImport === 'merge'
  const overwriteWasSelected = typeOfImport === 'overwrite'
  const fields = tableHeaders?.map(({ name }) => ({ key: name }))

  const handleChangeValue = (event, field) => {
    const isChecked = event.target.checked

    if (isChecked) {
      setSkipUpdateFields(prev => [...prev, field.key])
    } else {
      setSkipUpdateFields(prev => prev.filter(i => i !== field.key))
    }
  }

  return (
    <>
      <Row className='d-flex justify-content-center type-of-import'>
        {importTypes.map(({ id, label, description }) =>
          <Col key={id} xs={12} sm={6} lg={6} xl={4} className='mb-2'>
            <VisualPicker
              id={id}
              name='type-of-import'
              isSelected={typeOfImport === id}
              parentCallback={() => setTypeOfImport(id)}
            >
              <h6 className='d-block text-center px-3 pt-3 pb-2 m-0'>{label}</h6>
              <p className='mb-0 px-3 pb-3 text-center text-muted'>
                {description}
              </p>
            </VisualPicker>
          </Col>
        )}
      </Row>

      {typeOfImport &&
        <>
          <Row className='my-3 justify-content-center'>
            {overwriteWasSelected &&
              <Col xs={12} md={11} className='text-center'>
                <p>
                  An <strong>Overwrite import</strong> will import all fields from your data, including <code>sku</code>, <code>title</code>, <code>price</code>, <code>description</code> and other core fields. <br />
                  If the <code>sku</code> on a product in the import matches one in your database, this will overwrite the information in your database.
                </p>
              </Col>}

            {mergeWasSelected &&
              <Col xs={12} md={11} className='text-center'>
                <p>
                  A <strong>Merge import</strong> will import channel specific fields from your import. <br />
                  If the <code>sku</code> on a product matches one in your database, the channel specific fields listed below will be updated, <br />
                  but <code>sku</code>, <code>title</code>, <code>price</code>, <code>description</code> and other core fields will not be updated. <br />
                </p>
              </Col>}
          </Row>

          {mergeWasSelected &&
            <Row>
              <Col className='mb-n3'>
                <ResourceList
                  items={fields}
                  itemsPerPage={20}
                  className='import-fields'
                  searchableStrings={({ key }) => [key]}
                  itemComponent={
                    ({ item: field, searchTerms }) =>
                      <li className='list-group-item-flush d-flex flex-sm-row flex-column list-group-item-action list-group-item p-0'>
                        <ImportField
                          field={field}
                          searchTerms={searchTerms}
                          parentCallback={handleChangeValue}
                          skipUpdateFields={skipUpdateFields}
                        />
                      </li>
                  }
                />
              </Col>
            </Row>}
        </>}
    </>
  )
}
