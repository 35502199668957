import React from 'react'
import { ActionButton } from '../components'
import { FaDownload as Download } from 'react-icons/fa'

// data should be a json
export const DownloaderJSON = ({ data, text, icon, filePrefix = 'logs-', ...rest }) => {
  const download = (content, fileName, contentType) => {
    const a = document.createElement('a')
    // eslint-disable-next-line no-undef
    const file = new Blob([content], { type: contentType })
    a.href = URL.createObjectURL(file)
    a.download = fileName
    a.click()
  }

  const handleDownload = () => download(data, `${filePrefix}${Date.now()}.json`, 'text/plain')

  return (
    <ActionButton
      outline
      {...rest}
      color='primary'
      onClick={() => handleDownload()}
      icon={icon || <Download size={14} style={{ margin: '-4px 8px 0 0' }} />}
    >
      {text || 'Download'}
    </ActionButton>
  )
}
