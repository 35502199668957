/**
 * Returns if the channel is Authorized
 * @param {String} status
 * @returns {boolean}
 */
const isChannelAuthorized = ({ authStatus, availableChannels, slug, instanceNumber }) => {
  const { ChannelInstances } = availableChannels || {}
  const selfChannel = ChannelInstances?.find(i => i.instance === parseInt(instanceNumber) && i.channel.slug === slug)
  const { dateOfTokenExp } = selfChannel || {}

  const today = new Date()
  const tokenExpirationDate = new Date(dateOfTokenExp)
  const differenceInMillisenconds = tokenExpirationDate - today

  if (dateOfTokenExp) return authStatus === 'complete' && differenceInMillisenconds > 0
  return authStatus === 'complete'
}

export { isChannelAuthorized }
