import React, { useState } from 'react'
import { Button, Spinner, Modal, ModalHeader, ModalBody, Alert } from 'reactstrap'
import { useMutation } from '@apollo/react-hooks'
import { useStores } from '../stores/RootStore'
import { FaUserSlash as Suspend, FaUserCheck as Enable } from 'react-icons/fa'
import { useObserver } from 'mobx-react'
import { Permissions } from 'suredone-common'
import gql from 'graphql-tag'

const CHANGE_USER_STATUS = gql`
  mutation changeUserStatus($userToChangeId: ID!) {
    changeUserStatus(userToChangeId: $userToChangeId) {
      id
      enabled
    }
  }
`

const ChangeUserStatusButton = ({ user }) => {
  // user from prop is the user who will be suspended
  let { id: userId, enabled } = user || {}
  enabled = enabled !== false
  const { stores: { auth } } = useStores()
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(true)
  const [changeUserStatus, { loading, error }] = useMutation(
    CHANGE_USER_STATUS,
    { onCompleted: () => setShowSuccess(true) }
  )

  const permissions = new Permissions(user.organizationId, user.roles, user.scopes)
  const platformUser = auth.permissions.hasScope('platform:allOrganizations:users:suspend')
  const organizationUser = auth.permissions.hasScope('organization:users:suspend') &&
    !permissions.hasRole('Owner')
  const showButton = user.id !== auth.user.id && (platformUser || organizationUser)

  return useObserver(() =>
    showButton
      ? (
        <Button
          color='secondary'
          className='mr-1'
          outline={loading || auth.user.id === userId}
          disabled={loading || auth.user.id === userId}
          onClick={async e => {
            e.preventDefault()
            await changeUserStatus({ variables: { userToChangeId: userId } })
          }}
        >
          {loading ? <Spinner size='sm' className='mr-2' /> : enabled ? <Suspend /> : <Enable />}
          {enabled ? 'Suspend user' : 'Enable user'}
          <Modal isOpen={showSuccess} size='lg' toggle={e => setShowSuccess(false)}>
            <ModalHeader toggle={e => setShowSuccess(false)}>
              Successfully updated user status
            </ModalHeader>
            <ModalBody>
              <Alert color='success'>
                The user is <b>{enabled ? 'enabled' : 'suspended'}</b> now.
              </Alert>
            </ModalBody>
          </Modal>
          {error && showError && (
            <Modal isOpen size='lg' toggle={e => setShowError(false)}>
              <ModalHeader toggle={e => setShowError(false)}>
                Error updating user status
              </ModalHeader>
              <ModalBody>
                <Alert color='danger'>
                  {error.message}
                </Alert>
              </ModalBody>
            </Modal>
          )}
        </Button>)
      : null
  )
}

export { ChangeUserStatusButton }
