import React from 'react'
import { Card, CardHeader, CardBody } from 'reactstrap'
import { Loadable } from '../../../components'
import { SDLineChart } from './SDLineChart'
import { Line } from 'recharts'
import { withComma, arrSum, generateMargin } from './utils'

const OrdersItemTotals = ({ title, data, sampleData, useSample, loading, error }) => {
  const { GetDashboardData } = data || {}
  const { ototals, itotals } = GetDashboardData || {}
  const formatedData = (ototals || []).map(o => ({
    ...o,
    ototal: o.value,
    itotal: (itotals.find(i => i.label === o.label) || {}).value,
    legend1: (itotals.find(i => i.label === o.label) || {}).legend
  }))
  const totals = arrSum(formatedData, 'ototal')
  const mLeft = useSample ? -15 : generateMargin(formatedData)

  const lines = [
    <Line name='Order total' type='monotone' dataKey='ototal' stroke='#336db0' key='line-1' />,
    <Line name='Item total' type='monotone' dataKey='itotal' stroke='#27a844' key='line-2' />
  ]

  return (
    <Card>
      <CardHeader className='d-flex justify-content-between'>
        <span>{title}</span>
        {totals > 0 && <h6 className='mb-0'><Loadable loading={loading} inline content={`$${withComma(totals)}`} /></h6>}
      </CardHeader>
      <CardBody className='pl-0'>
        <Loadable
          loading={loading}
          inline
          error={error}
          style={{ minHeight: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          content={<SDLineChart data={useSample ? sampleData.totalSales : formatedData} lines={lines} mLeft={mLeft} />}
        />
      </CardBody>
    </Card>
  )
}

export { OrdersItemTotals }
