import React from 'react'
import { Card, CardHeader, CardBody } from 'reactstrap'
import { Loadable } from '../../../components'
import { SDBarChart } from './SDBarChart'
import { withComma, arrSum, generateMargin } from './utils'

const Orders = ({ title, data, sampleData, useSample, loading, error }) => {
  const { GetDashboardData } = data || {}
  const { orders } = GetDashboardData || {}
  const totals = arrSum(orders, 'value')
  const mLeft = useSample ? -35 : generateMargin(orders)

  return (
    <Card>
      <CardHeader className='d-flex justify-content-between'>
        <span>{title}</span>
        {totals > 0 && <h6 className='mb-0'><Loadable loading={loading} inline content={`${withComma(totals)}`} /></h6>}
      </CardHeader>
      <CardBody className='pl-0'>
        <Loadable
          loading={loading}
          inline
          error={error}
          style={{ minHeight: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          content={<SDBarChart data={useSample ? sampleData.totalOrders : orders} name='Orders total' mLeft={mLeft} />}
        />
      </CardBody>
    </Card>
  )
}

export { Orders }
