/** Data for page 1 */
// cIcon is used to show a colored icon instead of the no-colored react-icon
export const dataP1 = {
  allChannels: [
    { name: 'amazon', supported: true, cIcon: true },
    { name: 'ebay', supported: true, cIcon: true },
    { name: 'ebaymotors', supported: true, label: 'Ebay Motors' },
    { name: 'bigcommerce', supported: true },
    { name: 'facebook', supported: true, cIcon: true },
    { name: 'google', supported: true, cIcon: true },
    { name: 'instagram', supported: true },
    { name: 'shopify', supported: true, cIcon: true },
    { name: 'miva', supported: true, cIcon: true },
    { name: 'magentotwo', supported: true, cIcon: true, label: 'Magento2' },
    { name: 'magento', supported: true, cIcon: true },
    { name: 'etsy', supported: true, cIcon: true },
    { name: 'walmart', supported: true, cIcon: true },
    { name: 'shipstation', supported: true, cIcon: true },
    { name: 'skuvault', supported: true, cIcon: true },
    { name: 'storefront', supported: true, cIcon: true, label: 'SureDone Storefront' },
    { name: 'overstock', supported: false },
    { name: 'newegg', supported: false },
    { name: 'sears', supported: false },
    { name: 'wish', supported: false },
    { name: 'woocommerce', supported: false, label: 'WooCommerce' },
    { name: 'webshopmanager', supported: false },
    { name: 'deliverr', supported: false },
    { name: 'finale inventory', supported: false },
    { name: 'shipwire', supported: false },
    { name: 'shipworks', supported: false },
    { name: 'mercadolibre', supported: false, label: 'Mercado Libre' },
    { name: 'wayfair', supported: false },
    { name: 'salesforce', supported: false },
    { name: 'aliexpress', supported: false, label: 'AliExpress' }
  ],
  actions: [
    'Manage listings on multiple channels',
    'Manage inventory',
    'Manage orders',
    'Create shipping labels',
    'Facilitate Drop-shipping',
    'Ship through FBA',
    'Automate 3rd party tools',
    'Manage catalogs',
    'Run your own storefront website',
    'Reprice my products across channels',
    'Other'
  ]
}

/** Data for page 2 */
export const dataP2 = {
  orderVolumeInDolars: [
    { label: '$0-$24,999', points: 0 },
    { label: '$25,000-$49,999', points: 10 },
    { label: '$50,000-$74,999', points: 20 },
    { label: '$75,000-$99,999', points: 30 },
    { label: '$100,000-$249,999', points: 40 },
    { label: '$250,000-$1,000,000', points: 80 },
    { label: '$1Mil+', points: 150 }
  ],
  ordersPerMonthQuantity: [
    { label: '1-499', points: 0 },
    { label: '500-2,499', points: 10 },
    { label: '2,500-9,999', points: 20 },
    { label: '10,000-24,999', points: 40 },
    { label: '25,000+', points: 80 }
  ],
  skuCount: [
    { label: '1-1,999', points: 0 },
    { label: '2,000-14,999', points: 10 },
    { label: '15,000-49,999', points: 30 },
    { label: '50,000-149,999', points: 40 },
    { label: '150,000+', points: 60 }
  ],
  categoryOptions: [
    { value: 'Auto and Moto', label: 'Auto and Moto', points: 150 },
    { value: 'Books and Audible', label: 'Books and Audible', points: 0 },
    { value: 'Business, Industrial and Safety', label: 'Business, Industrial and Safety', points: 20 },
    { value: 'Clothing, Shoes, Jewelry and Watches', label: 'Clothing, Shoes, Jewelry and Watches', points: 0 },
    { value: 'Collectibles and Art', label: 'Collectibles and Art', points: 0 },
    { value: 'Electronics, Computers and Office', label: 'Electronics, Computers and Office', points: 20 },
    { value: 'Entertainment Memorabilia', label: 'Entertainment Memorabilia', points: 0 },
    { value: 'Handmade', label: 'Handmade', points: 0 },
    { value: 'Health and Beauty', label: 'Health and Beauty', points: 0 },
    { value: 'Home, Garden and Tools', label: 'Home, Garden and Tools', points: 20 },
    { value: 'Movies, Music and Games', label: 'Movies, Music and Games', points: 0 },
    { value: 'Pets', label: 'Pets', points: 0 },
    { value: 'Sporting Goods and Outdoors', label: 'Sporting Goods and Outdoors', points: 20 },
    { value: 'Toys, Hobbies, Kids and Babies', label: 'Toys, Hobbies, Kids and Babies', points: 0 },
    { value: 'Other', label: 'Other', points: 0 }
  ]
}

/**
 * @param {String} str
 * @returns {String} camelized String
 */
export const snakeToCamel = (str) => str.replace(/[-_\s.]+(.)?/g, (_, c) => c ? c.toUpperCase() : '')

/**
 * Calculates the redirect url after qualification test
 * @param {Boolean} isAFit
 * @param {Boolean} inPersonDemo
 * @param {Boolean} talkWithinHour
 * @returns {String}
 */
export const calculateRedirectUrl = (isAFit, inPersonDemo, talkWithinHour) => {
  if (talkWithinHour) return '/talk'
  if (inPersonDemo) return '/demo-schedule'
  if (isAFit) return '/register'
  if (!isAFit) return '/register'
}

/**
 * Checks if the user is a fit
 * @param {Array} currentChannels
 * @param {Array} wantedChannels
 * @param {Array} actionsToDo
 * @returns
 */
export const checkIfIsAFit = (currentChannels, wantedChannels, actionsToDo) => {
  const noCurrentSupportedChannels = currentChannels?.filter(({ supported }) => supported)?.length <= 0
  const noEnoughWantedSupportedChannels = wantedChannels?.filter(({ supported }) => supported)?.length <= 1
  const noActions = actionsToDo?.length <= 0
  const specificAction = actionsToDo?.length === 1 && actionsToDo?.[0] === 'Reprice my products across channels'
  if (noActions || specificAction) return false
  if (noCurrentSupportedChannels && noEnoughWantedSupportedChannels) return false
  return true
}
