import React, { useState } from 'react'
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Input,
  Label,
  Modal,
  FormText,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row
} from 'reactstrap'
import { ActionButton } from '../../components/ActionButton'
import { useMutation } from '@apollo/react-hooks'
import { useObserver } from 'mobx-react'
import { useStores } from '../../stores/RootStore'
import gql from 'graphql-tag'
import {
  FaPlus as Add,
  FaUser as User,
  FaBan as Ban
  // FaCrosshairs as ScopeTitleIcon,
  // FaKey as ScopeIcon
} from 'react-icons/fa'
import {
  Permissions,
  platformRoles,
  organizationRoles,
  getRole,
  getScope
} from 'suredone-common'
import { ScopesWrapper } from './ScopesWrapper'
import './UserPermissions.css'
import { ErrorAlert } from '../../components'

const debug = require('debug')('sd:UserPermissions')

const GRANT = gql`
  mutation ($input: RoleChangeInput!) {
    grantRole(input: $input)  {
      ... on User {
        id
        roles
      }
    }
  }
`
const REVOKE = gql`
  mutation ($input: RoleChangeInput!) {
    revokeRole(input: $input)  {
      ... on User {
        id
        roles
      }
    }
  }
`

function ScopeTable ({ role }) {
  const { scopes, inheritsFrom } = role
  // const children = expandRoles(inheritsFrom)
  debug({ scopes, role, inheritsFrom })
  return (
    <ul>
      {(inheritsFrom || []).map(roleName => {
        const role = getRole(roleName)
        return <li key={role.name + roleName}>Do anything that <b>{role.label || roleName}</b> can do.</li>
      })}
      {scopes.map(getScope).map(scope => <li key={scope.name}>{scope.label}</li>)}
    </ul>
  )
}

// all props are user related
function Role ({ user, permissions, roleName, showGrant = false, showRevoke = false }) {
  const role = getRole(roleName)
  const userHighestRole = permissions.getHighestRole().name
  const { stores: { auth } } = useStores()

  function refresh () {
    auth.refresh()
  }

  const [grantRole, { loading: grantLoading, error: grantError }] = useMutation(GRANT, { onCompleted: refresh })
  const [revokeRole, { loading: revokeLoading, error: revokeError }] = useMutation(REVOKE, { onCompleted: refresh })
  return useObserver(() =>
    <Card outline className='bg-light'>
      <CardHeader>
        <User /> <b>{role.label || roleName}</b>
      </CardHeader>
      <CardBody>
        {role.isPlatform ? <Alert color='warning'>This is a platform role. It applies to all organizations.</Alert> : null}
        <p>
          {role.description}
        </p>
        {(role.scopes && role.scopes.length) || (role.inheritsFrom && role.inheritsFrom.length)
          ? <> This user can: <ScopeTable role={role} /> </>
          : null}
      </CardBody>
      {(
        (showGrant && auth.permissions.canGrantRole(userHighestRole, user.organizationId)) || grantError ||
        (showRevoke && auth.permissions.canRevokeRole(userHighestRole, user.organizationId)) || revokeError
      ) && (
        <CardFooter className='p-3 d-flex justify-content-between'>
          {showRevoke && auth.permissions.canRevokeRole(userHighestRole, user.organizationId)
            ? (
              <ActionButton
                color='danger'
                className='mr-auto'
                disabled={revokeLoading || !permissions.hasRole(roleName)}
                loading={revokeLoading}
                icon={<Ban />}
                onClick={e => {
                  e.preventDefault()
                  revokeRole({ variables: { input: { roleName: role.name, userId: user.id } } })
                }}
              >
                Revoke Role
              </ActionButton>
              )
            : <span />}
          {showGrant && auth.permissions.canGrantRole(userHighestRole, user.organizationId)
            ? (
              <ActionButton
                color='primary'
                disabled={grantLoading || permissions.hasRole(roleName)}
                loading={grantLoading}
                icon={<Add />}
                onClick={e => {
                  e.preventDefault()
                  grantRole({ variables: { input: { roleName: role.name, userId: user.id } } })
                }}
              >
                Grant Role
              </ActionButton>
              )
            : (
              <span />
              )}
          {grantError && (
            <Row>
              <Col className='mt-2'>
                <Alert color='danger'>{grantError.message}</Alert>
              </Col>
            </Row>
          )}
          {revokeError && (
            <Row>
              <Col className='mt-2'>
                <Alert color='danger'>{revokeError.message}</Alert>
              </Col>
            </Row>
          )}
        </CardFooter>
      )}
    </Card>
  )
}

function AddRoleModal ({ user }) {
  const [isOpen, setIsOpen] = useState(false)
  const [showAll, setShowAll] = useState(false)
  const { stores: { auth } } = useStores()
  const permissions = new Permissions(user.organizationId, user.roles, user.scopes)
  const grantablePlatformRoles = showAll
    ? platformRoles
    : platformRoles
      .filter(role => auth.permissions.canGrantPlatformRole(role.name))
      .filter(role => !permissions.hasRole(role.name))
  const grantableOrganizationRoles = showAll
    ? organizationRoles
    : organizationRoles
      .filter(role => auth.permissions.canGrantRole(role.name, user.organizationId))
      .filter(role => !permissions.hasRole(role.name))
  const totalGrantable = grantableOrganizationRoles.length + grantablePlatformRoles.length
  const isAPlatformUser = auth.permissions.hasPlatformRole()
  return useObserver(() =>
    <Button color='primary' onClick={e => setIsOpen(true)}>
      <Add /> Add Role
      <Modal isOpen={isOpen} size='xl' toggle={e => setIsOpen(false)}>
        <ModalHeader toggle={e => setIsOpen(false)}>
          Add Role
        </ModalHeader>
        <ModalBody>
          {isAPlatformUser &&
            <Row>
              {grantablePlatformRoles.map(role => (
                <Col md={4} className='mb-4' key={role.name}>
                  <Role user={user} permissions={permissions} roleName={role.name} showGrant />
                </Col>
              ))}
            </Row>}
          <Row>
            {grantableOrganizationRoles.map(role => (
              <Col md={4} className='mb-4' key={role.name}>
                <Role user={user} permissions={permissions} roleName={role.name} showGrant />
              </Col>
            ))}
          </Row>
          {totalGrantable === 0
            ? <Alert color='info'>No roles are available for you to grant to this user.</Alert>
            : null}
        </ModalBody>
        <ModalFooter className='d-flex justify-content-between'>
          <Label check className='ml-4'>
            <Input type='checkbox' checked={showAll} onChange={e => setShowAll(e.target.checked)} /> Show all possible roles, including ones that are already granted.
          </Label>
          <Button outline onClick={e => setIsOpen(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Button>
  )
}

// user is the user to whom will edit the permission
// Why some scopes are on cognito and not infered by Roles ?
export function PermissionsEditor ({ user }) {
  const { stores: { auth } } = useStores()

  const permissions = new Permissions(user.organizationId, user.roles, user.scopes)
  const sortedUserRoles = [...platformRoles, ...organizationRoles].filter(
    role => (user.roles || []).indexOf(role.name) !== -1
  )

  const shouldShowRevoke = role => {
    const isOwnUser = user.id === auth.user.id
    const isRoleUser = role.name === 'User'
    return !isOwnUser && !isRoleUser
  }

  const itsOwnUser = user.id === auth.user.id
  const userCanViewPermissions = auth.permissions.hasScopeInOrganization('organization:permissions:read', user.organizationId)

  return (
    <div className='permissions-editor'>
      {userCanViewPermissions
        ? (
          <>
            <Card>
              <CardBody>
                <Row className='mb-2'>
                  <Col className='mb-3'>
                    <h5 className='font-weight-bold'>
                      Roles
                      <FormText className='mt-2' style={{ fontSize: '75%' }}>You can have one or more roles. Each role grants the ability to do certain things. Roles build on each other. For example the <b>Owner</b> of an organization can do everything an <b>Admin</b> can do, plus a few more things.</FormText>
                    </h5>
                  </Col>
                  <Col sm='auto' className='pl-2 mb-2 d-flex justify-content-end'>
                    <div>
                      {auth.permissions.canGrantRole('User', user.organizationId) && <AddRoleModal user={user} />}
                    </div>
                  </Col>
                </Row>
                <Row>
                  {sortedUserRoles.map(role => (
                    <Col md={6} lg={6} xl={4} className='mb-4' key={role.name}>
                      <Role user={user} permissions={permissions} roleName={role.name} showRevoke={shouldShowRevoke(role)} />
                    </Col>
                  ))}
                </Row>
              </CardBody>
            </Card>

            <Card className='permissions-card'>
              <CardBody>
                <Row className='mb-2'>
                  <Col className='mb-3'>
                    <h5 className='font-weight-bold'>
                      Permissions
                      <FormText className='mt-2' style={{ fontSize: '75%' }}>

                        Use this section if you want different permissions than the
                        roles above provide. <b>Note:</b> changing roles above will
                        overwrite any changes you make here.

                      </FormText>
                    </h5>
                  </Col>
                </Row>
                <ScopesWrapper
                  user={user}
                  permissions={permissions}
                  auth={auth}
                  userCanEditPermissions={(auth.permissions.hasScopeInOrganization('organization:permissions:write', user.organizationId) && user.id !== auth.user.id)}
                />
              </CardBody>
            </Card>
          </>
          )
        : (
          <Card>
            <CardBody>
              <ErrorAlert className='my-2' error={`You're not allowed to see ${itsOwnUser ? 'your own' : null} permissions`} />
            </CardBody>
          </Card>
          )}
    </div>
  )
}

debug('loaded')
