import React from 'react'
import { RefreshField } from './RefreshField'
import { SyncChannels } from './SyncChannels'

export const BigcommerceSync = ({ slug, instanceNumber, field }) => {
  return (
    <>
      <SyncChannels slug={slug} instanceNumber={instanceNumber} />
      <RefreshField slug={slug} instanceNumber={instanceNumber} field={field} />
    </>
  )
}
