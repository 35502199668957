import React from 'react'
import { useReward } from 'react-rewards'
import { ApprovedContent } from './ApprovedContent'
import { confetti } from '../../../components/utils'
import { ReadyToReviewContent } from './ReadyToReviewContent'
import { Card, CardBody, Row, UncontrolledCollapse } from 'reactstrap'
import { CancelledImport, ImportSummaryHeader, InProgressContent } from './ImportElements'

const ImportSummary = props => {
  let customProps
  const { channelImport, startedImport, idx } = props
  const { reward, isAnimating } = useReward('importReward', 'confetti', confetti)

  if (channelImport && channelImport.id) {
    customProps = channelImport
  } else if (startedImport && startedImport.id) {
    customProps = startedImport
  } else {
    customProps = channelImport || startedImport
  }

  const { id: channelImportId, status, importFileName, importFileUrl } = customProps || {}

  if (status === 'CANCELLED') return <CancelledImport idx={idx} status={status} />

  return (
    <Card className='w-100 m-0'>
      <ImportSummaryHeader customProps={customProps} />

      {isAnimating && // Shows confetti once the upload finished
        <div className='d-flex justify-content-center w-100'><span id='importReward' /></div>}

      <UncontrolledCollapse toggler={`id-${channelImportId}`}>
        <CardBody className='pt-0 mt-n2'>
          <Row className='mt-2 justify-content-center import-summary'>
            {importFileName && <p className='w-100 mt-3 mb-0 pl-2'>File: {importFileName}</p>}
            {status === 'IN_PROGRESS' && <InProgressContent customProps={customProps} />}
            {status === 'READY_TO_REVIEW' && <ReadyToReviewContent customProps={customProps} setShowReward={reward} />}
            {status === 'APPROVED' && importFileUrl && importFileName && ( // Prevent errors for old imports were status can still be APPROVED
              <ApprovedContent customProps={customProps} setShowReward={reward} />
            )}
          </Row>
        </CardBody>
      </UncontrolledCollapse>
    </Card>
  )
}

export { ImportSummary }
