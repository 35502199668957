import { amazonLegacy, amazonNew } from 'suredone-common'
import { useQueryParams } from './'

/**
 * stepNumber is 1-index
 * TODO: Found a better way to get which step is representing this data no matter which params are there
 * Hook to retrieve params from url
 * @param {String} slug facebook/amazon/...
 * @returns {[{Object},{{stepNumber: Number}},{Object},{{urlToRedirect: String}}}]}
 */
const useChannelQueryParams = (slug) => {
  const paramsObject = useQueryParams()
  // If no params
  if (Object.keys(paramsObject).length === 0) return [{}, { stepNumber: 0 }, {}, {}]
  // Prevent other query params than necessary
  switch (slug) {
    case 'facebook': {
      // eslint-disable-next-line
      const { code, granted_scopes: grantedScopes, denied_scopes: deniedScopes, state, cms_id, error, ...rest } = paramsObject
      // eslint-disable-next-line
      if (error || (deniedScopes && Object.keys(deniedScopes).length === 0)) return [{}, { stepNumber: 0 }, { state, denied_scopes: deniedScopes, ...rest, message: 'We need all scopes for integrating Suredone with Facebook.' }]
      // eslint-disable-next-line
      if (cms_id) return [{ cms_id }, { stepNumber: 2 }, {}]
      return [{ code, granted_scopes: grantedScopes, denied_scopes: deniedScopes, state }, { stepNumber: 1 }, {}]
    }
    case 'google': {
      // eslint-disable-next-line
      const { code, scope, state, error, ...rest } = paramsObject
      // eslint-disable-next-line
      if (error) return [{}, { stepNumber: 0 }, { state, ...rest, message: 'We need permissions for integrating Suredone with Google.' }]
      return [{ code, scope, state }, { stepNumber: 1 }, {}]
    }
    case 'shopify': {
      const { shop, accessToken } = paramsObject
      return [{ shop, accessToken }, { stepNumber: 1 }, {}]
    }
    case 'miva': {
      const { store_url: storeURL, access_token: accessToken, signature, store_code: storeCode } = paramsObject
      return [{ store_url: storeURL, access_token: accessToken, signature, store_code: storeCode }, { stepNumber: 1 }, {}]
    }
    case 'bigcommerce': {
      const { code, 'scope[]': scopeArray, scope, context } = paramsObject
      return [{ code, scope: scopeArray || scope, context }, { stepNumber: 1 }, {}]
    }
    case 'ebay': {
      const { ebaytkn, tknexp, username, state, code, expires_in: expIn } = paramsObject
      if (code) return [{ state, code, expires_in: expIn }, { stepNumber: 2 }, {}]
      return [{ ebaytkn, tknexp, username }, { stepNumber: 1 }, {}]
    }
    case 'walmart': {
      const { client_secret: clientSecret, client_id: clientId } = paramsObject
      return [{ client_secret: clientSecret, client_id: clientId }, { stepNumber: 1 }, {}]
    }
    case amazonLegacy:
    case amazonNew: {
      const { spapi_oauth_code: spapiOauthCode, selling_partner_id: sellingPartnerId, state } = paramsObject
      return [{ spapi_oauth_code: spapiOauthCode, selling_partner_id: sellingPartnerId, state }, { stepNumber: 1 }, {}]
    }
    case 'etsy': {
      return [paramsObject, { stepNumber: 1 }, {}]
    }
    default:
      return [{}, {}, {}]
  }
}

export default useChannelQueryParams
