import React, { useEffect } from 'react'
import { StatusesList } from '.'
import './Statuses.scss'
import './StatusesFrame.scss'

export const StatusesFrame = () => {
  useEffect(() => {
    document.body.classList.add('statuses-frame')
  }, [])

  return (
    <section>
      <StatusesList isFrame />
    </section>
  )
}
