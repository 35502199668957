import { useEffect, useRef } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'

export function useLazyQueryAsPromise (query, options) {
  // execute function and result<{data, error, called, loading, ...}>
  const [execute, result] = useLazyQuery(query, options)

  // references to the rejecter/resolver and promise
  const resolveRef = useRef()
  const promise = useRef()

  useEffect(() => {
    if (result.called && !result.loading && typeof resolveRef.current === 'object') {
      // resolve or reject depending on the result
      if (result.error) resolveRef.current.reject(JSON.stringify(result.error))
      else resolveRef.current.resolve(result)
      // clear the ref
      resolveRef.current = undefined
    }
  }, [result])

  // we could use a useCallback but if the query changes, execute could be redefined and then, a promise will be pending
  promise.current = (variables) => {
    // execute the query
    execute({ variables })

    // return a promise
    return new Promise((resolve, reject) => {
      // save it on the reference
      resolveRef.current = {
        resolve,
        reject
      }
    })
  }

  return [
    promise.current,
    result
  ]
}
