import React from 'react'
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter'// for optimal bundle size

/**
 * @param {{
 * children: React.JSX
 * language: String
 * className: String
 * key: String
 * }} props
 * @returns
 */
export const CodeBlock = ({ children, language, className, key }) => {
  return (
    <SyntaxHighlighter key={key} style={a11yDark} language={language || 'javascript'} className={className}>
      {children}
    </SyntaxHighlighter>
  )
}
