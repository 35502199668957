import React, { useMemo } from 'react'
import { usePagination, useTable } from 'react-table'
import { Col, Input, Pagination, PaginationItem, PaginationLink, Row, Table } from 'reactstrap'
import Debug from 'debug'
import Loadable from '../../../components/Loadable'

const debug = Debug('sd:ImportFileTable')

const TableBuilder = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
    pageCount,
    pageOptions,
    state: {
      pageIndex,
      pageSize
    }
  } = useTable(
    {
      columns,
      data
    },
    usePagination)

  const validImageFormats = ['png', 'jpg', 'jpeg']

  return (
    <div className='dataTables_wrapper dt-bootstrap4'>
      <Row className='justify-content-between my-1'>
        <Col className='col-4'>
          <span>Page{' '} <strong>{pageIndex + 1} of {pageOptions.length}</strong>{' '}</span>
          <span>
            | Go to page:{' '}
            <input
              type='number'
              defaultValue={pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
              }}
              style={{ width: '100px' }}
            />
          </span>{' '}
        </Col>
        <Col className='col-auto'>
          <Input
            type='select'
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value))
            }}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Input>
        </Col>
      </Row>
      <div className='table-responsive'>
        <Table
          bordered
          striped
          hover
          responsive
          {...getTableProps()}
          className='table dt-responsive nowrap w-100 dataTable dtr-inline'
          style={{ borderCollapse: 'collapse' }}
        >
          <thead>
            {
              headerGroups.map((headerGroup, index) => (
                <tr
                  key={index}
                  {...headerGroup.getHeaderGroupProps()}
                  style={{ maxWidth: '40px', overflow: 'auto', textAlign: 'center' }}
                >
                  {headerGroup.headers.map(column => (
                    column.Header === 'headerID' || column.Header === ''
                      ? null
                      : <th key={column.Header} {...column.getHeaderProps()}>{column.render('Header')}</th>
                  ))}
                </tr>
              ))
            }
          </thead>
          <tbody {...getTableBodyProps()}>
            {
              page.map((row, rowIndex) => {
                prepareRow(row)
                return (
                  <tr key={rowIndex} {...row.getRowProps()}>
                    {row.cells.map((cell, cellIndex) => {
                      const isAnImage = cell.value && validImageFormats.includes(cell.value.split('.').pop())
                      return (
                        <td
                          key={cellIndex}
                          {...cell.getCellProps()}
                          style={{
                            tableLayout: 'fixed',
                            width: '20px',
                            overflow: 'hidden',
                            wordWrap: 'break-word'
                          }}
                        >
                          {isAnImage
                            ? <img src={cell.value} alt='Product media' style={{ maxWidth: '50px' }} />
                            : cell.render('Cell')}
                          {}
                        </td>
                      )
                    })}
                  </tr>
                )
              })
            }
          </tbody>
        </Table>
      </div>
      <Pagination className='row justify-content-center'>
        <PaginationItem disabled={!canPreviousPage} onClick={() => gotoPage(0)}>
          <PaginationLink first />
        </PaginationItem>
        {' '}
        <PaginationItem disabled={!canPreviousPage} onClick={() => previousPage()}>
          <PaginationLink previous />
        </PaginationItem>
        {' '}
        <PaginationItem disabled={!canNextPage} onClick={() => nextPage()}>
          <PaginationLink next />
        </PaginationItem>
        {' '}
        <PaginationItem disabled={!canNextPage} onClick={() => gotoPage(pageCount - 1)}>
          <PaginationLink last />
        </PaginationItem>
        {' '}
      </Pagination>
    </div>
  )
}

const ImportFileTable = props => {
  const { tableHeaders, tableData, loading, error } = props

  const mapColumns = {}

  if (tableHeaders) {
    tableHeaders.forEach(header => { mapColumns[header.id] = header.name })
  }

  const columnsFactory = useMemo(
    () => [
      {
        Header: 'headerID',
        columns: mapColumns && Object.keys(mapColumns).map(column => {
          return { Header: mapColumns[column], id: column, accessor: mapColumns[column].toLowerCase() }
        })
      }
    ],
    [mapColumns]
  )

  const dataFactory = useMemo(
    () => {
      if (Array.isArray(tableData)) {
        return tableData && tableData.map(row => {
          const data = {}
          row.data.forEach(column => {
            const columnId = mapColumns[column.columnId]
            data[columnId] = column.value
          })
          return data
        })
      }
    },
    [tableData, mapColumns]
  )

  return (
    <>
      <Loadable
        inline
        loading={loading}
        error={error}
        content={
          <>
            <TableBuilder columns={columnsFactory} data={dataFactory} />
          </>
        }
      />
    </>
  )
}

export default ImportFileTable

debug('loaded')
