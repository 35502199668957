import { useLazyQuery } from '@apollo/react-hooks'
import { GET_BULK_STATUS_SUMMARY } from '../scenes/Channel/ChannelImportPage/graphqlCommon'
import { MILLISECONDS_TO_REFRESH_BSS } from '../scenes/Channel/ChannelImportPage/ImportElements'

const debug = require('debug')('sd:useBulkStatusSummary')

export const useBulkStatusSummary = ({ legacyJobId, avoidRefetchBSS, setAvoidRefetchBSS }) => {
  const [getBulkStatusSummary, { data, loading, error, called }] = useLazyQuery(
    GET_BULK_STATUS_SUMMARY,
    {
      fetchPolicy: 'no-cache',
      variables: { jobId: legacyJobId },
      pollInterval: avoidRefetchBSS ? 60000 : MILLISECONDS_TO_REFRESH_BSS,
      onCompleted: resp => {
        const { BulkStatusSummary } = resp || {}
        const { status: bulkStatus } = BulkStatusSummary || {}
        if (bulkStatus === 'complete') return setAvoidRefetchBSS(true)
      }
    })

  const { BulkStatusSummary } = data || {}
  const { status: bulkStatus } = BulkStatusSummary || {}
  debug('== STATUS ==>', bulkStatus)

  return [getBulkStatusSummary, { data, loading, error, called }]
}
