import React, { useEffect } from 'react'
import gql from 'graphql-tag'
import { Row, Col, Card } from 'reactstrap'
import { useQualifications } from '../../hooks'
import { snakeToCamel } from '../Qualifications'
import { assets } from '../../img/illustrations'
import { useMutation } from '@apollo/react-hooks'
import { useStores } from '../../stores/RootStore'
import { objectKeyMap } from '../../components/utils'

const debug = require('debug')('sd:WizardRegistration1')

const REGISTER_USER = gql`
  mutation RegisterUser($input: RegistrationData!) {
    RegisterUser(input: $input)  {
      ... on Organization {
        id
        name
        email
        timezone
      }
    }
  }
`

const Contact = ({ userId, timezone, setModalUserActionsEnabled, setModalState, setModalShowCloseOnError }) => {
  const { theSalvationArmy, gizmo } = assets
  const { stores: { auth } } = useStores()
  const { isFromExternalAuthChannel } = auth || {}
  const [registerUser, { loading }] = useMutation(REGISTER_USER,
    {
      onCompleted: (e) => {
        clearStorage()
      },
      onError: (e) => {
        setModalShowCloseOnError(e)
      }
    }
  )
  const initialRegistrationCallback = (storedValue) => {
    const { email, skuCount, ordersPerMonth, gmvPerMonth, primaryCategory, currentChannels, wantedChannels, ...otherValues } = storedValue
    registerUser({
      variables: {
        input: {
          ...objectKeyMap(otherValues, snakeToCamel),
          currentChannels: currentChannels?.map(i => i?.name),
          wantedChannels: wantedChannels?.map(i => i?.name),
          skuCount,
          ordersPerMonth,
          gmvPerMonth,
          primaryCategory,
          timezone,
          isFromExternalAuthChannel
        }
      }
    })
  }

  const { clearStorage } = useQualifications(initialRegistrationCallback)

  useEffect(() => {
    if (loading) {
      // Open modal to show video while user migration is happening
      setModalState(true)
      setModalUserActionsEnabled(false)
    } else {
      setModalUserActionsEnabled(true)
    }
  }, [loading, setModalState, setModalUserActionsEnabled])

  return (
    <form className='custom auth-form contact' id='registration-contact-form'>
      <Row>
        <Col xs={12} md={6} className='left-wrapper d-flex flex-wrap border-right px-4 px-lg-5 align-items-start'>

          <Row>
            <Col xs={12} className='mt-0 mb-4 d-none d-md-block'>
              <h1>The Power of SureDone</h1>
            </Col>

            <Col xs={12} className='mb-4'>
              <p className='mb-2 mt-3' style={{ fontSize: '.95rem' }}>See how easy SureDone is by:</p>
              <ul className='show-checks pl-4 ml-n1'>
                <li className='my-2'>Connecting to your sales channels</li>
                <li className='my-2'>Importing active listings and inventory</li>
                <li className='my-2'>Creating new products and variations</li>
                <li className='my-2'>Listing and modifying products</li>
                <li className='my-2'>Fulfilling orders and adding tracking</li>
              </ul>
            </Col>
          </Row>
        </Col>

        <Col xs={12} md={6} className='right-wrapper px-4 px-lg-5'>
          <div className='testimonials d-flex flex-column'>
            <Card className='shadow-lg rounded-lg'>
              <Row>
                <Col xs={12} sm={4} className='d-flex justify-content-center align-items-center pt-sm-4 pb-sm-4 pl-sm-4 p-0 mt-4 mt-sm-0'>
                  {theSalvationArmy}
                </Col>
                <Col xs={12} sm={8} className='p-4'>
                  <blockquote className='blockquote text-left border-0 p-0 m-0'>
                    <p>SureDone is the best choice for selling on eBay, Amazon, and a custom tailored storefront! As an enterprise customer, they've lived up to my expectations in every way.</p>
                    <footer className='blockquote-footer'><cite title='Salvation Army'>Salvation Army</cite>
                    </footer>
                  </blockquote>
                </Col>
              </Row>
            </Card>

            <Card className='shadow-lg rounded-lg'>
              <Row>
                <Col xs={12} sm={4} className='d-flex justify-content-center align-items-center pt-sm-4 pb-sm-4 pl-sm-4 p-0 mt-4 mt-sm-0'>
                  {gizmo}
                </Col>
                <Col xs={12} sm={8} className='p-4'>
                  <blockquote className='blockquote text-left border-0 p-0 m-0'>
                    <p>SureDone was the only vendor that could offer a customizable solution to fit our needs. Its feature-rich tool set enables us to feed multiple warehouse locations to our one unified eBay marketplace making SureDone our eCommerce platform of choice.</p>
                    <footer className='blockquote-footer'><cite title='Gizmo Trader'>Gizmo Trader</cite>
                    </footer>
                  </blockquote>
                </Col>
              </Row>
            </Card>
          </div>
        </Col>
      </Row>
    </form>
  )
}

export { Contact }
debug('loaded')
