import React from 'react'
import { useQuery } from '@apollo/client'
import { GET_SAVED_QUERIES } from './commonQueries'
import { makeSearch, maxHeightModifiers } from './utils'
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle, Spinner } from 'reactstrap'

export const SavedSearches = () => {
  const { loading, data, error } = useQuery(GET_SAVED_QUERIES)
  const { GetSavedQueries: queries } = data || {}

  return (
    <UncontrolledDropdown className='my-2 ml-1'>
      <DropdownToggle
        caret
        color='primary'
        style={{ backgroundColor: '#346cb0' }}
      >
        Saved <span className='d-sm-none d-xl-inline'>Searches</span>
      </DropdownToggle>
      <DropdownMenu modifiers={maxHeightModifiers}>
        {loading && <DropdownItem disabled><Spinner size='sm' className='mr-2' />Loading saved searches</DropdownItem>}
        {error && <DropdownItem disabled>Something went wrong. Please try again later.</DropdownItem>}
        {queries?.length === 0 && <DropdownItem disabled>No saved searches</DropdownItem>}
        {queries && queries.map((query, idx) => (
          <DropdownItem key={idx} onClick={() => makeSearch(query)}>
            {query?.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}
