export const searchboxSuggestions = {
  Products: [
    {
      key: 'sd-1234',
      hint: 'search anything',
      example: 'sd-1234',
      explanation: 'Items that match sd-1234'
    },
    {
      key: 'sku:',
      hint: 'contains',
      example: 'sku:abcd',
      explanation: 'Items whose sku contains abcd'
    },
    {
      key: 'guid:',
      hint: 'contains',
      example: 'guid:1234',
      explanation: 'Items whose guid contains 1234'
    },
    {
      key: 'stock:=',
      hint: 'equal to',
      example: 'stock:=5',
      explanation: 'Items with stock equals to 5'
    },
    {
      key: 'title:',
      hint: 'contains',
      example: 'title:Wheel',
      explanation: 'Items whose title contains Wheel'
    },
    {
      key: 'price:=',
      hint: 'equal to',
      example: 'price:=19.99',
      explanation: 'Items price equals to $19.99'
    },
    {
      key: 'color:=',
      hint: 'equal to',
      example: 'color:=blue',
      explanation: 'Items whose color is blue'
    },
    {
      key: 'datesold:=',
      hint: 'equal to',
      example: 'datesold:=2020-10-24',
      explanation: 'Items sold on October 24, 2020'
    },
    {
      key: 'sku:=',
      hint: 'equal to',
      example: 'sku:=abcd',
      explanation: 'Items with sku exactly the same as abcd'
    },
    {
      key: 'guid:=',
      hint: 'equal to',
      example: 'guid:=1234',
      explanation: 'Items with guid exactly the same as 1234'
    },
    {
      key: 'title:=',
      hint: 'equal to',
      example: 'title:=Wheel',
      explanation: 'Items with title exactly the same as Wheel'
    },
    {
      key: 'title:-=',
      hint: 'not equal to',
      example: 'title:-=Wheel',
      explanation: 'Items with title not equal to Wheel'
    },
    {
      key: 'title:^',
      hint: 'starts with',
      example: 'title:^Wheel',
      explanation: 'Items whose title starts with Wheel'
    },
    {
      key: 'title:$',
      hint: 'ends with',
      example: 'title:Wheel$',
      explanation: 'Items whose title ends with Wheel'
    },
    {
      key: 'stock:>',
      hint: 'more than',
      example: 'stock:>5',
      explanation: 'Items that are over 50 in stock'
    },
    {
      key: 'stock:<',
      hint: 'less than',
      example: 'stock:<5',
      explanation: 'Items that are under 50 in stock'
    },
    {
      key: 'stock:>=',
      hint: 'greater than or equal to',
      example: 'stock:>=50',
      explanation: 'Items that have 50 or more in stock'
    },
    {
      key: 'stock:<=',
      hint: 'less than or equal to',
      example: 'stock:<=50',
      explanation: 'Items that have 50 or less in stock'
    },
    {
      key: 'price:>',
      hint: 'more than',
      example: 'price:>19.99',
      explanation: 'Items priced over $19.99'
    },
    {
      key: 'price:<',
      hint: 'less than',
      example: 'price:<19.99',
      explanation: 'Items priced under $19.99'
    },
    {
      key: 'price:>=',
      hint: 'greater than or equal to',
      example: 'price:>=19.99',
      explanation: 'Items priced at $19.99 or more'
    },
    {
      key: 'price:<=',
      hint: 'less than or equal to',
      example: 'price:<=19.99',
      explanation: 'Items priced at 19.99 or less'
    },
    {
      key: 'status:=publish',
      hint: 'equal to',
      example: 'status:=publish',
      explanation: 'Items whose status is publish'
    },
    {
      key: 'status:=disable',
      hint: 'equal to',
      example: 'status:=disable',
      explanation: 'Items whose status is disable'
    },
    {
      key: 'status:=draft',
      hint: 'equal to',
      example: 'status:=draft',
      explanation: 'Items whose status is draft'
    },
    {
      key: 'status:=attribute',
      hint: 'equal to',
      example: 'status:=attribute',
      explanation: 'Items whose status is attribute'
    },
    {
      key: 'sku_type:=variation',
      example: 'sku_type:=variation',
      explanation: 'Items that have product variations'
    }
  ],
  Orders: [
    {
      key: 'sd-1234',
      hint: 'search anything',
      example: 'sd-1234',
      explanation: 'Orders that match sd-1234'
    },
    {
      key: 'date:2023-01-27',
      explanation: 'Orders placed on January 27th, 2023'
    },
    {
      key: 'date:>2023-01-27',
      explanation: 'Orders that occurred after January 27th, 2023'
    },
    {
      key: 'date:<2023-01-27',
      explanation: 'Orders that occurred before January 27th, 2023'
    },
    {
      key: 'date:="2023-01-27 00:00:01"',
      explanation: 'Orders occurred exactly on January 27, 2023 at 00:00:01hs'
    },
    {
      key: 'items:=2',
      explanation: 'Orders that have exactly two items'
    },
    {
      key: 'items:>2',
      explanation: 'Orders that have more than two items'
    },
    {
      key: 'shippingstatus:=complete',
      explanation: 'Orders that have a shipping status of complete'
    },
    {
      key: 'shippingstatus:=incomplete',
      explanation: 'Orders that have a shipping status of incomplete'
    },
    {
      key: 'shiptracking:=9991231293',
      explanation: 'Orders that have a ship tracking number of 9991231293'
    },
    {
      key: 'shippingcountry:=us',
      explanation: 'Orders with a US shipping address'
    }
  ],
  // The following Categories and Pages suggestions are shown only for users with SureDone Storefront enabled
  Categories: [
    {
      key: 'sd-1234',
      explanation: 'Categories that match sd-1234'
    },
    {
      key: 'title:=Motors',
      explanation: 'Category title is Motors'
    },
    {
      key: 'title:Motors',
      explanation: 'Category title includes Motors'
    },
    {
      key: 'status:publish',
      explanation: 'Category status is publish'
    },
    {
      key: 'status:disable',
      explanation: 'Category status is disable'
    },
    {
      key: 'state:active',
      explanation: 'Category satate is active'
    }
  ],
  Pages: [
    {
      key: 'sd-1234',
      explanation: 'Pages that match sd-1234'
    },
    {
      key: 'title:=Home',
      explanation: 'Page title is Home'
    },
    {
      key: 'title:Home',
      explanation: 'Page title includes Home'
    },
    {
      key: 'status:publish',
      explanation: 'Page status is publish'
    },
    {
      key: 'status:draft',
      explanation: 'Page status is draft'
    },
    {
      key: 'status:disable',
      explanation: 'Page status is disable'
    }
  ]
}
