import React, { useEffect } from 'react'
import { Row } from 'reactstrap'
import { Loadable, Page } from '../../components'
import { useStores } from '../../stores/RootStore'
// import { BigMetrics } from './atoms/BigMetrics'
// import { OnBoardingSteps } from './atoms/OnBoardingSteps'
import { PrompsAsAlerts } from './atoms/PrompsAsAlerts'
import { Todo2 } from './atoms/Todo2'
import { Channels } from './atoms/Channels'
import { News } from './atoms/News'
import { Graphs } from './graphs/Graphs'
import 'react-datepicker/dist/react-datepicker.css'
import './Dashboard.css'

const data = {
  welcomeMessage: 'Here’s what’s happening with your business today.',
  // Show promps as alerts
  prompsAsAlerts: false,
  // Metrics section
  salesText: 'Sales',
  sales: '104,890',
  linkToSales: '/sales',
  //
  ordersText: 'Orders',
  orders: 88,
  linkToOrders: '/orders',
  //
  productsText: 'Products',
  products: 32,
  linkToProducts: '/products',
  //
  channelsText: 'Active channels',
  linkToChannels: '/channels/add',
  // Todo section
  toDoTitle: 'Action Items',
  webinarPrompt: {
    startMessage: 'Sign up for our "Ask us Anything" webinar where we\'ll review how to use SureDone and answer your product questions.',
    linkText: 'Start',
    url: 'https://suredone.zoom.us/webinar/register/3516347486756/WN_d47o3tpXQny00czUqEoqEg'
  },
  channelsPrompt: {
    startMessage: 'Add more channels.',
    linkText: 'Start',
    url: '/channels/add'
  },
  productsPrompt: {
    startMessage: 'Create or import products.',
    linkText: 'Start',
    url: '/#!/products '
  },
  businessNamePrompt: {
    startMessage: 'Update your organization name.',
    linkText: 'Start',
    url: '/settings/organization/profile'
  },
  userNamePrompt: {
    startMessage: 'Update your username.',
    linkText: 'Start',
    url: '/settings/organization/profile#username'
  },
  settingsPrompt: {
    startMessage: 'Complete your business settings.',
    linkText: 'Start',
    url: '/settings/organization/profile'
  },
  logoUploadPrompt: {
    startMessage: 'Upload your logo.',
    linkText: 'Start',
    url: '/settings/organization/profile'
  },
  shippingSettingsPrompt: {
    startMessage: 'Complete your shipping settings.',
    linkText: 'Start',
    url: '/settings/shipping/general'
  },
  shippingAuthPrompt: {
    startMessage: 'Authorize a shipping carrier.',
    linkText: 'Start',
    url: '/settings/shipping/general'
  },
  readyOrders: 'orders ready to ship',
  readyOrdersNumber: 45,
  readyOrdersLink: '/orders/ready',
  unassignedOrders: 'unassigned orders',
  unassignedOrdersNumber: 9,
  unassignedOrdersLink: '/orders/unassigned',
  unreadMessages: 'unread messages',
  unreadMessagesNumber: 16,
  messagesLink: '/messages',
  // Channels section
  channelsTitle: 'Channels',
  // Announcements section
  newsTitle: 'Announcements',
  amountOfNews: 6
}

const Dashboard = () => {
  const { stores: { auth } } = useStores()
  const { user } = auth || {}
  const { firstName } = user || {}
  const { welcomeMessage, prompsAsAlerts } = data

  // Fixes a legacy statuses iframe issue when click browser's back button
  useEffect(() => {
    const el = document.querySelector('body.statuses-frame')
    if (el) window.history.back() // Means that the dashboard is shown, go back again
    return () => document.body.classList.remove('statuses-frame')
  }, [])

  return (
    <Page
      title={firstName ? `Hi, ${firstName}` : 'Hi there!'}
      description={welcomeMessage}
    >
      <Loadable
        content={
          <>
            {/* <BigMetrics data={data} /> */}

            {prompsAsAlerts && <PrompsAsAlerts data={data} />}

            {/* Welcome popup */}
            {/* <OnBoardingSteps organizationId={organizationId} /> */}

            <Row className='dashboard-cards'>
              <Todo2 data={data} user={user} permissions={auth.permissions} />
              <Channels data={data} />
              <News data={data} />
            </Row>

            {/* Only users with 'View Dashboard' scope can see the metrics */}
            {auth.permissions.hasScope('organization:dashboard:ui:read') &&
              <Graphs />}
          </>
        }
      />
    </Page>
  )
}

export { Dashboard }
