import React from 'react'
import { CodeBlock } from '../../../components'
import { InstructionCollapse } from '../InstructionCollapse'

const code = `<script>
    if (!window.shopData) window.shopData = {}
    if (!window.shopData.suredoneID) window.shopData.suredoneID = SUREDONEID
    if (!window.shopData.storefront) window.shopData.storefront = 'bigcommerce'
  </script>
  <div id="sd-fitment-search-page-root"></div>
  <script src="https://fitment.suredone.com/bundle.min.js"></script>`

const code2 = `{{#each product_results.products}}
    <script>
      if (!window.sdFitmentData) window.sdFitmentData = {}
      if (!window.sdFitmentData.searchResultSKUs) window.sdFitmentData.searchResultSKUs = []
      window.sdFitmentData.searchResultSKUs.push('{{sku}}')
    </script>
  {{/each}}`

export const BigcommerceSearchPage = () => {
  return (
    <InstructionCollapse id='FitmentSearch' title='6. Add Year Make Model filter to the Search Page'>
      <ol className='pl-3'>
        <li>Edit your theme files</li>
        <li>Go to <code>templates {'>'} pages {'>'} search.html</code></li>
        <li>We need to hide the Bigcommerce native search page in order to replace it with the fitment search page.
          So search for <code>{'<section class="nav">'}</code> and add an id equals to <b>SD-SearchNav</b> (<code>id="SD-SearchNav"</code>). You also have to add <code>style="display: none;"</code> so our widget can handle it.<br />
          Now search for <code>{'<section class="page">'}</code> and do almost the same, this time add an id equals to <b>SD-SearchPage</b> (<code>id="SD-SearchPage"</code>) and the <code>style="display: none;"</code> as well.<br />
        </li>
        <li>
          Now we need to add a widget point of mount. For that, add the following code right after the elements we were editing in the previous step <b>(replace SUREDONEID with your SureDone id)</b>:<br />
          <CodeBlock>{code}</CodeBlock>
        </li>
        <li>
          After all the changes, your <code>search.html</code> file should look like this:
          <img className='mb-0' src='https://assets.suredone.com/688015/logos/fitment-advanced-bigcommerce-searchpage-1.png' alt='Bigcommerce search file summary' />
        </li>
        <li>
          Lastly, go to <code>templates {'>'} components {'>'} search {'>'} product-listing.html</code>, add the following code and click "Save & apply file".
          <CodeBlock className='mt-1 mb-2'>{code2}</CodeBlock>
          <img className='mb-0' src='https://assets.suredone.com/688015/logos/fitment-advanced-bigcommerce-searchpage-2.png' alt='Bigcommerce product-listing file summary' />
        </li>
      </ol>
    </InstructionCollapse>
  )
}
