import { ObjectId } from 'bson'

// Returns a NEW object with the elements filtered by funtion passed
// Applies to inners objects Ex with null filter: {a: null, b: { c: null, d: 2}, e: 3} => {b:{ d:2 }, e:3}
export const filterObject = (obj, filterFunc) =>
  Object.fromEntries(Object.entries(obj).reduce((acum, current) => {
    const [k, v] = current
    if (filterFunc(k, v)) return acum
    if (Array.isArray(v)) return [...acum, [k, v]]
    if (ObjectId.isValid(v)) return [...acum, [k, v]] // Prevent the mongo ObjectId from being filtered
    if (typeof v === 'object') return [...acum, [k, filterObject(v, filterFunc)]]
    return [...acum, [k, v]]
  }, [])
  )

export const objectKeyMap = (obj, fn) =>
  Object.fromEntries(
    Object.entries(obj).map(
      ([k, v], i) => [fn(k), v]
    )
  )

export const isAllowedQualificationKeys = (key) => {
  return (
    key !== 'email' ||
    key !== 'firstName' ||
    key !== 'lastName' ||
    key !== 'organizationId' ||
    key !== 'utm_source' ||
    key !== 'utm_medium' ||
    key !== 'utm_campaign' ||
    key !== 'utm_campaignid' ||
    key !== 'utm_adgroupid' ||
    key !== 'utm_keyword' ||
    key !== 'gclid' ||
    key !== 'MatchType' ||
    key !== 'Distribution' ||
    key !== 'AdID' ||
    key !== 'Placement' ||
    key !== 'adp' ||
    key !== 'affid' ||
    key !== 'isAFit' ||
    key !== 'currentChannels' ||
    key !== 'wantedChannels' ||
    key !== 'actionsToDo' ||
    key !== 'skuCount' ||
    key !== 'ordersPerMonth' ||
    key !== 'gmvPerMonth' ||
    key !== 'primaryCategory' ||
    key !== 'source' ||
    key !== 'signed_payload' ||
    key !== 'plan' ||
    key !== 'company' ||
    key !== 'inPersonDemo' ||
    key !== 'totalPoints')
}
