import React from 'react'
import { Link as RLink } from 'react-router-dom'
import { UncontrolledDropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap'
import { useStores } from '../../stores/RootStore'
import { observer } from 'mobx-react'
import { UserAvatar, RestoreSelf } from '..'
import {
  FaUser as User,
  FaCog as Settings,
  FaSignOutAlt as LogOut,
  FaHeartbeat as SystemStatus,
  FaExternalLinkAlt as ExternalLink,
  FaQuestion as Help,
  FaClipboardList as Guides
} from 'react-icons/fa'
import './UserDropdownMenu.scss'

const UserDropdownMenuContent = ({ auth, user, onClick }) => {
  return (
    <>
      <DropdownItem header className='d-none d-md-block d-lg-none'>
        <User />&nbsp;{user.fullName() || user.primaryEmail}
      </DropdownItem>
      <DropdownItem tag={RLink} color='link' to='/settings/all' onClick={onClick}>
        <Settings /> Settings
      </DropdownItem>
      <DropdownItem tag='a' target='_blank' href='https://support.suredone.com/'>
        <Help /> Help <span><ExternalLink size={10} style={{ marginTop: '-4px' }} /></span>
      </DropdownItem>
      <DropdownItem tag='a' target='_blank' href='https://support.suredone.com/support/solutions'>
        <Guides /> Guides <span><ExternalLink size={10} style={{ marginTop: '-4px' }} /></span>
      </DropdownItem>
      <DropdownItem tag='a' target='_blank' href='https://status.suredone.com'>
        <SystemStatus /> System Status <span><ExternalLink size={10} style={{ marginTop: '-4px' }} /></span>
      </DropdownItem>
      <DropdownItem tag={RLink} color='link' to='/' onClick={_ => auth.logOut()}>
        <LogOut /> Log Out
      </DropdownItem>
      {auth.user.sudoer && <RestoreSelf />}
    </>
  )
}

const UserInfoData = ({ user }) => {
  return (
    <>
      <UserAvatar size={32} user={user} className='mr-2' />
      <span className='account-summary'>
        <span className='account-name'>
          {user.fullName() || user.primaryEmail}
        </span>
        <span className='account-description'>
          {user.fullName() && user.primaryEmail}
        </span>
      </span>
    </>
  )
}

// d-none d-md-flex
const _UserDropdownMenuDesktop = () => {
  const { stores } = useStores()
  const { auth } = stores
  const user = auth.user || {}
  return (
    <UncontrolledDropdown className='d-flex'>
      <DropdownToggle color='none' className='btn-account d-none d-md-flex'>
        <UserInfoData user={user} />
      </DropdownToggle>
      <DropdownMenu className='dashboard-dropdown'>
        <div className='dropdown-arrow ml-3' />
        <UserDropdownMenuContent user={user} auth={auth} />
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

// d-flex d-md-none
const _UserDropdownMenuMobile = ({ setSidebarOpen }) => {
  const { stores } = useStores()
  const { auth } = stores
  const user = auth.user || {}
  return (
    <>
      <UncontrolledDropdown>
        <DropdownToggle caret tag='button' className='btn-account collapsed'>
          <UserInfoData user={user} />
        </DropdownToggle>
        <DropdownMenu className='dropdown-aside collapse dropdown-aside-custom' tag='div'>
          <div>
            <UserDropdownMenuContent user={user} auth={auth} onClick={() => setSidebarOpen(false)} />
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  )
}

const UserDropdownMenuMobile = observer(_UserDropdownMenuMobile)
const UserDropdownMenuDesktop = observer(_UserDropdownMenuDesktop)
export { UserDropdownMenuDesktop, UserDropdownMenuMobile }
