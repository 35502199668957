/**
 * Loadable component
 *
 * This guy just displays a standard loading UI and errors in a uniform way.
 * If loading and error properties are falsy it displays normal content.
 */

import React from 'react'
import { Loading, LoadingInline } from './Loading'
import ErrorAlert from './ErrorAlert'

export default function Loadable ({ loading, error, content, inline, color = 'danger', style, loadingText = false }) {
  if (loading) {
    if (inline) {
      return <div style={style}><LoadingInline loadingText={loadingText} /></div>
    } else {
      return <Loading loadingText={loadingText} />
    }
  }

  if (error) return <ErrorAlert color={color} error={error.message} />

  return content
}

export { Loadable }
