import React from 'react'
import {
  FaAmazon as Amazon,
  FaEbay as Ebay,
  FaFacebook as Facebook,
  FaGoogle as Google,
  FaEtsy as Etsy,
  FaMagento as Magento
} from 'react-icons/fa'
import { amazonNew, amazonLegacy, channelsWithNewUI } from 'suredone-common'

const getLink = (slug, instance) => {
  if (channelsWithNewUI.includes(slug)) return `/channels/${slug}/${instance}`
  if (slug === 'storefront') return `/#!/settings/${slug}`
  return `/#!/settings/plugins/${slug}${instance === 0 ? '' : instance}`
}

const slugToIcon = new Map([
  [amazonLegacy, <Amazon key='Amazon' />],
  [amazonNew, <Amazon key='Amazon' />],
  ['ebay', <Ebay key='Ebay' />],
  ['facebook', <Facebook key='Facebook' />],
  ['etsy', <Etsy key='Etsy' />],
  ['google', <Google key='Google' />],
  ['magento', <Magento key='Magento' />],
  ['magentotwo', <Magento key='Magento' />]
])

const getValue = (stockValues, location) => {
  const findedObject = (stockValues || []).find(i => i.value === location.nodeID)
  return (findedObject || {}).stockField
}

const checkVisibility = (slug, validChannels) => validChannels.includes(slug)

const isNewUIHandlingAddChannel = (slug) => channelsWithNewUI.includes(slug)

// Adds a Hotjar tag when the user authorize a channel
const addHotjarTag = (channelName) => window.hj('tagRecording', [`${channelName.replace(/\s/g, '')}Auth`])

/**
 * As you edit the field mappings, an array of objects with duplicated values is enlarged. This function fixes it.
 * It also receives a second array of values to be removed from the original array.
 * @param {Array} valuesToBeCleaned
 * @param {Array} valuesToRemove
 * @returns {Array}
 */
const cleanPayload = (valuesToBeCleaned, valuesToRemove) => {
  return valuesToBeCleaned
    ?.filter(i => !valuesToRemove?.includes(i.organizationFieldName))
    ?.reverse()
    ?.filter((v, i, a) => a.findIndex(t => (t.organizationFieldName === v.organizationFieldName)) === i)
}

const hasChannel = (channel, channels) => channels?.some(i => i?.channel?.slug === channel)

const getChannelInstances = (channel, channelInstances) => channelInstances?.filter(i => i?.channel?.slug === channel)

export { slugToIcon, getLink, getValue, checkVisibility, isNewUIHandlingAddChannel, addHotjarTag, cleanPayload, hasChannel, getChannelInstances }

export const getAuthorizedChannels = channelInstances => channelInstances?.filter(i => i.authStatus === 'complete')
