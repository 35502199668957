import React from 'react'
import { useHistory } from 'react-router'
import { calculateRedirectUrl } from './utils'
import { ActionButton } from '../../components'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

export const TalkNextHourModal = ({ isOpen, isAFit, inPersonDemo }) => {
  const history = useHistory()

  const handleRedirect = talkWithinHour => history.push(calculateRedirectUrl(isAFit, inPersonDemo, talkWithinHour))

  return (
    <Modal centered isOpen={isOpen}>
      <ModalHeader className='pb-2'>Let's talk</ModalHeader>
      <ModalBody>
        <p>Would you like to speak to a SureDone Account Specialist within the next hour?</p>
      </ModalBody>
      <ModalFooter>
        <ActionButton onClick={() => handleRedirect(false)}>
          No
        </ActionButton>
        <ActionButton color='primary' onClick={() => handleRedirect(true)}>
          Yes
        </ActionButton>
      </ModalFooter>
    </Modal>
  )
}
