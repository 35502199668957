import React from 'react'
import { isAmazon } from 'suredone-common'
import { ChannelReport } from './ChannelReport'

export const ChannelReportsContainer = ({ slug, instanceNumber }) => {
  if (isAmazon(slug)) {
    return <ChannelReport reportType='AmazonBuyBox' title='Buy Box Report' slug={slug} instanceNumber={instanceNumber} />
  }

  return null
}
