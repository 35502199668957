import React from 'react'
import auth01 from './auth01.png'
import auth02 from './auth02.png'
import auth03 from './auth03.png'

// Instructions for users coming from Shopify to install the app
export const stepInstructions = {
  Permissions: {
    instructions: {
      stepInstructions: [
        {
          title: 'Log in in to your Shopify store',
          description: (
            <>
              Make sure you are logged in to the Shopify store you want to authorize. You can do it by going to{' '}
              <a href='https://www.shopify.com/login' target='_blank' rel='noopener noreferrer'>Shopify login</a>.
            </>
          )
        },
        {
          // TODO:: provide app url once it was published
          title: 'Install the SureDone app',
          description: (
            <>
              Go to <a href='https://apps.shopify.com/search?q=suredone' target='_blank' rel='noopener noreferrer'> this link</a> and install the SureDone app. Once installed, follow the instructions to finalize the authorization.
            </>
          )
        }
      ]
    }
  }
}

// Instructions for SureDone users who will authorize through the custom app (using /channels/shopify/{instance}/authorization/permissions?useCustomApp=true)
export const customAppStepInstructions = [
  {
    title: 'Create a Custom App',
    description: (
      <>
        1. From your Shopify admin page, click <b>Settings {'>'} Apps and sales channels</b>. <br />
        2. Click <b>Develop apps for your store</b>. If necessary, click <b>Allow custom app development</b>. <br />
        3. Click <b>Create an app</b>. <br />
        4. In the modal window, enter the <b>App name</b> and select an <b>App developer</b>. <br />
        5. Click <b>Create app</b>.
      </>
    ),
    imgSrc: auth01,
    imgAlt: 'Login with Shopify and create a Custom App'
  },
  {
    title: 'Select API scopes',
    description: (
      <>
        1. Click <b>Configure Admin API Scopes</b>. <br />
        2. <b>Check all boxes</b> for all of the various permissions where possible. At minimum you need to select: <br />
        <code> write_products</code>,
        <code> write_product_listings</code>,
        <code> write_orders</code>,
        <code> write_inventory</code>,
        <code> read_locations</code>,
        <code> write_fulfillments</code>,
        <code> write_assigned_fulfillment_orders</code>,
        <code> write_third_party_fulfillment_orders</code>,
        <code> write_merchant_managed_fulfillment_orders</code>,
        <code> write_shipping</code>,
        <code> write_price_rules</code>.<br />
        3. Click <b>Save</b>.
      </>
    ),
    imgSrc: auth02,
    imgAlt: 'Review and Grant API scopes'
  },
  {
    title: 'Install the app',
    description: (
      <>
        1. Click <b>Install app</b> <br />
        2. Go to <b>API Credentials</b>, under <b>Admin API access token</b> click <b>Reveal token once</b> and copy it. <br />
        3. Provide the <b>token</b> in the form below along with your <b>shop url</b>.
      </>
    ),
    imgSrc: auth03,
    imgAlt: 'Apply User Roles'
  }
]
