export const searachableKeys = {
  Product: [
    { key: 'id', type: 'product', example: 'id:=234' },
    { key: 'sku', type: 'product', example: 'sku:=XYZ12345' },
    { key: 'guid', type: 'product', example: 'guid:=6B29FC40' },
    { key: 'title', type: 'product', example: 'title:=Wheel' },
    { key: 'stock', type: 'product', example: 'stock:>50' },
    { key: 'total_stock', type: 'product', example: 'total_stock:=75' },
    { key: 'kitlinks', type: 'product', example: 'kitlinks:=ABC123' },
    { key: 'cost', type: 'product', example: 'cost:>=price' },
    { key: 'price', type: 'product', example: 'price:<150' },
    { key: 'discountprice', type: 'product', example: 'discountprice:>50' },
    { key: 'size', type: 'product', example: 'size:=xxl' },
    { key: 'color', type: 'product', example: 'color:blue' },
    { key: 'condition', type: 'product', example: 'condition:=new' },
    { key: 'brand', type: 'product', example: 'brand:apple' },
    { key: 'material', type: 'product', example: 'material:=leather' },
    { key: 'longdescription', type: 'product', example: 'longdescription:"High-strength steel wheel"' },
    { key: 'upc', type: 'product', example: 'upc:=' },
    { key: 'status', type: 'product', example: 'status:=publish' },
    { key: 'sku_type', type: 'product', example: 'sku_type:=variation' },
    { key: '{channel}sku', type: 'product', example: 'ebaysku:-=' },
    { key: '{channel}{instance}sku', type: 'product', example: 'ebay2sku:-=' },
    { key: 'iskit', type: 'product', example: 'iskit:1' },
    { key: 'media{1..10}', type: 'product', example: 'media1:https://image.com' },
    { key: 'mediax', type: 'product', example: 'mediax:https://image.com' },
    { key: 'datesold', type: 'product', example: 'datesold:>=2016-05-01T01:00:00' },
    { key: 'mediacount', type: 'product', example: 'mediacount:=5' },
    { key: 'weight', type: 'product', example: 'weight:>4.2' },
    { key: 'boxlength', type: 'product', example: 'boxlength:11' },
    { key: 'boxwidth', type: 'product', example: 'boxwidth:8.5' },
    { key: 'boxheight', type: 'product', example: 'boxheight:5' },
    { key: 'boxweight', type: 'product', example: 'boxweight:>3' },
    { key: 'dimweight', type: 'product', example: 'dimweight:=4' }
  ],
  Order: [
    { key: 'oid', type: 'order', example: 'oid:=242' },
    { key: 'order', type: 'order', example: 'order:amzn' },
    { key: 'ordernumber', type: 'order', example: 'ordernumber:=SD2404' },
    { key: 'channel', type: 'order', example: 'channel:=ebay' },
    { key: 'instance', type: 'order', example: 'instance:=2' },
    { key: 'items', type: 'order', example: 'items>=2' },
    { key: 'date', type: 'order', example: 'date:>=2016-04-01T07:00:00' },
    { key: 'dateutc', type: 'order', example: 'dateutc:>=2016-05-01T01:00:00' },
    { key: 'dateupdated', type: 'order', example: 'dateupdated:>2015-07-08' },
    { key: 'dateupdatedutc', type: 'order', example: 'dateupdatedutc:<2015-07-08' },
    { key: 'comments', type: 'order', example: 'comments:"Special delivery"' },
    { key: 'internalnotes', type: 'order', example: 'internalnotes:="Ship from Friday"' },
    { key: 'status', type: 'order', example: 'status:ORDERED' },
    { key: 'transactionid', type: 'order', example: 'transactionid:=123' },
    { key: 'correlationid', type: 'order', example: 'correlationid:=532' },
    { key: 'paymentdate', type: 'order', example: 'paymentdate:=2015-07-08' },
    { key: 'paymentstatus', type: 'order', example: 'paymentstatus:=COMPLETE' },
    { key: 'shippingcarrier', type: 'order', example: 'shippingcarrier:=Fedex' },
    { key: 'shippingservice', type: 'order', example: 'shippingservice:=Standard' },
    { key: 'shippingstatus', type: 'order', example: 'shippingstatus:=INCOMPLETE' },
    { key: 'shiptracking', type: 'order', example: 'shiptracking:=43256' },
    { key: 'total', type: 'order', example: 'total:>15.50' },
    { key: 'itemtotal', type: 'order', example: 'itemtotal:<=175.00' },
    { key: 'shippingtotal', type: 'order', example: 'shippingtotal:=0' },
    { key: 'handlingtotal', type: 'order', example: 'handlingtotal:>=12' },
    { key: 'taxtotal', type: 'order', example: 'taxtotal:17.50' },
    { key: 'discounttotal', type: 'order', example: 'discounttotal:=0' },
    { key: 'discountcode', type: 'order', example: 'discountcode:=' },
    { key: 'currency', type: 'order', example: 'currency:=usd' },
    { key: 'refundid', type: 'order', example: 'refundid:=abc123' },
    { key: 'refunddate', type: 'order', example: 'refunddate:2015-07-08' },
    { key: 'refunddetails', type: 'order', example: 'refunddetails:"Partially refunded"' },
    { key: 'email', type: 'order', example: 'email:=user@suredone.com' },
    { key: 'billingcountry', type: 'order', example: 'billingcountry:=us' },
    { key: 'billingfirstname', type: 'order', example: 'billingfirstname:=John' },
    { key: 'billingmiddlename', type: 'order', example: 'billingmiddlename:=Joseph' },
    { key: 'billinglastname', type: 'order', example: 'billinglastname:=Smith' },
    { key: 'billingstreet1', type: 'order', example: 'billingstreet1:="123 Main Street"' },
    { key: 'billingstreet2', type: 'order', example: 'billingstreet2:="456 Elm Avenue"' },
    { key: 'billingstreet3', type: 'order', example: 'billingstreet3:="789 Oak Lane"' },
    { key: 'billingcity', type: 'order', example: 'billingcity:="New York"' },
    { key: 'billingstateprovince', type: 'order', example: 'billingstateprovince:=NY' },
    { key: 'billingpostalcode', type: 'order', example: 'billingpostalcode:=10010' },
    { key: 'billingphone', type: 'order', example: 'billingphone:="+61 2 1234 5678"' },
    { key: 'billingphone2', type: 'order', example: 'billingphone2:="+54 341 645 9064"' },
    { key: 'shippingcountry', type: 'order', example: 'shippingcountry:=ca' },
    { key: 'shippingfirstname', type: 'order', example: 'shippingfirstname:=Peter' },
    { key: 'shippingmiddlename', type: 'order', example: 'shippingmiddlename:=Liam' },
    { key: 'shippinglastname', type: 'order', example: 'shippinglastname:=Williams' },
    { key: 'shippingstreet1', type: 'order', example: 'shippingstreet1:="321 Maple Drive"' },
    { key: 'shippingstreet2', type: 'order', example: 'shippingstreet2:="654 Cedar Street"' },
    { key: 'shippingstreet3', type: 'order', example: 'shippingstreet3:="987 Pine Avenue"' },
    { key: 'shippingcity', type: 'order', example: 'shippingcity:=Cityville' },
    { key: 'shippingstateprovince', type: 'order', example: 'shippingstateprovince:=Hamletown' },
    { key: 'shippingpostalcode', type: 'order', example: 'shippingpostalcode:=90210' },
    { key: 'shippingphone', type: 'order', example: 'shippingphone:="+1 555-123-4567"' },
    { key: 'shippingphone2', type: 'order', example: 'shippingphone2:="+44 20 1234 5678"' }
  ]
}
