/**
 npm bowser https://www.npmjs.com/package/bowser
 List of platforms, browsers and OS https://github.com/lancedikson/bowser/blob/HEAD/src/constants.js
 This file was made according to polyfill.io supported browsers https://cdn.polyfill.io/v3/supported-browsers/

 Settings for any particular OS or platform has more priority and redefines settings of standalone browsers.
 Thus, you can define OS or platform specific rules and they will have more priority in the end.
*/

// If a browser version isn't on the list, the user will see a notification asking for a browser update
export const preferredBrowsers = {
  // If a browser isn't here, that means we don't support it
  chrome: '>=70',
  safari: '>=11',
  firefox: '>=80',
  edge: '>=79',
  android: '>=6',

  // Per platform (mobile, desktop or tablet). For different versions between platforms
  mobile: {
    safari: '>=11',
    chrome: '>=70'
  }
}
