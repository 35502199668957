import React, { useState } from 'react'
import Highlighter from 'react-highlight-words'
import { CustomInput } from 'reactstrap'
import { FaExclamationTriangle as Warning } from 'react-icons/all'

// These fields can't be selected/deselected
const disabledFields = ['action', 'guid']

/**
 * One of these is returned for each table header. It has a checkbox
 * so the user can choose to skip the update of this field or not.
 * @param {{field: {key: string}, skipUpdateFields: [string], searchTerms: [string], parentCallback: function}} props
 * @returns {React.ReactHTML}
 */
export const ImportField = ({ field, skipUpdateFields, searchTerms, parentCallback }) => {
  const { key } = field
  const [checked, setChecked] = useState(skipUpdateFields.includes(key))

  const handleChange = (event) => {
    setChecked(event.target.checked)
    parentCallback(event, field)
  }

  if (disabledFields.includes(key)) {
    return (
      <label htmlFor={'field-' + key} className='d-flex w-100 align-items-center py-1 px-2 mb-0 text-muted disabled-field' style={{ pointerEvents: 'none' }}>
        <div className='pl-0 pr-1'>
          <CustomInput
            size='large'
            type='checkbox'
            id={'field-' + key}
            style={{ pointerEvents: 'none' }}
          />
        </div>
        <p className='mb-0'>
          <strong>
            <Highlighter
              autoEscape
              className='mr-1'
              highlightTag='mark'
              searchWords={searchTerms || []}
              textToHighlight={key || 'Unnamed field'}
            />
          </strong>
          <span>- required field</span>
        </p>
      </label>
    )
  }

  return (
    <label htmlFor={'field-' + key} className='d-flex w-100 align-items-center py-1 px-2 mb-0' style={{ cursor: 'pointer' }}>
      <div className='pl-0 pr-1'>
        <CustomInput
          size='large'
          type='checkbox'
          checked={checked}
          id={'field-' + key}
          onChange={e => handleChange(e)}
        />
      </div>
      <p className='mb-0'>
        <strong>
          <Highlighter
            autoEscape
            className='mr-1'
            highlightTag='mark'
            searchWords={searchTerms || []}
            textToHighlight={key || 'Unnamed field'}
          />
        </strong>
        <span>
          {checked
            ? <>- will not be modified</>
            : <>- will be overwritten <Warning color='#ffbf00' size={15} /></>}
        </span>
      </p>
    </label>
  )
}
