import React, { useState } from 'react'
import { Button, FormGroup, FormFeedback, Input, Label, Modal, ModalHeader, ModalBody, Spinner, Col, Row, UncontrolledAlert } from 'reactstrap'
import { Form, Field } from 'react-final-form'
import { ErrorAlert } from './'
import {
  FaEdit as Edit
} from 'react-icons/fa'
import { EMAIL } from 'suredone-common'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import Debug from 'debug'
const debug = Debug('sd:AddEmailButton')

const SEND_VERIFICATION_CODE = gql`
mutation sendVerificationCode($id: ID!, $email: String) {
  sendVerificationCode(id: $id, email: $email)
}
`

const VERIFY_EMAIL = gql`
mutation verifyOrganizationEmail($id: ID!, $email: String, $code: String) {
  verifyOrganizationEmail(id: $id, email: $email, code: $code)
}
`

const VerifyEmailCodeForm = (props) => {
  const { onVerifyEmail, customErrors, setCustomErrors, loadings, success, setSuccess, values, errors } = props
  return (
    <FormGroup>
      <Label>Verification code</Label>
      <Field
        name='code'
      >
        {({ input, meta }) => {
          return (
            <>
              <Row>
                <Col sm={8} lg={10}>
                  <Input
                    {...input}
                    type='text'
                    invalid={meta.touched && meta.invalid}
                    valid={success}
                  />
                </Col>
                {meta.error && !meta.pristine && meta.touched && <FormFeedback>{meta.error}</FormFeedback>}
                <Col sm={4} lg={2} className='mt-1 mt-sm-0'>
                  <Button
                    color='primary'
                    className='w-100'
                    disabled={loadings || meta.invalid || Object.keys(errors).length !== 0 || success}
                    onClick={() => onVerifyEmail(values.code)}
                  >
                    {loadings && <Spinner size='sm' />} Verify
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  {customErrors && <ErrorAlert isOpen={customErrors} toggle={() => setCustomErrors(false)} error='Invalid code for this email' />}
                  {success && <UncontrolledAlert className='mt-2' color='success' toggle={() => setSuccess(false)}> Succesfully verified </UncontrolledAlert>}
                </Col>
              </Row>
            </>
          )
        }}
      </Field>
    </FormGroup>
  )
}

const SendVerificationCodeForm = (props) => {
  const { onSendVerificationCode, loadings, customErrors, setCustomErrors, success, setSuccess, values } = props
  return (
    <FormGroup>
      <Label>New email</Label>
      <Field
        name='email'
        validate={email => { if (!EMAIL.test(email)) return 'Invalid email' }}
      >
        {({ input, meta }) => {
          return (
            <FormGroup>
              <Input
                {...input}
                type='email'
                invalid={meta.touched && meta.invalid}
              />
              {meta.error && !meta.pristine && meta.touched && <FormFeedback>{meta.error}</FormFeedback>}
              {customErrors && <ErrorAlert isOpen={customErrors} toggle={() => setCustomErrors(false)} error='An error ocurred. Please try again later.' />}
              {success && <UncontrolledAlert className='mt-2' color='success' toggle={() => setSuccess(false)}> The verification code was sent to <b>{values.email}</b> </UncontrolledAlert>}
              <Button color='link' type='submit' disabled={loadings || meta.invalid || success} onClick={() => onSendVerificationCode(values.email)} className='text-primary pl-0'>
                {loadings && <Spinner color='secondary' size='sm' />} Send verification code
              </Button>
            </FormGroup>
          )
        }}
      </Field>
    </FormGroup>
  )
}

const OrganizationEmailWithVerification = ({ organizationId, onSetEmail }) => {
  const [modal, setModal] = useState(false)

  // Errors and success for send code to email
  const [sendVerificationCodeError, setSendVerificationCodeError] = useState(false)
  const [sendVerificationCodeSuccess, setSendVerificationCodeSuccess] = useState(false)
  // Errors and success send the code to server
  const [verifyEmailError, setVerifyEmailError] = useState(false)
  const [verifyEmailSuccess, setVerifyEmailSuccess] = useState(false)
  // Enable add email Button
  const [disableAddEmail, setDisableAddEmail] = useState(true)

  const [sendVerificationCode, { loading: sendingVerificationCode }] = useMutation(SEND_VERIFICATION_CODE,
    {
      onCompleted: (e) => {
        setSendVerificationCodeSuccess(true)
      },
      onError: (e) => {
        setSendVerificationCodeError(true)
      }
    }
  )

  const [verifyEmail, { loading: verifyingLoading }] = useMutation(VERIFY_EMAIL,
    {
      onCompleted: () => {
        setVerifyEmailSuccess(true)
        setDisableAddEmail(false)
      },
      onError: (e) => {
        setVerifyEmailError(true)
        setDisableAddEmail(true)
      }
    }
  )

  const toggle = () => {
    setDisableAddEmail(true)
    setVerifyEmailSuccess(false)
    setModal(!modal)
  }

  const onHandleSubmit = async (values) => {
    const { email } = values
    onSetEmail(email)
    toggle()
  }

  const validateForm = values => {
    const errors = {}
    const { email, code } = values
    if (!EMAIL.test(email)) {
      errors.email = 'Invalid email'
    }
    if (!code) {
      errors.code = 'Required'
    }
    return errors
  }

  const renderChangeEmailForm = props => {
    const { handleSubmit, submitting, values } = props
    return (
      <form className='form' onSubmit={handleSubmit}>
        <SendVerificationCodeForm
          onSendVerificationCode={(email) => sendVerificationCode({ variables: { id: organizationId, email } })}
          loadings={submitting || sendingVerificationCode}
          customErrors={sendVerificationCodeError}
          setCustomErrors={setSendVerificationCodeError}
          success={sendVerificationCodeSuccess}
          setSuccess={setSendVerificationCodeSuccess}
          {...props}
        />
        <VerifyEmailCodeForm
          onVerifyEmail={(code) => verifyEmail({ variables: { id: organizationId, email: values.email, code } })}
          loadings={submitting || verifyingLoading}
          customErrors={verifyEmailError}
          setCustomErrors={setVerifyEmailError}
          success={verifyEmailSuccess}
          setSuccess={setVerifyEmailSuccess}
          {...props}
        />
        <Button color='primary' type='submit' className='mb-3' disabled={submitting || disableAddEmail}>
          {submitting && <Spinner color='secondary' size='sm' />} Change email
        </Button>
      </form>
    )
  }

  const addEmailLoading = false

  return (
    <>
      <Button color='primary' className='w-100' onClick={toggle} disabled={addEmailLoading}>
        <>
          <Edit /> Change
        </>
      </Button>
      <Modal isOpen={modal} toggle={toggle} className='modal-dialog-centered'>
        <ModalHeader toggle={toggle}>Change email</ModalHeader>
        <ModalBody>
          <Form onSubmit={onHandleSubmit} render={renderChangeEmailForm} validate={validateForm} />
        </ModalBody>
      </Modal>
    </>
  )
}

export { OrganizationEmailWithVerification }
debug('loaded')
