import React from 'react'
import { FaTasks } from 'react-icons/fa'
import { useQuery } from '@apollo/react-hooks'
import { OnboardingActions } from './OnboardingActions'
import { Col, Card, CardHeader, CardBody } from 'reactstrap'
import { GET_ORGANIZATION_DETAILS } from '../../OrganizationManager/commonQueries'

export const Todo2 = ({ data, user }) => {
  const { organizationId } = user
  const { toDoTitle } = data
  const { data: orgData } = useQuery(GET_ORGANIZATION_DETAILS, { variables: { id: organizationId } })
  const { OrganizationDetails } = orgData || { OrganizationDetails: {} }

  return (
    <Col xs='12' xl='4'>
      <Card id='actionitems'>
        <CardHeader>
          <h3 className='card-title mb-0'><FaTasks />{toDoTitle}</h3>
        </CardHeader>
        <CardBody className='p-3' style={{ height: '248px' }}>
          <OnboardingActions orgData={OrganizationDetails} />
        </CardBody>
      </Card>
    </Col>
  )
}
