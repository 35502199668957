import React from 'react'
import { Button } from 'reactstrap'
import { Loadable } from '../../components'
import { FilterGroup, FixedFilterGroup } from '.'
import { useFitment } from '../../hooks/useFitment'

export const FitAside = ({ sidebarOpen, setSidebarOpen }) => {
  const { activeFilters, results, loadingResults } = useFitment()
  const { year, make, model } = activeFilters || {}
  const groups = results && Object.keys(results)?.filter(i => i !== 'raw' && i !== 'totalFacetedProducts')

  return (
    <>
      <aside key='app-aside' className={`app-aside app-aside-expand-md app-aside-light ${sidebarOpen ? 'show' : ''}`}>
        <div className='aside-content'>
          <header className='aside-header d-block d-md-none text-right' style={{ backgroundColor: '#f6f7f9' }}>
            <Button close className='p-2' onClick={() => setSidebarOpen(false)} />
          </header>
          <div className='aside-menu' style={{ overflow: 'auto' }}>
            <nav id='stacked-menu' className='stacked-menu stacked-menu-has-collapsible'>
              <FixedFilterGroup title='Year' value={year} />
              <FixedFilterGroup title='Make' value={make} />
              <FixedFilterGroup title='Model' value={model} />
              <Loadable
                inline
                loading={loadingResults}
                style={{ marginTop: '4rem' }}
                content={groups?.map(i => <FilterGroup key={i} id={i} />)}
              />
            </nav>
          </div>
        </div>
      </aside>

      <div key='aside-backdrop' onClick={() => setSidebarOpen(false)} className={`aside-backdrop ${sidebarOpen ? 'show' : ''}`} />
    </>
  )
}
