import auth01 from './auth01-combine.png'
import auth03 from './auth03-combine.png'

import onboard01 from './onboard01.png'
// eslint-disable-next-line
import onboard01Help from './onboard01-help.png'
import onboard03 from './onboard03.png'
import onboard19 from './onboard19.png'

export const stepInstructions = {
  Permissions: {
    instructions: {
      stepInstructions: [
        {
          title: 'Login with Facebook & Verify Business User',
          description: 'Click "Authorize Facebook" below to start the authorization process. Verify that the user is connected to the Facebook business page or Instagram business account that will be associated with the SureDone product catalog.',
          imgSrc: auth01,
          imgAlt: 'Login with Facebook and Verify Business User'
        },
        {
          title: 'Review & Grant Permissions',
          description: 'SureDone requires permissions to create & manage product catalogs, process orders, manage the Facebook page associated with the business as well as manage settings to create a secure system user for a stable business integration. All requested permissions must be allowed for the SureDone Facebook integration to operate reliably.',
          imgSrc: auth03,
          imgAlt: 'Review and Grant Permissions'
        }
      ]
    }
  },
  Onboarding: {
    instructions: {
      stepInstructions: [
        {
          title: 'Onboard to Facebook Commerce Manager',
          description: 'Once you have authorized Facebook and granted permissions, you will be redirected to setup the Facebook Commerce Manager. This step will connect to or provision a Facebook Business Manager and set up all the necessary components to enable selling on Facebook surfaces. Choose the "Setup on Commerce Manager" option.',
          imgSrc: onboard01,
          imgAlt: 'Onboard to Facebook Commerce Manager'
        },
        {
          title: 'Complete Commerce Account Setup',
          description: 'You will be guided through a series of steps to create a commerce account, business page, catalog and setup payout details for Facebook to transfer order payments to. During this process you will also have the opportunity to configure all of your shipping and tax settings.',
          imgSrc: onboard03,
          imgAlt: 'Complete Commerce Account Setup'
        },
        {
          title: 'Get Help',
          description: 'Click on the "Contact Us" link on the right side of the onboarding screen if you need help during the setup process.',
          imgSrc: onboard01Help,
          imgAlt: 'Facebook Commerce Account Setup Help'
        },
        {
          title: 'Return to SureDone',
          description: 'Once you have completed the onboard process you will be presented with button "Go to SureDone" - you must click the link for SureDone to complete the onboard and import all of the Facebook settings you just set up.',
          imgSrc: onboard19,
          imgAlt: 'Return to SureDone'
        }
      ]
    }
  }
}
