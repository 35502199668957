import React, { useState } from 'react'
import { Table as ReactstrapTable, Pagination, PaginationItem, PaginationLink, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import moment from 'moment'
import {
  useTable,
  useTableState,
  usePagination,
  useSortBy
  // userFilters
} from 'react-table'
import { FaCaretUp as CaretUp, FaCaretDown as CaretDown } from 'react-icons/fa'

import matchSorter from 'match-sorter'

function DefaultColumnFilter ({
  column: { filterValue, preFilteredRows, setFilter }
}) {
  const count = preFilteredRows.length

  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}

export function SelectColumnFilter ({
  column: { filterValue, setFilter, preFilteredRows, id }
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value=''>All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

export function SliderColumnFilter ({
  column: { filterValue, setFilter, preFilteredRows, id }
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  return (
    <>
      <input
        type='range'
        min={min}
        max={max}
        value={filterValue || min}
        onChange={e => {
          setFilter(parseInt(e.target.value, 10))
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  )
}

function fuzzyTextFilterFn (rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

fuzzyTextFilterFn.autoRemove = val => !val

export default function Table ({
  columns,
  data,
  hasModal,
  /* reactstrap Table props */
  tag,
  size,
  bordered,
  borderless,
  striped,
  dark,
  hover,
  responsive
}) {
  const tableState = useTableState({ pageIndex: 0 })

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      }
    }),
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter
    }),
    []
  )

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    // pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    // setPageSize,
    state: [{
      // pageSize,
      pageIndex
    }]
  } = useTable(
    {
      columns,
      data,
      hasModal,
      defaultColumn,
      filterTypes,
      state: tableState
    },
    useSortBy,
    usePagination
  )

  const [modal, setModal] = useState(false)
  const [logRowData, setLogRowData] = useState({})

  const toggle = () => setModal(!modal)

  const handleClickRow = (data) => {
    return hasModal
      ? (
          toggle(),
          setLogRowData(data)
        )
      : null
  }

  const logsModalContent = () => {
    const data = logRowData && logRowData.original
    let fieldData
    try {
      fieldData = data ? JSON.parse(data.field_data) : undefined
    } catch (e) {
      fieldData = data.field_data
    }
    return data
      ? (
        <ReactstrapTable borderless striped responsive>
          <tbody>
            <tr>
              <th scope='row'>Id:</th>
              <td>{data.id}</td>
            </tr>
            <tr>
              <th scope='row'>Date:</th>
              <td>{moment(data.created_at).format('ll LTS')}</td>
            </tr>
            <tr>
              <th scope='row'>Result:</th>
              <td>{data.result}</td>
            </tr>
            <tr>
              <th scope='row'>Message:</th>
              <td>{data.message}</td>
            </tr>
            <tr>
              <th scope='row'>Action:</th>
              <td>{data.action}</td>
            </tr>
            <tr>
              <th scope='row'>Instance:</th>
              <td>{data.instance}</td>
            </tr>
            <tr>
              <th scope='row'>Method:</th>
              <td>{data.method}</td>
            </tr>
            <tr>
              <th scope='row'>Field data:</th>
              <td>
                {typeof fieldData === 'object'
                  ? <pre><code>{JSON.stringify(fieldData, null, 4)}</code></pre>
                  : data.field_data ? data.field_data : 'No data'}
              </td>
            </tr>
          </tbody>
        </ReactstrapTable>)
      : null
  }

  return (
    <>
      <ReactstrapTable
        {...getTableProps()}
        {...{ tag, size, bordered, borderless, striped, dark, hover, responsive }}
      >
        <thead>
          {headerGroups.map((headerGroup, idx) => (
            <tr key={idx} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, idx) => (
                <th key={idx} {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>{column.isSorted ? (column.isSortedDesc ? <CaretDown /> : <CaretUp />) : ''}</span>
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {hasModal &&
          <Modal isOpen={modal} size='lg' toggle={toggle}>
            <ModalHeader toggle={toggle}>Log details</ModalHeader>
            <ModalBody>
              {logsModalContent()}
            </ModalBody>
            <ModalFooter><Button onClick={toggle}>Close</Button></ModalFooter>
          </Modal>}
        <tbody>
          {page.map(
            (row, i) =>
              prepareRow(row) || (
                <tr
                  {...row.getRowProps()}
                  key={i}
                  onClick={() => handleClickRow(row)}
                >
                  {row.cells.map((cell, index) => {
                    return <td key={index} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
          )}
        </tbody>
      </ReactstrapTable>
      {/*
        Pagination can be built however you'd like.
        This is just a very basic UI implementation:
        */}
      {pageCount > 1 &&
        <Pagination className='row justify-content-center'>
          {pageCount > 5 &&
            <PaginationItem disabled={!canPreviousPage}>
              <PaginationLink onClick={e => { e.preventDefault(); gotoPage(0) }}>&laquo;</PaginationLink>
            </PaginationItem>}
          {pageCount > 5 &&
            <PaginationItem disabled={!canPreviousPage}>
              <PaginationLink onClick={e => { e.preventDefault(); previousPage() }}>&lt;</PaginationLink>
            </PaginationItem>}
          {[-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5]
            .filter(index => pageIndex + 1 + index > 0 && pageIndex + 1 + index <= pageCount)
            .map(index =>
              <PaginationItem key={pageIndex + 1 + index} active={index === 0}>
                <PaginationLink onClick={e => { e.preventDefault(); gotoPage(pageIndex + index) }}>{pageIndex + 1 + index}</PaginationLink>
              </PaginationItem>
            )}
          {pageCount > 5 &&
            <PaginationItem disabled={!canNextPage}>
              <PaginationLink onClick={e => { e.preventDefault(); nextPage() }}>&gt;</PaginationLink>
            </PaginationItem>}
          {pageCount > 5 &&
            <PaginationItem disabled={!canNextPage}>
              <PaginationLink onClick={e => { e.preventDefault(); gotoPage(pageCount - 1) }}>&raquo;</PaginationLink>
            </PaginationItem>}
          {/*
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
            <select
              value={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                Show {pageSize}
                </option>
              ))}
            </select>
            */}
        </Pagination>}
    </>
  )
}
export { Table }
