export const operators = [
  { operator: ':', description: 'A field contains the value you specify', example: 'title:Motors' },
  { operator: ':=', description: 'A field is exactly the same to the value you specify', example: 'title:=Motors' },
  { operator: ':-', description: 'Negation', example: 'size:-xl' },
  { operator: ':-=', description: 'A field is not exactly the same to the value you specify', example: 'title:-=Motors' },
  { operator: ':>', description: 'Greater than an amount or after the specified date', example: 'price:>9.99' },
  { operator: ':>=', description: 'Greater or equal to an amount', example: 'stock:>=5' },
  { operator: ':<', description: 'Lower than an amount or before the specified date', example: 'date:<2023-06-02' },
  { operator: ':<=', description: 'Lower or equal to an amount', example: 'cost:<=15' },
  { operator: ':^', description: 'A field starts with the value you specify', example: 'sku:^ABC' },
  { operator: ':value$', description: 'A field ends with the value you specify', example: 'guid:789$' },
  { operator: '()', description: 'Or', example: '(color:blue color:red)' }
]
