import { useMutation, useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import Debug from 'debug'
import { useHistory } from 'react-router'
import { AVAILABLE_CHANNELS, GET_CHANNEL } from '../scenes/Channel'

const debug = Debug('sd:useCreateChannel')

const CREATE_NEW_CHANNEL_INSTANCE = gql`
mutation channelInstanceCreateNew ($slug: String!, $instanceNumber: Int!) {
  channelInstanceCreateNew (slug: $slug, instanceNumber:$instanceNumber) {
    id
    instance
    enabled
    channel {
      id
      slug
      name
      logoUrl
      description
      readme
    }
  }
}
`

/**
 *
 * @param {String} slug
 * @param {{pathname: String, search: String }} redirect The url to redirect after a correct add of channel
 */
const useCreateChannel = (slug, redirect) => {
  const { loading, error, data } = useQuery(GET_CHANNEL, { variables: { slug } })
  const { ChannelInstances } = data || { ChannelInstances: [] }
  const { Channel } = data || { Channel: {} }
  const { id } = Channel || {}
  const history = useHistory()

  /**
   * returns the index to insert the new channel and channel new instance number
   * @param {String} channelId id of the channel (ex: facebook -> 13)
   * @param {{channelInstance}} channelInstances
   * @returns {{instanceToCreate: Number, indexToInsert:Number}}
   */
  const getNextAvailableInstance = (channelId, channelInstances) => {
    const channelInstancesLength = channelInstances.length
    const { instance, index } = channelInstances.reduce((acc, currentInstance, idx) => {
      if (currentInstance.channel.id === channelId) {
        return currentInstance.instance === 0
          ? { instance: Math.max(currentInstance.instance + 1, acc.instance), index: idx }
          : { instance: Math.max(currentInstance.instance, acc.instance), index: idx }
      }
      return acc
    }, { instance: 0, index: channelInstancesLength - 1 })
    return { instanceToCreate: instance + 1, indexToInsert: index + 1 }
  }
  // Calculate the index where the new channel should be added and the new instance number
  const { instanceToCreate, indexToInsert } = getNextAvailableInstance(id, ChannelInstances)

  const [createNewChannel, { loading: loadingMutation, error: errorMutation }] = useMutation(CREATE_NEW_CHANNEL_INSTANCE, {
    onError: (error) => { debug(error) }, // This prevents the unhandled exception
    update (cache, { data: { channelInstanceCreateNew } }) {
      const { ChannelInstances, ...rest } = cache?.readQuery({ query: AVAILABLE_CHANNELS })
      ChannelInstances.splice(indexToInsert, 0, channelInstanceCreateNew)
      cache?.writeQuery({
        query: AVAILABLE_CHANNELS,
        data: { ChannelInstances, ...rest }
      })
      const { instance } = channelInstanceCreateNew
      redirect ? history.push(redirect) : history.push(`/channels/${slug}/${instance}/authorization`)
    }
  })

  const addNewChannel = () => createNewChannel({ variables: { slug, instanceNumber: instanceToCreate } })

  return [addNewChannel, { loading: (loadingMutation || loading), error: errorMutation || error, instanceToCreate, indexToInsert }, createNewChannel]
}

export default useCreateChannel
debug('loaded')
