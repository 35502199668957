import React, { useState } from 'react'
import { TypeOfSearch } from './TypeOfSearch'
import { SavedSearches } from './SavedSearches'
import { SearchTipsModal, Searchbox } from '..'

export const MainSearch = () => {
  const [typeOfSearch, setTypeOfSearch] = useState('Products')

  const [searchboxOpen, setSearchboxOpen] = useState(false)
  const toggleSearchbox = () => setSearchboxOpen(prev => !prev)

  const [searchTipsOpen, setSearchTipsOpen] = useState(false)
  const toggleSearchTips = () => setSearchTipsOpen(prev => !prev)

  return (
    <>
      <div className='main-searchbox top-bar-item top-bar-item-full pr-1'>
        <Searchbox handlers={{ searchboxOpen, typeOfSearch, setSearchboxOpen, toggleSearchbox, toggleSearchTips }} />
      </div>
      <div className='top-bar-item top-bar-item-right px-0'>
        <div class='type-products-orders-block d-none d-sm-none d-md-block'>
          <div class='header-nav nav pl-0 pr-4'>
            <TypeOfSearch typeOfSearch={typeOfSearch} setTypeOfSearch={setTypeOfSearch} />
            <SavedSearches />
          </div>
        </div>
      </div>
      <SearchTipsModal toggleSearchTips={toggleSearchTips} searchTipsOpen={searchTipsOpen} />
    </>
  )
}
