import React from 'react'
import { VisualPicker } from './VisualPicker'

const RowPicker = ({ item, isSelected, handlepickedItem }) => {
  const { title, subTitle, id } = item || {}
  return (
    <>
      <VisualPicker
        id={id}
        name='onboard-type'
        isSelected={isSelected}
        parentCallback={handlepickedItem}
      >
        <div className='p-3'>
          {title && <h6 className='mb-0'>{title}</h6>}
          {subTitle && <p className='text-muted mb-0' style={{ fontSize: '.9rem' }}>{subTitle}</p>}
        </div>
      </VisualPicker>
    </>
  )
}

/**
 *
 * @param {[{id: String, title?: String, subTitle?: String}]}} items
 */
const TablePicker = ({ items, pickedItem, parentCallback }) => {
  const handlePickedItem = event => {
    parentCallback(event)
  }

  return (
    <>
      {items.map(item => {
        return (
          <div key={item.id}>
            <RowPicker
              item={item}
              pickedItem={pickedItem}
              handlepickedItem={handlePickedItem}
              isSelected={pickedItem === item.id}
            />
          </div>
        )
      })}
    </>
  )
}

export { TablePicker }
