export const stepInstructions = {
  Permissions: {
    instructions: {
      stepInstructions: [
        {
          title: 'Select your marketplace',
          description: 'Please select the marketplace where you want to sell your products.',
          imgAlt: 'Select your marketplace'
        },
        {
          title: 'Login with Amazon & Grant Application Access',
          description: 'Click "Authorize Amazon" below to start the authorization process. Grant application access to SureDone to enable management of your listings and orders.',
          imgAlt: 'Login with Amazon and Grant Application Access'
        }
      ]
    }
  }
}
