import React, { useState } from 'react'
import Select from 'react-select'
import 'react-select/dist/react-select.css'

export const SelectMultiType = ({ data }) => {
  const { item, parentCallback, settings } = data || {}
  const { value: { value } } = item

  // Find and format initial values for select-multi
  const selecteds = value || []
  const settingsFilter = settings.filter(s => s.name === item.name)
  let selfSettings
  if (settingsFilter[0]) {
    // Options from server
    selfSettings = (settingsFilter[0] && settingsFilter[0].selectOptions) || []
  } else {
    // Options from json config
    selfSettings = item.selectOptions || []
  }
  const filtered = selecteds.map(sa => selfSettings?.filter(ss => sa === (ss && ss.name) || null))
  const polishedOptions = filtered?.map(i => i[0] ? i[0] : null)
  const preparedForSelect = polishedOptions?.map(item => ({
    value: (item && item.value) || null,
    label: (item && item.value) || null
  }))
  // Initial select-multi state
  const [multiSelected, setMultiSelected] = useState(preparedForSelect)

  // select-multi options formatter
  const options = selfSettings.map(i => ({
    value: (i && i.value) || null,
    label: (i && i.value) || null
  }))

  const handleChange = (event) => {
    const isEmpty = event.split(',')[0] === ''
    // Stuff to handle select-multi changes properly
    const properEvent = event.split(',').map((i, idx) => ({
      value: event.split(',')[idx] || null,
      label: event.split(',')[idx] || null
    }))
    setMultiSelected(isEmpty ? event : properEvent)
    // Stuff to send ['1', '3'] instead of ['Mens', 'Ladies'] to backend
    const properNames = selfSettings
      .filter(ss => properEvent.find(pp => ss.value === pp.value))
      .map(i => i.name)
    parentCallback(isEmpty ? { name: [] } : { name: properNames }, item)
  }

  return (
    <Select
      closeOnSelect={false}
      multi
      onChange={handleChange}
      options={options}
      placeholder=''
      removeSelected
      simpleValue
      value={multiSelected}
    />
  )
}
