import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Organization } from 'suredone-common'
import { ResourceList, ResourceListItem } from '../../components/SearchableLists'
import { Loadable, Page } from '../../components'
import { OrganizationSummary } from './OrganizationSummary'
import Debug from 'debug'

const debug = Debug('sd:OrganizationManager')

export const GET_ALL_ORGANIZATIONS = gql`{
  AllOrganizations {
    ... on Organization {
      id
      name
      slug
      legacyAccountId
      email
      phone
      address {
        street1
        street2
        city
        stateProvince
        postalCode
        country
      }
    }
  }
}`

// TODO PERMISSIONS
export function OrganizationManager () {
  const { loading, error, data } = useQuery(GET_ALL_ORGANIZATIONS)
  const response = (data && data.AllOrganizations) ? data.AllOrganizations : []
  const items = response.map(({
    id,
    name,
    slug,
    legacyAccountId,
    email,
    phone,
    address
  }) => new Organization({
    id,
    name,
    slug,
    legacyAccountId,
    email,
    phone,
    address
  }))

  return (
    <Page
      title='Organizations'
      description='All organizations'
    >
      <Loadable
        loading={loading}
        error={error}
        content={
          <ResourceList
            items={items}
            itemPlural='organizations'
            itemComponent={
              ({ item: organization, searchTerms }) =>
                <ResourceListItem to={`organizations/${organization.id}/profile`}>
                  <OrganizationSummary isListItem searchTerms={searchTerms} organization={organization} />
                </ResourceListItem>
            }
            searchableStrings={o => [
              o.id,
              o.name,
              o.slug,
              o.legacyAccountId,
              o.email,
              o.phone,
              o.timezone
            ]}
            searchPlaceholder='Search organizations'
            className='organization-list'
          />
        }
      />
    </Page>
  )
}

debug('loaded')
