/* eslint-disable camelcase */
import React from 'react'
import moment from 'moment'
import { Loadable } from '../../components'
import { Card, CardBody } from 'reactstrap'
import { useQuery } from '@apollo/react-hooks'
import { utcToLocalTime } from '../../components/utils'
import { GET_SCHEDULED_EVENT } from '../DemoSchedule/commonQueries'
import { GET_QUALIFICATIONS } from '../Qualifications/commonQueries'
import { FaCircle as Circle, BiCalendarAlt as Calendar, BiWorld as World, BiVideo as Camera } from 'react-icons/all'

export const ScheduledEvent = ({ email }) => {
  const { data: qData, loading: qLoading, error: qError } = useQuery(GET_QUALIFICATIONS, { variables: { email } })
  const { GetQualifications } = qData || {}
  const { calendlyData } = GetQualifications || {}
  const { event, invitee } = calendlyData || {}
  const { uri: eventUri } = event || {}
  const { uri: inviteeUri } = invitee || {}
  const eventId = eventUri?.split('/scheduled_events/')?.[1]
  const inviteeId = inviteeUri?.split('/invitees/')?.[1]

  const { data: sData, loading: sLoading, error: sError } = useQuery(GET_SCHEDULED_EVENT, {
    variables: {
      eventId,
      inviteeId
    },
    skip: !eventId || !inviteeId
  })
  const { GetScheduledEvent } = sData || {}
  const { eventData, inviteeData } = GetScheduledEvent || {}
  const { resource: eventResource } = eventData || {}
  const { resource: inviteeResource } = inviteeData || {}
  const { name: eventName, location, start_time, end_time } = eventResource || {}
  const { join_url } = location || {}
  const { timezone, email: inviteeEmail } = inviteeResource || {}

  const getCity = timezone => timezone?.split('/').pop().replace('_', ' ')

  const humanize = (start_time, end_time, timezone) => {
    const startInLocalTime = utcToLocalTime(start_time, timezone)
    const endInLocalTime = utcToLocalTime(end_time, timezone)
    const startHour = moment(startInLocalTime).format('HH:mm')
    const endHour = moment(endInLocalTime).format('HH:mm')
    const day = moment(startInLocalTime).format('D')
    const dayOfWeek = moment(startInLocalTime).format('dddd')
    const month = moment(startInLocalTime).format('MMMM')
    const year = moment(startInLocalTime).year()
    return `${startHour}-${endHour}, ${dayOfWeek}, ${month} ${day}, ${year}`
  }

  return (
    <Loadable
      inline
      error={qError || sError}
      style={{ margin: 'auto' }}
      loading={qLoading || sLoading}
      content={
        <Card className='m-auto scheduled-event p-3'>
          <CardBody>
            <ul>
              <li className='mb-2'>
                <h5>
                  <Circle size={18} color='#346cb0' className='mb-1 mr-2' />
                  {eventName}
                </h5>
              </li>
              {timezone &&
                <>
                  <li>
                    <Calendar size={20} className='my-0 mr-2' />
                    <span>{humanize(start_time, end_time, timezone)}</span>
                  </li>
                  <li>
                    <World size={20} className='my-0 mr-2' />
                    <span>{getCity(timezone)} time</span>
                  </li>
                </>}
              {join_url &&
                <li>
                  <Camera size={20} className='my-0 mr-2' />
                  <a href={join_url} target='_blank' rel='noopener noreferrer'>{join_url}</a>
                </li>}
            </ul>
            <p className='mt-3 mb-0'>A calendar invitation has been sent to <b>{inviteeEmail || 'your email address'}</b></p>
          </CardBody>
        </Card>
      }
    />
  )
}
