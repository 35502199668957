import React from 'react'
import { Loadable } from '../../components'
import { useQuery } from '@apollo/react-hooks'
import { GET_CHANNEL } from './commonQueries'
import { ChannelContent } from './ChannelContent'

const debug = require('debug')('sd:ChannelContainer')

const ChannelInstructions = ({ slug, instanceNumber }) => {
  const { loading, error, data } = useQuery(GET_CHANNEL, { variables: { slug } })
  const { Channel } = data || { Channel: {} }
  const { readme, readme_images: readmeImages } = Channel || {}

  return (
    <Loadable
      inline
      loading={loading}
      error={error}
      style={{ textAlign: 'center' }}
      content={
        <>
          <ChannelContent
            readme={readme}
            readmeImages={readmeImages}
            slug={slug}
          />
        </>
      }
    />
  )
}

export { ChannelInstructions }
debug('loaded')
