import React from 'react'
import { Table } from 'reactstrap'

export const DefaultShipMappingTable = ({ data }) => {
  if (!data) {
    return 'No data saved'
  }

  const keys = Object.keys(data)
  const tableData = keys?.map(k => ({
    key: k,
    carrier: data[k]?.split('*')?.[0],
    service: data[k]?.split('*')?.[1]
  }))

  return (
    <Table bordered size='sm'>
      <thead>
        <tr>
          <th>Channel Shipping Service</th>
          <th>Carrier</th>
          <th>Service</th>
        </tr>
      </thead>
      <tbody>
        {tableData?.map(({ key, carrier, service }) => (
          <tr key={key}>
            <td>{key}</td>
            <td>{carrier}</td>
            <td>{service}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}
