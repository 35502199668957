/**
 * Utilities for normalizing user data from different sources.
 * They come in several flavors, part of a JWT, backend call from AWS Cognito api, etc.
 */

/* eslint-disable-file no-multi-spaces */

/**
 * Utility class for normalizing our concept of a user. All the data on this
 * class is expected to be present in the places we refer to users:
 *  - Frontend, representing the logged in user
 *  - Frontend, representing another user on the platform
 *  - Backend, constructed from the Cognito token
 *  - Backend, constructed from the Cognito API
 *  - Backend, constructed from MongoDB
 *
 * Check out the from*() functions to construct users in the above secnarios.
 */
export class User {
  /**
   * Constructs a user object.
   */
  constructor (
    id, // cognito.username (string), mongo._id (ObjectId)
    username, // cognito.preferred_username (string) [optional, can be null]
    primaryEmail, // cognito.email (string)
    roles = [], // cognito.custom:roles (string, comma separated)
    scopes = [], // cognito.custom:scopes (string, comma separted)
    organizationId, // cognito.custom:organizationId (string), mongo (ObjectId)
    legacyUserId, // cognito.custom:legacyUserId (string)
    legacyAccountId, // cognito.custom:legacyAccountId (string)
    legacyAccountName, // cognito.custom:legacyAccountName (string)
    legacyApiToken, // cognito.custom:legacyApiToken (string)
    legacyPrimary, // cognito.custom:legacyPrimary (string)
    firstName, // cognito.given_name (string)
    lastName, // cognito.family_name (string)
    sudoer, // If this is target user for sudo, set this to the user performing the sudo
    enabled, // If this user is allowed to log in. on status:'active' on legacy means true, other case false
    hasPendingInvite, // The user was invited but didn't reset the password
    salesforceContactId, // The contact id on Salesforce
    mfaEnabled, // has this user the MFA enabled?
    createDate,
    agreeTOS // the date of last acceptance of the Terms of Service
  ) {
    if (!id) throw new Error('User missing id')
    if (!primaryEmail) throw new Error('User missing primaryEmail')
    if (!Array.isArray(roles)) throw new Error(`roles must be an array, got ${JSON.stringify(roles)}`)
    if (!Array.isArray(scopes)) throw new Error(`scopes must be an array, got ${JSON.stringify(scopes)}`)
    this.id = id
    this.username = username
    this.primaryEmail = primaryEmail
    this.roles = roles
    this.scopes = scopes
    this.organizationId = organizationId
    this.legacyUserId = legacyUserId
    this.legacyAccountId = legacyAccountId
    this.legacyAccountName = legacyAccountName
    this.legacyApiToken = legacyApiToken
    this.legacyPrimary = legacyPrimary === 'true' || legacyPrimary === true // coerce to boolean
    this.firstName = firstName
    this.lastName = lastName
    this.sudoer = sudoer
    this.enabled = enabled
    this.hasPendingInvite = hasPendingInvite
    this.salesforceContactId = salesforceContactId
    this.mfaEnabled = mfaEnabled
    this.createDate = createDate
    this.agreeTOS = agreeTOS
  }

  fullName () {
    const name = this.firstName || this.lastName ? [this.firstName, this.lastName].join(' ') : undefined
    return name
  }

  /**
   * Prepare user to provide good information for sentry error handling.
   */
  getForSentry () {
    const user = Object.assign({}, this)
    user.email = user.primaryEmail // sentry recognizes email
    delete user.primaryEmail
    delete user.legacyApiToken
    // We want to know if an admin was doing the operation on behalf of a
    // user, and have the same formatting/fields
    if (user.sudoer) {
      if (user.sudoer.legacyApiToken) delete user.sudoer.legacyApiToken
      user.sudoer.email = user.sudoer.primaryEmail
      delete user.sudoer.primaryEmail
      // Why can't javascript have a builtin thing that cleans object properties?
      user.sudoer = Object.fromEntries(Object.entries(user.sudoer).filter(([key, value]) => typeof value !== 'undefined'))
    }
    return Object.fromEntries(Object.entries(user).filter(([key, value]) => typeof value !== 'undefined'))
  }

  /**
   * Returns a user object constructed from a user MongoDB entry.
   */
  static fromMongo (data) {
    const id = data._id.toString()
    // TODO: remove this migration step
    const deprecated = data.currentLegacyUser || {}
    const newUser = new User(
      id,
      data.username,
      data.primaryEmail,
      data.roles,
      data.scopes,
      // Before we add the organizataions feature, we'll just use the legacyAccountId
      data.organizationId,
      data.legacyUserId || deprecated.legacyUserId,
      data.legacyAccountId || deprecated.legacyAccountId,
      data.legacyAccountName || deprecated.legacyAccountName,
      data.legacyApiToken || deprecated.legacyApiToken,
      data.legacyPrimary || deprecated.legacyPrimary,
      data.firstName,
      data.lastName,
      data.sudoer,
      data.enabled,
      data.hasPendingInvite,
      data.salesforceContactId,
      data.mfaEnabled,
      data.createDate,
      data.agreeTOS
    )
    return newUser
  }

  /**
   * Returns a user object constructed from a Cognito backend adminGetUser()
   * or simillar call.
   */
  static fromCognitoSdk (data) {
    // Flatten out the UserAttributes array
    const flatAttributes = Object.assign(
      ...data.UserAttributes
        .map(({ Name, Value }) => ({ [Name]: Value }))
    )
    flatAttributes['cognito:username'] = data.Username
    return User.fromJwt(flatAttributes)
  }

  /**
   * Returns a user object constructed from a Cognito JSON Web Token that has
   * been decoded and parsed from JSON into an object.
   */
  static fromJwt (data) {
    const {
      'cognito:username': id,
      preferred_username: username,
      email: primaryEmail,
      given_name: firstName,
      family_name: lastName,
      'custom:roles': roles,
      'custom:scopes': scopes,
      'custom:organizationId': organizationId,
      'custom:legacyUserId': legacyUserId,
      'custom:legacyAccountId': legacyAccountId,
      'custom:legacyAccountName': legacyAccountName,
      'custom:legacyApiToken': legacyApiToken,
      'custom:legacyPrimary': legacyPrimary,

      // Handle sudo case
      'custom:s:id': sudoId,
      'custom:s:username': sudoUsername,
      'custom:s:email': sudoPrimaryEmail,
      'custom:s:roles': sudoRoles,
      'custom:s:scopes': sudoScopes,
      'custom:s:organizationId': sudoOrganizationId,
      'custom:s:legacyUserId': sudoLegacyUserId,
      'custom:s:legacyAccountId': sudoLegacyAccountId,
      'custom:s:legacyAccountName': sudoLegacyAccountName,
      'custom:s:legacyApiToken': sudoLegacyApiToken,
      'custom:s:legacyPrimary': sudoLegacyPrimary,
      'custom:s:given_name': sudoFirstName,
      'custom:s:family_name': sudoLastName
    } = data

    const mainUser = new User(
      id,
      username,
      primaryEmail,
      (roles || '').split(',').map(s => s.trim()).filter(a => a),
      (scopes || '').split(',').map(s => s.trim()).filter(a => a),
      (organizationId || legacyAccountId) ? (organizationId || legacyAccountId) + '' : undefined,
      legacyUserId,
      legacyAccountId,
      legacyAccountName,
      legacyApiToken,
      legacyPrimary,
      firstName,
      lastName
    )

    // Handle sudo case
    const sudoActive = !!sudoId
    if (sudoActive) {
      return new User(
        sudoId,
        sudoUsername,
        sudoPrimaryEmail,
        (sudoRoles || '').split(',').map(s => s.trim()).filter(a => a),
        (sudoScopes || '').split(',').map(s => s.trim()).filter(a => a),
        (sudoOrganizationId || sudoLegacyAccountId) ? (sudoOrganizationId || sudoLegacyAccountId) + '' : undefined,
        sudoLegacyUserId,
        sudoLegacyAccountId,
        sudoLegacyAccountName,
        sudoLegacyApiToken,
        sudoLegacyPrimary,
        sudoFirstName,
        sudoLastName,
        mainUser
      )
    } else {
      return mainUser
    }
  }
}
