import React from 'react'
import { Card, CardHeader, CardBody, UncontrolledCollapse } from 'reactstrap'

export const InstructionCollapse = ({ id, title, children, defaultOpen = false }) => {
  return (
    <Card className='instruction-collapse'>
      <CardHeader id={id} className='has-arrow' style={{ scrollMarginTop: '25px' }}>
        <h3 className='m-0'>{title}</h3>
      </CardHeader>
      <UncontrolledCollapse toggler={`#${id}`} defaultOpen={defaultOpen}>
        <CardBody>
          {children}
        </CardBody>
      </UncontrolledCollapse>
    </Card>
  )
}
