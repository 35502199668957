import React, { useState } from 'react'
import { Button } from 'reactstrap'
import { FaAngleUp, FaAngleDown } from 'react-icons/fa'
import './GoToButton.scss'

export const GoToButton = ({ direction }) => {
  document.body.classList.add('hide-intercom-launcher')

  const [visible, setVisible] = useState(false)
  const renderToTop = direction === 'top' || direction === 'both'
  const renderToBottom = direction === 'bottom' || direction === 'both'

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const scrollToBottom = () => {
    window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' })
  }

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop
    const userHasSCrolled = document.body.scrollHeight > document.body.clientHeight

    if (direction === 'both' && userHasSCrolled) {
      setVisible(true)
    }

    if (direction === 'top') {
      if (scrolled > 300) { setVisible(true) } else { setVisible(false) }
    }

    if (direction === 'bottom') {
      if (scrolled < 300) { setVisible(true) } else { setVisible(false) }
    }
  }

  window.addEventListener('scroll', toggleVisible)

  return (
    <>
      {renderToTop && (
        <Button
          id='go-to-top'
          color='primary'
          onClick={scrollToTop}
          className={visible ? 'show' : ''}
        >
          <FaAngleUp className='m-0 mt-n1' size={26} />
        </Button>
      )}

      {renderToBottom && (
        <Button
          id='go-to-bottom'
          color='primary'
          onClick={scrollToBottom}
          className={visible ? 'show' : ''}
        >
          <FaAngleDown className='m-0 mt-1' size={26} />
        </Button>
      )}
    </>
  )
}
