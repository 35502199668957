import React, { useState } from 'react'
import { Col, Input, FormText, Row } from 'reactstrap'
import { LoadingInline, ErrorModal } from '../../components'
import {
  getRole,
  getScope,
  organizeScopes,
  organizationScopesVisibleSet,
  platformScopesVisibleSet
} from 'suredone-common'
import { useMutation, useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

// import { FaUser as User } from 'react-icons/fa'
import Debug from 'debug'
import { GET_FITMENT_STATUS } from '../Fitment/commonQueries'

const debug = Debug('sd:ScopesWrapper')

const GRANT_SCOPE = gql`
  mutation ($input: ScopeChangeInput!) {
    grantScope(input: $input)  {
      ... on User {
        id
        scopes
      }
    }
  }
`
const REVOKE_SCOPE = gql`
  mutation ($input: ScopeChangeInput!) {
    revokeScope(input: $input)  {
      ... on User {
        id
        scopes
      }
    }
  }
`

const ScopeSummary = ({ data, user, permissions, auth, userCanEditPermissions, disableAllCheckboxes, setDisableAllCheckboxes, showRevoke, showGrant }) => {
  const { fields } = data
  const userAlreadyScopes = permissions.getAllScopes()
  const [checked, setChecked] = useState(userAlreadyScopes)

  const refreshIfMe = () => {
    if (user.id === auth.user.id) auth.refresh()
  }

  // Mutation stuff
  const [grantScope, { loading: grantLoading, error: grantError }] = useMutation(
    GRANT_SCOPE,
    {
      onCompleted: () => {
        refreshIfMe()
        setDisableAllCheckboxes(false)
      }
    })
  const [revokeScope, { loading: revokeLoading, error: revokeError }] = useMutation(
    REVOKE_SCOPE,
    {
      onCompleted: () => {
        refreshIfMe()
        setDisableAllCheckboxes(false)
      }
    }
  )

  const onHandleChange = (e) => {
    const scopeName = e.target.name
    const scopeType = scopeName.split(':').pop()
    const splittedAndGeneral = scopeName.split(':').filter((member, index) => { return index !== scopeName.split(':').length - 1 })
    setDisableAllCheckboxes(true)
    if (e.target.checked) {
      // Checks READ and WRITE checkboxes if the scope to be assigned is WRITE
      scopeType === 'write'
        ? setChecked([...checked, e.target.name, [...splittedAndGeneral, 'read'].join(':')])
        : setChecked([...checked, e.target.name])
      grantScope({ variables: { input: { scopeName, userId: user.id } } })
    } else {
      // Discheck READ and WRITE checkboxes if the scope to be unassigned is READ
      scopeType === 'read'
        ? setChecked(checked.filter(i =>
          i !== [...splittedAndGeneral, 'read'].join(':') &&
          i !== [...splittedAndGeneral, 'write'].join(':')))
        : setChecked(checked.filter(i => i !== e.target.name))
      revokeScope({ variables: { input: { scopeName, userId: user.id } } })
    }
  }
  debug({ fields })

  const enabledOnlyForPlatforms = ['organization:fitment:read', 'organization:fitment:write']

  return (
    <Row className='my-1'>
      {(fields || []).map(({ name, label, description, isPlatform }) => {
        const disabled = !userCanEditPermissions || disableAllCheckboxes || grantLoading || revokeLoading || (auth.permissions.hasRole('PlatformSupport') && !enabledOnlyForPlatforms.includes(name))
        const { userHasScope, explicitlyGranted, grantingRoles } = permissions.scopeInfo(name)
        debug({ permissions, name, checked, userHasScope, explicitlyGranted, grantingRoles })
        return (
          !isPlatform || (isPlatform && auth.permissions.hasRole('PlatformSupport'))
            ? (
              <Col xs={6} key={name} className=''>
                <div className={`visual-picker visual-picker-fluid ${disabled ? 'disabled' : ''}`}>
                  <Input
                    id={name}
                    name={name}
                    checked={(checked || []).includes(name)}
                    type='checkbox'
                    // size='large'
                    disabled={disabled}
                    // className='custom-control-input'
                    onChange={onHandleChange}
                  />
                  <label className='visual-picker-figure' htmlFor={name}>
                    <span className='visual-picker-content text-large p-3'>
                      <div>
                        <strong className={'text-large' + (disabled ? ' text-muted' : '')}>
                          {label}
                          {grantLoading || revokeLoading ? <LoadingInline /> : null}
                        </strong>
                      </div>
                      <FormText>{description}</FormText>
                      {
                        grantingRoles.length
                          ? <FormText color={disabled ? 'muted' : 'normal'}>Granted by <strong>{grantingRoles.map(roleName => getRole(roleName).label || roleName).join(', ')}</strong> role</FormText>
                          : null
                      }
                      {/*
                    explicitlyGranted ? <Alert color='warning'>This permission is granted by this checkbox only, not by any role.</Alert> : null
                    */}
                    </span>
                  </label>
                </div>
                <ErrorModal error={grantError} />
                <ErrorModal error={revokeError} />
              </Col>
              )
            : null
        )
      })}
    </Row>
  )
}

const ScopesWrapper = ({ user, permissions, auth, userCanEditPermissions, showRevoke, showGrant }) => {
  const [disableAllCheckboxes, setDisableAllCheckboxes] = useState(false)
  const allPlatformScopes = [...platformScopesVisibleSet]
  const allOrganizationScopes = [...organizationScopesVisibleSet]
  const scopesToOrganize = allOrganizationScopes.concat(allPlatformScopes)
  const { scopesGroups: userScopesByGroup } = organizeScopes(scopesToOrganize.map(scopeName => getScope(scopeName)))

  const { data: fData } = useQuery(GET_FITMENT_STATUS, { variables: { targetOrganizationId: user.organizationId } })
  const { getFitmentStatus: fitmentEnabled } = fData || {}

  return (
    // Shows scopes by scopeGroup => 'Settings Management', 'Feature Management', ...
    userScopesByGroup && Object.entries(userScopesByGroup).map(scopeGroup => {
      const scopeGroupName = scopeGroup[0]
      const scopeGroupContent = scopeGroup[1]
      // Creates an object with an array of the same scope type => {automation: [...], bulk: [...], bulk-exports: [...], ...}
      const properObject = scopeGroupContent && [...scopeGroupContent].reduce(function (acc, cur) {
        if (!acc[cur.name.split(':')[1]]) acc[cur.name.split(':')[1]] = cur
        else acc[cur.name.split(':')[1]] = Array.isArray(acc[cur.name.split(':')[1]]) ? acc[cur.name.split(':')[1]].concat(cur) : [acc[cur.name.split(':')[1]]].concat(cur)
        return acc
      }, {})
      // Array of arrays, each in alphabetical order
      const arrayOfScopes = Object.keys(properObject)
        .map(e => ({ type: e, fields: Array.isArray(properObject[e]) ? properObject[e] : [properObject[e]] }))
        .sort((a, b) => (a.type > b.type) ? 1 : ((b.type > a.type) ? -1 : 0))

      if (scopeGroupName === 'Fitment' && !fitmentEnabled) {
        return null
      }

      return (
        <Row key={scopeGroupName}>
          <Col sm={5}>
            <h6 className='mb-1'>{scopeGroupName}</h6>
            <div className='text-muted'>{((arrayOfScopes[0] || {}).fields[0] || {}).generalDescription}</div>
          </Col>
          <Col sm={7}>
            {/* Generate rows of scopes with the same type => { bulk -> read/write } { dashboard -> read/write } */}
            {arrayOfScopes.map(scopeObj => (
              <ScopeSummary
                key={((scopeObj || {}).fields[0] || {}).name}
                data={scopeObj}
                user={user}
                auth={auth}
                userCanEditPermissions={userCanEditPermissions}
                permissions={permissions}
                setDisableAllCheckboxes={setDisableAllCheckboxes}
                disableAllCheckboxes={disableAllCheckboxes}
              />
            ))}
          </Col>
        </Row>
      )
    })
  )
}

export { ScopesWrapper }

debug('loaded')
