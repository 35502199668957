import React from 'react'
import { Card, CardBody } from 'reactstrap'
import { useQuery } from '@apollo/react-hooks'
import Debug from 'debug'
import { Loadable, Page, ActionButton } from '../../components'
import {
  FaPlus as Plus
} from 'react-icons/fa'
import { logos } from './ChannelsLogos'
import { getLink, isNewUIHandlingAddChannel } from './utils'
import useCreateChannel from '../../hooks/useCreateChannel'
import { GET_CHANNEL } from './commonQueries'
import { ChannelContent } from './ChannelContent'
const debug = Debug('sd:AddChannel')

export function AddChannel ({ slug }) {
  const { loading, error, data } = useQuery(GET_CHANNEL, { variables: { slug } })
  const { Channel } = data || { Channel: {} }
  const { description, readme, readme_images: readmeImages } = Channel || {}

  const [addNewChannel, { loading: newChannelLoading, error: errorAddNewChannel }] = useCreateChannel(slug)

  return (
    <Page
      back={{ to: '/channels/add', name: 'Add Channel' }}
      // title={`Add ${name ? name : ''} Channel`}
      description={description}
      logo={<div className='automation-logo p-1 mx-auto d-flex'>{logos[slug]}</div>}
      actions={
        isNewUIHandlingAddChannel(slug)
          ? (
            <ActionButton
              color='primary'
              disable={newChannelLoading || loading}
              loading={newChannelLoading || loading}
              icon={<Plus />}
              onClick={() => addNewChannel()}
            >
              Add Channel
            </ActionButton>
            )
          : (
            <a href={getLink(slug, 0)}>
              <ActionButton
                color='primary'
                icon={<Plus />}
              >
                Add Channel
              </ActionButton>
            </a>)
      }
    >
      <Loadable
        loading={loading}
        error={error}
        content={
          <Card>
            <CardBody>
              <ChannelContent
                errorMutation={errorAddNewChannel}
                readme={readme}
                readmeImages={readmeImages}
                slug={slug}
              />
            </CardBody>
          </Card>
        }
      />

    </Page>
  )
}

debug('loaded')
