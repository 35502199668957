import React from 'react'
import { defaultLogsSearch } from '.'
import { Card, CardBody } from 'reactstrap'
import { Page, EmptyState } from '../../components'

export const WrongFilterSyntax = ({ organizationId }) => {
  return (
    <Page title='Logs' className='logs-list'>
      <Card>
        <CardBody>
          <EmptyState
            isEmpty
            action={{ content: 'Remove all filters', url: `/logs/${organizationId}?${defaultLogsSearch}` }}
            content='Something went wrong with your filters'
          />
        </CardBody>
      </Card>
    </Page>
  )
}
