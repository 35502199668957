import { useLazyQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { useEffect, useState } from 'react'
import { useDebounce } from '.'

const EMAIL_AVAILABLE = gql`
  query emailAvailable($emailCandidate: String) {
    emailAvailable(emailCandidate: $emailCandidate)
  }
`

/**
 *
 * @param {String} [initialEmail]
 * @returns {[(initialEmail: string)=> void, {loading: boolean, emailAvailable: boolean | undefined }]}
 */
const useEmailAvailability = (initialEmail) => {
  const [emailInput, setEmailInput] = useState(initialEmail)
  const [debouncedValue] = useDebounce(emailInput, 550)

  const [emailQuery, { loading: emailLoading, data, error }] = useLazyQuery(EMAIL_AVAILABLE)

  useEffect(() => {
    const onQuery = (debouncedValue) => {
      emailQuery({ variables: { emailCandidate: debouncedValue } })
    }
    if (debouncedValue !== '') onQuery(debouncedValue)
  }, [debouncedValue, emailQuery])

  return [setEmailInput, { loading: emailLoading, emailAvailable: data && data.emailAvailable, error }]
}

export default useEmailAvailability
