import React from 'react'
import { Page } from '../../../components'
import { SettingsShopify, SettingsBigcommerce } from '..'
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom'

export const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1)

export const SettingsContainer = () => {
  const { path, params } = useRouteMatch()
  const fixedPath = path.split('/:')[0]
  const { slug } = params // shopify|bigcommerce

  const routes = [
    {
      name: 'Instance Settings',
      path: `${fixedPath}/shopify/:instance`,
      render: props => <SettingsShopify routeProps={props} />
    },
    {
      name: 'Instance Settings',
      path: `${fixedPath}/bigcommerce/:instance`,
      render: props => <SettingsBigcommerce routeProps={props} />
    }
  ]
  const defaultView = `${fixedPath}/${slug}/0`

  return (
    <Page
      className='fitment-settings'
      title={`${capitalize(slug) || ''} Fitment Settings`}
      description={`These settings configure the fitment module that will be displayed on your ${capitalize(slug)} storefront`}
    >
      <Switch>
        {routes.map((routeProps, index) => (<Route key={index} {...routeProps} />))}
        <Redirect to={defaultView} />
      </Switch>
    </Page>
  )
}
