import React, { useState } from 'react'
import Select from 'react-select'
import { useQuery } from '@apollo/client'
import { TypeFilters } from './TypeFilters'
import { ErrorSummary } from './ErrorSummary'
import { DownloadErrors } from './DownloadErrors'
import { people } from '../../../img/illustrations'
import { useHistory, useLocation } from 'react-router'
import { StatusesPagination } from './StatusesPagination'
import { EmptyState, Loadable } from '../../../components'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { GET_STATUSES } from '../../Statuses/commonQueries'
import { ResourceList } from '../../../components/SearchableLists'
import { daysToSelect, prepareStatuses, filterStatuses } from './utils'
import './ChannelErrors.scss'

export const STATUSES_LIMIT = 150

const formatError = e => {
  if (e?.message?.includes('statement contains too many placeholders')) {
    return { message: 'There are too many results, please reduce the number of search days.' }
  }
  return e
}

const getShowingText = (page, count) => {
  if (count <= STATUSES_LIMIT) return <><b>{count}</b> items</>
  const start = page * STATUSES_LIMIT + 1
  const end = Math.min((page + 1) * STATUSES_LIMIT, count)
  return <><b>{start}-{end}</b> of <b>{count}</b> items</>
}

export const ErrorsList = ({ params, helpers }) => {
  const history = useHistory()
  const location = useLocation()
  const { daysAgo, setDaysAgo, pageIndex, setPageIndex } = helpers
  const { match } = params || {}
  const { slug, instanceNumber } = match?.params || {}

  const { loading, error, data } = useQuery(GET_STATUSES, {
    variables: {
      filter: {
        context: slug,
        onlyErrors: true,
        daysAgo: daysAgo?.value || 1,
        instance: Number(instanceNumber),
        limit: STATUSES_LIMIT,
        offset: pageIndex * STATUSES_LIMIT
      }
    },
    onCompleted: () => { window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'instant' }) }
  })

  const [typeFilter, setTypeFilter] = useState('all')
  const [modalContent, setModalContent] = useState({})
  const [modal, setModal] = useState(false)
  const toggle = () => { setModal(!modal) }

  const { count, statuses = [] } = data?.getLogStatusesByFilter || {}
  const formattedStatuses = prepareStatuses(statuses)
  const filteredStatuses = filterStatuses(typeFilter, formattedStatuses)

  const updateUrlParams = (newParams) => {
    const updatedSearchParams = new URLSearchParams(location.search)
    Object.keys(newParams).forEach((key) => {
      updatedSearchParams.set(key, newParams[key])
    })
    history.push({
      pathname: location.pathname,
      search: updatedSearchParams.toString()
    })
  }

  const handleChangeDaysAgo = e => {
    setDaysAgo(e)
    setPageIndex(0)
    updateUrlParams({ daysago: e?.value || 1, page: 0 })
  }

  return (
    <>
      <div className='actions mb-3'>
        <div className='left d-flex align-items-center'>
          <Select
            clearable={false}
            disabled={loading}
            value={daysAgo || 1}
            options={daysToSelect}
            className='days-ago-select'
            onChange={handleChangeDaysAgo}
          />
          {statuses && <TypeFilters typeFilter={typeFilter} setTypeFilter={setTypeFilter} />}
        </div>
        <hr className='w-100 mb-4 d-sm-none' />
        <div className='right'>
          {filteredStatuses?.length ? <DownloadErrors items={filteredStatuses} /> : null}
        </div>
      </div>
      <Loadable
        inline
        loading={loading}
        error={formatError(error)}
        style={{ padding: '1rem' }}
        content={
          <EmptyState
            isEmpty={!statuses?.length}
            customImage={people.noLogs}
            heading='No errors were found'
          >
            <p className='mb-2 mt-4'>
              Page <b>{pageIndex + 1}</b> of <b>{Math.ceil(count / STATUSES_LIMIT)}</b> | Showing {getShowingText(pageIndex, count)}
            </p>
            <ResourceList
              itemsPerPage={STATUSES_LIMIT}
              itemPlural='products'
              className='errors-list'
              items={filteredStatuses}
              searchPlaceholder='Search errors'
              itemComponent={({ item, searchTerms }) => <ErrorSummary item={item} searchTerms={searchTerms} toggleModal={toggle} setModalContent={setModalContent} />}
              searchableStrings={i => {
                const { identifier, title, message, result } = i || {}
                return [identifier, title, message, result]
              }}
            />
            <StatusesPagination resultsCount={count} pageIndex={pageIndex} setPageIndex={setPageIndex} updateUrlParams={updateUrlParams} />
          </EmptyState>
        }
      />

      <Modal centered isOpen={modal} toggle={toggle} autoFocus={false} size='lg'>
        <ModalHeader toggle={toggle}>
          Identifier: {modalContent?.header}
        </ModalHeader>
        <ModalBody>
          {modalContent?.body}
        </ModalBody>
      </Modal>
    </>
  )
}
