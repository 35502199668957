import React, { useState } from 'react'
import { ErrorModal } from './ErrorModal'
import { FaUserCheck as UserCheck } from 'react-icons/fa'
import { FiCheckCircle as Check } from 'react-icons/fi'
import { ActionButton } from './ActionButton'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

const COMPLETE_REGISTRATION = gql`
  mutation completeRegistration($email: String!) {
    completeRegistration(email: $email)
  }
`

export const CompleteRegistration = ({ user }) => {
  // userId is the id from who will receive again the invite
  const { primaryEmail } = user || {}

  const [successComplete, setSuccessComplete] = useState(false)
  const [resendInvite, { loading, error }] = useMutation(
    COMPLETE_REGISTRATION,
    { onCompleted: () => setSuccessComplete(true) }
  )

  return (
    <>
      <ActionButton
        color='secondary'
        loading={loading}
        disabled={successComplete}
        icon={successComplete ? <Check /> : <UserCheck size='12' />}
        onClick={() => resendInvite({ variables: { email: primaryEmail } })}
      >
        Complete registration
      </ActionButton>
      {error && <ErrorModal header='Error finishing the registration' error={error?.message} />}
    </>
  )
}
