import React from 'react'
import { observer } from 'mobx-react'
import logo from '../../img/suredone-logo-gray-white-5000x1015.png'
import Particles from 'react-particles-js'
import particles from './particles.json'
import './AuthPage.scss'

const _AuthPage = ({ headerSubtitle, form, children, footer }) => {
  return (
    <>
      <header className='auth-header'>
        <h1>
          <img className='img-fluid mb-3' alt='SureDone Logo' style={{ maxHeight: '50px', width: '100% \0' }} src={logo} />
        </h1>
        {headerSubtitle}
        <Particles
          params={particles}
          canvasClassName='particles-js-canvas-el'
        />
      </header>
      {form}
      {children}
      <footer className='auth-footer mb-5'>
        {footer}
      </footer>
    </>
  )
}

const AuthPage = observer(_AuthPage)

export { AuthPage }
