/**
 * Usage example
    <EmailVerificationWizard
      id={id}
      onSaveButton={(email) => changeEmail({ variables: { id, input: { email } } }) }
      onLoadingSave={updatingOrganization}
      step1MutationProps={{ mutationVariables: { id, user, ... }, SendVerificationCodegql: SEND_VERIFICATION_CODE}}
      step2MutationProps={{ mutationVariables: { id, user, ... }, SendVerificationCodegql: VERIFY_EMAIL}}
    />
 */

import React, { useState } from 'react'
import { Route, Switch, Redirect, NavLink as RNavLink, useRouteMatch } from 'react-router-dom'
import { Card, CardHeader, CardFooter, CardBody, Button } from 'reactstrap'
import { Steps, Step, ActionButton } from '..'
import { SendVerificationCodeForm } from './SendVerificationCodeForm'
import { VerifyEmailForm } from './VerifyEmailForm'

const debug = require('debug')('sd:EmailVerificationWizard')

// TODO Make a save email for organization that checks emails is verified
const Step1 = ({ steps, setEmail, SendVerificationCodegql, mutationVariables, setShowEmailStepper }) => {
  return (
    <Card>
      <CardHeader>
        Change Email
      </CardHeader>
      <CardBody className='p-4'>
        {steps}
        <SendVerificationCodeForm
          SendVerificationCodegql={SendVerificationCodegql}
          mutationVariables={mutationVariables}
          setEmail={setEmail}
        />
      </CardBody>
      <CardFooter className='d-flex justify-content-end p-3'>
        <Button onClick={() => setShowEmailStepper(false)} color='secondary' className='mr-3'>
          Cancel
        </Button>
        <Button
          tag={RNavLink}
          to='verifycode'
          color='primary'
        >
          Next
        </Button>
      </CardFooter>
    </Card>
  )
}

const Step2 = ({ steps, email, onSaveButton, loading, VerifyEmailgql, mutationVariables, setShowEmailStepper }) => {
  return (
    <Card>
      <CardHeader>
        Change Email
      </CardHeader>
      <CardBody className='p-4'>
        {steps}
        <VerifyEmailForm
          VerifyEmailMutation={VerifyEmailgql}
          mutationVariables={{ ...mutationVariables, email }}
          email={email}
        />
      </CardBody>
      <CardFooter className='d-flex justify-content-end p-3'>
        <Button onClick={() => setShowEmailStepper(false)} color='secondary' className='mr-3'>
          Cancel
        </Button>
        <ActionButton onClick={() => onSaveButton(email)} color='primary' loading={loading}>
          Save email
        </ActionButton>
      </CardFooter>
    </Card>
  )
}

const EmailVerificationWizard = ({ organizationId, onSaveButton, onLoadingSave, step1MutationProps, step2MutationProps, setShowEmailStepper }) => {
  const { url } = useRouteMatch()
  const [email, setEmail] = useState('')

  const steps = (
    <Steps>
      <Step
        to={`${url}/getcode`}
        desktopPrefix='Step 1: '
        name='Get Code'
      />
      <Step
        to={`${url}/verifycode`}
        desktopPrefix='Step 2: '
        name='Verify code'
      />
    </Steps>
  )

  return (
    <>
      <Switch>
        <Route
          path={`${url}/getcode`}
          name='Get Code'
          render={
            props => <Step1 steps={steps} id={organizationId} setEmail={setEmail} {...step1MutationProps} setShowEmailStepper={setShowEmailStepper} />
          }
        />
        <Route
          exact
          path={`${url}/verifycode`}
          name='Verify code'
          render={props => (
            <Step2
              id={organizationId}
              steps={steps}
              email={email}
              onSaveButton={onSaveButton}
              loading={onLoadingSave}
              setShowEmailStepper={setShowEmailStepper}
              {...step2MutationProps}
            />
          )}
        />
        <Redirect to={`${url}/getcode`} />
      </Switch>
    </>
  )
}

export { EmailVerificationWizard }
debug('loaded')
