import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useStores } from '../stores/RootStore'
import { GET_CHANNEL_SETTINGS } from '../scenes/Channel/commonQueries'
import { isAmazon } from 'suredone-common'

const groupTitles = [
  { group: 'taxes', title: 'Taxes' },
  { group: 'imports', title: 'Imports' },
  { group: 'features', title: 'Features' },
  { group: 'shipping', title: 'Shipping' },
  { group: 'specifics', title: 'Item Specifics' },
  { group: 'defaults', title: 'Account Defaults' },
  { group: 'fitment', title: 'Fitment Compatibility' },
  { group: 'templates', title: 'Templates & Description' }
]

export const useChannelSettings = (slug, instanceNumber) => {
  // Get organizationId from Auth
  const { stores: { auth } } = useStores()
  const { user } = auth || {}
  const { organizationId } = user || {}

  const { loading, error, data } = useQuery(GET_CHANNEL_SETTINGS, { variables: { slug, instanceNumber, organizationId } })
  const { Channel } = data || { Channel: {} }
  const { settings: defaultSettings } = Channel || {}
  const { ChannelInstance } = data || { ChannelInstance: {} }
  const { settings } = ChannelInstance || {}

  const [info, setInfo] = useState([])

  // Only for ebay an amazon, it converts ebay_currency into ebay6_currency to make a correct comparison
  const contextHelper = (instanceName, defaultName) => {
    if (instanceNumber > 0 && (slug === 'ebay' || isAmazon(slug))) {
      const nameWithContext = defaultName.replace(slug, `${slug}${instanceNumber}`)
      return instanceName === nameWithContext
    }
    return instanceName === defaultName
  }

  const calcSettings = (defSett) => {
    const result = (settings || []).find(chiSett => contextHelper(chiSett.name, defSett.name))
    return result
  }

  useEffect(() => {
    // Creates a proper info object. For value we'll use the channel instance settings.
    // If it's undefined, the value will be a default setting

    const fullInfo = (defaultSettings || []).map((defSett, idx) => {
      const result = calcSettings(defSett)
      return {
        ...defSett,
        value: result && !(result.value?.length === 0 && (result.type === 'SELECT' || result?.type === 'TOGGLE'))
          ? result
          : {
              // value: testing(defSett) || {
              name: defSett.name,
              type: defSett.type,
              value: [(defSett.value || [])[0]],
              selectOptions: defSett.selectOptions
            },
        generalIndex: idx
      }
    })
    setInfo(fullInfo)
    // eslint-disable-next-line
  }, [defaultSettings, settings])

  const getGroupNames = items => Array.from(new Set((items || []).map(i => i.groupName)))

  const groupByGroupName = (groups, items) => (groups || []).map(group => (items || []).filter(item => item.groupName === group))

  const filterGroups = (groupedItems, neededGroups) => (groupedItems || []).filter(item => neededGroups.includes(item[0].groupName) ? item : null)

  // It can receive [{ groupName: 'imports' }] or just 'imports' to return 'Imports'
  const getGroupTitle = (group = []) => {
    const current = group[0].groupName || group
    return (groupTitles.find(i => i.group === current) || {}).title
  }

  const getSet = setName => (info || []).find(i => i.name === setName)

  // Returns ebay or ebay2
  const getContext = () => instanceNumber > 0 ? `${slug}${instanceNumber}` : slug

  // Converts ebay_storecats into ebay7_storecats if instanceNumber > 0
  const settingWithContext = setting => instanceNumber > 0 ? setting.replace(slug, `${slug}${instanceNumber}`) : setting

  return [settings, { loading, error }, info, { getGroupNames, groupByGroupName, filterGroups, getGroupTitle, getSet, getContext, settingWithContext }, organizationId]
}
