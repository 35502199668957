import React from 'react'
import { Pill } from '../../../components'
import { Card, CardBody } from 'reactstrap'
import searching from './assets/searching.png'
import typeOfSearch from './assets/typeOfSearch.png'
import savedSearches from './assets/savedSearches.png'
import { FiltersAndOperators } from './FiltersAndOperators'
import { LoggedOutPage } from '../../../components/LoggedOutPage'
import { FaExternalLinkAlt as ExternalLink } from 'react-icons/fa'
import './SearchDocs.scss'

const relatedLinks = {
  'How to Search SureDone for Products and Orders': 'https://support.suredone.com/support/solutions/articles/1000233246',
  'Searching for kits and bundles': 'https://support.suredone.com/support/solutions/articles/1000241841',
  'How do I search by eBay Listing Type?': 'https://support.suredone.com/support/solutions/articles/1000198964',
  'Searching for active, inactive, incomplete and suppressed Amazon Items in SureDone': 'https://support.suredone.com/support/solutions/articles/1000253388'
}

export const SearchDocs = () => {
  return (
    <LoggedOutPage
      className='search-docs'
      title='Searching SureDone'
      description='Use the Dashboard to search for Products, Orders, and more.'
    >
      <Card>
        <CardBody>
          <h2>Get started</h2>
          <p>
            First define the type of search you want to perform. <code>Products</code> search is the default but you can change it to search for <code>Orders</code>.
          </p>
          <img src={typeOfSearch} alt='Type of Search' />
          <p>
            Once you have defined the above, all that remains is to use the Search box to specify what are you looking for. For example, <code>Products</code> based on their <code>title</code>.
          </p>
          <img src={searching} alt='Searching' />
          <p>
            If you have saved searches you will find them under <code>Saved Searches</code>.
          </p>
          <img src={savedSearches} alt='Saved Searches' />
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <h2>Search filters and operators</h2>
          <p className='mb-0'>
            You can use filters and operators to further refine your searches. The more terms you provide in your search query, the fewer the number of results.
          </p>
          <p className='mt-3 mb-4'>
            <iframe style={{ aspectRatio: '16 / 9', width: '75%', margin: '0 auto', display: 'block' }} src='https://www.youtube.com/embed/-xtAly4j4K4' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;' allowfullscreen />
          </p>
          <FiltersAndOperators />
          <h3 className='mt-0'>
            Negate and combine search terms
          </h3>
          <p>
            Negate any search filter with a hyphen (<code>-</code>) so that matches for it aren’t included. You can use more than one search term to narrow down your search and reduce the number of results.
          </p>
          <p>
            <Pill>color:=blue</Pill> will search for items that are blue.
          </p>
          <p>
            <Pill>color:-=blue</Pill> will search for items that <u>are not</u> blue (note the <code>-</code> negating the above).
          </p>
          <p>
            <Pill>color:=blue material:-=leather</Pill> will search for items that are blue and not made of leather.
          </p>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <h2>Best practices</h2>
          <p>
            Many searches can be performed with a single search term. Use something that would be fairly specific, such as a <code>guid</code> or <code>id</code>. If you’re seeing too few results, make the search term less specific. If there are too many results, include additional terms, one at a time.
          </p>
          <p>
            Use a wider range of values when using dates or amounts as search terms. Currency conversions and time zone differences between you and your customer are a common source of confusion when looking up information. In these cases, additional search terms or even different ones altogether can help.
          </p>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <h2 className='pt-2 pb-1'>Related links</h2>
          <ul className='pl-3'>
            {Object.keys(relatedLinks).map(k => (
              <li key={k} className='mb-2'>
                <a href={relatedLinks[k]} target='_blank' rel='noopener noreferrer'>
                  {k} <ExternalLink size={10} />
                </a>
              </li>
            ))}
          </ul>
        </CardBody>
      </Card>
    </LoggedOutPage>
  )
}
