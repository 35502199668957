export const channelData = [
  { id: 'google', name: 'Google' },
  { id: 'amzn', name: 'Amazon' },
  { id: 'ebay', name: 'eBay' },
  { id: 'walmart', name: 'WalMart' }
]

export const channelIdToName = new Map(channelData.map(e => [e.id, e.name]))

export const siteData = [
  { channel: 'google', site: 'us', name: 'United States' },
  { channel: 'ebay', site: '0', name: 'United States' },
  { channel: 'ebay', site: '100', name: 'eBay Motors' }
]
