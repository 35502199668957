import { useState } from 'react'
import Cookies from 'universal-cookie'

const useCookies = () => {
  const cookies = new Cookies()
  const [items] = useState(() => {
    if (cookies.getAll()) {
      return cookies.getAll()
    }
    return null
  })

  return [items]
}

export default useCookies
