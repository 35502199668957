import React from 'react'
import { InstructionCollapse } from '../'
import { CodeBlock } from '../../../components'

const code = `<script>
    if (!window.shopData) window.shopData = {}
    if (!window.shopData.storefront) window.shopData.storefront = "shopify"
    if (!window.shopData.suredoneID) window.shopData.suredoneID = SUREDONEID
    if (!window.sdFitmentData) window.sdFitmentData = {}
    window.sdFitmentData.category = "{{ collection.title }}"
    window.sdFitmentData.categoryName =  "{{ collection.title }}"
  </script>
  <div id="sd-fitment-categories-root"></div>`

export const ShopifyCategorySubFilter = () => {
  return (
    <InstructionCollapse id='CategoriesSubFilter' title='4. Add Fitment Filter to Category Page'>
      <ol className='pl-3'>
        <li>Edit your theme files</li>
        <li>On editor search for <b>main-collection-product-grid.liquid</b></li>
        <li>We need to hide Shopify category page in order to replace with fitment category page.
          So search for the html tag that takes care of that and add to the parent <code>div</code> an id equals to <b>SD-CollectionPage</b> and <b>style="display: none;"</b> so our widget can find and show it.
          <img src='https://assets.mysuredone.com/688015/logos/fitment-advanced-shopify-CSF-DIV-1.png' alt='Shopify parent div' />
          <img src='https://assets.mysuredone.com/688015/logos/fitment-advanced-shopify-CSF-DIV-2.png' alt='Shopify parent div2' />
        </li>
        <li>
          Now we need to add a widget point of mount. For that add following code on <code>categories.html</code> right above the <code>div</code> of step 2 <b style={{ margin: '8px 0', display: 'inline-block' }}>(replace SUREDONEID with your SureDone id)</b>:
          <CodeBlock>{code}</CodeBlock>
          <img src='https://assets.mysuredone.com/688015/logos/fitment-advanced-shopify-CSF-DIV-3.png' alt='Shopify point of mount' />
        </li>
        <li>Lastly we need to load the widget on this page so paste following code: <code>{'<script src="https://fitment.suredone.com/bundle.min.js"></script>'}</code><br />
          <img src='https://assets.mysuredone.com/688015/logos/fitment-advanced-shopify-CSF-DIV-4.png' alt='Shopify bundle load' />
          <img src='https://assets.mysuredone.com/688015/logos/fitment-advanced-shopify-CSF-DIV-5.png' alt='Shopify Category fitment result' />
        </li>
      </ol>
    </InstructionCollapse>
  )
}
