import React, { useState } from 'react'
import Select from 'react-select'
import { Pill } from '../../../components'
import { operators } from './assets/operators'
import { searachableKeys } from './assets/searachableKeys'
import { ResourceList } from '../../../components/SearchableLists'
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap'

const availableTabs = ['Filters', 'Operators']
const typesOfSearch = ['Product', 'Order']
const selectOptions = typesOfSearch.map(i => ({ value: i, label: i }))

export const FiltersAndOperators = () => {
  const [activeTab, setActiveTab] = useState('1')
  const [selectedOption, setSelectedOption] = useState(selectOptions[0])

  const toggleActiveTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const handleChangeSelect = e => {
    setSelectedOption(e)
  }

  return (
    <>
      <Nav tabs className='border-top mt-5'>
        {availableTabs.map((i, idx) => (
          <NavItem key={`${i}-${idx}`}>
            <NavLink
              style={{ cursor: 'pointer' }}
              onClick={() => toggleActiveTab(`${idx + 1}`)}
              className={`${activeTab === `${idx + 1}` ? 'active' : ''}`}
            >
              {i}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId='1'>
          <Row className='my-3'>
            <Col sm='12'>
              <p>
                Use filters to limit your search terms so that they only apply to specific fields. Preface a search term with one or more of these filters. If your search term must include a space, wrap it in quotation marks (<Pill>title:"Disk brake"</Pill>).
              </p>
              <Row className='resource-list-header mx-0 py-2 align-items-center'>
                <Col xs={3} lg={4} className='pl-3'>Key</Col>
                <Col xs={5} lg={4} className='d-flex align-items-center'>
                  <span className='mr-2'>Type</span>
                  <Select
                    clearable={false}
                    searchable={false}
                    value={selectedOption}
                    options={selectOptions}
                    onChange={handleChangeSelect}
                    defaultValue={selectOptions[0]}
                  />
                </Col>
                <Col>Example</Col>
              </Row>
              <ResourceList
                itemsPerPage={30}
                searchPlaceholder='Search...'
                items={searachableKeys[selectedOption?.value]}
                searchableStrings={({ key, type }) => [key, type]}
                itemComponent={(
                  { item: { key, type, example } }) => (
                    <li key={`k-${key}`} className='list-group-item list-group-item-action px-3 py-2'>
                      <Row className='align-items-center'>
                        <Col xs={4}><Pill>{key}</Pill></Col>
                        <Col xs={4}>{type}</Col>
                        <Col xs={4}><code>{example}</code></Col>
                      </Row>
                    </li>
                )}
              />
            </Col>
          </Row>
        </TabPane>

        <TabPane tabId='2'>
          <Row className='my-3'>
            <Col sm='12'>
              <p>
                You can use the following operators when searching for amounts and dates to refine your search to a particular range:
              </p>
              <Row className='resource-list-header mx-0 py-2 align-items-center'>
                <Col xs={3} className='pl-3'>Operator</Col>
                <Col xs={5} className='pl-3'>Description</Col>
                <Col className='pl-3'>Example</Col>
              </Row>
              <ResourceList
                itemsPerPage={20}
                items={operators}
                searchPlaceholder='Search...'
                searchableStrings={({ operator, description }) => [operator, description]}
                itemComponent={(
                  { item: { operator, description, example } }) => (
                    <li key={`k-${operator}`} className='list-group-item list-group-item-action px-3 py-2'>
                      <Row className='align-items-center'>
                        <Col xs={3}><Pill>{operator}</Pill></Col>
                        <Col xs={5}>{description}</Col>
                        <Col><code>{example}</code></Col>
                      </Row>
                    </li>
                )}
              />
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </>
  )
}
