import React, { useState } from 'react'
import { FormGroup, Row, Col, Input, FormFeedback, Label } from 'reactstrap'
import Select from 'react-select'
import { Field, Form } from 'react-final-form'
import { Organization } from 'suredone-common'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'
import { GET_ORGANIZATION_DETAILS, ORGANIZATION_UPDATE } from '../../OrganizationManager/commonQueries'

import Debug from 'debug'
import { ErrorAlert, SaveButton } from '../../../components'
import { getCountryByISO, getCountriesList } from '../../../components/utils'
import { addHotjarTag } from '../utils'
import { GET_AUTH_STATUS } from '../commonQueries'
const debug = Debug('sd:EbayOrgLocation')

const EbayOrgLocation = ({ organizationId, instanceNumber, channelName, slug }) => {
  const { loading: loadingQuery, error, data } = useQuery(GET_ORGANIZATION_DETAILS, {
    variables: { id: organizationId }
  })
  const { OrganizationDetails } = data || { OrganizationDetails: {} }
  const {
    name,
    slug: orgSlug,
    legacyAccountId,
    email,
    phone,
    address,
    timezone
  } = OrganizationDetails

  const organization = new Organization({
    id: organizationId,
    name,
    slug: orgSlug,
    legacyAccountId,
    email,
    phone,
    address,
    timezone
  })
  const history = useHistory()
  const [showSuccess, setShowSuccess] = useState(false)
  const [updateOrganization, { loading, error: updateError }] = useMutation(ORGANIZATION_UPDATE,
    {
      onCompleted: (data) => {
        setShowSuccess(true)
        history.push(`/channels/${slug}/${instanceNumber}/authorization`)
      },
      refetchQueries: [{
        query: GET_AUTH_STATUS,
        variables: { slug, instanceNumber }
      }],
      awaitRefetchQueries: true,
      onError: (e) => {
        setShowSuccess(false)
      }
    }
  )

  const onHandleSubmit = async (values) => {
    if (window.analytics) {
      const currentEvent = `${channelName} Auth${instanceNumber}: Required settings`
      const currentHistory = window?.analyticsEventHistory
      const newHistory = [currentEvent, ...currentHistory].slice(0, 10)
      window.analyticsEventHistory = newHistory
      const fullFilled = {
        action: 'User clicks on save and complete',
        instanceNumber,
        channel: channelName,
        slug: `${slug}${instanceNumber > 0 ? instanceNumber : ''}`,
        pathHistory: window?.analyticsPathHistory,
        priorPath: window?.analyticsPathHistory?.[0],
        eventHistory: newHistory,
        priorEvent: newHistory?.[1]
      }
      window.analytics.track(currentEvent, fullFilled)
    }
    // Adds a Hotjar tag when the user authorize a channel
    addHotjarTag(channelName)
    updateOrganization({ variables: { id: organization.id, input: { address: { ...values } } } })
  }

  return (
    <>
      <Form
        onSubmit={onHandleSubmit}
        initialValues={organization.address}
        loading={loadingQuery || loading}
        success={showSuccess}
        customError={error || updateError}
      >
        {props => <EbayOrgLocationForm {...props} />}
      </Form>
    </>
  )
}

const EbayOrgLocationForm = ({ handleSubmit, submitting, pristine, valid, form, loading, dirtySinceLastSubmit, success, customError, initialValues }) => {
  return (
    <form className='form p-1' onSubmit={handleSubmit}>
      <Label className='mb-2' tag='legend'>Organization settings</Label>
      <p>eBay requires this data to successfully list products</p>
      <Row>
        <Col lg={6}>
          <FormGroup>
            <Label for='country'>Country</Label>
            <div className='form-label-group select-with-label'>
              <Field
                name='country'
                validate={name => name ? undefined : 'Country is required'}
                // react-select needs value/label fields
                format={isoValue => isoValue ? ({ label: getCountryByISO(isoValue), isoValue }) : isoValue}
                // we pick the countryName
                parse={country => country && (country.isoValue)}
              >
                {({ input, meta }) => (
                  <>
                    <Select
                      {...input}
                      id='Country'
                      // Run the original onChange and do more stuff
                      style={{ borderColor: meta.invalid && meta.touched ? '#dc3545' : '#aaa', height: '47px' }}
                      clearable={false}
                      options={getCountriesList()}
                      placeholder=''
                    />
                    <Label for='country'>Country</Label>
                    {meta.invalid && <div style={{ fontSize: '12px', color: '#dc3545' }}>Country is required</div>}
                  </>
                )}
              </Field>
            </div>
          </FormGroup>
          <FormGroup>
            <Label for='postalCode'>Postal code</Label>
            <div className='form-label-group'>
              <Field
                name='postalCode'
                validate={postalCode => {
                  if (!postalCode) return 'Postal code is required'
                }}
              >
                {({ input, meta }) => (
                  <>
                    <Input {...input} id='postalcode' invalid={meta.touched && meta.invalid} type='text' placeholder='Postal Code' />
                    <Label for='postalcode'>Postal Code</Label>
                    {meta.error && meta.touched && <FormFeedback>{meta.error}</FormFeedback>}
                  </>
                )}
              </Field>
            </div>
          </FormGroup>
          {customError && <ErrorAlert error={customError} />}
        </Col>
      </Row>
      <FormGroup className='float-right'>
        <SaveButton
          color='primary'
          className='mb-3'
          type='submit'
          loading={loading}
          disabled={submitting || pristine || !valid || loading}
          // The following sintax {state ? 'true' : false} is to avoid console warnings
          success={success ? 'true' : null}
          dirtysincelastsubmit={dirtySinceLastSubmit ? 'true' : null}
        >
          Save & Complete
        </SaveButton>
      </FormGroup>
    </form>
  )
}
export { EbayOrgLocation }

debug('loaded')
