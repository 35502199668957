import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { useStores } from '../../stores/RootStore'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import { Loadable } from '../../components'
import { AuthPage } from '../Auth/AuthPage'
import { WizardInfoModal } from './WizardInfoModal'
import { Contact, useRegister } from '.'
import {
  FaSignOutAlt as LogOut
} from 'react-icons/fa'
import './RegistrationWizard.scss'
const debug = require('debug')('sd:RegistrationWizard')

/**
 * This Wizard works on this way
 * 1) RegistrationWizard checks Last step completed by a graphQL call (only if
 *    organizationID doesn't exist that means never complete the basic data,
 *    otherwise redirect to step/1)
 * 2) At wizard-register/step/1 the Modal that shows info is displayed only if
 *    organizationID doesn't exist
 * 3) After filling the form, each time next is clicked, GraphQL call storing
 *    data on mongo and cognito
 *
 * First step creates organizationId so next steps just update this
 * organization collection and cognito params
 *
 * Last step should add to SetMoreOrganizationData the submitToLegacy boolean
 * so all migrations starts
 *
 * If you tryed to navigate to other step/N where N is not the next
 * uncompleted form, wizard redirects to next uncompleted form
 *
 * User only can go to previous form with previous button or stepper button
 * but is not available to go through url
 *
 * If user go back to a previusly completed step and fill the form again, then
 * all the nexts forms will have to be completed again
 *
 * It would be great to create 2 components, Submit button and Next button
 * where Submit is similar to Next (calls apollo mutation with
 * SetMoreOrganizationData) but adding to Submit button the submitToLegacy:
 * true and auth.refresh...
 */

const Steps = ({ userId, organizationData, WIZARD_STEPS, setModalUserActionsEnabled, setModalState, setModalShowCloseOnError }) => {
  const { id: organizationId } = organizationData || {}
  const { firstName, lastName, timezone } = useRegister(organizationId)

  return (
    <Contact
      userId={userId}
      organizationData={organizationData}
      firstName={firstName}
      lastName={lastName}
      timezone={timezone}
      setModalUserActionsEnabled={setModalUserActionsEnabled}
      setModalState={setModalState}
      setModalShowCloseOnError={setModalShowCloseOnError}
    />
  )
}

const Footer = ({ modalState, setModalState, modalUserActionsEnabled, modalShowCloseOnError }) => {
  const { stores: { auth } } = useStores()

  return (
    <>
      <WizardInfoModal
        modalState={modalState}
        setModalState={setModalState}
        modalUserActionsEnabled={modalUserActionsEnabled}
        modalShowCloseOnError={modalShowCloseOnError}
      />
      <Button tag={Link} color='link' to='/' onClick={_ => auth.logOut()}>
        <LogOut /> Log Out
      </Button>
    </>
  )
}

const _RegistrationWizard = () => {
  const { stores: { auth } } = useStores()
  const { id: userId } = (auth || {}).user
  const WIZARD_STEPS = ['contact']
  // This updates the lastStepCompleted to redirect user

  // Now modal is controlled by submit form so the handler should be accessible from 2 components
  // As the modal only exists on this part of the app, it doesn't justify useContext API
  const [modal, setModal] = useState(false)
  const [modalUserActionsEnabled, setModalUserActionsEnabled] = useState(false)
  const [modalShowCloseOnError, setModalShowCloseOnError] = useState(false)

  return (
    <AuthPage
      headerSubtitle={<p>Welcome to SureDone. Let's get you set up!</p>}
      form={
        <Loadable
          content={
            <Steps
              userId={userId}
              WIZARD_STEPS={WIZARD_STEPS}
              setModalState={setModal}
              setModalUserActionsEnabled={setModalUserActionsEnabled}
              setModalShowCloseOnError={setModalShowCloseOnError}
            />
          }
        />
      }
      footer={
        <Footer
          modalState={modal}
          setModalState={setModal}
          modalUserActionsEnabled={modalUserActionsEnabled}
          modalShowCloseOnError={modalShowCloseOnError}
        />
      }
    />
  )
}
const RegistrationWizard = observer(_RegistrationWizard)

export { RegistrationWizard }

debug('loaded')
