import React from 'react'
import { InfoAlert } from '../InfoAlert'
import { Card, CardBody } from 'reactstrap'
import { FitmentFAQ } from '../FitmentFAQ'
import { FitmentSummary } from '../FitmentSummary'
import { BigcommerceAddFYV } from './AddFitYourVehicle'
import { BigcommerceEditThemeFile } from './EditThemeFiles'
import { FitmentCustomFacets } from '../FitmentCustomFacets'
import { BigcommerceSearchPage } from './BigcommerceSearchPage'
import { BigcommerceCategorySubFilter } from './CategorySubFilter'
import { BigcommerceHideMyVehicleButton } from './HideMyVehicleButton'
import './BigcommerceAInstructions.scss'

export const BigcommerceAInstructions = () => {
  return (
    <>
      <InfoAlert>
        Check{' '}
        <a href='https://bigcommerce-fitment.suredone.com/' target='_blank' rel='noopener noreferrer'>
          <b>this guide</b>
        </a>{' '}
        for basic widget installation.
      </InfoAlert>
      <Card>
        <CardBody>
          <FitmentFAQ />
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <FitmentSummary />
          <BigcommerceEditThemeFile />
          <FitmentCustomFacets />
          <BigcommerceHideMyVehicleButton />
          <BigcommerceCategorySubFilter />
          <BigcommerceAddFYV />
          <BigcommerceSearchPage />
        </CardBody>
      </Card>
    </>
  )
}
