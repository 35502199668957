import React from 'react'
import Select from 'react-select'
import { Field } from 'react-final-form'
import { Col, Label, FormGroup } from 'reactstrap'

const SelectType = ({ data }) => {
  const { label, fieldName, options, description, disabled } = data
  return (
    <>
      <Col sm={7}>
        <Label tag={`${description && 'legend'}`} className={`${description && 'mb-0'}`} for={fieldName}>{label}</Label>
        {description && <p>{description}</p>}
      </Col>
      <Col sm={5}>
        <Field
          name={fieldName}
          // react-select needs value/label fields
          format={value => value ? ({ value, label: value }) : value}
          // we pick the selected value
          parse={selected => selected && (selected.value)}
        >
          {({ input, meta }) => {
            return (
              <FormGroup>
                <Select
                  {...input}
                  onChange={e => input.onChange(e)}
                  clearable={false}
                  options={options}
                  placeholder=''
                  style={{ borderColor: meta.invalid ? '#dc3545' : '#aaa' }}
                  disabled={disabled}
                />
                {meta.invalid && <div style={{ fontSize: '12px', color: '#dc3545', marginTop: '2px' }}>{label} is required</div>}
              </FormGroup>
            )
          }}
        </Field>
      </Col>
    </>
  )
}

export { SelectType }
