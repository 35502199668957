import gql from 'graphql-tag'

/**
* GraphQL query that get the storefront status.
*/
export const GET_STOREFRONT = gql`
query GetStorefrontStatus {
 StorefrontStatus {
   live
  }
}
`
// This query gets the data for the user. Don't mix with authStore (that one has cognito data)
export const GET_USER = gql`
query getUserData {
  User {
    id
    mfaEnabled
  }
}
`
