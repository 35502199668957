import React from 'react'
import { CodeBlock } from '../../../components'
import { InstructionCollapse } from '../InstructionCollapse'

const code = `{% if product.selected_or_first_available_variant.sku != blank -%}
    <script>
      if (!window.shopData) window.shopData = {}
      if (!window.shopData.storefront) window.shopData.storefront = "shopify"
      if (!window.shopData.suredoneID) window.shopData.suredoneID = SUREDONEID
      if (!window.sdFitmentData) window.sdFitmentData = {}
      window.sdFitmentData.sku = '{{product.selected_or_first_available_variant.sku}}'
    </script>
  {%- endif %}
  <script src="https://fitment.suredone.com/bundle.min.js"></script>`

export const ShopifyAddFYV = () => {
  return (
    <InstructionCollapse id='AddFYV' title='5. Add Fits Your Vehicle and Year Make Model table to the product page'>
      <ol className='pl-3'>
        <li>Edit your theme files</li>
        <li>Go to Sections {'>'} <code>main-product.liquid</code></li>
        <li>Add the following code:
          <CodeBlock>{code}</CodeBlock>
          Please <b>replace SUREDONEID with your SureDone id</b>.
        </li>
        <li>Now we need to add the root of the <strong>fitment table</strong> and <strong>fit your vehicle</strong> tags where you want to show each component.</li>
        <li>
          Example: We want to show below product description section so search <i>product__description</i> and paste following code below closing div:
          <CodeBlock language='css'>{'<div id="sd-fitment-fyv-root"></div>\n <div id="sd-fitment-chart-root"></div>'}</CodeBlock>
        </li>
        <img src='https://assets.suredone.com/multichannel-platform/updates/fitment-advanced-shopify-editor.png' alt='Shopify editor example' />
        <li>
          Click Save and wait until deployed
        </li>
        <img src='https://assets.suredone.com/multichannel-platform/updates/fitment-advanced-pdp.png' alt='Fitment Advanced product page demo' />
      </ol>
    </InstructionCollapse>
  )
}
