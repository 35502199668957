import React from 'react'
import { Card, CardBody, Col } from 'reactstrap'
import { Pill, makeSearch } from '../../../components'
import { FaExternalLinkAlt as ExternalLink } from 'react-icons/all'

export const LastSearches = ({ lastSearches }) => {
  if (!lastSearches) return null

  return (
    <Col className='mb-3 mb-md-0'>
      <Card className='h-100 mb-0'>
        <CardBody>
          <h6>Last Searches</h6>
          <ul className='last-searches pl-0 mb-0'>
            {lastSearches?.map(({ type, query }, idx) => (
              <li
                className='mb-2'
                key={`${query}${idx}`}
                style={{ listStyle: 'none' }}
              >
                <Pill
                  style={{ cursor: 'pointer' }}
                  onClick={() => makeSearch({ type, query })}
                >
                  {query} <ExternalLink color='#b5b6ba' className='mr-0' size={10} />
                </Pill>
              </li>
            ))}
          </ul>
        </CardBody>
      </Card>
    </Col>
  )
}
