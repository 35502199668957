import React from 'react'
import { Button } from 'reactstrap'
import { FaSearchPlus } from 'react-icons/fa'

export const Magnifier = ({ filter, handleAddFilter, columnId, original }) => {
  const handleClickMagnifier = (filter) => {
    const instance = original?.instance
    handleAddFilter(filter)

    // In case the user try to replicate the context filter, if it's ebay 3
    // we have to query context === 'ebay' and instance === 3
    if (columnId === 'context' && instance > 0) {
      handleAddFilter({ key: 'instance', opr: '=', val: instance })
    }
  }

  return (
    <Button
      size='xs'
      className='magnifier btn-sm'
      onClick={() => handleClickMagnifier(filter)}
    >
      <FaSearchPlus className='m-0' />
    </Button>
  )
}
