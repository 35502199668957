import React from 'react'
import { slugFormatter } from './utils'
import { useQuery } from '@apollo/react-hooks'
import { Loadable, Pill } from '../../components'
import { AVAILABLE_CHANNELS } from '../../scenes/Channel/commonQueries'

export const ChannelsFilter = ({ handleAddFilter, activeFilters }) => {
  const { loading, error, data } = useQuery(AVAILABLE_CHANNELS)
  const { ChannelInstances: availableChannels } = data || {}

  const applyFilter = (slug, instance) => {
    handleAddFilter({ key: 'context', opr: '=', val: slugFormatter(slug) })
    handleAddFilter({ key: 'instance', opr: '=', val: instance })
  }

  // Disables the channel filter when it has already been filtered by channel
  const disableChannelFilters = (activeFilters || []).some(({ key }) => key === 'context')

  return (
    <Loadable
      inline
      error={error}
      loading={loading}
      style={{ margin: '1rem 0 1.5rem' }}
      content={
        <>
          <p className='m-0'>
            Channels:
          </p>
          <div className='d-flex flex-wrap align-items-start mt-1 mb-3'>
            <Pill
              addable
              rounded
              key='suredone-0'
              className='mr-2 mb-2'
              disabled={disableChannelFilters}
              onAdd={() => applyFilter('suredone', 0)}
            >
              suredone
            </Pill>
            {(availableChannels || []).map(({ id, instance, channel: { slug } }) =>
              <Pill
                addable
                rounded
                key={id}
                className='mr-2 mb-2'
                disabled={disableChannelFilters}
                onAdd={() => applyFilter(slug, instance)}
              >
                {slugFormatter(slug)}{instance > 0 && instance}
              </Pill>
            )}
            <Pill
              addable
              rounded
              className='mr-2 mb-2'
              key='automation-0'
              disabled={disableChannelFilters}
              onAdd={() => applyFilter('automation', 0)}
            >
              automation
            </Pill>
          </div>
        </>
      }
    />
  )
}
