import React, { useState } from 'react'
import Select from 'react-select'
import { CopyToClipboard } from '../../../components'
import { Button, UncontrolledCollapse } from 'reactstrap'
import 'react-select/dist/react-select.css'

const TemplatesDetails = ({ templates }) => {
  const templatesForRender = templates?.map(({ name, value }, idx) => {
    return (
      <p key={idx} className='m-0 px-2 py-1 text-left' style={{ borderBottom: '1px solid #c6c9d566', backgroundColor: '#00000008' }}>
        ID: {name} <br />
        Name: {value}
      </p>
    )
  })
  const templatesForCopy = templates?.map(({ name, value }) => `
  Profile ID: ${name}
  Profile Name: ${value}
  `)
  return (
    <>
      <div
        className='mb-2'
        style={{ overflow: 'auto', maxHeight: '140px', borderRadius: '0.25rem', border: '1px solid #c6c9d5' }}
      >
        {templatesForRender}
      </div>
      <CopyToClipboard value={templatesForCopy} compact={false} className='float-right' />
    </>
  )
}

export const SelectType = ({ data }) => {
  const { item, parentCallback, settings } = data || {}
  const { value: { value }, selectOptions, name } = item

  // Find and format initial values for select
  const selected = (Array.isArray(value) ? value : [value]) || []
  const selfSettings = ((settings || []).filter(s => s.name === item.name)[0] || {}).selectOptions || []
  const filtered = (selected || []).map(sa => selfSettings.filter(ss => sa === (ss && ss.name) || null))
  // The value could come frome default select options (selectOptions) or from instance (settings)
  const correct = selectOptions.length
    ? (selectOptions || []).find(i => i.name === (value && value[0]))
    : filtered[0] && filtered[0][0] && filtered[0][0]
  // Initial select state
  const [selectedOption, setSelectedOption] = useState({
    value: (correct && correct.value) || null,
    label: (correct && correct.value) || null
  })

  // Format options for react-select, using default "selectOptions" or "selfSettings" from instance
  const options = ((selectOptions.length ? selectOptions : selfSettings) || []).map(({ value }) => ({
    value,
    label: value
  }))

  const handleChange = (event) => {
    // Stuff to handle select changes properly,
    const properEvent = event || { value: '', label: '' }
    // Handle proper values for the default "selectOptions" or "selfSettings" from instance
    const properName = (selectOptions.length ? selectOptions : selfSettings)
      .find(i => (i.value === (event && event.value)) ? event : '')
    setSelectedOption({ ...event, ...properName, label: properEvent.label })
    parentCallback({ ...event, ...properName, label: properEvent.label }, item)
  }

  return (
    <>
      <Select
        placeholder=''
        options={options}
        clearable={false}
        value={selectedOption}
        onChange={handleChange}
      />

      {/* Only Walmart has default_shipping_template_id */}
      {name === 'default_shipping_template_id' && selfSettings?.length && (
        <>
          <Button color='link' className='pl-0' id={item.name} style={{}}>
            View template details
          </Button>
          <UncontrolledCollapse toggler={item.name} className='px-1 w-100'>
            <TemplatesDetails templates={selfSettings} />
          </UncontrolledCollapse>
        </>
      )}
    </>
  )
}
