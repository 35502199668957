import React, { useState, useEffect } from 'react'
import { Loadable, ErrorAlert, EmptyState } from '../../components'
import { people } from '../../img/illustrations'
import { SaveButton } from '../../components/SaveButton'
import { Form, FormGroup, Label, Col, Row } from 'reactstrap'
import { StockSettingItem } from './StockSettingItem'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { GET_CHANNEL_STOCK_FULFILLMENT, UPDATE_STOCK_FULFILLMENT } from './commonQueries'
import { getValue } from './utils'
import Debug from 'debug'
const debug = Debug('sd:ChannelStockFulfillment')

export const ChannelStockFulfillment = ({ slug, instanceNumber }) => {
  const { noLocations } = people
  const { loading, error, data } = useQuery(
    GET_CHANNEL_STOCK_FULFILLMENT,
    { variables: { slug, instanceNumber } }
  )
  const { ChannelInstance } = data || {}
  const { stockMappings, authStatus } = ChannelInstance || {}
  const { locations, stockFields, stockValues } = stockMappings || {}
  const notAuthorizedInstance = authStatus !== 'complete'
  const locationsIsEmpty = (locations || []).length < 1

  const [info, setInfo] = useState([{}])

  // Mutation stuff
  const [isDirty, setIsDirty] = useState(false)
  const [mutationError, setMutationError] = useState(false)
  const [updateFields, { loading: mLading, data: mData }] = useMutation(UPDATE_STOCK_FULFILLMENT, {
    onCompleted: () => setIsDirty(false),
    onError: (err) => {
      debug('onError, e:', err)
      setMutationError(err || 'There was an error updating stock fields. Try again please.')
    }
  })

  useEffect(() => {
    const fullInfo = (locations || []).map((location, idx) => ({
      value: getValue(stockValues, location),
      name: location.nodeID,
      label: location.label,
      generalIndex: idx
    }))
    setInfo(fullInfo)
  }, [locations, stockValues])

  const handleChangeValue = (event, field) => {
    const position = field.generalIndex
    setIsDirty(true)
    setMutationError(false)
    // Updates info with the new stock value
    info[position].value = (event || {}).value
  }

  const onHandleSubmit = (e) => {
    e.preventDefault()
    const fieldsWithValue = info
      .filter(info => info.value)
      .map(i => ({ stockField: i.value, nodeID: i.name }))
    updateFields({
      variables: {
        slug,
        instanceNumber,
        stockMappings: fieldsWithValue
      }
    })
  }

  return (
    <Loadable
      inline
      loading={loading}
      error={error}
      style={{ textAlign: 'center' }}
      content={
        <EmptyState
          isEmpty={notAuthorizedInstance || locationsIsEmpty}
          heading='No locations.'
          content='You can not manage locations from this channel until the authorization process is complete or you have available locations.'
          customImage={noLocations}
        >
          <Form onSubmit={onHandleSubmit} className='channel-settings'>
            {slug === 'shopify' &&
              <p>
                <strong>Note:</strong> When mapping to <code>total_stock</code>, all other location fields are ignored.
              </p>}

            <Row className='align-items-baseline'>
              <Col><Label tag='legend' className='mb-3'>Inventory Location</Label></Col>
              <Col><Label tag='legend' className='mb-3'>Inventory Field</Label></Col>
            </Row>

            <FormGroup tag='fieldset'>
              {(info || []).map((item, idx) =>
                <StockSettingItem item={item} key={item.nodeId || idx} stockFields={stockFields} parentCallback={handleChangeValue} />
              )}
            </FormGroup>

            {mutationError && <ErrorAlert error={mutationError} />}

            <div className='d-flex justify-content-end'>
              <SaveButton
                type='submit'
                color='primary'
                loading={mLading}
                disabled={!isDirty}
                success={mData ? 'true' : null}
                dirtysincelastsubmit={isDirty ? 'true' : null}
              />
            </div>
          </Form>
        </EmptyState>
      }
    />
  )
}
