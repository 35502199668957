/**
 * Utilities for normalizing user data from different sources.
 * They come in several flavors, part of a JWT, backend call from AWS Cognito api, etc.
 */

/* eslint-disable-file no-multi-spaces */

import Debug from 'debug'

const debug = Debug('sd:common:organization')

/**
 * Utility class for normalizing our concept of a user. All the data on this
 * class is expected to be present in the places we refer to users:
 *  - Frontend, representing the logged in user
 *  - Frontend, representing another user on the platform
 *  - Backend, constructed from the Cognito token
 *  - Backend, constructed from the Cognito API
 *  - Backend, constructed from MongoDB
 *
 * Check out the from*() functions to construct users in the above secnarios.
 */
export class Organization {
  /**
   * Constructs a user object.
   */
  constructor ({
    id,
    name,
    slug,
    legacyAccountId,
    email,
    address,
    phone,
    bio,
    fileUrl,
    lastStepCompleted,
    primaryContactFirstName,
    primaryContactLastName,
    subscribeToNewsletter,
    timezone,
    businessPerson,
    stripeId,
    cachedChannelInstance,
    salesforceLeadId,
    userId,
    salesforceAccountId,
    stripePlanId,
    source,
    utmSource,
    affid,
    utmCampaign,
    utmMedium,
    channelEventCreated,
    createDate,
    demographics,
    stripeChurn,
    isTrialing,
    stripeTrialEnd,
    stripeTrialStart,
    billingPeriodStart,
    billingPeriodEnd,
    stripePlanTTL,
    stripePrepay,
    demoWall,
    showGMV,
    showUpdates,
    downgradeEnabled,
    deactivatedWall,
    billingLimits,
    stripeUpgrade,
    stripePlanIdAddons,
    checkoutLink
  }) {
    if (!id) throw new Error('Organization missing id')
    this.id = id
    this.name = name
    this.slug = slug
    this.email = email
    this.address = address
    this.legacyAccountId = legacyAccountId
    this.phone = phone
    this.bio = bio
    this.fileUrl = fileUrl
    this.lastStepCompleted = lastStepCompleted
    this.primaryContactFirstName = primaryContactFirstName
    this.primaryContactLastName = primaryContactLastName
    this.subscribeToNewsletter = subscribeToNewsletter
    this.timezone = timezone
    this.businessPerson = businessPerson
    this.stripeId = stripeId
    this.cachedChannelInstance = cachedChannelInstance
    this.salesforceLeadId = salesforceLeadId
    this.userId = userId
    this.salesforceAccountId = salesforceAccountId
    this.stripePlanId = stripePlanId
    this.source = source
    this.affid = affid
    this.utmSource = utmSource
    this.utmCampaign = utmCampaign
    this.utmMedium = utmMedium
    this.channelEventCreated = channelEventCreated
    this.createDate = createDate
    this.demographics = demographics
    this.stripeChurn = stripeChurn
    this.isTrialing = isTrialing
    this.stripeTrialEnd = stripeTrialEnd
    this.stripeTrialStart = stripeTrialStart
    this.billingPeriodStart = billingPeriodStart
    this.billingPeriodEnd = billingPeriodEnd
    this.stripePlanTTL = stripePlanTTL
    this.stripePrepay = stripePrepay
    this.demoWall = demoWall
    this.showGMV = showGMV
    this.showUpdates = showUpdates
    this.downgradeEnabled = downgradeEnabled
    this.deactivatedWall = deactivatedWall
    this.billingLimits = billingLimits
    this.stripeUpgrade = stripeUpgrade
    this.stripePlanIdAddons = stripePlanIdAddons
    this.checkoutLink = checkoutLink
  }

  /**
   * Returns a user object constructed from a user MongoDB entry.
   */
  static fromMongo (data) {
    const id = data._id.toString()

    return new Organization({
      id,
      name: data.name,
      slug: data.slug,
      legacyAccountId: data.legacyAccountId,
      email: data.email,
      address: data.address,
      phone: data.phone,
      bio: data.bio,
      fileUrl: data.fileUrl,
      lastStepCompleted: data.lastStepCompleted,
      primaryContactFirstName: data.primaryContactFirstName,
      primaryContactLastName: data.primaryContactLastName,
      subscribeToNewsletter: data.subscribeToNewsletter,
      timezone: data.timezone,
      businessPerson: data.businessPerson,
      stripeId: data.stripeId,
      salesforceLeadId: data.salesforceLeadId,
      cachedChannelInstance: data.cachedChannelInstance,
      userId: data.userId,
      salesforceAccountId: data.salesforceAccountId,
      stripePlanId: data.stripePlanId,
      source: data.source,
      affid: data.affid,
      utmSource: data.utmSource,
      utmCampaign: data.utmCampaign,
      utmMedium: data.utmMedium,
      channelEventCreated: data.channelEventCreated,
      createDate: data.createDate,
      demographics: data.demographics,
      stripeChurn: data.stripeChurn,
      isTrialing: data.isTrialing,
      stripeTrialEnd: data.stripeTrialEnd,
      stripeTrialStart: data.stripeTrialStart,
      billingPeriodStart: data.billingPeriodStart,
      billingPeriodEnd: data.billingPeriodEnd,
      stripePlanTTL: data.stripePlanTTL,
      stripePrepay: data.stripePrepay,
      demoWall: data.demoWall,
      showGMV: data.showGMV,
      showUpdates: data.showUpdates,
      downgradeEnabled: data.downgradeEnabled,
      deactivatedWall: data.deactivatedWall,
      inPersonDemo: data.inPersonDemo,
      billingLimits: data.billingLimits,
      stripeUpgrade: data.stripeUpgrade,
      stripePlanIdAddons: data.stripePlanIdAddons,
      checkoutLink: data.checkoutLink
    })
  }
}

debug('loaded')
