import React, { useState } from 'react'
import moment from 'moment'
import { sampleData } from './sampleData'
import { Orders } from '../graphs/Orders'
import { TaxTotal } from '../graphs/TaxTotal'
import { people } from '../../../img/illustrations'
import { DateRangePicker } from '../../../components'
import { ShipCostTotal } from './ShipCostTotal'
import { ShippingTotal } from '../graphs/ShippingTotal'
import { EmptyState } from '../../../components/EmptyState'
import { Button, Card, CardBody, Col, Row } from 'reactstrap'
import { OrdersItemTotals } from '../graphs/OrdersItemTotals'
import { MdReorder as List, MdBorderAll as Squares } from 'react-icons/md'
import '../Dashboard.css'
import 'react-datepicker/dist/react-datepicker.css'

import { useQuery } from '@apollo/react-hooks'
import { GRAPHS_DATA, GET_ONE_ORDER } from '../commonQueries'

export const Graphs = () => {
  // Starts with the last month
  const [startDate, setStartDate] = useState(moment(new Date()).subtract(1, 'month'))
  const [endDate, setEndDate] = useState(new Date())
  const [listMode, setListMode] = useState(false)
  const [useSample, setUseSample] = useState(false)

  const { loading, error, data } = useQuery(GRAPHS_DATA, {
    variables: {
      from: moment(startDate).format('YYYY-MM-DD'),
      to: moment(endDate).format('YYYY-MM-DD')
    }
  })
  const { GetDashboardData: graphData } = data || {}
  const { orders, ototals } = graphData || {}
  const noDataForGraphs = (orders || []).length < 1 || (ototals || []).length < 1
  const { emptyGraph: image } = people

  useQuery(GET_ONE_ORDER, {
    onCompleted: ({ GetOneOrder }) => {
      if (!GetOneOrder) setUseSample(true)
    }
  })

  const handleChangeDateRangePicker = event => {
    const { start, end } = event || {}
    if (start) setStartDate(start)
    if (end) setEndDate(end)
  }

  return (
    <section className='dashboard-graphs'>
      <Row>
        <Col>
          <Card id='graphrange'>
            <CardBody className='align-items-center justify-content-between d-flex flex-wrap custom-pickers' style={{ zIndex: 2 }}>
              <div className='d-flex align-items-center flex-wrap'>
                <span className='title mr-2'>Graphs range</span>
                <DateRangePicker
                  selectedStartDate={startDate}
                  onChangeDates={handleChangeDateRangePicker}
                />
              </div>
              <div className='align-items-center d-none d-lg-flex'>
                <Button onClick={() => setListMode(true)} className='mr-2'><List size={18} style={{ margin: 0 }} /></Button>
                <Button onClick={() => setListMode(false)}><Squares size={18} style={{ margin: 0 }} /></Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <EmptyState
        isEmpty={noDataForGraphs && !useSample && !loading}
        customImage={image}
        content='There is no data for that date range, please select another range.'
      >
        <Row className={(useSample && !loading) ? 'sample-data' : ''}>
          <Col xs={12}><OrdersItemTotals title='Total Sales' data={data} sampleData={sampleData} useSample={useSample} loading={loading} error={error} /></Col>
        </Row>

        <Row className={(useSample && !loading) ? 'sample-data' : ''}>
          <Col xs={12} lg={!listMode && 6}><Orders title='Total Orders' data={data} sampleData={sampleData} useSample={useSample} loading={loading} error={error} /></Col>
          <Col xs={12} lg={!listMode && 6}><TaxTotal title='Total Tax' data={data} sampleData={sampleData} useSample={useSample} loading={loading} error={error} /></Col>
        </Row>

        <Row className={(useSample && !loading) ? 'sample-data' : ''}>
          <Col xs={12} lg={!listMode && 6}><ShippingTotal title='Total Shipping' data={data} sampleData={sampleData} useSample={useSample} loading={loading} error={error} /></Col>
          <Col xs={12} lg={!listMode && 6}><ShipCostTotal title='Total Shipments Cost' data={data} sampleData={sampleData} useSample={useSample} loading={loading} error={error} /></Col>
        </Row>
      </EmptyState>
    </section>
  )
}
