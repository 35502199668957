import React from 'react'
import { MainNavbar } from '../components'

/**
 * Renders the page with only the blue header
 * aside and main can be received by props
 * @param {{children: React.ReactHTMLElement, setSidebarOpen: function}} props
 */
export const OnlyHeaderPage = ({ children, setSidebarOpen }) => {
  return (
    <>
      <MainNavbar setSidebarOpen={setSidebarOpen} />
      {children}
    </>
  )
}
