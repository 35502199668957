import React, { useEffect, useCallback } from 'react'
import Debug from 'debug'
import { Card, CardBody } from 'reactstrap'
import { Loadable } from '../../components'
import { useHistory } from 'react-router-dom'
import { useQualifications } from '../../hooks'
import { CalendlyEventListener } from 'react-calendly'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { LoggedOutPage } from '../../components/LoggedOutPage'
import { GET_QUALIFICATIONS, UPDATE_QUALIFICATIONS } from '../Qualifications/commonQueries'
const debug = Debug('sd:DemoSchedule')

const trackEvent = (eventName, customAttributes) => {
  if (window.analytics) {
    const currentEvent = eventName
    const currentHistory = window?.analyticsEventHistory || []
    const newHistory = [currentEvent, ...currentHistory].slice(0, 10)
    window.analyticsEventHistory = newHistory
    const fullFilled = {
      ...customAttributes,
      pathHistory: window?.analyticsPathHistory,
      priorPath: window?.analyticsPathHistory?.[1],
      eventHistory: newHistory,
      priorEvent: newHistory?.[1]
    }
    window.analytics.track(currentEvent, fullFilled)
  }
}

const scrollToTop = () => window.scrollTo(0, 0)

export const DemoSchedule = () => {
  const history = useHistory()
  const { storedValue } = useQualifications()
  const { email, firstName, lastName, company } = storedValue || {}

  const { data: qData, loading: qLoading, error: qError } = useQuery(GET_QUALIFICATIONS, {
    variables: { email },
    skip: !email
  })
  const { GetQualifications } = qData || {}
  const { calendlyData } = GetQualifications || {}

  const redirectToRegister = useCallback(() => {
    history.push('/register')
  }, [history])

  const [updateQualifications] = useMutation(UPDATE_QUALIFICATIONS, {
    onCompleted: () => redirectToRegister(),
    onError: error => debug({ error })
  })
  // Schedule a demo iframe params
  const baseUrl = 'https://calendly.com/suredone/demo-call'
  const calendlyParams = `${email ? `&email=${email.replace('+', '%2B')}` : ''}${firstName ? `&first_name=${firstName}` : ''}${lastName ? `&last_name=${lastName}` : ''}${company ? `&a1=${company}` : ''}&a3=Demonstration%20Call`

  // Executed once the Calendy iframe was loaded
  const handleCalendlyLoaded = () => {
    trackEvent('Schedule a demo: Calendy was loaded', {
      action: 'Calendy was loaded',
      description: 'The Calendly iframe is ready to schedule a demo.'
    })
  }

  // Executed once the user selects date and time (the demo is close to be scheduled, but didn't confirmed yet)
  const handleSelectedDate = () => {
    trackEvent('Schedule a demo: Selected date and time', {
      action: 'User selects date and time on calendly iframe',
      description: 'Date and time for scheduled demonstration were selected'
    })
  }

  // Executed when the demo schedule was confirmed
  const handleEventScheduled = e => {
    const { data } = e || {}
    const { payload } = data || {}
    scrollToTop()
    // Saves the data on mongo
    updateQualifications({
      variables: {
        qualifications: {
          email,
          calendlyData: payload
        }
      }
    })
    trackEvent('Schedule a demo: Individual demo sign up', {
      email,
      action: 'Clicks schedule event'
    })
  }

  useEffect(() => {
    scrollToTop()
    // eslint-disable-next-line
  }, [])

  // Prevents a new demo schedule for a user that already have one
  useEffect(() => {
    if (calendlyData) {
      redirectToRegister()
    }
  }, [calendlyData, redirectToRegister])

  return (
    <LoggedOutPage
      title='Schedule a Quick Call'
    >
      <Card className='mb-5'>
        <CardBody>
          <Loadable
            inline
            error={qError}
            loading={qLoading}
            content={
              <>
                <p className='mb-0'>
                  80% of our prospects find that we show them what they want to see faster than they find it themselves - and together we'll figure out if SureDone will help you scale your business. We're not focused on "making a sale". We start with education. We'll give you access and a helping hand. Schedule your call below!
                </p>
                <CalendlyEventListener
                  onEventTypeViewed={() => handleCalendlyLoaded()}
                  onDateAndTimeSelected={() => handleSelectedDate()}
                  onEventScheduled={e => handleEventScheduled(e)}
                >
                  <iframe
                    width='100%'
                    height='885px'
                    frameBorder='0'
                    title='Calendly'
                    src={`${baseUrl}?embed_domain=''&embed_type=Inline${calendlyParams}`}
                  />
                </CalendlyEventListener>
              </>
            }
          />
        </CardBody>
      </Card>
    </LoggedOutPage>
  )
}
