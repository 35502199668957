export const stepInstructions = {
  Permissions: {
    instructions: {
      stepInstructions: [
        {
          title: 'Login with Etsy & Grant Application Access',
          description: 'Click "Authorize Etsy" below to start the authorization process. Grant application access to SureDone to enable management of your listings and orders.'
        }
      ]
    }
  }
}
