import React from 'react'
import { Col, Row } from 'reactstrap'

const VerticalStep = (props) => {
  const { number, title, description, imgSrc, imgAlt } = props

  return (
    <Row className='mx-4 mx-md-0 justify-content-center'>
      <Row className='border-bottom w-100'>
        <Row className='my-4'>
          <Col lg={1} className='d-flex justify-content-center align-items-center'>
            <p className='metric-value h4'>
              <span>{number}</span>
            </p>
          </Col>
          <Col lg={6} className='d-flex align-items-center'>
            <Row>
              <Col>
                <h6>{title}</h6>
                <p>{description}</p>
              </Col>
            </Row>
          </Col>
          {imgSrc &&
            <Col lg={5} className='d-flex justify-content-center align-items-center'>
              <img src={imgSrc} className='img-thumbnail' alt={imgAlt || 'Explicative Image'} />
            </Col>}
        </Row>
      </Row>
    </Row>
  )
}

const VerticalStepperTitle = (props) => {
  const { title } = props
  return (
    <Row className='border-bottom ml-2 mr-3'>
      <Row className='my-2'>
        <Col>
          <h4>{title}</h4>
        </Col>
      </Row>
    </Row>
  )
}

export { VerticalStepperTitle, VerticalStep }
