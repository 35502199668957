import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Select from 'react-select'
import { savedLogsDays } from './utils'
import { DateRangePicker } from '../../components'

// turns 4 into 04, but 11 is still 11
const with0 = (num) => ('0' + num).slice(-2)

const today = moment().format('YYYY-MM-DD') // 2021-02-23
const yesterday = moment().subtract(1, 'day').format('YYYY-MM-DD') // 2021-02-22
const currentMonth = with0(1 + moment().month()) // + 1 because in moment jan === 0 and dec === 11
const currentYear = moment().year()
const past7days = moment().subtract(7, 'day').format('YYYY-MM-DD') // 2021-02-16
const past30days = moment().subtract(30, 'day').format('YYYY-MM-DD') // 2021-01-24
const dayStart = '00:00:00'
const dayEnd = '23:59:59'

const dateRangeOptions = [
  { value: 'Today', label: 'Today', start: `${today}T${dayStart}`, end: `${today}T${dayEnd}` },
  { value: 'Yesterday', label: 'Yesterday', start: `${yesterday}T${dayStart}`, end: `${yesterday}T${dayEnd}` },
  { value: 'Past 7 days', label: 'Past 7 days', start: `${past7days}T${dayStart}`, end: `${today}T${dayEnd}` },
  { value: 'Past 30 days', label: 'Past 30 days', start: `${past30days}T${dayStart}`, end: `${today}T${dayEnd}` },
  { value: 'Month to date', label: 'Month to date', start: `${currentYear}-${currentMonth}-01T${dayStart}`, end: `${today}T${dayEnd}` },
  { value: 'Custom', label: 'Custom' }
]

export const DateFilter = ({ handleAddFilter, handleRemoveFilter, activeFilters }) => {
  const [selectValue, setSelectValue] = useState()

  const handleChangeDateRange = event => {
    const { value, start, end } = event || {}
    handleRemoveFilter('createdAt')
    setSelectValue(event?.value)

    if (value !== 'Custom') { // Custom dates will be handled by <DateRangePicker />
      handleAddFilter({ key: 'createdAt', opr: '>', val: start })
      handleAddFilter({ key: 'createdAt', opr: '<', val: end })
    }
  }

  const handleChangeDateRangePicker = event => {
    const { start, end } = event || {}
    handleRemoveFilter('createdAt')
    const startDate = moment(start).format('YYYY-MM-DDTHH:mm:ss')
    const endDate = moment(end).format('YYYY-MM-DDTHH:mm:ss')
    if (start) handleAddFilter({ key: 'createdAt', opr: '>', val: startDate })
    if (end) handleAddFilter({ key: 'createdAt', opr: '<', val: endDate })
  }

  // Removes date selection when date filter was removed
  useEffect(() => {
    const hasDateFilter = activeFilters?.find(({ key }) => key === 'createdAt')
    if (!hasDateFilter && selectValue !== 'Custom') setSelectValue()
  }, [activeFilters, selectValue])

  return (
    <div className='d-flex flex-wrap align-items-baseline'>
      <Select
        className='mb-2'
        clearable={false}
        value={selectValue}
        placeholder='Date range'
        options={dateRangeOptions}
        onChange={handleChangeDateRange}
      />
      {selectValue === 'Custom' && (
        <>
          <small className='w-100 mt-0 mb-2 text-muted'>Filter logs by <code>createdAt</code> time in UTC</small>
          <DateRangePicker
            showTimeSelect
            onChangeDates={handleChangeDateRangePicker}
            minDate={moment().subtract(savedLogsDays, 'day')}
          />
        </>
      )}
    </div>
  )
}
