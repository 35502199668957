import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { Col, Row } from 'reactstrap'
import { Pill } from '../../components'
import { DateFilter, FilterModal, formatActiveFilters } from './index'

// Filter options
// value: String      Required for react-select
// label: String      Required for react-select
// type: String       input|fixed
// filters: [String]  if type === 'fixed', filters will have all the possible fixed values for that filter

const initialOptions = [
  { value: 'identifier', label: 'identifier', type: 'input' },
  { value: 'context', label: 'context', type: 'input' },
  { value: 'integration', label: 'integration', type: 'input' },
  { value: 'requestId', label: 'requestId', type: 'input' }
]

const extraOptions = [
  { value: 'action', label: 'action', type: 'fixed', filters: ['add', 'edit', 'relist', 'end', 'delete', 'start', 'sold', 'skip', 'other', 'import', 'export', 'acknowledge', 'tracking'] },
  { value: 'accountId', label: 'accountId', type: 'input' },
  { value: 'channelId', label: 'channelId', type: 'input' },
  { value: 'id', label: 'id', type: 'input' },
  { value: 'instance', label: 'instance', type: 'input' },
  { value: 'jobId', label: 'jobId', type: 'input' },
  { value: 'method', label: 'method', type: 'fixed', filters: ['bulk', 'ui', 'api', 'sync', 'auto'] },
  { value: 'operation', label: 'operation', type: 'input' },
  { value: 'result', label: 'result', type: 'fixed', filters: ['success', 'error', 'failure', 'queued', 'pending', 'warning', 'submitted', 'complete', 'rejected'] },
  { value: 'retry', label: 'retry', type: 'input' },
  { value: 'type', label: 'type', type: 'fixed', filters: ['item', 'order', 'category', 'inventory', 'price', 'image', 'variation', 'shipping', 'page', 'setting', 'authorize', 'register', 'connection', 'event'] }
]

export const FilterBy = ({ handleAddFilter, handleRemoveFilter, activeFilters }) => {
  const areActiveFiltersToShow = activeFilters?.length > 0

  const [modal, setModal] = useState(false)
  const [actualFilterAttempt, setActualFilterAttempt] = useState({})
  const [optionsToFilter, setOptionsToFilter] = useState(initialOptions)

  const toggle = () => setModal(!modal)

  useEffect(() => {
    if (activeFilters?.length > 0) {
      const _initialOptions = initialOptions.map(i => i.value)
      const showAllOptions = activeFilters.some(i => _initialOptions?.includes(i?.key))
      if (showAllOptions) setOptionsToFilter([...initialOptions, ...extraOptions])
    }
    // eslint-disable-next-line
  }, [activeFilters])

  const handleChange = event => {
    setActualFilterAttempt(event)
    setOptionsToFilter([...initialOptions, ...extraOptions])
    toggle()
  }

  const handleClickPill = e => {
    const allOptions = [...initialOptions, ...extraOptions]
    const filterToEdit = allOptions.find(i => i.value === e?.key)
    setActualFilterAttempt(filterToEdit)

    if (e.key !== 'createdAt') {
      toggle()
    }
  }

  return (
    <>
      <Row>
        <Col>
          <div className='d-flex flex-nowrap align-items-baseline'>
            <Select
              clearable={false}
              onChange={handleChange}
              options={optionsToFilter}
              placeholder='+ Add filter by'
              className='filter-by-select mb-3'
            />
            <FilterModal
              modal={modal}
              toggle={toggle}
              handleAddFilter={handleAddFilter}
              actualFilterAttempt={actualFilterAttempt}
            />
          </div>
        </Col>

        <Col xs={12} lg={6}>
          <DateFilter handleAddFilter={handleAddFilter} handleRemoveFilter={handleRemoveFilter} activeFilters={activeFilters} />
        </Col>
      </Row>

      {/* Active Filters */}
      <Row>
        <Col>
          {areActiveFiltersToShow && (
            <>
              <h6 className='mb-2 mt-3'>Active filters:</h6>
              {activeFilters?.map(({ key, val, opr }, idx) =>
                <Pill
                  closable
                  rounded
                  name={key}
                  key={key + idx}
                  className='mr-2 mb-2'
                  onClose={handleRemoveFilter}
                  onClick={() => handleClickPill({ key, val, opr })}
                >
                  {formatActiveFilters({ key, val, opr })}
                </Pill>
              )}
            </>
          )}
        </Col>
      </Row>
    </>
  )
}
