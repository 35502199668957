import React from 'react'
import { useQuery } from '@apollo/client'
import { LastSearches, SavedSearches } from '.'
import { useLocalStorage } from '../../../hooks'
import { Card, CardBody, Row } from 'reactstrap'
import { GET_SAVED_QUERIES } from '../../../components/MainNavbar/commonQueries'

export const LAST_SEARCHES_KEY = 'sd-last-searches'

export const YourSearches = () => {
  const [lastSearches] = useLocalStorage(LAST_SEARCHES_KEY)
  const { loading, data, error } = useQuery(GET_SAVED_QUERIES)
  const { GetSavedQueries: savedSearches } = data || {}

  if (!lastSearches && !savedSearches?.length > 0) return null

  return (
    <Card className='your-searches'>
      <CardBody>
        <h5 className='mb-3'>Your Searches</h5>
        <Row>
          <LastSearches lastSearches={lastSearches} />
          <SavedSearches savedSearchesData={{ savedSearches, loading, error }} />
        </Row>
      </CardBody>
    </Card>
  )
}
