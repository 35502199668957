import React from 'react'
import { Field } from 'react-final-form'
import { Col, FormFeedback, FormGroup, Input, Label } from 'reactstrap'

const InputType = ({ data }) => {
  const { label, fieldName, description } = data
  return (
    <>
      <Col sm={7}>
        <Label tag={`${description && 'legend'}`} className={`${description && 'mb-0'}`} for={fieldName}>{label}</Label>
        {description && <p>{description}</p>}
      </Col>
      <Col sm={5}>
        <Field
          name={fieldName}
        >
          {({ input, meta }) => {
            return (
              <FormGroup>
                <Input
                  {...input}
                  type='text'
                  invalid={meta.touched && meta.invalid}
                />
                {meta.error && meta.touched && (
                  <FormFeedback>{meta.error}</FormFeedback>
                )}
              </FormGroup>
            )
          }}
        </Field>
      </Col>
    </>
  )
}

export { InputType }
