import React from 'react'
import Select from 'react-select'
import { Field } from 'react-final-form'
import { Col, Label, FormGroup } from 'reactstrap'

const multiSelectFormatter = (event, delimiter) => {
  let arrayOfValues = event
  if (delimiter && typeof event === 'string') {
    arrayOfValues = event?.split(delimiter) || []
  }
  return arrayOfValues.map(i => ({ value: i, label: i }))
}

export const SelectMultiType = ({ data, extraData }) => {
  const { delimiter, label, fieldName, description, getOptionsFrom } = data
  const { optionsToSelect } = extraData || {}

  return (
    <>
      <Col sm={7}>
        <Label tag={`${description && 'legend'}`} className={`${description && 'mb-0'}`} for={fieldName}>{label}</Label>
        {description && <p>{description}</p>}
      </Col>

      <Col sm={5}>
        <Field
          name={fieldName}
          format={value => multiSelectFormatter(value, delimiter)}
          parse={e => (e || '').split(',').join(delimiter)}
        >
          {({ input, meta }) => {
            return (
              <FormGroup>
                <Select
                  {...input}
                  multi
                  simpleValue
                  placeholder=''
                  removeSelected
                  clearable={false}
                  closeOnSelect={false}
                  options={optionsToSelect?.[getOptionsFrom]}
                  style={{ borderColor: meta.invalid ? '#dc3545' : '#aaa' }}
                />
                {meta.invalid && <div style={{ fontSize: '12px', color: '#dc3545', marginTop: '2px' }}>{label} is required</div>}
              </FormGroup>
            )
          }}
        </Field>
      </Col>
    </>
  )
}
