import React from 'react'
import { Nav, NavLink } from 'reactstrap'
import { useRouteMatch, NavLink as RNavLink } from 'react-router-dom'

// Hacer un userCoverTabs e instanciarlo en userCover porque solo tiene sentido la existencia de
// las tabs con un cover

const CoverTabs = ({ availableTabs }) => {
  const match = useRouteMatch()
  const { url } = match
  return (
    <Nav className='page-navs'>
      <div className='nav-scroller'>
        <div className='nav nav-center nav-tabs'>
          {availableTabs && availableTabs.map(tab => (<NavLink key={tab} to={`${url}/${tab.toLowerCase()}`} tag={RNavLink}>{tab}</NavLink>))}
        </div>
      </div>
    </Nav>
  )
}

export { CoverTabs }
