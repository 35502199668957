import gql from 'graphql-tag'

export const GET_CUSTOMER = gql`
  query GetCustomer {
    GetCustomer {
      id
      isLegacyCustomer
      invoiceSettings {
        default_payment_method
      }
      product {
        active
        created
        description
        id
        metadata {
          display
        }
        name
        statement_descriptor
        type
        updated
      }
      subscription {
        id
        cancel_at
        cancel_at_period_end
        canceled_at
        current_period_end
        current_period_start
        days_until_due
        default_payment_method
        metadata {
          display
          priceId_to_downgrade_at_period_end
          payment_method
        }
        status
        trial_end
        trial_start
        price {
          unit_amount
        }
      }
      price {
        billing_scheme
        unit_amount
        type
        recurring {
          aggregate_usage
          interval
          interval_count
          trial_period_days
          usage_type
        }
        product
        livemode
        id
        currency
        created
        active
      }
      paymentMethods {
        id
        billing_details {
          address {
            city
            country
            line1
            line2
            postal_code
            state
          }
          email
          name
          phone
        }
        card {
          brand
          exp_month
          exp_year
          country
          last4
          name
        }
        created
        customer
      }
    }
  }
`

export const UPDATE_SUBSCRIPTION_WITH_PM = gql`
  mutation UpdateSubscriptionWithPM($input: UpdateSubsData!) {
    UpdateSubscriptionWithPM(input: $input) {
      id
      isLegacyCustomer
      product {
        active
        created
        description
        id
        metadata {
          display
        }
        name
        statement_descriptor
        type
        updated
      }
      subscription {
        id
        cancel_at
        cancel_at_period_end
        canceled_at
        current_period_end
        current_period_start
        days_until_due
        metadata {
          display
          priceId_to_downgrade_at_period_end
          payment_method
        }
        status
        trial_end
        trial_start
        price {
          unit_amount
        }
      }
      price {
        billing_scheme
        unit_amount
        type
        recurring {
          aggregate_usage
          interval
          interval_count
          trial_period_days
          usage_type
        }
        product
        livemode
        id
        currency
        created
        active
      }
      paymentMethods {
        id
        billing_details {
          address {
            city
            country
            line1
            line2
            postal_code
            state
          }
          email
          name
          phone
        }
        card {
          brand
          exp_month
          exp_year
          country
          last4
          name
        }
        created
        customer
      }
    }
  }
`

export const CREATE_ONE_TIME_CHARGES_WITH_PM = gql`
  mutation CreateOneTimeChargesWithPM($input: OneTimeCharge!) {
    CreateOneTimeChargesWithPM(input: $input)
  }
`

export const UPDATE_DEFAULT_PAYMENT_METHOD = gql`
  mutation UpdateDefaultPaymentMethod($input: UpdateDefaultPMData!) {
    UpdateDefaultPaymentMethod(input: $input) {
      id
    }
  }
`

export const CREATE_SETUP_INTENT = gql`
  mutation CreateSetupIntent {
    CreateSetupIntent {
      id
      client_secret
      customer
      payment_method
      status
      created
      cancellation_reason
  }
}
`

// If you need to restore listings, please check https://github.com/suredone/suredone/pull/9108 and https://github.com/suredone/suredone/pull/9151
export const GET_USER_USAGE = gql`
  query UserUsage($legacyAccountId: Int!) {
    UserUsage(legacyAccountId: $legacyAccountId, calculate: true) {
      bypass
      exports
      exportsRemaining
      orders
      ordersFree
      ordersVolume
      ordersVolumeFree
      ordersVolumeFreeRemaining
      periodStart
      ordersVolumeRemaining
      periodEnd
      plan
      products
      soldProducts
      trial
      updates
      updatesApi
      updatesBulk
      updatesAuto
      updatesRemaining
    }
  }
`

export const GET_TAX_ESTIMATION = gql`
query GetTaxEstimation($input: TaxInputData) {
  GetTaxEstimation(input: $input) {
    amountToCollect
    rate
  }
}
`

export const VALIDATE_PROMOTION_CODE = gql`
  mutation ValidatePromotionCode($input: DiscountCode!) {
    ValidatePromotionCode(input: $input) {
      id
      code
      active
      created
      customer
      expires_at
      times_redeemed
      max_redemptions
      coupon {
        id
        name
        valid
        created
        currency
        duration
        amount_off
        percent_off
        times_redeemed
        max_redemptions
        duration_in_months
        metadata {
          description
        }
      }
      restrictions {
        minimum_amount
        first_time_transaction
        minimum_amount_currency
      }
    }
  }
`

export const CREATE_ACCESSED_LINK_EVENT = gql`
  mutation CreateAccessedLinkEvent($decodedCheckout: JSON!) {
    CreateAccessedLinkEvent(decodedCheckout: $decodedCheckout)
  }
`
