import React from 'react'
import { prepareForDownload } from './utils'
import { DownloaderCSV, DownloaderJSON } from '../../../components'

export const DownloadErrors = ({ items }) => {
  const csvData = prepareForDownload(items, 'csv')
  const jsonData = prepareForDownload(items, 'json')

  return (
    <>
      <DownloaderCSV data={csvData} text='Download .csv' filePrefix='errors-' />
      <DownloaderJSON data={jsonData} text='Download .json' filePrefix='errors-' />
    </>
  )
}
