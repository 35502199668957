import {
  ApolloClient,
  ApolloLink,
  Observable,
  createHttpLink,
  InMemoryCache
} from '@apollo/client'
import { RetryLink } from '@apollo/client/link/retry'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import * as Sentry from '@sentry/react'

const sentryLink = new ApolloLink((operation, forward) => {
  Sentry.addBreadcrumb({
    category: 'graphql',
    data: { query: operation.query.loc.source.body },
    message: operation.operationName,
    level: 'debug'
  })
  return forward(operation)
})

// This function transform graphql errors to real errors so retry link will process them
const gqlErrorToNetworkError = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    if (data && data.errors && data.errors.length) {
      throw new Error(data.errors[0].message)
    }
    return data
  })
})

const retryIf = (error, operation) => {
  const shouldRetry = error?.toString()?.toLowerCase()?.includes('too many requests')
  return error && shouldRetry
}

const promiseToObservable = promise =>
  new Observable((subscriber) => {
    promise.then(
      (value) => {
        if (subscriber.closed) return
        subscriber.next(value)
        subscriber.complete()
      },
      err => subscriber.error(err)
    )
    return subscriber
  })

/**
 * Creates an apollo client given a JWT token
 */
export function createApolloClient (jwtToken, refresh) {
  const cache = new InMemoryCache()

  const httpLink = createUploadLink({
    uri: '/graphql',
    credentials: 'omit',
    headers: {
      Authorization: jwtToken
    }
  })

  const refreshCognitoSessionPromise = async (operation) => {
    const newJwtToken = await refresh(true)
    const oldHeaders = operation.getContext().headers
    operation.setContext({
      headers: {
        ...oldHeaders,
        Authorization: newJwtToken
      }
    })
  }

  // clean __typename from the mutations
  const cleanTypeName = new ApolloLink((operation, forward) => {
    const { variables, operationName } = operation || {}
    if (variables && operationName !== 'uploadTemplatesFiles') {
      const omitTypename = (key, value) => (key === '__typename' ? undefined : value)
      operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename)
    }
    return forward(operation)
  })

  // check which error throws cognito when token expired
  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (networkError) {
      switch (networkError.statusCode) {
        case 401: {
          // error code is set to 401 UNAUTHENTICATED when token Expired
          // retry the request, onError needs to return an observable
          return promiseToObservable(refreshCognitoSessionPromise(operation)).flatMap(() => forward(operation))
        }
        default:
      }
    }
  })

  const retryLink = new RetryLink({
    delay: {
      initial: 250,
      jitter: false
    },
    attempts: {
      max: 8,
      retryIf
    }
  })

  const links = ApolloLink.from([
    sentryLink,
    cleanTypeName,
    errorLink,
    retryLink,
    gqlErrorToNetworkError,
    httpLink
  ])

  const client = new ApolloClient({
    cache,
    link: links
  })
  return client
}

/**
 * Creates an apollo client given a JWT token
 */
export function createApolloClientAnon () {
  const cache = new InMemoryCache()

  const httpLink = createHttpLink({
    uri: '/public/graphql',
    credentials: 'omit'
  })

  // clean __typename from the mutations
  const cleanTypeName = new ApolloLink((operation, forward) => {
    if (operation.variables) {
      const omitTypename = (key, value) => (key === '__typename' ? undefined : value)
      operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename)
    }
    return forward(operation)
  })

  const link = cleanTypeName.concat(httpLink)
  const client = new ApolloClient({
    cache,
    link: ApolloLink.from([sentryLink, link])
  })
  return client
}
