import React from 'react'
import { useFitment } from '../../hooks/useFitment'
import { Row, Col, Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import { FaAngleLeft as Prev, FaAngleRight as Next, FaAngleDoubleLeft as DPrev, FaAngleDoubleRight as DNext } from 'react-icons/fa'

export const FitPagination = () => {
  const { results, activeFilters, pageSize, updateURL } = useFitment()
  const { page: pageIndex } = activeFilters || {}
  const { totalFacetedProducts: resultsCount } = results || {}
  const amountOfResults = resultsCount?.[0]?.total
  const pageCount = Math.ceil(amountOfResults / pageSize)
  const canPreviousPage = pageIndex > 0
  const canNextPage = (pageIndex + 1) < pageCount
  const gotoPage = p => updateURL('page', p)

  return (
    <Row className='mt-3'>
      <Col>
        <Pagination className='row justify-content-center'>
          {pageCount > 3 && (
            <>
              <PaginationItem disabled={!canPreviousPage}><PaginationLink onClick={() => gotoPage(0)}><DPrev className='m-0' /></PaginationLink></PaginationItem>
              <PaginationItem disabled={!canPreviousPage}><PaginationLink onClick={() => gotoPage(pageIndex - 1)}><Prev className='m-0' /></PaginationLink></PaginationItem>
            </>
          )}
          {[-2, -1, 0, 1, 2]
            .filter(index => pageIndex + 1 + index > 0 && pageIndex + 1 + index <= pageCount)
            .map(index =>
              <PaginationItem key={pageIndex + 1 + index} active={index === 0}>
                <PaginationLink onClick={() => gotoPage(pageIndex + index)}>{pageIndex + 1 + index}</PaginationLink>
              </PaginationItem>
            )}
          {pageCount > 3 && (
            <>
              <PaginationItem disabled={!canNextPage}><PaginationLink onClick={() => gotoPage(pageIndex + 1)}><Next className='m-0' /></PaginationLink></PaginationItem>
              <PaginationItem disabled={!canNextPage}><PaginationLink onClick={() => gotoPage(pageCount - 1)}><DNext className='m-0' /></PaginationLink></PaginationItem>
            </>
          )}
        </Pagination>
      </Col>
    </Row>
  )
}
