import React from 'react'
import { ErrorAlert } from '../../components'
import { useQuery } from '@apollo/react-hooks'
import { REFRESH_FIELD } from './commonQueries'

export const RefreshField = ({ slug, instanceNumber, field }) => {
  const { error } = useQuery(REFRESH_FIELD, { variables: { slug, instanceNumber, field } })

  if (error) {
    return <ErrorAlert error={error} />
  } else {
    return null
  }
}
