import React from 'react'
import { ResultGrid } from '.'
import { Card, CardBody } from 'reactstrap'
import { useFitment } from '../../hooks/useFitment'
import { EmptyState, Loadable, Page } from '../../components'

export const FitResults = () => {
  const { loadingResults, activeFilters, errorResults } = useFitment()

  return (
    <main key='app-main' className='app-main'>
      <div className='wrapper'>
        <Page
          title='Results'
          back={{ to: '/fitment/search', name: 'Search' }}
        >
          <Card>
            <CardBody>
              <EmptyState
                isEmpty={!activeFilters}
                content='Something went wrong with your filters'
                action={{ content: 'Perform a new search', url: '/fitment/search' }}
              >
                <Loadable
                  inline
                  error={errorResults}
                  loading={loadingResults}
                  content={
                    <EmptyState
                      isEmpty={false}
                      heading='No results'
                      content='Nothing found, try editing your search'
                    >
                      <ResultGrid />
                    </EmptyState>
                  }
                />
              </EmptyState>
            </CardBody>
          </Card>
        </Page>
      </div>
    </main>
  )
}
