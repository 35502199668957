import React from 'react'
import defaultImage from '../logo.png'

const Logo = ({ logoUrl, to }) => {
  return (
    <div className='automation-summary list-group-item-figure justify-content-center p-2'>
      <a className='user-avatar user-avatar-md' alt='Automation title image' href={to}>
        <img src={logoUrl || defaultImage} alt='Automation company logo' />
      </a>
    </div>
  )
}

export { Logo }
