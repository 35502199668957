import React from 'react'
import { Jumbotron } from 'reactstrap'

export const FitmentSummary = () => {
  return (
    <>
      <h2>Advance Instructions</h2>
      <p className='mb-2'>A basic knowledge of theme development is required.</p>
      <Jumbotron className='p-2 my-4' style={{ backgroundColor: '#f6f7f9' }}>
        <ol className='pl-4'>
          <li><a href='#ThemeFiles'>How to edit theme files</a></li>
          <li><a href='#CustomFacets'>How to customize your shop filters</a></li>
          <li><a href='#HideMVB'>How to hide My Garage Button</a></li>
          <li><a href='#CategoriesSubFilter'>How to add Fitment Filter to Category Page</a></li>
          <li><a href='#AddFYV'>How to add Fit Your Vehicle to the Product Page</a></li>
          <li><a href='#FitmentSearch'>How to add Year Make Model filter to the Search Page</a></li>
        </ol>
      </Jumbotron>
    </>
  )
}
