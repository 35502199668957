import React from 'react'
import { ActionButton, Pill } from '../../components'
import { FaExternalLinkAlt as ExternalLink } from 'react-icons/fa'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const docsLinks = {
  'Read the docs': 'https://app.suredone.com/search-instructions',
  'Searching SureDone': 'https://support.suredone.com/support/solutions/articles/1000297038-searching-suredone',
  'How to Search SureDone for Products and Orders': 'https://support.suredone.com/support/solutions/articles/1000233246',
  'Searching for kits and bundles': 'https://support.suredone.com/support/solutions/articles/1000241841',
  'How do I search by eBay Listing Type?': 'https://support.suredone.com/support/solutions/articles/1000198964',
  'Searching for active, inactive, incomplete and suppressed Amazon Items in SureDone': 'https://support.suredone.com/support/solutions/articles/1000253388'
}

export const SearchTipsModal = ({ toggleSearchTips, searchTipsOpen }) => {
  return (
    <Modal centered isOpen={searchTipsOpen} toggle={toggleSearchTips} size='lg'>
      <ModalHeader toggle={toggleSearchTips} className='pb-2'>Search Tips</ModalHeader>
      <ModalBody>
        <p>
          If you want to find <code>fields</code> whose value contains <code>X</code>, add the <code>field:X</code> filter. For example <Pill>title:"Wheel 20x9"</Pill> will search your items for all those whose <code>title</code> contains <code>Wheel 20x9</code>.
        </p>
        <p>
          If you want the value to be <code>exact</code>, add <code>=</code> to the above. Thus, <Pill>title:="Wheel 20x9"</Pill> will search your items for all those whose <code>title</code> is exactly the same as <code>Wheel 20x9</code>.
        </p>
        <p>
          Leave a value unspecified to search for items with missing information. For example <Pill>upc:=</Pill> will search for items items with no <code>UPC</code>.
        </p>
        <p>
          You can combine as many conditions as you wish. For example <Pill>color:=blue material:=metal</Pill> will search for products with <code>color blue</code> <b>and</b> <code>material metal</code>.
        </p>
        <p>
          You can add parentheses to search for items that match one of the conditions. For example <Pill>(color:=blue color:=yellow)</Pill> will search for products with <code>color blue</code> <b>or</b> <code>color yellow</code>.
        </p>
        <p>
          Let's look at a harder combination: products whose <b>stock is less than or equal to 15</b>, its <b>price is greater than 100</b> and the <b>brand is Toyota, BMW or Ford</b>. <br />
          Easy as pie, you can search for <Pill>{'stock:<=15 price:>100 (brand:=Toyota brand:=BMW brand:=Ford)'}</Pill>
        </p>

        <h5 className='pt-2 pb-1'>Still in doubt?</h5>
        <ul className='pl-3'>
          {Object.keys(docsLinks).map(k => (
            <li key={k} className='mb-2'>
              <a href={docsLinks[k]} target='_blank' rel='noopener noreferrer'>
                {k} <ExternalLink size={10} />
              </a>
            </li>
          ))}
        </ul>
      </ModalBody>
      <ModalFooter>
        <ActionButton onClick={toggleSearchTips}>
          Close
        </ActionButton>
      </ModalFooter>
    </Modal>
  )
}
