import React from 'react'
import gql from 'graphql-tag'
import Loadable from '../Loadable'
import { Link } from 'react-router-dom'
import { termsOfService } from './content'
import { ActionButton } from '../ActionButton'
import { useStores } from '../../stores/RootStore'
import { AuthPage } from '../../scenes/Auth/AuthPage'
import { Card, CardBody, Row, Col } from 'reactstrap'
import { FiCheckCircle as Check } from 'react-icons/fi'
import { FaSignOutAlt as LogOut } from 'react-icons/fa'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { ScrollingProgressBar } from '../ScrollingProgressBar'
import './TOSRequiredWall.scss'

export const SHOW_TOS_REQUIRED_WALL = gql`
  query showTOSRequiredWall {
    showTOSRequiredWall
  }
`

export const UPDATE_AGREE_TOS = gql`
  mutation updateTOSacceptance {
    updateTOSacceptance
  }
`

export const TOSRequiredWall = () => {
  const { stores: { auth } } = useStores()
  const { user: { id: userId } } = auth

  const { data, loading, error } = useQuery(SHOW_TOS_REQUIRED_WALL, { variables: { id: userId } })
  const { showTOSRequiredWall } = data || {}

  const [updateAgreeTOS, { loading: loadingUpdate }] = useMutation(UPDATE_AGREE_TOS, {
    onCompleted: () => auth.refresh()
  })

  return (
    <Loadable
      error={error}
      loading={loading}
      content={
        showTOSRequiredWall
          ? (
            <AuthPage>
              <Card className='custom auth-form mx-auto mt-3 mb-n5 p-5 terms-page'>
                <CardBody className='p-0'>
                  <ScrollingProgressBar />
                  <Row>
                    <Col className='initial'>
                      <h1 className='text-center w-100'>Our terms and conditions have been updated</h1>
                      <p className='my-2 mb-3'>
                        A summary of changes, along with the complete terms, is included below. If you have any questions, please <a target='_blank' rel='noopener noreferrer' href='https://support.suredone.com/support/tickets/new'>open a ticket on our support site</a>.
                        In order to continue to use SureDone, please review and accept the updated terms and conditions.
                      </p>
                    </Col>
                  </Row>
                  <Row className='terms'>
                    <Col>
                      {termsOfService}
                    </Col>
                  </Row>
                  <Row className='mt-4'>
                    <Col xs={12} className='d-flex justify-content-center'>
                      <ActionButton icon={<Check className='mr-2' />} size='lg' loading={loadingUpdate} color='primary' onClick={() => updateAgreeTOS()}>
                        Accept Terms of Service
                      </ActionButton>
                    </Col>
                    <Col xs={12} className='d-flex justify-content-center mt-3'>
                      <ActionButton icon={<LogOut className='mr-2' />} tag={Link} color='link' to='/' onClick={() => auth.logOut()}>
                        Log Out
                      </ActionButton>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </AuthPage>
            )
          : null
      }
    />
  )
}
