import React from 'react'
import { AdvanceCustomization } from '.'
import { GoToButton } from '../../components'
import { LoggedOutPage } from '../../components/LoggedOutPage'
import { ShopifyAInstructions } from './Shopify/ShopifyAInstructions'
import { BigcommerceAInstructions } from './Bigcommerce/BigcommerceAInstructions'
import './FitmentInstructions.scss'

export const FitmentInstructions = ({ storefront }) => {
  return (
    <LoggedOutPage
      className='fitment-advanced-instructions'
      title={`${storefront} Fitment Advance Instructions`}
    >
      {storefront === 'Shopify' && <ShopifyAInstructions />}
      {storefront === 'Bigcommerce' && <BigcommerceAInstructions />}

      <AdvanceCustomization />

      <GoToButton direction='top' />
    </LoggedOutPage>
  )
}
