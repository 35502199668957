import React, { useEffect } from 'react'
import Bowser from 'bowser'
import RootStore from './stores/RootStore'
import { preferredBrowsers } from './preferredBrowsers'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { Provider, observer } from 'mobx-react'
import { createBrowserHistory } from 'history'
import { ApolloProvider } from '@apollo/react-hooks'
import { useGTM } from './hooks/useGTM'

import { Alert, Button } from 'reactstrap'
import { Loading } from './components/Loading'
import asyncComponent from './components/AsyncComponent'
import LoggedIn from './LoggedIn'
import { ProductUpdates } from './scenes/ProductUpdates/ProductUpdates'
import { Qualifications } from './scenes/Qualifications'
import { DemoSchedule } from './scenes/DemoSchedule/DemoSchedule'
import { Talk } from './scenes/Talk/Talk'
import { PersonReferral } from './scenes/Referral/PersonReferral'
import { AutomatedReferral } from './scenes/Referral/AutomatedReferral'

// The following imports are just to establish an import order to fix a dumb css issue
import { UserSummary2 } from './scenes/UserManager/UserSummary2'
import { PermissionsEditor } from './scenes/UserManager/UserPermissions'

import { FaGift as Gift } from 'react-icons/fa'

import * as Sentry from '@sentry/react'
import { createApolloClientAnon } from './apollo'
import { FitmentInstructions } from './scenes/FitmentInstructions'
import { SearchDocs } from './scenes/Search/Documentation'

const browser = Bowser.getParser(window.navigator.userAgent)

window.Sentry = Sentry
if (window.serverConfig) {
  const { sentryDSN, environment, release, sampleRate } = window.serverConfig
  Sentry.init({
    dsn: sentryDSN,
    environment,
    release,
    sampleRate,
    beforeBreadcrumb (crumb, hint) {
      const { category, data } = crumb
      // Drop debug logs
      if (category === 'console' && data && data.extra && data.extra.arguments) return null
      return crumb
    },
    beforeSend (event, hint) {
      // Limit event size to 180k (under sentry hard limit of 200k according to https://github.com/getsentry/sentry-docs/pull/310/files)
      // by dropping breadcrumbs
      while (JSON.stringify(event).length > 180000 && event.breadcrumbs.length > 0) {
        event.breadcrumbs = event.breadcrumbs.slice(2)
        debug(`Dropping breadcrumbs, now there are ${event.breadcrumbs.length}`)
      }
      return event
    }
  })
}

const AsyncAuth = asyncComponent(() => import('./scenes/Auth'))

const debug = require('debug')('sd:App')
const stores = new RootStore()
window.stores = stores

// Get rid of warning for ordering imports above
debug({ UserSummary2, PermissionsEditor })

// Make sure root appears in our history so our back buttons work
// when visiting pages via direct link
const history = createBrowserHistory()
window.browserHistory = history
if (history.location.pathname !== '/') {
  const oldLocation = Object.assign({}, history.location)
  history.replace('/')
  history.push(oldLocation)
}

// Analytics path change hook
history.listen(({ pathname }) => {
  window.analyticsPathHistory = [pathname, ...(window.analyticsPathHistory || [])].slice(0, 10) // save the latest 10 visited pathnames
  if (window.analytics) window.analytics.page()
})

const ApolloWrapper = observer(
  function ApolloWrapper ({ children }) {
    if (!stores.auth.apolloClient) return <Loading />
    return <ApolloProvider client={stores.auth.apolloClient}>{children}</ApolloProvider>
  }
)

export const ApolloWrapperAnon = observer(
  function ApolloWrapper ({ children }) {
    const client = createApolloClientAnon()
    if (!client) return <Loading />
    return <ApolloProvider client={client}>{children}</ApolloProvider>
  }
)

// App.js redirect

const _App = () => {
  const { name: browserName, version } = (browser.getBrowser())
  const isASupportedBrowser = browser.satisfies(preferredBrowsers)

  // Initializes GTM
  useGTM()
  const { auth } = stores

  // Initializes window.analyticsPathHistory and window.analyticsEventHistory
  useEffect(() => {
    window.analyticsPathHistory = [history?.location?.pathname]
    window.analyticsEventHistory = []
  }, [])

  return (
    <Router history={history}>
      <Provider stores={stores}>
        <Switch>
          <Route
            path='/product-updates'
            name='Product updates'
            render={() => <ProductUpdates />}
          />
          <Route
            path='/fitment-advance-instructions/bigcommerce'
            name='Bigcommerce Advance Instructions'
            render={() => <FitmentInstructions storefront='Bigcommerce' />}
          />
          <Route
            path='/fitment-advance-instructions/shopify'
            name='Shopify Advance Instructions'
            render={() => <FitmentInstructions storefront='Shopify' />}
          />
          <Route
            path='/search-instructions'
            name='Search Instructions'
            render={() => <SearchDocs />}
          />
          <Route
            path='/q'
            render={() => <ApolloWrapperAnon><Qualifications /></ApolloWrapperAnon>}
            name='Qualifications'
          />
          <Route
            path='/demo-schedule'
            render={() => <ApolloWrapperAnon><DemoSchedule /></ApolloWrapperAnon>}
            name='Schedule a demo'
          />
          <Route
            path='/talk'
            render={() => <ApolloWrapperAnon><Talk /></ApolloWrapperAnon>}
            name='Talk'
          />
          <Route
            path='/person-referral'
            render={() => <PersonReferral />}
            name='Person referral'
          />
          <Route
            path='/automated-referral'
            render={() => <AutomatedReferral />}
            name='Automated referral'
          />
          <Route
            path='/legacy-logout'
            name='Legacy Logout'
            render={props => {
              // If we get here we don't have a legacy host, so simulate logout instead
              auth.simulateLegacyLogout()
              return <Redirect to='/login' />
            }}
          />
          <Route
            path='/v3/:path+'
            name='V3 Redirect'
            render={({ match, location, history }) => {
              debug('test', { match, location, history })
              // debug(match.path)
              // history.push('/' + match.path)
              return <Redirect to={'/' + match.params.path} />
            }}
          />
          <Route>
            {!auth.loggingIn && (auth.state === auth.states.loggedIn || auth.state === auth.states.wizardLoggedIn)
              ? <ApolloWrapper><LoggedIn /></ApolloWrapper>
              : (auth.state === auth.states.forceChangePassword
                  ? <Redirect to='/change-password-required' />
                  : (auth.state === auth.states.sendMFACode
                      ? <Redirect to='/mfa-code' />
                      : <AsyncAuth />))}
          </Route>
        </Switch>

        {/*
          Alerts at top of page go here
          As the notifications are positioned with fixed property, the order of them is important
          Thus, the notifications of higher importance should go at the end
        */}

        {/* Server offline */}
        {!auth.serverOnline && (
          <Alert color='warning' className='fixed-top text-center'>
            Server offline. Retrying in {auth.serverOnlineSecondsUntilRetry} seconds&hellip;
            <Button
              size='sm'
              color='link'
              onClick={e => {
                e.preventDefault()
                window.location = window.location // eslint-disable-line no-self-assign
              }}
            >
              retry now
            </Button>
          </Alert>
        )}

        {/* Update available */}
        {auth.releaseNow !== auth.releaseAtBoot && (
          <Alert color='success' className='fixed-top text-center'>
            <b>Update available!</b>
            <Button
              color='primary'
              size='sm'
              className='mx-2'
              onClick={e => {
                e.preventDefault()
                window.location = window.location // eslint-disable-line no-self-assign
              }}
            >
              <Gift />
              Reload
            </Button>
          </Alert>
        )}

        {/* Not supported browser */}
        {!isASupportedBrowser && (
          <Alert color='danger' className='fixed-top text-center'>
            We have detected that you are using {browserName} {version?.split('.')[0]} and unfortunately we do not support it. <br />
            Please <a target='_blank' rel='noopener noreferrer' href='https://www.whatismybrowser.com/guides/how-to-update-your-browser/'>update your browser</a> to have a better experience on Suredone.
          </Alert>
        )}
      </Provider>
    </Router>
  )
}

const App = _App
export default observer(App)

debug('loaded')
