//
// Search utilities
//

function normalizeString (s) {
  return (s || '').trim().toLowerCase()
}

function tokenizeQuery (searchQuery) {
  const normalizedQueryString = normalizeString(searchQuery)
  const tokens = new Set(normalizedQueryString.split(/\s+/) || [])
  const normalizedQuery = [...tokens.values()].join(' ')
  // Also include the fully normalizd query as a token to improve exact matches
  tokens.add(normalizedQuery)
  // And double it, for good measure
  return [...tokens.values(), ...[normalizedQuery]]
}

/**
 * Returns true if a string matches a given searchQuery.
 *
 * Does some optimizations under the hood for case insensitivity and other
 * search goodness. (maybe ngrams / fuzzy / phonemes in future)
 */
function stringMatchesQuery (string, searchQuery) {
  const tokens = tokenizeQuery(searchQuery)
  const normalizedString = normalizeString(string)
  return tokens.some(t => normalizedString.indexOf(t) !== -1)
}
window.stringMatchesQuery = stringMatchesQuery

function stringMatchesTokens (string, tokens) {
  // debug('stringMatchesTokens', string, tokens)
  const normalizedString = normalizeString(string)
  return tokens.some(t => normalizedString.indexOf(t) !== -1)
}

function scoreString (string, tokens) {
  const normalizedString = normalizeString(string)
  return tokens
    .map(t => normalizedString.indexOf(t) !== -1)
    .reduce((a, b) => a + b, 0)
}

export { stringMatchesTokens, scoreString, tokenizeQuery }
