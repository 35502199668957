import { useState } from 'react'
import { useStores } from '../stores/RootStore'

const useEmailConfirmationCode = () => {
  const { stores: { auth } } = useStores()
  const [resending, setResending] = useState(false)
  const [resendError, setResendingError] = useState(false)
  const [success, setSuccess] = useState(false)

  /**
   * Resends confirmation code to given email
   * @param {String} email
   * @param {() => void} [loginRedirect]
   * @returns
   */
  const resendConfirmationCode = async (email, loginRedirect) => {
    setResending(true)
    try {
      setResendingError(false)
      const result = await auth.resendConfirmationCode()
      setSuccess(true)
      return result
    } catch (error) {
      if (error.message === 'User is already confirmed.' && loginRedirect) return loginRedirect()
      setResendingError(error)
      setSuccess(false)
    } finally {
      setResending(false)
    }
  }

  return {
    resendConfirmationCode,
    resending,
    resendError,
    success
  }
}

export default useEmailConfirmationCode
