import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { FaPlus } from 'react-icons/all'
import { ImportSummary } from './ImportSummary'
import { useStores } from '../../../stores/RootStore'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { ResourceList } from '../../../components/SearchableLists'
import { Loadable, EmptyState, ErrorAlert, VisualPicker } from '../../../components'
import { GET_CHANNEL_INFO, START_FULL_IMPORT, START_SAMPLE_IMPORT } from './graphqlCommon'
import { infoMessage, HowManyProducts, FullImportForm, SampleImportForm, getDuration, formatDuration } from './ImportElements'
import { Card, CardHeader, CardBody, CardText, CardSubtitle, CardTitle, Col, Collapse, Row, UncontrolledCollapse } from 'reactstrap'
import './ChannelImport.css'

const debug = require('debug')('sd:ChannelImport')

export const ChannelImport = props => {
  const { slug, instanceNumber } = props
  const { stores: { auth } } = useStores()
  const { user: { primaryEmail } } = auth

  const { loading, error, data } = useQuery(GET_CHANNEL_INFO, { variables: { slug, instanceNumber } })
  const { ChannelInstance } = data || { ChannelInstance: {} }
  const { enabled, authStatus } = ChannelInstance
  const { ChannelImport } = data || { ChannelImport: [] }

  const { id: queryImportId, status } = (ChannelImport && ChannelImport[0]) || {}
  const { Channel } = data || { Channel: {} }
  const { name: channelName, import_rate: importRate } = Channel

  const [customError, setCustomError] = useState()
  const [showStartImportError, setShowStartImportError] = useState(false)
  const [notificationEmail, setNotificationEmail] = useState('')
  const [showSampleImportError, setShowSampleImportError] = useState(false)

  useEffect(() => {
    setNotificationEmail(primaryEmail)
  }, [primaryEmail])

  const updateCache = (name) => (cache, data) => {
    const newImport = data.data[name]
    const { ChannelImport, ...rest } = cache?.readQuery({
      query: GET_CHANNEL_INFO,
      variables: { slug, instanceNumber }
    })
    ChannelImport.unshift(newImport)
    cache?.writeQuery({
      query: GET_CHANNEL_INFO,
      variables: { slug, instanceNumber },
      data: { ChannelImport, ...rest }
    })
  }

  const [startFullImport, { data: dataMutation, loading: fullLoadingMutation, error: fullErrorMutation }] = useMutation(START_FULL_IMPORT, {
    onError: error => {
      if (error?.message?.includes('not extensible') || error?.message?.includes('non-writable')) {
        auth.refresh()
      } else {
        debug('startFullImportHandler, error:', error)
        setShowStartImportError(true)
      }
    },
    update: updateCache('StartFullImport')
  })
  debug({ fullErrorMutation })
  const { StartFullImport } = dataMutation || {}
  const { id: mutationImportId } = StartFullImport || ''

  const [startSampleImport, { data: sampleDataMutation, loading: sampleLoadingMutation, error: sampleErrorMutation }] = useMutation(START_SAMPLE_IMPORT, {
    onError: () => setShowSampleImportError(true),
    update: updateCache('StartSampleImport')
  })
  debug({ sampleErrorMutation })

  /**
   * Inform the user about needed previous steps for make a full import
   **/
  useEffect(() => {
    if (enabled === undefined || authStatus === undefined) return
    let customErrorMessage
    if (!(enabled && authStatus === 'complete')) {
      customErrorMessage = { message: 'You can not import data from this channel until ' }
      if (!enabled) {
        customErrorMessage.message = customErrorMessage.message + 'the integration is enabled '
        if (authStatus !== 'complete') {
          customErrorMessage.message = customErrorMessage.message + 'and authorization is complete.'
        }
      } else if (authStatus !== 'complete') customErrorMessage.message = customErrorMessage.message + 'the authorization process is complete.'
      setCustomError(customErrorMessage)
    }
  }, [enabled, authStatus])

  const startFullImportHandler = async values => {
    const { amountOfProducts } = values
    const estimation = getDuration(amountOfProducts, importRate)
    const formatedInput = { ...values, amountOfProducts: Number(amountOfProducts.value), estimatedTime: estimation }

    if (window.analytics) {
      const currentEvent = 'Clicked Import'
      const currentHistory = window?.analyticsEventHistory
      const newHistory = [currentEvent, ...currentHistory].slice(0, 10)
      window.analyticsEventHistory = newHistory
      const fullFilled = {
        action: 'User clicks on Import all products',
        channelName,
        channelInstance: instanceNumber,
        slug: `${slug}${instanceNumber > 0 ? instanceNumber : ''}`,
        pathHistory: window?.analyticsPathHistory,
        priorPath: window?.analyticsPathHistory?.[0],
        eventHistory: newHistory,
        priorEvent: newHistory?.[1]
      }
      window.analytics.track(currentEvent, fullFilled)
    }
    startFullImport({ variables: { slug, instanceNumber, input: formatedInput } })
  }

  const startSampleImportHandler = async values => {
    if (window.analytics) {
      const currentEvent = 'Clicked Import'
      const currentHistory = window?.analyticsEventHistory
      const newHistory = [currentEvent, ...currentHistory].slice(0, 10)
      window.analyticsEventHistory = newHistory
      const fullFilled = {
        action: 'User clicks on sample import',
        channelName,
        channelInstance: instanceNumber,
        slug: `${slug}${instanceNumber > 0 ? instanceNumber : ''}`,
        pathHistory: window?.analyticsPathHistory,
        priorPath: window?.analyticsPathHistory?.[0],
        eventHistory: newHistory,
        priorEvent: newHistory?.[1]
      }
      window.analytics.track(currentEvent, fullFilled)
    }
    startSampleImport({ variables: { slug, instanceNumber } })
  }

  const FullImportCardBody = ({ amountOfProducts, importType }) => {
    const estimation = getDuration(amountOfProducts, importRate)
    debug({ amountOfProducts })
    const humanized = formatDuration(estimation)
    return (
      <CardBody>
        {status === 'CANCELLED' && infoMessage}
        <CardTitle><h4>Full import</h4></CardTitle>
        <CardText className=''>
          Perform a full import of every active product in your {channelName} account. Once it is ready, we will email you and you will get a chance to review the data before applying it to your account.
        </CardText>
        <CardSubtitle className='mt-1s text-muted'>
          Importing {amountOfProducts && amountOfProducts.label} products normally takes {estimation < 10 ? 'about' : ((amountOfProducts || {}).label || '').indexOf('+') !== -1 ? 'at least' : 'up to'} {humanized}.<br />
        </CardSubtitle>
        <Collapse isOpen={importType === 'Full'}>
          <Form
            className='form'
            onSubmit={props => startFullImportHandler({ ...props, amountOfProducts })}
            initialValues={{ email: notificationEmail, importAction: 'match' }}
            loadingMutation={fullLoadingMutation}
            dataMutation={dataMutation}
            errorMutation={fullErrorMutation}
            slug={slug}
          >
            {props => <FullImportForm {...props} />}
          </Form>
        </Collapse>
        {showStartImportError &&
          <ErrorAlert
            isOpen={showStartImportError}
            toggle={() => setShowStartImportError(false)}
            error={fullErrorMutation}
          >
            {fullErrorMutation.message}
          </ErrorAlert>}
      </CardBody>
    )
  }

  const SampleImportCardBody = () => {
    return (
      <CardBody>
        <CardTitle><h4>Sample import</h4></CardTitle>
        <CardText className='mt-2'>Perform a sample import for your {channelName} account. Once it is ready, we will email you and you will get a chance to review the data before applying it to your account.</CardText>
        <CardSubtitle className='text-muted'>Importing 100 products usually takes 1-2 minutes.</CardSubtitle>
        <Form
          className='form'
          onSubmit={startSampleImportHandler}
          loadingMutation={sampleLoadingMutation}
          dataMutation={sampleDataMutation}
          errorMutation={sampleErrorMutation}
        >
          {props => <SampleImportForm {...props} />}
        </Form>
        {showSampleImportError &&
          <ErrorAlert
            isOpen={showSampleImportError}
            toggle={() => setShowSampleImportError(false)}
            error={sampleErrorMutation}
          >
            {sampleErrorMutation.message}
          </ErrorAlert>}
      </CardBody>
    )
  }

  // status is the status of ChannelImport[0] (the last import executed)
  const disabledNewImport = status === 'IN_PROGRESS'
  const alreadyHasImports = queryImportId || mutationImportId

  const StartNewImport = () => {
    const [amountOfProducts, setAmountOfProducts] = useState()
    const [isOpenChild, setIsOpenChild] = useState(false)
    const [importType, setTypeOfImport] = useState('Sample')

    const handleChangedAmount = event => {
      setAmountOfProducts(event)
      setIsOpenChild(!!event)
    }

    const handlePickedTypeOfImport = event => {
      setTypeOfImport(event)
    }

    const content = (
      <Row className='p-3'>
        <Col>
          <HowManyProducts channelName={channelName} parentCallback={handleChangedAmount} />
          <Collapse isOpen={isOpenChild} className='my-2'>
            <VisualPicker id='Full' isSelected={importType === 'Full'} name='import-type' parentCallback={handlePickedTypeOfImport}>
              <FullImportCardBody amountOfProducts={amountOfProducts} importType={importType} />
            </VisualPicker>
            {!alreadyHasImports &&
              <VisualPicker id='Sample' isSelected={importType === 'Sample'} name='import-type' parentCallback={handlePickedTypeOfImport}>
                <SampleImportCardBody />
              </VisualPicker>}
          </Collapse>
        </Col>
      </Row>
    )

    const loadingNewImport = fullLoadingMutation || sampleLoadingMutation

    return (
      <Card style={{ pointerEvents: disabledNewImport && 'none', boxShadow: disabledNewImport && 'none' }}>
        {alreadyHasImports
          ? (
            <>
              <CardHeader id='new-import' className='d-flex align-items-center justify-content-between' style={{ border: disabledNewImport && '1px solid #ededee', borderRadius: 'calc(0.25rem - 1px)' }}>
                {disabledNewImport
                  ? <p className='d-flex align-items-center mb-0'>Complete the previous import before starting a new one</p>
                  : <h5 className='d-flex align-items-center mb-0'>{loadingNewImport ? 'Running' : 'Run'} new import</h5>}
                {!disabledNewImport && <FaPlus size={18} color='#bbb' className='mr-0' />}
              </CardHeader>
              <UncontrolledCollapse toggler='new-import' isOpen={!disabledNewImport}>
                <Loadable
                  inline
                  content={content}
                  loading={loadingNewImport}
                  style={{ padding: '12px 0' }}
                  error={fullErrorMutation || sampleErrorMutation}
                />
              </UncontrolledCollapse>
            </>)
          : (
            <Loadable
              inline
              loading={loadingNewImport}
              error={fullErrorMutation || sampleErrorMutation}
              content={content}
              style={{ padding: '12px 0' }}
            />)}
      </Card>
    )
  }

  return (
    <Loadable
      inline
      error={error}
      loading={loading}
      style={{ textAlign: 'center' }}
      content={
        <EmptyState
          isEmpty={customError}
          heading='No imports.'
          content={(customError || {}).message}
        >
          <StartNewImport />
          {ChannelImport?.length
            ? (
              <ResourceList
                disableSearch
                itemsPerPage={5}
                className='imports'
                items={ChannelImport}
                searchableStrings={({ key }) => [key]}
                itemComponent={
                    ({ item }) =>
                      <li className='list-group-item py-2 px-0'>
                        <ImportSummary
                          idx={item?.id}
                          channelImport={item}
                          startedImport={StartFullImport}
                        />
                      </li>
                  }
              />
              )
            : null}
        </EmptyState>
      }
    />
  )
}
