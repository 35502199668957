import React from 'react'
import { useQuery } from '@apollo/client'
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap'
import { FaClipboardList as Orders, FaCubes as Products, FaClone as Pages, FaTags as Categories } from 'react-icons/all'
import { GET_STOREFRONT } from '../commonQueries'

const searchTypes = {
  Products: <Products />,
  Orders: <Orders />
}

export const TypeOfSearch = ({ typeOfSearch, setTypeOfSearch }) => {
  const { data } = useQuery(GET_STOREFRONT)
  const { StorefrontStatus } = data || {}
  const storefronEnabled = StorefrontStatus?.live === 'on'

  if (storefronEnabled) {
    searchTypes.Categories = <Categories />
    searchTypes.Pages = <Pages />
  }

  return (
    <UncontrolledDropdown className='my-2 mx-1'>
      <DropdownToggle caret color='primary' style={{ backgroundColor: '#346cb0' }}>{typeOfSearch}</DropdownToggle>
      <DropdownMenu>
        {Object.keys(searchTypes).map(k => <DropdownItem key={k} onClick={() => setTypeOfSearch(k)}>{searchTypes[k]} {k}</DropdownItem>)}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}
