import React from 'react'
import { Alert } from 'reactstrap'
import { AVAILABLE_CHANNELS } from '.'
import { icons } from './ChannelsLogos'
import { GET_CHANNEL } from './commonQueries'
import { getLink, slugToIcon } from './utils'
import { useQuery, useApolloClient } from '@apollo/react-hooks'
import { SidebarSection, LoadingInline } from '../../components'
import { channelsWithNewUI } from 'suredone-common'
import { FaExchangeAlt as Channels, FaPlus as Plus } from 'react-icons/fa'

const debug = require('debug')('sd:ChannelSidebar')

export function ChannelSidebar (props) {
  const client = useApolloClient()
  const onCompleted = (result) => {
    const { ChannelInstances } = result || { ChannelInstances: [] }
    const { AvailableChannels } = result || { AvailableChannels: [] }
    AvailableChannels.forEach(channel => {
      const { slug, ...rest } = channel
      const data = {
        Channel: { slug, __typename: 'Channel', ...rest },
        ChannelInstances
      }
      client.writeQuery({
        query: GET_CHANNEL,
        variables: { slug },
        data
      })
    })
  }
  const { loading, error, data } = useQuery(AVAILABLE_CHANNELS, { onCompleted })
  debug({ data })
  const { ChannelInstances } = data || { ChannelInstances: [] }

  const listOfChannels = ChannelInstances.map(
    ({ instance, channel: { slug, name }, customLabel }) =>
      ({
        title: customLabel || name + (instance === 0 ? '' : ` ${instance}`),
        to: getLink(slug, instance),
        hard: !channelsWithNewUI.includes(slug),
        icon: slugToIcon.get(slug) || icons[slug],
        childTracker: {
          eventName: 'Channels: visit',
          data: {
            action: `User clicks ${name} ${instance > 0 ? instance : ''} inside Channels at the sidebar`,
            channelName: name,
            channelInstance: instance
          }
        }
      })
  )

  listOfChannels.push({
    title: 'SureDone Storefront',
    to: '/#!/settings/storefront',
    hard: true,
    icon: icons.storefront,
    childTracker: { eventName: 'Channels: visit', data: { action: 'User clicks SureDone Storefront inside Channels at the sidebar' } }
  })

  const addChannelLink = [
    {
      title: 'Add Channel',
      to: '/channels/add',
      icon: <Plus />,
      childTracker: {
        eventName: 'Clicked Channel add',
        data: {
          action: 'User clicks Add Channel inside Channels at the sidebar',
          buttonLocation: 'Sidebar'
        }
      }
    }
  ]

  return (
    <SidebarSection
      title='Channels'
      to='/channels'
      icon={<Channels />}
      navLinks={[].concat(
        loading ? [{ icon: <LoadingInline /> }] : [],
        error ? [{ title: <Alert color='danger'>{JSON.stringify(error)}</Alert> }] : [],
        listOfChannels,
        addChannelLink
      )}
      {...props}
    />
  )
}

debug('loaded')
