import React from 'react'
import { Col, Row, UncontrolledAlert } from 'reactstrap'
import { Link } from 'react-router-dom'

const PrompsAsAlerts = ({ data }) => {
  const {
    enabledChannels, channelsPrompt,
    completedBusinessSettings, settingsPrompt
  } = data

  return (
    <Row>
      <Col xs='12'>
        {enabledChannels < 3 &&
          <UncontrolledAlert color='info'>
            <Link to={channelsPrompt.url} className='btn btn-link btn-xs'>
              {channelsPrompt.startMessage}
            </Link>
          </UncontrolledAlert>}
      </Col>
      <Col xs='12'>
        {!completedBusinessSettings &&
          <UncontrolledAlert color='info'>
            <Link to={settingsPrompt.url} className='btn btn-link btn-xs'>
              {settingsPrompt.startMessage}
            </Link>
          </UncontrolledAlert>}
      </Col>
    </Row>
  )
}

export { PrompsAsAlerts }
