import React, { useState } from 'react'
import Select from 'react-select'
import 'react-select/dist/react-select.css'

export const options = [
  { value: 'on', label: 'on', name: 'on' },
  { value: 'off', label: 'off', name: 'off' }
]

const trueValues = ['on', 'true']
const parseBooleans = value => trueValues.includes(value) ? 'on' : 'off'

export const ToggleType = ({ data }) => {
  const { item, parentCallback } = data || {}
  const { value: { value } } = item || {}

  const [toggleValue, setToggleValue] = useState(parseBooleans(value && value[0]))

  const handleChange = (event) => {
    setToggleValue(event)
    parentCallback(event, item)
  }

  return (
    <Select
      options={options}
      clearable={false}
      value={toggleValue}
      onChange={handleChange}
      placeholder=''
    />
  )
}
