/* eslint-disable multiline-ternary */
import React, { useState, useEffect } from 'react'
import debug from 'debug'
import { snakeToCamel } from './utils'
import { ActionButton } from '../../components'
import { useMutation } from '@apollo/react-hooks'
import { useHistory, Link } from 'react-router-dom'
import { objectKeyMap } from '../../components/utils'
import { EMAIL } from 'suredone-common'
import { ChannelsList, dataP1, checkIfIsAFit } from './'
import { FaArrowRight as NextIcon } from 'react-icons/fa'
import { STORE_PAGE_1_QUALIFICATIONS } from './commonQueries'
import { LoggedOutPage } from '../../components/LoggedOutPage'
import { useEmailAvailability, useQualifications } from '../../hooks'
import { Input, Label, Card, CardBody, Button, FormText, FormFeedback } from 'reactstrap'

export const Page1 = () => {
  const history = useHistory()
  const { storedValue, setValue } = useQualifications()
  const {
    email,
    company,
    currentChannels,
    wantedChannels,
    actionsToDo
  } = storedValue
  const [restOfChannels, setRestOfChannels] = useState([])
  const [userSpecialAction, setUserSpecialAction] = useState()

  const [setEmailInput, { loading, emailAvailable }] = useEmailAvailability(email)
  const goNextPage = () => history.push('/q/2')

  const [storeQualifications, { loading: storing }] = useMutation(STORE_PAGE_1_QUALIFICATIONS, {
    onCompleted: goNextPage,
    onError: error => debug({ error })
  })

  const disabledNextButton = !EMAIL.test(email) || loading || !emailAvailable || !company

  const handleClickedChannel = e => {
    if (!e.wanted) {
      if (e.checked) {
        setValue(storedValue => ({
          ...storedValue,
          wantedChannels: storedValue.wantedChannels?.filter(obj => obj.name !== e.name),
          currentChannels: storedValue.currentChannels?.some(obj => obj.name === e.name) ? storedValue.currentChannels : [...storedValue.currentChannels, { name: e.name, supported: e.supported }]
        }))
      }
      if (!e.checked) {
        setValue(storedValue => ({ ...storedValue, currentChannels: storedValue.currentChannels?.filter(obj => obj.name !== e.name) }))
      }
    }
    if (e.wanted) {
      if (e.checked) setValue(storedValue => ({ ...storedValue, wantedChannels: storedValue.wantedChannels?.some(obj => obj.name === e.name) ? storedValue.wantedChannels : [...storedValue.wantedChannels, { name: e.name, supported: e.supported }] }))
      if (!e.checked) setValue(storedValue => ({ ...storedValue, wantedChannels: storedValue.wantedChannels?.filter(obj => obj.name !== e.name) }))
    }
  }

  const handleClickedAction = (checked, item) => {
    if (checked) setValue(storedValue => ({ ...storedValue, actionsToDo: [...storedValue.actionsToDo, item] }))
    if (!checked) setValue(storedValue => ({ ...storedValue, actionsToDo: storedValue.actionsToDo.filter(i => i !== item) }))
  }

  const handleSubmit = e => {
    e.preventDefault()
    const isAFit = checkIfIsAFit(currentChannels, wantedChannels, actionsToDo)
    const newValues = { isAFit }
    if (userSpecialAction) newValues.actionsToDo = [...storedValue.actionsToDo, userSpecialAction]
    setValue(storedValue => ({ ...storedValue, ...newValues }))
    if (window.analytics) {
      const currentEvent = 'Quiz: User needs'
      const currentHistory = window?.analyticsEventHistory || []
      const newHistory = [currentEvent, ...currentHistory].slice(0, 10)
      window.analyticsEventHistory = newHistory
      const fullFilled = {
        action: 'Clicks next',
        email: storedValue?.email,
        pathHistory: window?.analyticsPathHistory,
        priorPath: window?.analyticsPathHistory?.[1],
        eventHistory: newHistory,
        priorEvent: newHistory?.[1]
      }
      window.analytics.track(currentEvent, fullFilled)
      debug({ currentEvent, fullFilled })
    }
    const cleanCurrentChannels = storedValue?.currentChannels?.map(i => i?.name)
    const cleanWantedChannels = storedValue?.wantedChannels?.map(i => i?.name)
    const qualifications = objectKeyMap({
      ...storedValue,
      ...newValues,
      currentChannels: cleanCurrentChannels,
      wantedChannels: cleanWantedChannels
    }, snakeToCamel)
    storeQualifications({ variables: { qualifications } })
  }

  useEffect(() => {
    const currentChannelNames = currentChannels?.map(i => i.name)
    setRestOfChannels(dataP1.allChannels.filter(({ name }) => !currentChannelNames?.includes(name)))
  }, [currentChannels])

  return (
    <LoggedOutPage
      title='Welcome to SureDone!'
      className='qualifications'
      description="Growing e-commerce businesses run into similar fundamental problems. Hair on fire. Stuff everywhere and buried in spreadsheets. Too many systems to log in to and not enough time in the day. The good news? SureDone helps solves them. We're going to ask you a few questions that will help us optimize your experience."
    >
      <Card className='mb-5'>
        <CardBody>
          <Label tag='legend'>What is your email?</Label>
          <Input
            autoFocus
            invalid={emailAvailable === false} // When undefined is not invalid
            type='email'
            value={email}
            autoCorrect='off'
            spellCheck='false'
            autoComplete='off'
            autoCapitalize='off'
            onChange={e => {
              setValue(storedValue => ({ ...storedValue, email: e?.target?.value?.trim().toLowerCase() }))
              setEmailInput((e.target.value).toLowerCase().trim())
            }}
          />
          {!loading && emailAvailable !== undefined && !emailAvailable
            ? (
              <FormFeedback style={{ fontSize: '0.75rem' }} className='mt-n2 d-flex align-items-baseline'>
                A user with that email already exists. {' '}
                <Button className='pl-1' color='link' style={{ fontSize: '0.75rem' }} to='/login' tag={Link}>
                  Back to Login
                </Button>
              </FormFeedback>
              ) : (
                <FormText className='mt-n2 mb-3'>
                  Your account will be created with this email
                </FormText>
              )}

          <Label tag='legend'>Company name</Label>
          <Input
            autoFocus
            type='text'
            value={company}
            autoCorrect='off'
            spellCheck='false'
            autoComplete='off'
            autoCapitalize='off'
            onChange={e => {
              setValue(storedValue => ({ ...storedValue, company: e?.target?.value }))
            }}
          />
          <Label tag='legend' className='mt-5'>What channels and tools do you connect to?</Label>
          <ChannelsList channels={dataP1.allChannels} clicked={handleClickedChannel} currentSelected={currentChannels} />

          <Label tag='legend' className='mt-5'>What channels and tools would you like to connect to?</Label>
          <ChannelsList wanted channels={restOfChannels} clicked={handleClickedChannel} currentSelected={wantedChannels} />

          <Label tag='legend' className='mt-5'>What do you want to do?</Label>
          <div className='d-flex flex-wrap w-100'>
            {dataP1.actions.map((item, idx) =>
              <Label check key={idx} className='custom-control custom-checkbox mb-0 w-100' style={{ cursor: 'pointer' }}>
                <Input
                  id={item}
                  name={item}
                  type='checkbox'
                  className='custom-control-input'
                  checked={actionsToDo?.some(action => action === item)}
                  onChange={e => handleClickedAction(e.target.checked, item)}
                />
                <span className='custom-control-label'>{item}</span>
              </Label>
            )}

            {actionsToDo?.some(action => action === 'Other') &&
              <form onSubmit={e => handleSubmit(e)}>
                <Label className='mt-2'>Let us know what!</Label>
                <Input autoFocus onChange={e => setUserSpecialAction(e.target.value)} />
              </form>}
          </div>

          <div className='d-flex w-100 justify-content-end'>
            <ActionButton
              type='submit'
              color='primary'
              loading={storing}
              className='continue'
              disabled={disabledNextButton}
              onClick={e => handleSubmit(e)}
            >
              Next <NextIcon className='mr-0 ml-1' />
            </ActionButton>
          </div>
        </CardBody>
      </Card>
    </LoggedOutPage>
  )
}
