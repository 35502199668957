import React, { useState } from 'react'
import { FitAside, FitResults } from '.'
import { OnlyHeaderPage } from '../../components'
import './Fitment.scss'

export const FitmentResults = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  return (
    <OnlyHeaderPage setSidebarOpen={setSidebarOpen}>
      <div className='fitment-results'>
        <FitAside sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <FitResults />
      </div>
    </OnlyHeaderPage>
  )
}
