import auth01 from './auth01.png'

export const stepInstructions = {
  Permissions: {
    instructions: {
      stepInstructions: [
        {
          title: 'Login with BigCommerce & Connect Store',
          description: 'Click "Authorize BigCommerce" below to start the authorization process. Connect an existing store or sign up with BigCommerce through this flow.',
          imgSrc: auth01,
          imgAlt: 'Login or Sign up with BigCommerce'
        },
        {
          title: 'Select BigCommerce Integration Instance',
          description: 'If you have multiple BigCommerce stores in SureDone, select which instance to link with this app authorization.'
        }
      ]
    }
  }
}
