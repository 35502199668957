/**
 * As json files make it difficult to save regular expressions, here we will define them
 * and in [channel]-config.json we will indicate which of them we want to use under userInputNeeded.modifiers
 */

// Stuff for only_hostname. It'll replace URL_WORDS_TO_REMOVE with the word at the same position on REPLACE
const SHOP_WORDS_TO_REPLACE = ['http://', 'https://', 'www.', '/']
const SHOP_REPLACE_WITH = ['', '', '', '']

// eslint-disable-next-line
String.prototype.replaceArray = function (find, replace) {
  let replaceString = this
  for (let i = 0; i < find.length; i++) {
    replaceString = replaceString.replace(find[i], replace[i])
  }
  return replaceString
}

export const fieldModifiers = {
  only_hostname: url => url.replaceArray(SHOP_WORDS_TO_REPLACE, SHOP_REPLACE_WITH)
}
