import React from 'react'
import { CodeBlock } from '../../../components'
import { InstructionCollapse } from '../InstructionCollapse'

const code = `<script>
    if (!window.sdFitmentData) window.sdFitmentData = {}
    if (!window.sdFitmentData.searchResultSKUs) window.sdFitmentData.searchResultSKUs = []
    window.sdFitmentData.searchResultSKUs.push('{{item.selected_or_first_available_variant.sku}}')
  </script>`

const code2 = `<script>
    if (!window.shopData) window.shopData = {}
    if (!window.shopData.suredoneID) window.shopData.suredoneID = SUREDONEID
    if (!window.shopData.storefront) window.shopData.storefront = 'shopify'
  </script>
  <div id="sd-fitment-search-page-root"></div>
  <script src="https://fitment.suredone.com/bundle.min.js"></script>`

export const ShopifySearchPage = () => {
  return (
    <InstructionCollapse id='FitmentSearch' title='6. Add Year Make Model filter to the Search Page'>
      <ol className='pl-3'>
        <li>Edit your theme files</li>
        <li>Go to <code>Snippets {'>'} main-search.liquid</code></li>
        <li>Search for <code>id="main-search-filters"</code> and add the <code>SD-SearchNav</code> class. You also have to add <code>style="display: none;"</code> so our widget can handle it.<br />
          Now search for <code>id="ProductGridContainer"</code> and add the <code>SD-SearchPage</code> class. Add the <code>style="display: none;"</code> as well.<br />
          <img className='mb-0' src='https://assets.suredone.com/688015/logos/fitment-advanced-shopify-searchpage-1.png' alt='Shopify search file summary' />
        </li>
        <li>
          Scroll down a bit and inside the <code>{'{%- for item in search.results -%}'}</code>, just before the <code>{'<li class="grid__item">'}</code>, paste the following:
          <CodeBlock className='mt-1 mb-2'>{code}</CodeBlock>
          <img className='mb-0' src='https://assets.suredone.com/688015/logos/fitment-advanced-shopify-searchpage-2.png' alt='Shopify search file summary' />
        </li>
        <li>
          Lastly, add the following code right before the <code>{'{% schema %}'}</code> <b>(replace SUREDONEID with your SureDone id)</b>:<br />
          <CodeBlock>{code2}</CodeBlock>
          <img className='mb-0' src='https://assets.suredone.com/688015/logos/fitment-advanced-shopify-searchpage-3.png' alt='Shopify search file summary' />
        </li>
      </ol>
    </InstructionCollapse>
  )
}
