import React from 'react'
import { DownloaderCSV, DownloaderJSON } from '../../components'

export const DownloadLogs = ({ logs, isPlatformUser, downloadButtonText }) => {
  // Remove unneeded columns
  const cleanColumns = () => (logs || []).map(row =>
    Object.keys(row)
      .filter(key => !['s3Path', '__typename'].includes(key))
      .filter(key => !isPlatformUser && ['serverName', 'releaseType'].includes(key))
      .reduce((obj, key) => {
        obj[key] = row[key]
        return obj
      }, {})
  )

  cleanColumns()

  const format = value => {
    if (typeof value === 'object') {
      return JSON.stringify(value || {}, null, 4)?.split('"').join('')
    }
    if (typeof value === 'string') {
      return value.split('"').join('')
    }
    return value
  }

  const logsRows = logs?.map(({
    id,
    type,
    role,
    jobId,
    result,
    action,
    userId,
    method,
    release,
    message,
    warnings,
    context,
    instance,
    timestamp,
    createdAt,
    accountId,
    channelId,
    operation,
    requestId,
    serverName,
    identifier,
    integration,
    releaseType,
    truncatedFields,
    fieldData,
    request,
    response
  }) =>
    [
      id,
      type,
      role,
      jobId,
      result,
      warnings,
      action,
      userId,
      method,
      release,
      message?.message,
      context,
      instance,
      timestamp,
      createdAt,
      accountId,
      channelId,
      operation,
      requestId,
      serverName,
      identifier,
      integration,
      releaseType,
      truncatedFields,
      format(fieldData),
      format(request),
      format(response)
    ]
  )

  const csvData = [
    ['id', 'type', 'role', 'job id', 'result', 'warnings', 'action', 'user id', 'method', 'release', 'message', 'channel', 'instance', 'timestamp', 'created At', 'account id', 'channel id', 'operation', 'request Id', 'server name', 'identifier', 'integration', 'release type', 'truncated fields', 'field data', 'request', 'response'],
    ...logsRows || []
  ]

  const jsonData = JSON.stringify(logs || {}, null, '\t')

  return logs
    ? (
      <>
        <DownloaderJSON data={jsonData} text={`${downloadButtonText || 'Download'} as .json`} className='mr-3' />
        <DownloaderCSV data={csvData} text={`${downloadButtonText || 'Download'} as .csv`} />
      </>)
    : <></>
}
