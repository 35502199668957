import React from 'react'
import { Table } from 'reactstrap'

export const DetailedLogRow = ({ row }) => {
  const { id, type, role, retry, jobId, result, action, userId, method, release, message, warnings, context, request, instance, response, timestamp, createdAt, fieldData, accountId, channelId, operation, requestId, serverName, identifier, integration, releaseType, truncatedFields } = row?.original || {}

  const data = [
    { label: 'id', value: id },
    { label: 'type', value: type },
    { label: 'role', value: role },
    { label: 'retry', value: retry },
    { label: 'job id', value: jobId },
    { label: 'result', value: result },
    { label: 'action', value: action },
    { label: 'user id', value: userId },
    { label: 'method', value: method },
    { label: 'release', value: release },
    { label: 'message', value: message },
    { label: 'warnings', value: warnings },
    { label: 'channel', value: context },
    { label: 'instance', value: instance },
    { label: 'timestamp', value: timestamp },
    { label: 'created At', value: createdAt },
    { label: 'account id', value: accountId },
    { label: 'channel id', value: channelId },
    { label: 'operation', value: operation },
    { label: 'request Id', value: requestId },
    { label: 'server name', value: serverName },
    { label: 'identifier', value: identifier },
    { label: 'integration', value: integration },
    { label: 'release type', value: releaseType },
    { label: 'truncated fields', value: truncatedFields },
    { label: 'field data', value: fieldData },
    { label: 'request', value: request },
    { label: 'response', value: response }
  ]

  return (
    <Table responsive size='sm' className='sub-table'>
      <tbody style={{ boxShadow: 'inset 0px 0px 0px 1px #ccc' }}>
        {data.map(({ label, value }) => (
          <tr key={label + value}>
            <th scope='row' style={{ minWidth: '110px' }}>{label}</th>
            <td>{value}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}
