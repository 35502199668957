import { useState } from 'react'
import { useStores } from '../stores/RootStore'

const useGlobalLogout = () => {
  const { stores: { auth } } = useStores()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  const globalLogOut = async () => {
    setLoading(true)
    try {
      setError(false)
      const result = await auth.globalSignOut()
      setLoading(false)
      setSuccess(true)
      return result
    } catch (error) {
      setError(error)
      setLoading(false)
      setSuccess(false)
    }
  }

  return {
    globalLogOut,
    loading,
    error,
    success
  }
}

export default useGlobalLogout
