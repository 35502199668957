import React from 'react'
import { useLocalStorage } from '../../../hooks'
import { LAST_SEARCHES_KEY } from './YourSearches'
import { FaExternalLinkAlt as ExternalLink } from 'react-icons/fa'
import { ActionButton, Pill, makeSearch } from '../../../components'

export const SearchPreview = ({ finalQueryValue, typeOfSearch }) => {
  const anyFilterApplied = finalQueryValue?.[0]
  const [lastSearches, setLastSearches] = useLocalStorage(LAST_SEARCHES_KEY)

  const addToLastSearches = (searchToSave) => {
    if (!lastSearches) {
      setLastSearches([searchToSave])
    } else {
      setLastSearches(storedValue => ([searchToSave, ...storedValue]))
    }
  }

  const search = ({ type, query }) => {
    addToLastSearches({ type, query })
    makeSearch({ type, query })
  }

  const generateSearch = () => {
    if (!finalQueryValue) return

    const filters = Object.keys(finalQueryValue)?.map(k => {
      const { connector, input, key, operator } = finalQueryValue[k] || {}
      if (!key || !operator) return []
      return `${connector ? `${connector} ` : ''}${key}${operator}${input}`
    })

    const lastANDindex = filters?.findLastIndex(i => i?.includes('AND'))
    const firstORindex = filters?.findIndex(i => i?.includes('OR'))

    // The query is a sequence of ANDs
    if (firstORindex < 0) {
      const query = filters?.join('').replaceAll('AND', '')
      return search({ type: typeOfSearch, query })
    }

    // The query is a sequence of ORs
    if (lastANDindex < 0) {
      const query = filters?.join('').replaceAll('OR', '')
      return search({ type: typeOfSearch, query: `(${query})` })
    }

    // The query is a combination of ANDs and ORs
    const arrayOfANDs = filters.slice(0, lastANDindex)
    const arrayOfORs = filters.slice(firstORindex - 1)
    const stringOfANDs = arrayOfANDs?.join('').replaceAll('AND', '')
    const stringOfORs = `(${arrayOfORs?.join('')?.replaceAll('OR', '')?.replaceAll('AND ', '')})`
    const query = `${stringOfANDs} ${stringOfORs}`
    return search({ type: typeOfSearch, query })
  }

  if (!anyFilterApplied) return null

  return (
    <>
      <hr className='d-none d-lg-block' />
      <div className='search-preview'>
        {Object.keys(finalQueryValue)?.map((k, idx) => {
          const { connector, key, operator, input } = finalQueryValue[k] || {}
          if (!key) return null
          return (
            <React.Fragment key={key + idx}>
              {' '}{connector && <span className='text-muted'>{connector}</span>}
              {' '}<Pill size='lg'>{key}{operator}{input}</Pill>
            </React.Fragment>
          )
        })}

        {finalQueryValue?.[0] && (
          <ActionButton
            size='sm'
            color='primary'
            onClick={generateSearch}
          >
            Search {typeOfSearch} <ExternalLink size={10} />
          </ActionButton>
        )}
      </div>
    </>
  )
}
