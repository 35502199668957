import React from 'react'
import Avatar from 'react-avatar'
import Debug from 'debug'
const debug = Debug('sd:UserAvatar')

export function UserAvatar (props) {
  const { user } = props
  const { id, primaryEmail } = user
  const reversedId = id.slice(5).split().reverse().join()// + id.slice(0, 5)
  // debug('revesedId', reversedId, primaryEmail)
  return (
    <Avatar
      {...props}
      round
      name={`${user?.firstName} ${user?.lastName}` || primaryEmail}
      // email={primaryEmail}
      color={Avatar.getRandomColor(reversedId)}
    />
  )
}

debug('loaded')
