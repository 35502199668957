import React, { useEffect } from 'react'
import Debug from 'debug'
import { addHotjarTag } from '../utils'
import { icons } from '../ChannelsLogos'
import { useQuery } from '@apollo/react-hooks'
import { ActionButton } from '../../../components'
import { useAuthDebounce } from './useAuthDebounce'
import { FaFacebook as FbIcon, FaAmazon as AmazonIcon } from 'react-icons/fa'
import { GET_CHANNEL_AUTH_URL, AVAILABLE_CHANNELS } from '../commonQueries'
import { isAmazon } from 'suredone-common'

const debug = Debug('sd:AuthButton')

export const AuthButton = (props) => {
  const { slug, instanceNumber, channelName, disabled = false, values = {}, step } = props
  const { store_base_url: shopUrl, clientId, clientSecret, marketplaceId } = values || {}
  const { name: stepName, userInputNeeded } = step || {}
  const { google: googleGicon } = icons

  // Query when render or 400ms after user stop typing
  const [debouncedObject] = useAuthDebounce({ shopUrl, clientId, clientSecret, marketplaceId: marketplaceId?.value }, 400)
  const { loading, data } = useQuery(GET_CHANNEL_AUTH_URL, { variables: { slug, instanceNumber, formParams: debouncedObject } })

  // Token expiration stuff
  const today = new Date()
  const { data: availableChannels } = useQuery(AVAILABLE_CHANNELS)
  const { ChannelInstances } = availableChannels || {}
  const selfChannel = ChannelInstances?.find(i => i.instance === parseInt(instanceNumber) && i.channel.slug === slug)
  const { dateOfTokenExp } = selfChannel || {}
  const tokenExpired = dateOfTokenExp && new Date(dateOfTokenExp) - today < 0
  const handleClick = () => {
    if (window.analytics) {
      const currentEvent = 'Clicked Channel Auth'
      const currentHistory = window?.analyticsEventHistory
      const newHistory = [currentEvent, ...currentHistory].slice(0, 10)
      window.analyticsEventHistory = newHistory
      const fullFilled = {
        action: `User clicks on authorize ${channelName}`,
        channelInstance: instanceNumber,
        channelName,
        slug: `${slug}${instanceNumber > 0 ? instanceNumber : ''}`,
        pathHistory: window?.analyticsPathHistory,
        priorPath: window?.analyticsPathHistory?.[0],
        eventHistory: newHistory,
        priorEvent: newHistory?.[1]
      }
      window.analytics.track(currentEvent, fullFilled)
    }
    addHotjarTag(channelName)
  }

  useEffect(() => {
    // Simulates a click on Authorize channel button when the channel is ebay, auth step is 'OAuth' and token isn't expired
    if (slug === 'ebay' && stepName === 'OAuth' && !userInputNeeded && !tokenExpired) {
      const timer = setInterval(() => document?.getElementById('authorize-channel-button')?.click(), 1500)
      return () => clearInterval(timer)
    }
  }, [slug, stepName, userInputNeeded, tokenExpired])

  // Shows a button according to Google guidelines
  if (slug === 'google') {
    return (
      <ActionButton
        tag='a'
        loading={loading}
        className='google-auth'
        id='authorize-channel-button'
        onClick={() => handleClick()}
        disabled={disabled || loading}
        href={data && data.ChannelInstance.authUrl && data.ChannelInstance.authUrl.url}
      >
        <span className='icon-wrapper'>
          <i className='google-icon'>
            {googleGicon}
          </i>
        </span>
        Sign in with Google
      </ActionButton>
    )
  }

  // Shows a button according to Facebook guidelines
  if (slug === 'facebook') {
    return (
      <ActionButton
        tag='a'
        loading={loading}
        className='facebook-auth'
        id='authorize-channel-button'
        onClick={() => handleClick()}
        disabled={disabled || loading}
        href={data && data.ChannelInstance.authUrl && data.ChannelInstance.authUrl.url}
      >
        <span className='icon-wrapper'>
          <FbIcon size={22} className='facebook-icon m-0' />
        </span>
        Sign in with Facebook
      </ActionButton>
    )
  }

  // Shows a button according to Amazon guidelines
  if (isAmazon(slug)) {
    return (
      <ActionButton
        tag='a'
        loading={loading}
        className='amazon-auth'
        id='authorize-channel-button'
        onClick={() => handleClick()}
        disabled={disabled || loading}
        href={data && data.ChannelInstance.authUrl && data.ChannelInstance.authUrl.url}
      >
        <span className='icon-wrapper'>
          <AmazonIcon size={22} className='amazon-icon m-0' />
        </span>
        Authorize Amazon
      </ActionButton>
    )
  }

  if (slug === 'shopify') {
    return (
      <ActionButton
        tag='a'
        color='primary'
        style={{ color: '#FFF' }}
        id='authorize-channel-button'
        onClick={() => handleClick()}
        href='https://apps.shopify.com/search?q=suredone'
      >
        Install SureDone app
      </ActionButton>
    )
  }

  return (
    <ActionButton
      tag='a'
      color='primary'
      loading={loading}
      style={{ color: '#FFF' }}
      id='authorize-channel-button'
      onClick={() => handleClick()}
      disabled={disabled || loading}
      href={data && data.ChannelInstance.authUrl && data.ChannelInstance.authUrl.url}
    >
      Authorize {channelName}
    </ActionButton>
  )
}

debug('loaded')
