import React from 'react'
import { InstructionCollapse } from '../InstructionCollapse'

export const ShopifyEditThemeFile = () => {
  return (
    <InstructionCollapse id='ThemeFiles' title='1. How to edit Theme Files'>
      <ol className='pl-3'>
        <li>Go to theme page</li>
        <li>Click 3 dots on current theme</li>
        <li>Select edit code</li>
      </ol>
    </InstructionCollapse>
  )
}
