/* eslint-disable no-undef */
import { useState, useEffect } from 'react'

const appEndpoint = window.serverConfig.legacyAppEndpoint

const useYmm = (suredoneID, storefront, domain, year, make) => {
  const [data, setData] = useState({ years: undefined, makes: undefined, models: undefined })
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState({ loadingYears: false, loadingMakes: false, loadingModels: false })

  useEffect(() => {
    const uri = `${appEndpoint}/public/fitment/rest/storefront/ymm`
    const path = `?suredoneID=${suredoneID}&storefront=${storefront}&domain=${domain}`

    const fetcher = async (year, make) => {
      try {
        if (!year && !make) {
          setLoading((l) => ({ ...l, loadingYears: true }))
          setError(false)
          const _years = await fetch(`${uri}${path}`)
          const years = await _years.json()
          if (Array.isArray(years)) {
            setData(d => ({ ...d, years: [...new Set(years?.map(i => i?._id))].filter(x => x).sort((a, b) => b - a) }))
            setLoading((l) => ({ ...l, loadingYears: false }))
          }
        }
        if (year && !make) {
          setLoading((l) => ({ ...l, loadingMakes: true }))
          const _makes = await fetch(`${uri}${path}${year ? `&year=${year}` : ''}`)
          const makes = await _makes.json()
          if (Array.isArray(makes)) {
            setData(d => ({ ...d, makes: [...new Set(makes?.map(i => i?._id))] }))
            setLoading((l) => ({ ...l, loadingMakes: false }))
          }
        }
        if (year && make) {
          setLoading((l) => ({ ...l, loadingModels: true }))
          const _models = await fetch(`${uri}${path}${year ? `&year=${year}` : ''}${make ? `&make=${make}` : ''}`)
          const models = await _models.json()
          if (Array.isArray(models)) {
            setData(d => ({ ...d, models: [...new Set(models?.map(i => i?._id))] }))
            setLoading((l) => ({ ...l, loadingModels: false }))
          }
        }
      } catch (e) {
        setError(true)
      }
    }
    fetcher(year, make)
  }, [suredoneID, year, make, storefront, domain])

  return { data, error, loading }
}

export { useYmm }
