import React from 'react'

// This function is for channels that already have created stores, so we won't take them to the traditional onboard,
// instead, we will send them to the final step with the selected created commerce (cms_id)
export const AuthLinkButton = ({ href, text }) => {
  return (
    <a className='btn btn-primary' href={href}>
      {text}
    </a>
  )
}
