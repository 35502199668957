import React from 'react'
import { Col, Card, CardBody, CardTitle, CardSubtitle, CardText } from 'reactstrap'

export const ProductCard = ({ item }) => {
  const { facets, guid } = item || {}
  const { price, brand } = facets || {}

  return (
    <Col xs={12} sm={4} lg={3} xl={2}>
      {/* <a href='#product' target='_blank' rel='noopener noreferrer' className='product'> */}
      <Card className='product'>
        <CardBody>
          <CardSubtitle tag='h6' className='mt-2 text-muted'>
            {brand}
          </CardSubtitle>
          <CardTitle tag='h6' className='name mb-n2'>
            {guid}
          </CardTitle>
          <CardText tag='h5'>
            ${price}
          </CardText>
        </CardBody>
      </Card>
      {/* </a> */}
    </Col>
  )
}
