'use strict'

/**
 * The SureDone permissions system
 *
 * This system is broken into basically three main objects:
 *   1. A list of scopes a user can have which control what that user can do
 *      in the app.
 *   2. A list of roles a user can have that grant a user a group of scopes,
 *      and can inherit from other roles.
 *   3. A Permissions object, that can take roles and scopes from a user and
 *      help your code quickly and accurately work out what that user can do.
 *
 * Now, scopes and roles come in two flavors: Platform and organization.
 *
 * Platform scopes and roles grant abilities that are platform wide and don't
 * depend on what organization the user is a part of, whereas organization
 * scopes and roles pertain to a particular organization. For example a scope
 * that allows read access to an organization's automations doesn't allow
 * access to automations from other organizations.
 *
 * A user can gain a scope in 2 different ways.
 * 1) The scope can be gained through a Role. Each role can have zero or more
 * scopes, and all these scopes are granted to users that have the Role.
 * Additionally, each role can inherit from other Roles and the user gains
 * these scopes too.
 * 2) The scope can be explicitly listed on the user. This only needs to
 * happen for scopes that are not granted through roles. Furthermore, if you
 * try to explicitly grant a scope that a user already has through a role, it
 * won't be added.
 * Scopes can be grouped. These groups are used to control who can grant/revoke
 * the scope. For example, if the user has the scope called:
 *   "organization:scopes:group:Feature Management:grant"
 * then this user is able to grant any scope of the Feature Management
 * group, like "organization:automation:ui:read"
 *
 *
 * Usage:
 *
 * // Setup a permissions object so we can ask questions about what this
 * // user is allowed to do
 * const organizationId = '5df12dd30998200007ae354e'
 * const userRoles = ['PlatformAdmin', ' User']
 * const userScopes = ['organization:bulk:ui:read', 'organization:bulk:ui:write']
 * const p = new Permissions(organizationId, userRoles, userScopes)
 *
 * // See if we are an admin for our organization (no)
 * assert(p.hasRole('Admin') === false)
 *
 * // See if we can access the Bulk Status UI for our organization (yes)
 * assert(p.hasRole('organization:bulk:ui:read') === true)
 *
 * // See if we can create a PlatformOwner (a super super user, no)
 * assert(p.canGrantRole('PlatformAdmin') === false)
 *
 * // See if we can create a PlatformSupport user (yes)
 * assert(p.canGrantRole('PlatformSupport') === true)
 *
 * // See if we can create an Admin on our own organization (no)
 * assert(p.canGrantRole('Admin', organizationId) === false)
 *
 * // Modify our current object to see what it would be like if we were to
 * // revoke our 'PlatformAdmin' role
 * p.revokeRole('PlatformAdmin')
 * assert(p.canGrantRole('PlatformSupport') === false) // can't do this now!
 *
 * // Now see what our roles and scopes would look like after loosing our
 * // PlatformAdmin role. The backend would do this to make the change
 * // permanent and persist it:
 * p.getCognitoAttributes()
 * // [
 * //   { Name: 'custom:roles', Value: 'User' },
 * //   { Name: 'custom:scopes', Value: 'organization:bulk:ui:read,organization:bulk:ui:write' }
 * // ]
 * p.getMongoAttributes()
 * // {
 * //   roles: ['User'],
 * //   scopes: ['organization:bulk:ui:read', 'organization:bulk:ui:write']
 * // }
 *
 */

import { ObjectId } from 'bson'

//
// Role / Permission definitions
//

//
// Platform roles are reserved for employees or other trusted people.
// They apply across the whole platform rather than to individual organizations.
//

export const platformRoles = Object.freeze([
  Object.freeze({
    name: 'PlatformOwner',
    inheritsFrom: Object.freeze(['PlatformAdmin']),
    scopes: Object.freeze([
      'platform:roles:grantAny',
      'platform:roles:revokeAny',
      'platform:scopes:grantAny',
      'platform:scopes:revokeAny'
    ]),
    description: 'Super user for trusted inner circle of platform managers. Can grant/revoke PlatformAdmins. Not removable except by other PlatformOwners.',
    numberHierarchy: 1
  }),
  Object.freeze({
    name: 'PlatformAdmin',
    inheritsFrom: Object.freeze(['PlatformSupport', 'PlatformDeveloper']),
    scopes: Object.freeze([
      'platform:scopes:platform:alpha:use:grant',
      'platform:scopes:platform:alpha:use:revoke',
      ...['PlatformAdmin', 'PlatformSupport'].map(role => `platform:roles:${role}:grant`),
      ...['PlatformAdmin', 'PlatformSupport'].map(role => `platform:roles:${role}:revoke`)
    ]),
    description: 'Can do anything including administrate platform users except for PlatformOwners.',
    numberHierarchy: 2
  }),
  Object.freeze({
    name: 'PlatformDeveloper',
    scopes: Object.freeze([
      'platform:graphqlPlayground:use'
    ]),
    description: 'Can access development tools.',
    numberHierarchy: 3
  }),
  Object.freeze({
    name: 'PlatformSupport',
    scopes: Object.freeze([
      'platform:allUsers:sudo',
      'platform:allUsers:list',
      'platform:allUsers:finish-registration',
      'platform:allOrganizations:users:read',
      'platform:allOrganizations:list',
      'platform:allOrganizations:settings',
      'platform:allOrganizations:roles:grantAny',
      'platform:allOrganizations:roles:revokeAny',
      // 'platform:allOrganizations:scopes:grantAny',
      // 'platform:allOrganizations:scopes:revokeAny',
      'platform:scopes:platform:beta:use:grant', // This may change because we decided that handle beta as a whole instead as little features
      'platform:scopes:platform:beta:use:revoke', // So probably each user will have a cognito custom:beta:boolean to decide whether show beta features or not
      'platform:allOrganizations:users:suspend',
      'platform:allOrganizations:settings:ui:read',
      'platform:allOrganizations:settings:ui:write',
      'platform:allOrganizations:permissions:write',
      'platform:allOrganizations:permissions:read',
      'platform:allOrganizations:logs:read',

      // The following scopes are kinda weird, but they are organization
      // scopes that only Platform users can grant/revoke.
      'platform:scopes:organization:options:ui:read:grant',
      'platform:scopes:organization:options:ui:read:revoke',
      'platform:scopes:organization:options:ui:write:grant',
      'platform:scopes:organization:options:ui:write:revoke',

      // Security scopes
      'platform:security:mfa:required'
    ]),
    description: 'Can view users/organizations and general internal data and become any user from any customer organization for troubleshooting purposes.',
    numberHierarchy: 4
  })
])

// Groups of scopes which Admin has full control over granting/revoking
export const adminScopeGroups = Object.freeze([
  'Dashboard',
  'Products',
  'Bulk Uploads',
  'Orders & Shipping',
  'Automations',
  'Settings',
  'Fitment',
  'Permissions',
  'API Token'
])

//
// Organization roles apply to individual organizations. It's possible to be an Admin on one organization and a User ano another.
//

export const organizationRoles = Object.freeze([
  Object.freeze({
    name: 'Owner',
    inheritsFrom: Object.freeze(['Admin']),
    scopes: Object.freeze([
      'organization:roles:grantAny',
      'organization:roles:revokeAny',
      'organization:scopes:grantAny',
      'organization:scopes:revokeAny'
    ]),
    description: 'Full owner of an organization. Can do anything including create other Owners.',
    numberHierarchy: 5
  }),
  Object.freeze({
    name: 'Admin',
    inheritsFrom: Object.freeze(['User', 'SettingsManager', 'ProductManager', 'OrderShippingManager']),
    scopes: Object.freeze([
      // 'organization:users:sudo',
      'organization:users:suspend',
      'organization:users:read',
      'organization:users:write',
      'organization:permissions:read',
      'organization:permissions:write',
      ...['Admin', 'SettingsManager', 'ProductManager', 'OrderShippingManager', 'User'].map(role => `organization:roles:${role}:grant`),
      ...['Admin', 'SettingsManager', 'ProductManager', 'OrderShippingManager', 'User'].map(role => `organization:roles:${role}:revoke`),
      ...adminScopeGroups.map(groupName => `organization:scopes:group:${groupName}:grant`),
      ...adminScopeGroups.map(groupName => `organization:scopes:group:${groupName}:revoke`)
    ]),
    description: 'Can create and administrate organization Users, including other Admins. Cannot change organization Owners.',
    numberHierarchy: 6
  }),
  Object.freeze({
    name: 'SettingsManager',
    label: 'Settings Manager',
    inheritsFrom: Object.freeze(['User']),
    scopes: Object.freeze([
      'organization:settings:ui:read',
      'organization:settings:ui:write',
      'organization:settings:api-token:read',
      'organization:settings:api-token:write'
    ]),
    description: 'User with default access to manage settings and the organization API token.',
    numberHierarchy: 7
  }),
  Object.freeze({
    name: 'ProductManager',
    label: 'Product Manager',
    inheritsFrom: Object.freeze(['User']),
    scopes: Object.freeze([
      'organization:bulk:ui:read',
      'organization:bulk:ui:write',
      'organization:bulk-exports:ui:read',
      'organization:bulk-exports:ui:write',
      'organization:editor:ui:read',
      'organization:editor:ui:write',
      'organization:media:ui:read',
      'organization:media:ui:write'
    ]),
    description: 'User with default access to manage products, bulk uploads and media.',
    numberHierarchy: 8
  }),
  Object.freeze({
    name: 'OrderShippingManager',
    label: 'Order & Shipping Manager',
    inheritsFrom: Object.freeze(['User']),
    scopes: Object.freeze([
      'organization:orders:ui:read',
      'organization:orders:ui:write',
      'organization:shipping:ui:read',
      'organization:shipping:ui:write'
    ]),
    description: 'User with default access to manage orders & shipping settings',
    numberHierarchy: 9
  }),
  Object.freeze({
    name: 'User',
    scopes: Object.freeze([
      'organization:users:read',
      'organization:dashboard:ui:read',
      'organization:logs:read',
      'organization:notifications:approachinglimits:read',
      'organization:notifications:limitsreached:read',
      'organization:reports:read'
    ]),
    description: 'Basic organization user.',
    numberHierarchy: 10
  })
])

//
// Platform scopes apply across the entire application
//
// userVisible means which scopes are visible to front end for grant/revoke

export const platformScopes = Object.freeze([

  //
  // Platform
  //

  // Roles
  {
    name: 'platform:roles:grantAny',
    label: 'Grant any Platform Role',
    group: 'Platform Role Management',
    description: 'Allows the user to grant any platform role to any other user on the platform.',
    userVisible: false
  },
  {
    name: 'platform:roles:revokeAny',
    label: 'Revoke any Platform Role',
    group: 'Platform Role Management',
    description: 'Allows the user to revoke any platform role from any other user on the platform.',
    userVisible: false
  },
  ...platformRoles.map(role => ({
    name: `platform:roles:${role.name}:grant`,
    label: `Grant the ${role.name} Role`,
    group: 'Platform Role Management',
    description: `Allows the user to grant the ${role.name}s at the same or lower level in the role hierarchy.`,
    userVisible: false
  })),
  ...platformRoles.map(role => ({
    name: `platform:roles:${role.name}:revoke`,
    label: `Revoke the ${role.name} Role`,
    group: 'Platform Role Management',
    description: `Allows the user to remove ${role.name}s role on the platform.`,
    userVisible: false
  })),

  // Scopes
  {
    name: 'platform:scopes:grantAny',
    label: 'Grant any scope',
    group: 'Platform Scope Management',
    description: 'Allows the user to grant any scope on the platform.',
    userVisible: false
  },
  {
    name: 'platform:scopes:revokeAny',
    label: 'Revoke any scope',
    group: 'Platform Scope Management',
    description: 'Allows the user to remove any scope on the platform.',
    userVisible: false
  },
  {
    name: 'platform:allOrganizations:users:read',
    label: 'Look at users in any organization',
    group: 'Platform Scope Management',
    description: 'Allows the user view users on any organization.',
    userVisible: false
  },
  {
    name: 'platform:allOrganizations:users:suspend',
    label: 'Suspend a user',
    group: 'Platform Scope Management',
    description: 'Allows the user to suspend any user on the platform.',
    userVisible: false
  },
  ...[
    'platform:graphqlPlayground:use',
    'platform:alpha:use',
    'platform:beta:use'
  ].map(scope => ({
    name: `platform:scopes:${scope}:grant`,
    label: `Grant platform ${scope} scope`,
    group: 'Platform Scope Management',
    description: `Allows users to create ${scope} scopes`,
    userVisible: false
  })),
  ...[
    'platform:graphqlPlayground:use',
    'platform:alpha:use',
    'platform:beta:use'
  ].map(scope => ({
    name: `platform:scopes:${scope}:revoke`,
    label: `Revoke organization scope ${scope}`,
    group: 'Platform-Only Organization Settings',
    description: 'Organization permissions that only Platform users can change',
    userVisible: false
  })),

  // These scopes are only grantable by platform users. Dunno why ask Mati
  ...[
    'organization:options:ui:read',
    'organization:options:ui:write'
  ].map(scope => ({
    name: `platform:scopes:${scope}:grant`,
    label: `Grant organization scope ${scope}`,
    group: 'Platform-Only Organization Settings',
    description: 'Organization permissions that only Platform users can change',
    userVisible: false
  })),
  ...[
    'organization:options:ui:read',
    'organization:options:ui:write'
  ].map(scope => ({
    name: `platform:scopes:${scope}:revoke`,
    label: `Revoke platform ${scope} scope`,
    group: 'Platform Scope Management',
    description: 'Allows users to remove platform scopes',
    userVisible: false
  })),

  // Features
  {
    name: 'platform:graphqlPlayground:use',
    label: 'Revoke graphql use',
    group: 'Platform Feature Management',
    description: 'Allows users to use graphqlPlayground',
    userVisible: false
  },
  {
    name: 'platform:alpha:use',
    label: 'Use alpha features',
    group: 'Platform Feature Management',
    description: 'Allows users to use alpha features',
    userVisible: false
  },
  {
    name: 'platform:beta:use',
    label: 'Use beta features',
    group: 'Platform Feature Management',
    description: 'Allows users to use beta features',
    userVisible: false
  },

  // Organizations
  {
    name: 'platform:allOrganizations:list',
    label: 'List all organizations',
    group: 'Organization Management',
    description: 'Allows users to list all organizations',
    userVisible: false
  },
  {
    name: 'platform:allOrganizations:settings',
    label: 'Edit all organizations',
    group: 'Organization Management',
    description: 'Allows user to edit all organizations settings+',
    userVisible: false
  },
  {
    name: 'platform:allOrganizations:permissions:read',
    label: 'View permissions for users in all organizations',
    group: 'Organization Management',
    description: 'Allows user to see permissions for users in any organization',
    userVisible: false
  },
  {
    name: 'platform:allOrganizations:permissions:write',
    label: 'Edit permissions for users in all organizations',
    group: 'Organization Management',
    description: 'Allows user to change permissions for users in any organization',
    userVisible: false
  },
  {
    name: 'platform:allOrganizations:settings:ui:read',
    label: 'View all organization settings',
    group: 'Organization Management',
    description: 'Allows user to view the settings of any organization',
    userVisible: false
  },
  {
    name: 'platform:allOrganizations:settings:ui:write',
    label: 'Edit all organization settings',
    group: 'Organization Management',
    description: 'Allows user to change the settings of any organization',
    userVisible: false
  },
  {
    name: 'platform:allOrganizations:logs:read',
    label: 'View all organization logs',
    group: 'Logs',
    description: 'Allows user to view the logs of any organization',
    userVisible: false
  },
  // TODO: Check with Ryan this means
  {
    name: 'platform:allOrganizations:roles:grantAny',
    label: 'Grant any organization role',
    group: 'Organization Management',
    description: 'Allows users to grant any role at any organization',
    userVisible: false
  },
  {
    name: 'platform:allOrganizations:roles:revokeAny',
    label: 'Revoke any organization role',
    group: 'Organization Management',
    description: 'Allows users to remove any role at any organization',
    userVisible: false
  },
  ...organizationRoles.map(role => ({
    name: `platform:allOrganizations:roles:${role.name}:grant`,
    label: `Grant ${role.name} role`,
    group: 'Organization Management',
    description: `Allows users to create ${role.name} at any organization`,
    userVisible: false
  })),
  ...organizationRoles.map(role => ({
    name: `platform:allOrganizations:roles:${role.name}:revoke`,
    label: `Revoke ${role.name} role`,
    group: 'Organization Management',
    description: `Allows users to remove ${role.name} at any organization`,
    userVisible: false
  })),

  // User
  {
    name: 'platform:allUsers:list',
    label: 'List all users',
    group: 'Platform User Management',
    description: 'List all users of the platform',
    userVisible: false
  },
  {
    name: 'platform:allUsers:sudo',
    label: 'Became other user',
    group: 'Platform User Management',
    description: 'Became other users',
    userVisible: false
  },
  {
    name: 'platform:allUsers:finish-registration',
    label: 'Complete an unfinished registration of other user',
    group: 'Platform User Management',
    description: 'Finish other user registration',
    userVisible: false
  },

  // Security
  {
    name: 'platform:security:mfa:required',
    label: 'MFA is required for platform users',
    group: 'Platform Security',
    description: 'Force user to have mfa activated',
    userVisible: false
  }

])

//
// Account scopes apply to users of a specific organization
//
// * If new scope group is added remember to add it to scope management list
// userVisible means which scopes are visible to front end for grant/revoke

export const organizationScopes = Object.freeze([

  // Roles
  {
    name: 'organization:roles:grantAny',
    label: 'Grant any role',
    group: 'Role Management',
    description: 'Create any role in your organization',
    userVisible: false
  },
  {
    name: 'organization:roles:revokeAny',
    label: 'Revoke any role',
    group: 'Role Management',
    description: 'Remove any role in your organization',
    userVisible: false
  },
  ...organizationRoles.map(role => ({
    name: `organization:roles:${role.name}:grant`,
    label: `Grant ${role.name} role`,
    group: 'Role Management',
    description: `Create ${role.name} in your organization`,
    userVisible: false
  })),
  ...organizationRoles.map(role => ({
    name: `organization:roles:${role.name}:revoke`,
    label: `Revoke ${role.name} role`,
    group: 'Role Management',
    description: `Remove ${role.name} in your organization`,
    userVisible: false
  })),

  //
  // Scopes
  //

  {
    name: 'organization:scopes:grantAny',
    label: 'Grant any permission',
    group: 'Scope Management',
    description: 'Allows the users to create any role in their organization',
    userVisible: false
  },
  {
    name: 'organization:scopes:revokeAny',
    label: 'Revoke any permission',
    group: 'Scope Management',
    description: 'Allows the users to create any role in their organization',
    userVisible: false
  },

  // Manage scopes by group (ex: for admins)
  ...adminScopeGroups.map(groupName => ({
    name: `organization:scopes:group:${groupName}:grant`,
    label: `Grant ${groupName} permissions`,
    group: 'Scope Management',
    description: `Allows the users to grant the scope group ${groupName} to users in their organization`,
    userVisible: false
  })),
  ...adminScopeGroups.map(groupName => ({
    name: `organization:scopes:group:${groupName}:revoke`,
    label: `Revoke ${groupName} permissions`,
    group: 'Scope Management',
    description: `Allows the users to revoke the scope group ${groupName} to users in their organization`,
    userVisible: false
  })),

  //
  // Settings
  //

  //
  // UI Features
  //

  // Dashboard
  {
    name: 'organization:dashboard:ui:read',
    label: 'View Dashboard',
    group: 'Dashboard',
    description: 'See the organization Organization dashboard',
    generalDescription: 'Permissions for the Organization dashboard',
    userVisible: true
  },
  {
    name: 'organization:dashboard:ui:write',
    label: 'Edit Dashboard',
    group: 'Dashboard',
    description: 'Make changes to the layout and content of the Organization dashboard',
    userVisible: true
  },

  // Product Editor
  {
    name: 'organization:editor:ui:read',
    label: 'View Products',
    group: 'Products',
    description: 'View products and related data',
    generalDescription: 'Permissions for the products editor',
    userVisible: true
  },
  {
    name: 'organization:editor:ui:write',
    label: 'Edit Products',
    group: 'Products',
    description: 'Create and edit products',
    userVisible: true
  },
  {
    name: 'organization:media:ui:read',
    label: 'View Media',
    group: 'Products',
    description: 'View media files',
    userVisible: true
  },
  {
    name: 'organization:media:ui:write',
    label: 'Edit Media',
    group: 'Products',
    description: 'Upload and edit media files',
    userVisible: true
  },

  // Bulk Uploads
  {
    name: 'organization:bulk:ui:read',
    label: 'View Bulk Uploads',
    group: 'Bulk Uploads',
    description: 'See all Bulk Uploads and results for the Organization',
    generalDescription: 'Permissions for the Bulk Upload system',
    userVisible: true
  },
  {
    name: 'organization:bulk:ui:write',
    label: 'Create Bulk Uploads',
    group: 'Bulk Uploads',
    description: 'Trigger new Bulk Uploads by submitting files',
    userVisible: true
  },

  {
    name: 'organization:bulk-exports:ui:read',
    label: 'View Bulk Exports',
    group: 'Bulk Uploads',
    description: 'See all Bulk Exports for the Organization',
    userVisible: true
  },
  {
    name: 'organization:bulk-exports:ui:write',
    label: 'Create Bulk Exports',
    group: 'Bulk Uploads',
    description: 'Trigger new Bulk Exports by submitting files',
    userVisible: true
  },

  // Orders & Shipping
  {
    name: 'organization:orders:ui:read',
    label: 'View Orders',
    group: 'Orders & Shipping',
    description: 'View all orders and related data',
    generalDescription: 'Permissions for Orders & Shipping',
    userVisible: true
  },
  {
    name: 'organization:orders:ui:write',
    label: 'Edit Orders',
    group: 'Orders & Shipping',
    description: 'Make changes orders and related data',
    userVisible: true
  },

  {
    name: 'organization:shipping:ui:read',
    label: 'View Shipping Settings',
    group: 'Orders & Shipping',
    description: 'View settings related to shipping',
    userVisible: true
  },
  {
    name: 'organization:shipping:ui:write',
    label: 'Change Shipping Settings',
    group: 'Orders & Shipping',
    description: 'Make changes to shipping settings',
    userVisible: true
  },

  // Automations
  {
    name: 'organization:automation:ui:read',
    label: 'View Automations',
    group: 'Automations',
    description: 'View automations, logs and configuration data, excluding secrets',
    generalDescription: 'Permissions for the Automations system',
    userVisible: true
  },
  {
    name: 'organization:automation:ui:write',
    label: 'Edit Automations',
    group: 'Automations',
    description: 'Create and edit automations. Change automation secrets.',
    userVisible: true
  },

  // Settings
  {
    name: 'organization:settings:ui:read',
    label: 'View Settings',
    group: 'Settings',
    description: 'View but not edit organization settings',
    generalDescription: 'Permissions for organization settings',
    userVisible: true
  },
  {
    name: 'organization:settings:ui:write',
    label: 'Change Settings',
    group: 'Settings',
    description: 'Change organization settings',
    generalDescription: 'Permissions for organization settings',
    userVisible: true
  },

  // Fitment
  {
    name: 'organization:fitment:read',
    label: 'See fitment',
    group: 'Fitment',
    description: 'Allows the users to view fitment page',
    generalDescription: 'Permissions for Fitment & SureFit app',
    userVisible: true
  },
  {
    name: 'organization:fitment:write',
    label: 'Change fitment',
    group: 'Fitment',
    description: 'Allows the users to change fitment page',
    generalDescription: 'Permissions for Fitment & SureFit app',
    userVisible: true
  },

  // Permissions
  {
    name: 'organization:permissions:read',
    label: 'View Permissions',
    group: 'Permissions',
    description: 'View but not edit permissions for users in the organization',
    generalDescription: 'Organization Permissions',
    userVisible: true
  },
  {
    name: 'organization:permissions:write',
    label: 'Change Permissions',
    group: 'Permissions',
    description: 'Change permissions for users in the organization',
    generalDescription: 'Organization Permissions',
    userVisible: true
  },

  // View Members
  {
    name: 'organization:users:read',
    label: 'View Members',
    group: 'Settings',
    description: 'View the members in an organization',
    generalDescription: 'Permissions for organization settings',
    userVisible: true
  },
  {
    name: 'organization:users:write',
    label: 'Change Members',
    group: 'Settings',
    description: 'Resend invites to join an organization',
    generalDescription: 'Permissions for organization settings',
    userVisible: true
  },
  {
    name: 'organization:users:suspend',
    label: 'Suspend a user',
    group: 'Scope Management',
    description: 'Allows the users to suspend another user in their organization',
    userVisible: false
  },

  // Logs
  {
    name: 'organization:logs:read',
    label: 'Read logs',
    group: 'Logs',
    description: 'Allows the users to view logs',
    userVisible: false
  },

  // Keep this 2 permissions here for compatibility
  {
    name: 'organization:options:ui:read',
    label: 'View Options',
    group: 'Settings',
    description: 'View but not edit organization options',
    generalDescription: 'Permissions for organization settings',
    userVisible: true,
    isPlatform: true
  },
  {
    name: 'organization:options:ui:write',
    label: 'Change Options',
    group: 'Settings',
    description: 'Change organization options',
    generalDescription: 'Permissions for organization settings',
    userVisible: true,
    isPlatform: true
  },

  // API Tokens
  {
    name: 'organization:settings:api-token:read',
    label: 'View API Token',
    group: 'API Token',
    description: 'View but not change the API token for the Organization',
    generalDescription: 'Permissions for managing the Organization\'s API token',
    userVisible: true
  },
  {
    name: 'organization:settings:api-token:write',
    label: 'Change API Token',
    group: 'API Token',
    description: 'Change the API token for the Organization',
    userVisible: true
  },

  // Notifications
  {
    group: 'Notifications',
    label: 'View Approaching Limits Notification',
    name: 'organization:notifications:approachinglimits:read',
    description: 'Receive approaching limits notifications',
    generalDescription: 'Permissions for view Organization\'s notifications',
    userVisible: true
  },
  {
    userVisible: false, // It's here just for read/write pair compatibility
    name: 'organization:notifications:approachinglimits:write'
  },
  {
    group: 'Notifications',
    label: 'View Limits Reached Notification',
    name: 'organization:notifications:limitsreached:read',
    description: 'Receive reached limits notifications',
    generalDescription: 'Permissions for view Organization\'s notifications',
    userVisible: true
  },
  {
    userVisible: false, // It's here just for read/write pair compatibility
    name: 'organization:notifications:limitsreached:write'
  },

  // Reports
  {
    group: 'Reports',
    label: 'View Reports',
    name: 'organization:reports:read',
    description: 'Allows the users to view reports',
    generalDescription: 'Permissions for view Organization\'s reports',
    userVisible: true
  },
  {
    userVisible: false, // Just for read/write pair compatibility
    name: 'organization:reports:write'
  },

  // Deprecated
  ...[
    'bulk',
    'bulk-exports',
    'dashboard',
    'editor',
    'media',
    'options',
    'orders',
    'settings',
    'shipping'
  ].map(uiFeature => ({
    name: `account:${uiFeature}:ui:read`,
    label: `Read ${uiFeature} feature`,
    group: 'Account Feature Management',
    description: `Allows the users to read ${uiFeature} in their organization`,
    userVisible: false
  })),
  ...[
    'bulk',
    'bulk-exports',
    'dashboard',
    'editor',
    'media',
    'options',
    'orders',
    'settings',
    'shipping'
  ].map(uiFeature => ({
    name: `account:${uiFeature}:ui:write`,
    label: `Write ${uiFeature} feature`,
    group: 'Account Feature Management',
    description: `Allows the users to write ${uiFeature} in their organization`,
    userVisible: false
  }))
])

// These are the fields that an organization user cannot edit but a super user, by sudo, can edit.
// For example, an Owner can edit the organization's bio but cannot edit stripeTrialEnd.
export const organizationPlatformRestrictedFields = Object.freeze([
  'stripeTrialEnd'
])

//
// Validation / Accessors for roles and scopes
//

const platformRolesByName = new Map(platformRoles.map(r => [r.name, Object.assign({ isPlatform: true }, r)]))
const organizationRolesByName = new Map(organizationRoles.map(r => [r.name, r]))

/**
 * Generates an object of scopes names grouped by group tag and a set of scopes Names
 * @param {[string]} scopeObjectArray - Array of scopes Object
 * @returns scopeGroups,scopeSet - scopes object with key group, value set ordered ,Set of scopes names
 */

// Just to take advantage of array iteration
export const organizeScopes = (scopeObjectArray) => scopeObjectArray.reduce((acc, curScopeObj) => {
  // curScopeObj : {name, label, group, description, userVisible}
  // if group exist, add element to Set else create the Set with the scope obj
  if (acc.scopesGroups[curScopeObj.group]) {
    acc.scopesGroups[curScopeObj.group].add(curScopeObj)
  } else {
    acc.scopesGroups[curScopeObj.group] = new Set([curScopeObj])
  }
  acc.scopeSet.add(curScopeObj.name)
  curScopeObj.userVisible && acc.scopeVisibleSet.add(curScopeObj.name)
  return acc
}, { scopesGroups: {}, scopeSet: new Set(), scopeVisibleSet: new Set() })

export const {
  scopesGroups: platformScopesByGroup,
  scopeSet: platformScopesSet,
  scopeVisibleSet: platformScopesVisibleSet // visible means userVisible property is true
} = organizeScopes(platformScopes)
export const {
  scopesGroups: organizationScopesByGroup,
  scopeSet: organizationScopesSet,
  scopeVisibleSet: organizationScopesVisibleSet // visible means userVisible property is true
} = organizeScopes(organizationScopes)

export const organizationScopesVisible = organizationScopesSet
export const platformScopesVisible = platformScopesSet

export function validatePlatformRole (roleName) {
  if (!platformRolesByName.has(roleName)) throw new Error(`Invalid platform role ${roleName}`)
}

// map each scopes and return name on each getPlatformRole

export function getPlatformRole (roleName) {
  validatePlatformRole(roleName)
  return platformRolesByName.get(roleName)
}

export function validateAccountRole (roleName) {
  if (!organizationRolesByName.has(roleName)) throw new Error(`Invalid organization role ${roleName}`)
}

export function getAccountRole (roleName) {
  validateAccountRole(roleName)
  return organizationRolesByName.get(roleName)
}

export function validateRole (roleName) {
  if (!platformRolesByName.has(roleName) && !organizationRolesByName.has(roleName)) throw new Error(`Invalid role ${roleName}`)
}

/**
 * Gets the object describing a role from the role name.
 */
export function getRole (roleName) {
  validateRole(roleName)
  return platformRolesByName.get(roleName) || organizationRolesByName.get(roleName)
}

export function validatePlatformScope (scopeName) {
  if (!platformScopesSet.has(scopeName)) throw new Error(`Invalid platform scope ${scopeName}`)
}

export function validateAccountScope (scopeName) {
  if (!organizationScopesSet.has(scopeName)) throw new Error(`Invalid organization scope ${scopeName}`)
}

export function validateScope (scopeName) {
  if (!platformScopesSet.has(scopeName) && !organizationScopesSet.has(scopeName)) throw new Error(`Invalid scope ${scopeName}`)
}

/**
 * Gets the object describing a scope from the scope name.
 * @param {string} scopeName - Scope name
 * @returns {Object} scopeObject
  }
 */
export function getScope (scopeName) {
  validateScope(scopeName)
  // To gain a bit of performance
  return scopeName.split(':')[0] === 'platform' ? { isPlatform: true, ...platformScopes.find(scope => scope.name === scopeName) } : { isPlatform: false, ...organizationScopes.find(scope => scope.name === scopeName) }
}

//
// Utility Functions
//

export function expandRole (roleName) {
  const role = getRole(roleName)
  return [...new Set(
    [roleName, ...(role.inheritsFrom && role.inheritsFrom.length ? expandRoles(role.inheritsFrom) : [])]
  ).values()]
}

export function expandRoles (roleNames) {
  return [...new Set(
    [].concat(...roleNames.map(expandRole))
  ).values()]
}

export function roleToScopes (roleName) {
  const role = getRole(roleName)
  return [...new Set(
    [
      ...role.scopes,
      ...(role.inheritsFrom && role.inheritsFrom.length ? rolesToScopes(role.inheritsFrom) : [])
    ]
  ).values()]
}

export function rolesToScopes (roleNames) {
  return [...new Set(
    [].concat(...roleNames.map(roleToScopes))
  ).values()]
}

export function validateOrganizationId (organizationId) {
  if (ObjectId.isValid(organizationId)) return organizationId.toString()
  throw new Error(`Invalid Organization ID ${organizationId}`)
}

/**
 * Converts an organization scope string into the equivalent platform scope
 * string. For example, given the string 'organization:foo:bar', this will
 * return the string 'platform:allOrganizations:foo:bar'
 * @param {String} organizationScope - the organization scope to be converted
 */
export function getPlatformScopeFromOrganizationScope (organizationScope) {
  const strippedScope = organizationScope.split(/^organization:/).filter(a => a).join()
  return `platform:allOrganizations:${strippedScope}`
}

// export function roleToScopesWithDetails (roleName) {
//   const role = getRole(roleName)
//   return [...role.scopesWithDetails, ...(role.inheritsFrom && role.inheritsFrom.length ? rolesToScopes(role.inheritsFrom) : [])]
// }

// export function rolesToScopesWithDetails (roleNames) {
//   return [].concat(...roleNames.map(roleToScopes))
// }

/**
 * Permissions convenience class. Takes minimal description of permissions and
 * provides a permissions checking api.
 *
 * @property organizationId (string) the id of currently active organization
 * @property roleNames (Array) array of role names for platform and organization
 *                             roles active on this user.
 * @property scopeNames (Array) array of scope names for platform and organization
 *                              scopes active on this user. These scopes are beyond
 *                              the scopes granted by any roles.
 *
 * Semantically, any roles/scopes that are not platform level will be evaluated
 * in the context of the organization.
 *
 * When a user switches between organizations, the backend swaps out these two
 * attributes so they have the new appropriate values.
 *
 */
export class Permissions {
  /**
   * @user is a cognito jwt payload with the specific properties above.
   */
  constructor (organizationId, roleNames = [], scopeNames = []) {
    this.organizationId = validateOrganizationId(organizationId)
    this.baseRoles = roleNames.slice()
    this.baseRoles.map(validateRole)
    this.baseScopes = scopeNames.slice().filter(s => !s.startsWith('-'))
    this.baseScopes.map(validateScope)
    this.excludedScopes = scopeNames.slice().filter(s => s.startsWith('-')).map(s => s.slice(1))
    this.excludedScopes.map(validateScope)
    this.calculateExpandedRolesAndScopes()
  }

  /**
   * Returns true if the user is in the organization defined by
   * targetOrganizationId.
   * @param {String | ObjectId} targetOrganizationId - the organization to
   *   test the current permissions against.
   * @throws {Error} if the organization id is invalid or cannot be made valid
   */
  hasSameOrganization (targetOrganizationId) {
    if (!targetOrganizationId) throw new Error('You must provide a targetOrganizationId')
    targetOrganizationId = validateOrganizationId(targetOrganizationId)
    return targetOrganizationId === this.organizationId
  }

  /**
   *
   * Returns true if the user has a given role, otherwise returns false.
   * Just does a basic scope check, does not take into account any platform
   * equivalent scopes.
   * @param {String} roleName - name of the role.
   */
  hasRole (roleName) {
    return this.roleSet.has(roleName)
  }

  /**
   * Returns true if the user has a given role in the organization. Otherwise
   * returns false.
   * @param {String} roleName - the name of the role to check
   * @param {String | ObjectId} targetOrganizationId - a valid organization id
   * @throws {Error} if the organization id is invalid or cannot be made valid
   */
  hasRoleInOrganization (roleName, targetOrganizationId) {
    return this.hasSameOrganization(targetOrganizationId) && this.hasRole(roleName)
  }

  /**
   * Returns true if the user has any of the given roles.
   * @param {[String]} roleList - a list of names of roles to check
   */
  hasAnyRole (roleList) {
    return roleList.some(roleName => this.roleSet.has(roleName))
  }

  /**
   * Returns true if the user has any of the given roles in the organization.
   * Otherwise returns false.
   * @param {[String]} roleList - a list of names of roles to check
   * @param {String | ObjectId} targetOrganizationId - a valid organization id
   * @throws {Error} if the organization id is invalid or cannot be made valid
   */
  hasAnyRoleInOrganization (roleList, targetOrganizationId) {
    return this.hasSameOrganization(targetOrganizationId) && this.hasAnyRole(roleList)
  }

  /**
   * Returns true if the user has a given scope, otherwise returns false.
   * @param {String} scopeName - the name of the scope to check
   */
  hasScope (scopeName) {
    return this.scopeSet.has(scopeName)
  }

  /**
   * Returns true if the user has a given scope in the organization, otherwise
   * returns false. Returns true if user has a platform equivalent scope.
   * @param {String} scopeName - the name of the scope to check
   * @param {String | ObjectId} targetOrganizationId - a valid organization id
   * @throws {Error} if the organization id is invalid or cannot be made valid
   */
  hasScopeInOrganization (scopeName, targetOrganizationId) {
    const platformScope = getPlatformScopeFromOrganizationScope(scopeName)
    return this.hasScope(platformScope) || (this.hasScope(scopeName) && this.hasSameOrganization(targetOrganizationId))
  }

  /**
   * Returns true if any item in the list it receives is in organizationPlatformRestrictedFields
   * @param {[String]} listOfFields - a list of fields to update
   * @returns {Boolean}
   */
  hasPlatformRestrictedField (listOfFields) {
    return organizationPlatformRestrictedFields.some(element => listOfFields.includes(element))
  }

  /**
   * Validates if the update can be performed only by a platform user
   * or if it can be performed by a platform user and a common user too
   * @param {String | ObjectId} targetOrganizationId - a valid organization id
   * @param {[String]} listOfFields - list of fields to update
   * @returns {Boolean}
   */
  canMutateOrganizationFields (targetOrganizationId, listOfFields) {
    if (this.hasPlatformRestrictedField(listOfFields)) {
      // Only platform users with this scope will be able to perform the update
      return this.hasScope('platform:allOrganizations:settings:ui:write')
    }
    // Platform users with the scope AND users from that organization will be able to perform the update
    return this.hasScopeInOrganization('organization:settings:ui:write', targetOrganizationId)
  }

  /**
   * Returns true if the user has any of the given scopes, otherwise returns false.
   * @param {[String]} scopeList - a list of names of scopes to check
   */
  hasAnyScope (scopeList) {
    return scopeList.some(scopeName => this.hasScope(scopeName))
  }

  /**
   * Returns true if the user has any of the given scopes in the organization, otherwise
   * returns false. Returns true if user has a platform equivalent scope.
   * @param {[String]} scopeList - a list of names of scopes to check
   * @param {String | ObjectId} targetOrganizationId - a valid organization id
   * @throws {Error} if the organization id is invalid or cannot be made valid
   */
  hasAnyScopeInOrganization (scopeList, targetOrganizationId) {
    const platformScopeList = scopeList.map(getPlatformScopeFromOrganizationScope)
    return this.hasAnyScope(platformScopeList) || (this.hasAnyScope(scopeList) && this.hasSameOrganization(targetOrganizationId))
  }

  /**
   * Returns true if the user has all the given scopes, false otherwise.
   * @param {[String]} scopeList - a list of names of scopes to check
   */
  hasAllScopes (scopeList) {
    return scopeList.every(scopeName => this.hasScope(scopeName))
  }

  /**
   * Returns true if the user has all the given scopes in the organization, false otherwise.
   * Also returns true if user has equivalent platform scopes to the organization scopes in the list.
   * @param {[String]} scopeList - a list of names of scopes to check
   * @param {String | ObjectId} targetOrganizationId - a valid organization id
   * @throws {Error} if the organization id is invalid or cannot be made valid
   */
  hasAllScopesInOrganization (scopeList, targetOrganizationId) {
    const pairs = scopeList.map(s => [s, getPlatformScopeFromOrganizationScope(s)])
    const pairwiseOr = pairs.map(
      ([scope, platformScope]) => this.hasScope(scope) || this.hasScope(platformScope)
    )
    const allPairsTrue = pairwiseOr.every(a => a)
    return this.hasSameOrganization(targetOrganizationId) && allPairsTrue
  }

  /**
   * Returns true if the user is allowed to grant the given platform role.
   */
  canGrantPlatformRole (roleName) {
    return this.hasAnyScope([
      'platform:roles:grantAny',
      `platform:roles:${roleName}:grant`
    ])
  }

  /**
   * Returns true if the user is allowed to grant the given role in the given organization.
   */
  canGrantOrganizationRole (roleName, targetOrganizationId) {
    if (!targetOrganizationId) throw new Error('You must provide a targetOrganizationId')
    targetOrganizationId = validateOrganizationId(targetOrganizationId)
    // Some scopes let you grant organization roles without being part of the target organization
    if (this.hasAnyScope([
      'platform:allOrganizations:roles:grantAny',
      `platform:allOrganizations:roles:${roleName}:grant`
    ])) return true

    // All other scopes require that you be in the same target organization
    if (targetOrganizationId !== this.organizationId) return false

    return this.hasAnyScope([
      'organization:roles:grantAny',
      `organization:roles:${roleName}:grant`
    ])
  }

  /**
   * Returns true if the user is allowed to grant the given role. If the role
   * is an organization role, the second parameter is required, and the check
   * is performed with the given target organization. Otherwise, the second
   * parameter is ignored.
   */
  canGrantRole (roleName, targetOrganizationId) {
    targetOrganizationId = validateOrganizationId(targetOrganizationId)
    const role = getRole(roleName)
    if (role.isPlatform) return this.canGrantPlatformRole(roleName)
    else return this.canGrantOrganizationRole(roleName, targetOrganizationId)
  }

  /**
   * Returns true if the user is allowed to revoke the given platform role.
   */
  canRevokePlatformRole (roleName) {
    return this.hasAnyScope([
      'platform:roles:revokeAny',
      `platform:roles:${roleName}:revoke`
    ])
  }

  /**
   * Returns true if the user is allowed to revoke the given role in the given organization.
   */
  canRevokeOrganizationRole (roleName, targetOrganizationId) {
    if (!targetOrganizationId) throw new Error('You must provide a targetOrganizationId')
    targetOrganizationId = validateOrganizationId(targetOrganizationId)

    // Some scopes let you revoke organization roles without being part of the target organization
    if (this.hasAnyScope([
      'platform:allOrganizations:roles:revokeAny',
      `platform:allOrganizations:roles:${roleName}:revoke`
    ])) return true

    // All other scopes require that you be in the same target organization
    if (targetOrganizationId !== this.organizationId) return false
    return this.hasAnyScope([
      'organization:roles:revokeAny',
      `organization:roles:${roleName}:revoke`
    ])
  }

  /**
   * Returns true if the user is allowed to revoke the given role. If the role
   * is an organization role, the second parameter is required, and the check
   * is performed with the given target organization. Otherwise, the second
   * parameter is ignored.
   */
  canRevokeRole (roleName, targetOrganizationId) {
    targetOrganizationId = validateOrganizationId(targetOrganizationId)
    const role = getRole(roleName)
    if (role.isPlatform) return this.canRevokePlatformRole(roleName)
    else return this.canRevokeOrganizationRole(roleName, targetOrganizationId)
  }

  /**
   * Returns true if the user is allowed to grant the given platform scope.
   */
  canGrantPlatformScope (scopeName) {
    return this.hasAnyScope([
      'platform:scopes:grantAny',
      `platform:scopes:${scopeName}:grant`
    ])
  }

  /**
   * Returns true if the user is allowed to revoke the given platform role.
   */
  canRevokePlatformScope (scopeName) {
    return this.hasAnyScope([
      'platform:scopes:revokeAny',
      `platform:scopes:${scopeName}:revoke`
    ])
  }

  /**
 * Returns true if the user is allowed to grant the given organization scope.
 */
  canGrantOrganizationScopeGroup (scopeGroupName, targetOrganizationId) {
    if (!targetOrganizationId) throw new Error('You must provide a targetOrganizationId')
    targetOrganizationId = validateOrganizationId(targetOrganizationId)

    // Some scopes let you grant organization scopes without being part of the target organization
    if (this.hasAnyScope([
      'platform:allOrganizations:scopes:grantAny'
    ])) return true

    return this.hasAnyScope([
      `organization:scopes:group:${scopeGroupName}:grant`,
      'organization:scopes:grantAny'
    ])
  }

  /**
   * Returns true if the user is allowed to revoke the given organization scope.
   */
  canRevokeOrganizationScopeGroup (scopeGroupName, targetOrganizationId) {
    // Some scopes let you grant organization scopes without being part of the target organization
    if (this.hasAnyScope([
      'platform:allOrganizations:scopes:revokeAny'
    ])) return true

    return this.hasAnyScope([
      `organization:scopes:group:${scopeGroupName}:revoke`,
      'organization:scopes:revokeAny'
    ])
  }

  /**
   * Returns true if the user is allowed to grant scopes of a group of scopes. If the scope
   * is an organization scope, the second parameter is required, and the check
   * is performed with the given target organization. Otherwise, the second
   * parameter is ignored.
   */
  canGrantScope (scopeName, targetOrganizationId) {
    targetOrganizationId = validateOrganizationId(targetOrganizationId)
    const scope = getScope(scopeName)
    if (scope.isPlatform) return this.canGrantPlatformScope(scopeName)
    else return this.canGrantOrganizationScopeGroup(scope.group, targetOrganizationId)
  }

  /**
   * Returns true if the user is allowed to revoke the given scope. If the scope
   * is an organization scope, the second parameter is required, and the check
   * is performed with the given target organization. Otherwise, the second
   * parameter is ignored.
   */
  canRevokeScope (scopeName, targetOrganizationId) {
    targetOrganizationId = validateOrganizationId(targetOrganizationId)
    const scope = getScope(scopeName)
    if (scope.isPlatform) return this.canRevokePlatformScope(scopeName)
    else return this.canRevokeOrganizationScopeGroup(scope.group, targetOrganizationId)
  }

  /**
   * Returns true if the user is allowed to suspend the given user.
   */
  canSuspendUser (otherUser) {
    // This scope lets you suspend any user roles without being part of the user's organization
    if (this.hasScope('platform:allOrganizations:users:suspend')) return true

    // This scope requires that you be in the same organization
    if (otherUser.organizationId.toString() !== this.organizationId) return false
    return this.hasScope('organization:users:suspend')
  }

  /**
   * Adds a role. Does not modify user object but subsequent calls to this
   * object's hasRole() and getCognitoAttributes() reflect the new role.
   */
  grantRole (roleName) {
    validateRole(roleName)
    this.baseRoles.push(roleName)

    // Housekeeping: If any of the scopes on this new role being granted would
    // come into conflict with an existing excluded scope, let the new role
    // win by dropping that excluded scope
    const expandedRoles = expandRole(roleName)
    const roleScopeSet = new Set(rolesToScopes(expandedRoles))
    this.excludedScopes = this.excludedScopes.filter(excludedScope => !roleScopeSet.has(excludedScope))

    // Then go ahead and recalculate as normal
    this.calculateExpandedRolesAndScopes()
  }

  /**
   * Removes a role. Does not modify user object but subsequent calls to this
   * object's hasRole() and getCognitoAttributes() reflect the missing role.
   */
  revokeRole (roleName) {
    validateRole(roleName)
    const roleIndex = this.baseRoles.indexOf(roleName)
    if (roleIndex !== -1) this.baseRoles.splice(roleIndex, 1)

    // Housekeeping: Now that we've dropped the role, drop any excluded scopes
    // that are no longer counteracting implicit scopes granted by the
    // remaining roles
    const expandedRoles = expandRoles(this.baseRoles)
    const roleScopeSet = new Set(rolesToScopes(expandedRoles))
    this.excludedScopes = this.excludedScopes.filter(excludedScope => roleScopeSet.has(excludedScope))

    // Then go ahead and recalculate as normal
    this.calculateExpandedRolesAndScopes()
  }

  /**
   * Adds a scope. Does not modify user object but subsequent calls to this
   * object's hasScope() and getCognitoAttributes() reflect the new scope.
   */
  grantScope (scopeName) {
    validateScope(scopeName)
    // If this scope is disallowed by excludedScopes, correct that
    const excludedScopeIndex = this.excludedScopes.indexOf(scopeName)
    if (excludedScopeIndex !== -1) {
      this.excludedScopes.splice(excludedScopeIndex, 1)
      this.calculateExpandedRolesAndScopes()
    }
    // Then, only allow explicit scope grant if we don't have the scope via a role
    if (!this.hasScope(scopeName)) {
      this.baseScopes.push(scopeName)
      this.calculateExpandedRolesAndScopes()
    }
  }

  /**
   * Removes a scope. Does not modify user object but subsequent calls to this
   * object's hasScope() and getCognitoAttributes() reflect the missing
   * scope.
   */
  revokeScope (scopeName) {
    validateScope(scopeName)
    const baseScopeIndex = this.baseScopes.indexOf(scopeName)
    // If a scope is explicitly added via base scopes, remove it
    if (baseScopeIndex !== -1) {
      this.baseScopes.splice(baseScopeIndex, 1)
      this.calculateExpandedRolesAndScopes()
    }
    // If we still have this scope after removing, add it to excluded scopes
    if (this.hasScope(scopeName)) {
      this.excludedScopes.push(scopeName)
      this.calculateExpandedRolesAndScopes()
    }
  }

  /**
   * Returns an array of attributes in a format suitable for passing to the
   * aws-sdk CognitoIdentityServiceProvider function adminUpdateUserAttributes()
   */
  getCognitoAttributes () {
    return [
      { Name: 'custom:roles', Value: this.baseRoles.join(',') },
      { Name: 'custom:scopes', Value: [].concat(this.baseScopes, this.excludedScopes.map(s => `-${s}`)).join(',') }
    ]
  }

  /**
   * Returns an array of attributes suitable to passing to the database
   */
  getMongoAttributes () {
    return {
      roles: this.baseRoles.slice(),
      scopes: this.baseScopes.slice().concat(this.excludedScopes.map(s => `-${s}`))
    }
  }

  /**
   * Internal function to recalculate after changing base roles or scopes.
   */
  calculateExpandedRolesAndScopes () {
    // Expanded Roles are defiend as all those explicit + all those inherited
    this.expandedRoles = expandRoles(this.baseRoles)

    // Exapnded scopes are defined as scopes granted by roles plus scopes
    // granted explicitly, minus excluded scopes
    const roleScopes = rolesToScopes(this.expandedRoles) // scopes granted by roles
    const roleScopeSet = new Set(roleScopes)
    const excludedScopeSet = new Set(this.excludedScopes)
    const uniqueBaseScopes = this.baseScopes.filter(baseScope => !roleScopeSet.has(baseScope))
    this.expandedScopes = roleScopes
      .concat(uniqueBaseScopes)
      .filter(s => !excludedScopeSet.has(s)) // manually remove excluded scopes

    // These sets are the final results for fast membership tests
    this.roleSet = new Set(this.expandedRoles)
    this.scopeSet = new Set(this.expandedScopes)

    // Deduplicate roles
    this.baseRoles = [...(new Set(this.baseRoles)).values()]
    // Deduplicate scopes: if any base scopes are already provided by a role,
    // drop them in favor of the role's version
    this.baseScopes = [...(new Set(
      this.baseScopes.filter(baseScope => !roleScopeSet.has(baseScope))
    )).values()
    ]
    // Deduplicate excludedScopes
    this.excludedScopes = [...(new Set(this.excludedScopes)).values()]
  }

  /**
   * Returns a list of all expanded scopes held by this user.
   */
  getAllScopes () {
    return [...this.scopeSet.values()]
  }

  /**
   * Returns the highest Role of current user
   */
  getHighestRole () {
    return this.baseRoles.reduce((accumRoleObject, roleName) => {
      const role = getRole(roleName)
      // Smaller RoleName, higher Hierarchy
      const higherRole = accumRoleObject.numberHierarchy > role.numberHierarchy ? role : accumRoleObject
      return higherRole
    }, getRole('User'))
  }

  /**
   * Calculates the difference between the current permissions and the given
   * permissions object.
   *
   * The convention for the return value names is A.calculateDifference(B)
   *
   * The return values take the form of an object of lists:
   * {
   *   rolesShared: [...], rolesA: [...], rolesB: [...],
   *   scopesShared: [...], scopesA: [...], scopesB: [...]
   * }
   * Where rolesA are the roles that A has the B does not, etc.
   */
  calculateDifference (B) {
    return {
      rolesShared: [...this.roleSet.values()].filter(role => B.roleSet.has(role)),
      rolesA: [...this.roleSet.values()].filter(role => !B.roleSet.has(role)),
      rolesB: [...B.roleSet.values()].filter(role => !this.hasRole(role)),
      scopesShared: [...this.scopeSet.values()].filter(scope => B.scopeSet.has(scope)),
      scopesA: [...this.scopeSet.values()].filter(scope => !B.scopeSet.has(scope)),
      scopesB: [...B.scopeSet.values()].filter(scope => !this.hasScope(scope))
    }
  }

  /**
   * For a given scope, returns an object of how the user has gotten that scope.
   */
  scopeInfo (scopeName) {
    const userHasScope = this.hasScope(scopeName)
    const explicitlyGranted = this.baseScopes.includes(scopeName)
    const explicitlyExcluded = this.excludedScopes.includes(scopeName)
    const grantingRoles = [...this.roleSet.values()].filter(roleName => {
      const role = getRole(roleName)
      return role.scopes.includes(scopeName)
    })
    return {
      userHasScope,
      explicitlyGranted,
      explicitlyExcluded,
      grantingRoles
    }
  }

  hasPlatformRole () {
    const platformRoleNames = platformRoles.map(({ name }) => name)
    return this.hasAnyRole(platformRoleNames)
  }
}
