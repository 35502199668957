import React from 'react'
import { Pill } from '../../components'

/**
 * Show a list of suggestions to be filtered
 * @param {{
 * suggestions: [{ key: string, opr: string, val: string }]
 * activeFilters: [{ key: string, opr: string, val: string }]
 * handleAddFilter: function
 * }} props
 * @returns
 */
export const FilterSuggestions = ({ suggestions, handleAddFilter, activeFilters }) => {
  if (!suggestions || !suggestions.length) return null

  const applyFilter = (event) => {
    handleAddFilter(event)
  }

  const showDisabled = currentKey => (activeFilters || []).some(({ key }) => key === currentKey)

  return (
    <div className='d-flex flex-wrap align-items-start mt-1 mb-3'>
      {suggestions?.map(({ key, opr, val }) => (
        <Pill
          addable
          rounded
          key={key}
          className='mr-2 mb-2'
          disabled={showDisabled(key)}
          onAdd={() => applyFilter({ key, opr, val })}
        >
          {key}: {val}
        </Pill>)
      )}
    </div>
  )
}
