import React from 'react'
import { Col, Label, Input, FormGroup, FormFeedback } from 'reactstrap'
import { Field } from 'react-final-form'

const CheckboxType = ({ data }) => {
  const { label, fieldName, _comment } = data
  return (
    <>
      <Col sm={7}>
        <Label for={fieldName}>{label}</Label>
      </Col>
      <Col sm={5}>
        <Field
          name={fieldName}
          type='checkbox'
          validate={terms => !terms && `You must accept the ${label} to authorize this carrier`}
        >
          {({ input, meta }) => (
            <FormGroup>
              <Label
                check
                for={fieldName}
                className='custom-control custom-checkbox mb-2'
                style={{ cursor: 'pointer' }}
              >
                <Input
                  {...input}
                  id={fieldName}
                  type='checkbox'
                  className='custom-control-input'

                />
                <span className='custom-control-label'>
                  {_comment
                    ? <>I accept the <a target='_blank' rel='noopener noreferrer' href={_comment}>{label}</a>.</>
                    : 'I\'m agree.'}
                </span>
              </Label>
              {meta.error && <FormFeedback className='d-block mt-n2 mb-3'>{meta.error}</FormFeedback>}

            </FormGroup>
          )}
        </Field>
      </Col>
    </>
  )
}

export { CheckboxType }
