import React from 'react'
import Select from 'react-select'
import { Label, Spinner } from 'reactstrap'

const formatOptions = (options, type) => options?.map(i => ({ value: i, label: i, type }))

/**
 * Select for initial fitment search
 * @param {{
 * id: string
 * label: string
 * placeholder: string
 * autoFocus: boolean
 * loading: boolean
 * disabled: boolean
 * onChange: function
 * value: {
 *  type: string
 *  label: string|number
 *  value: string|number
 * }
 * }} props
 * @returns {React.ReactHTML}
 */
export const FitSelect = ({ id, label, options, placeholder, autoFocus, loading, value, onChange, disabled }) => {
  return (
    <div className='select-wrapper'>
      <Label tag='legend' className='mb-1'>{label}</Label>
      <Select
        value={value}
        clearable={false}
        onChange={onChange}
        autoFocus={autoFocus}
        disabled={disabled || loading}
        options={formatOptions(options, id)}
        style={{ cursor: disabled ? 'auto' : 'pointer' }}
        placeholder={loading ? <Spinner size='sm' className='spinner' /> : placeholder}
      />
    </div>
  )
}
