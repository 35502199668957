import React, { useState } from 'react'
import { Input } from 'reactstrap'
import { isValidURL } from 'suredone-common'

export const InputType = ({ data }) => {
  const { item, parentCallback } = data || {}
  const { value: { value }, name } = item || {}
  const [valueHasChanged, setValueHasChanged] = useState()
  const [inputValue, setInputValue] = useState(value && value[0])

  const handleChange = (event) => {
    setInputValue(event)
    setValueHasChanged(true)
    parentCallback({ ...event, name: event }, item)
  }

  return (
    <Input
      type='text'
      value={inputValue}
      onChange={(e) => handleChange(e.target.value)}
      valid={name === 'store_url' && valueHasChanged && isValidURL(inputValue)}
      invalid={name === 'store_url' && valueHasChanged && !isValidURL(inputValue)}
    />
  )
}
