import { useEffect } from 'react'
import TagManager from 'react-gtm-module'

const getGTMEnv = (host) => {
  switch (host) {
    case 'app.suredone.com': return { auth: 'ws2U6nsPi696IzzgnzhWow', preview: 'env-1' }
    case 'app-stage.suredone.com':
    case 'app-demo.suredone.com':
    case 'app-qa.suredone.com': return { auth: 'RRVKBlOwiI3bJzEpP1d-Tg', preview: 'env-4' }
    case 'app-beta.suredone.com':
    default: return { auth: 'npFUuM3MjJ0McLDoEjRv6A', preview: 'env-3' }
  }
}

export const useGTM = () => {
  const gtmId = 'GTM-5BHZSB7'
  const host = window.location.host
  const tagManagerArgs = {
    gtmId,
    ...getGTMEnv(host)
  }

  // Initializes the GTM depending on the environment once the component is already mounted
  useEffect(() => {
    TagManager.initialize(tagManagerArgs)
    // eslint-disable-next-line
  }, [])

  return {}
}
