import gql from 'graphql-tag'

export const ORGANIZATION_UPDATE = gql`
mutation ($id: ID!, $input: OrganizationInput) {
  UpdateOrganization(id: $id, input: $input) {
    id
    legacyAccountId
    name
    slug
    email
    phone
    timezone
    isTrialing
    stripePrepay
    address {
      street1
      street2
      city
      stateProvince
      postalCode
      country
    }
  }
}
`

export const GET_ORGANIZATION_DETAILS = gql`
  query OrganizationDetails($id: ID!) {
    OrganizationDetails(id: $id) {
      ... on Organization {
        id
        name
        # apiToken
        bio
        slug
        timezone
        createDate
        legacyAccountId
        email
        phone
        fileUrl
        isTrialing
        stripeTrialEnd
        stripePrepay
        stripeId
        billingPeriodEnd
        billingPeriodStart
        demoWall
        showGMV
        showUpdates
        downgradeEnabled
        deactivatedWall
        demographics
        address {
          street1
          street2
          city
          stateProvince
          postalCode
          country
        }
        isLegacyCustomer
        checkoutLink
      }
    }
  }
`

export const GET_ORG_ID_FROM_LEGACY_ACCOUNT_ID = gql`
  query OrgIdFromLegacyAccountId($legacyAccountId: Int!) {
    OrgIdFromLegacyAccountId(legacyAccountId: $legacyAccountId)
  }
`

export const GET_API_TOKEN = gql`
  query OrganizationDetails($id: ID!) {
    OrganizationDetails(id: $id) {
      ... on Organization {
        id
        slug
        apiToken
      }
    }
  }
`
