import React from 'react'
import { useParams } from 'react-router'
import { Card, CardBody } from 'reactstrap'
import { useQuery } from '@apollo/react-hooks'
import { GET_LOG_DETAIL } from './commonQueries'
import { people } from '../../img/illustrations'
import { useStores } from '../../stores/RootStore'
import { CodeBlock, CopyToClipboard, EmptyState, Loadable, Page, RelatedLogs } from '../../components'
import './Logs.scss'

const maxCharacters = 4000

function truncate (thing, length = 256) {
  if (typeof thing === 'string') {
    return thing.slice(0, length) + (thing.length > length ? '... (truncated)' : '')
  } else if (Array.isArray(thing)) {
    return thing.map(t => truncate(t, length))
  } else if (thing && typeof thing === 'object') {
    const newThing = {}
    Object.keys(thing).forEach(key => {
      newThing[key] = truncate(thing[key], length)
    })
    return newThing
  } else {
    return thing
  }
}

export const SpecificLog = ({ targetOrgData }) => {
  const targetOrganizationId = targetOrgData?.id
  const { noLogs } = people
  const { logIdentifier } = useParams()
  const { stores: { auth } } = useStores()
  const { user } = auth || {}
  const { legacyAccountId } = user || {}

  const { loading, error, data } = useQuery(GET_LOG_DETAIL, {
    variables: {
      userId: parseInt(legacyAccountId),
      targetOrganizationId,
      filter: {
        filterType: 'AND',
        filters: [
          {
            opr: '=',
            key: 'id',
            val: logIdentifier // mongo log ID
          }
        ]
      }
    }
  })
  const { UserLogs } = data || {}
  const { logs, count: logsCount } = UserLogs || {}
  const logData = logs?.[0] || {}
  const logKeys = logData && Object.keys(logData)
  const isEmpty = logKeys?.length < 1
  const formatted = logKeys?.filter(i => i !== '__typename')?.map(k => ({ key: k, value: logData[k] }))

  const auxFormatter = (key, val) => {
    // Handles fieldData large objects
    if (key === 'fieldData' && val) {
      const fieldDataAsString = JSON.stringify(val)
      if (fieldDataAsString?.length >= maxCharacters) {
        return (
          <p style={{ background: 'rgb(43, 43, 43)', color: 'rgb(248, 248, 242)', padding: '0.5em', overflow: 'auto', maxHeight: '400px' }}>
            {fieldDataAsString}
          </p>
        )
      }
    }

    // Handles objects
    if (val && typeof val === 'object' && !Array.isArray(val)) {
      return <CodeBlock language='json'>{`${JSON.stringify(truncate(val || {}), null, 2)}`}</CodeBlock>
    }

    // Handles arrays
    if (val && Array.isArray(val)) {
      return val.map((item, idx) => <CodeBlock language='json' key={idx} className='json mb-0'>{`${JSON.stringify(truncate(item || {}), null, 2)}`}</CodeBlock>)
    }

    // Handles request and response
    if (key === 'request' || key === 'response') {
      if (val && Array.isArray(val)) {
        return val.map((item, idx) => <CodeBlock language='json' key={idx} className='json mb-0'>{`${JSON.stringify(truncate(item || {}), null, 2)}`}</CodeBlock>)
      }

      return (
        <CodeBlock language='json' className='limit-height'>
          {`${val}`}
        </CodeBlock>
      )
    }

    return <span>{val}</span>
  }

  const copyToClipboard = val => {
    if (val && typeof val === 'object' && !Array.isArray(val)) return `${JSON.stringify(val || {}, null, 2)}`
    if (val && Array.isArray(val)) return val.map(item => `${JSON.stringify(item || {}, null, 2)}`)
    return `${val}`
  }

  return (
    <Page
      title={'Log ' + (logData?.identifier || '')}
      className='specific-log'
      back={{ to: `/logs/${targetOrganizationId}`, name: 'logs' }}
    >
      <Card>
        <CardBody>
          <Loadable
            inline
            error={error}
            loading={loading}
            content={
              <EmptyState
                heading='No log'
                isEmpty={isEmpty}
                customImage={noLogs}
                content='It seems that the log identifier is incorrect, please check it and try again.'
              >
                {formatted?.map(({ key, value }, idx) =>
                  <div key={idx} className='d-flex flex-nowrap w-100 m-0 py-2 border-bottom'>
                    <div className='col-key'>
                      <b className='d-flex'>{key}:</b>
                    </div>
                    <div className='col-data'>
                      {auxFormatter(key, value)}
                    </div>
                    <div className='col-copy'>
                      <CopyToClipboard
                        size='sm'
                        className='my-1'
                        compact={false}
                        content='Copy raw value'
                        value={copyToClipboard(value)}
                      />
                    </div>
                  </div>
                )}
              </EmptyState>
            }
          />
        </CardBody>
      </Card>

      {(!loading && logsCount && (
        <Card>
          <CardBody>
            <RelatedLogs
              parentLog={logData}
              targetOrgData={targetOrgData}
            />
          </CardBody>
        </Card>
      )) || null}
    </Page>
  )
}
