import gql from 'graphql-tag'

export const GET_AUTH_STATUS = gql`
  query ChannelInstanceWizard($slug: String!, $instanceNumber: Int!) {
    ChannelInstance(slug: $slug, instanceNumber: $instanceNumber) {
      id
      authStatus
    }
  }
`

export const AVAILABLE_CHANNELS = gql`
  query ChannelData {
    AvailableChannels {
      id
      slug
      name
      logoUrl
      description
      readme
    }
    ChannelInstances {
      id
      channel {
        id
        slug
        name
        logoUrl
        description
        readme
      }
      instance
      enabled
      customLabel
      authStatus
      dateOfTokenExp
    }
  }
`

export const GET_CHANNEL_AUTH_URL = gql`
  query GetAuthUrl( $slug: String!, $instanceNumber: Int!, $formParams: FormParams) {
    ChannelInstance(instanceNumber: $instanceNumber, slug: $slug) {
      id
      authUrl(formParams: $formParams) {
        url
        commerceManagers {
          page_id
          page_name
          page_category
          cms_id
          order_management_apps {
            id
            category
            link
            name
            namespace
          }
        }
      }
    }
  }
`

export const GET_CHANNEL = gql`
  query GetChannel ($slug: String!) {
    Channel (slug: $slug) {
      id
      slug
      name
      logoUrl
      description
      readme
      readme_images {
        src
        alt
      }
      settings {
        name
        label
        selectOptions {
          name
          value
          label
          description
        }
        description
        groupName
        type
      }
    }
    ChannelInstances {
      id
      channel {
        id
        slug
      }
      instance
    }
  }
`

export const GET_ACTION_URL = gql`
  query GetActionURL ($slug: String!, $instanceNumber: Int!, $actionName: String) {
    ChannelInstance (slug: $slug, instanceNumber: $instanceNumber) {
      id
      actionUrl(actionName: $actionName)
    }
  }
`

export const GET_CHANNEL_INSTANCE = gql`
  query GetChannel ($slug: String!, $instanceNumber: Int!) {
    Channel (slug: $slug) {
      id
      slug
      name
      description
      readme
      authSteps {
        name
        stepUrl
        action
        nextStep
        linkInstructions {
          url
          label
          openInNewTab
        }
        instructions {
          title
          stepInstructions {
            title
            description
            imgSrc
            imgAlt
          }
        }
        form {
          name
          label
        }
        userInputNeeded {
          name
          hint
          type
          fieldToGetOptions
          label
          required
          modifiers
        }
      }
    }
    ChannelInstance(slug: $slug, instanceNumber: $instanceNumber) {
      id
      enabled
      authStatus
    }
  }
`

export const GET_CHANNEL_STOCK_FULFILLMENT = gql`
  query GetChannelStockFulfillment ($slug: String!, $instanceNumber: Int!) {
    ChannelInstance(instanceNumber: $instanceNumber, slug: $slug) {
      id
      authStatus
      stockMappings {
        stockFields
        locations {
          nodeID
          label
        }
        stockValues {
          stockField
          value
        }
      }
    }
  }
`

export const GET_ITEM_SPECIFICS = gql`
  query GetItemSpecifics ($slug: String!, $instanceNumber: Int!) {
    ChannelInstance(instanceNumber: $instanceNumber, slug: $slug) {
      id
      itemspecific {
        description
        isInline
        label
        name
      }
    }
  }
`

export const GET_MAPPINGS_SPECIFIC = gql`
  query GetMappingsSpecific ($slug: String!, $instanceNumber: Int!) {
    ChannelInstance(instanceNumber: $instanceNumber, slug: $slug) {
      id
      mappingsSpecific {
        description
        isInline
        label
        name
      }
    }
  }
`

export const GET_CHANNEL_SETTINGS = gql`
  query GetChannelSettings ($slug: String!, $instanceNumber: Int!, $organizationId: ID!) {
    # Default settings (with default values)
    Channel (slug: $slug) {
      id
      settings {
        name
        value
        label
        groupIndex
        selectOptions {
          name
          value
          label
          description
          mappingValues
        }
        description
        help_text
        groupName
        type
        advanced
      }
    }
    # Instance settings values
    ChannelInstance (slug: $slug, instanceNumber: $instanceNumber) {
      id
      settings {
        name
        value
        selectOptions{
          name
          value
          label
          description
        }
        type
      }
    }
    # Selectable options of each mappable setting
    OrganizationDetails(id: $organizationId) {
      id
      mappableProductFields {
        name
        label
        description
      }
    }
  }
`

export const UPDATE_CHANNEL_SETTINGS = gql`
mutation updateChannelInstanceSettings($slug: String!, $instanceNumber: Int!, $settings: [ChannelSettingValueInput]) {
  updateChannelInstanceSettings(instanceNumber: $instanceNumber, slug: $slug, settings: $settings) {
    id
    settings {
      name
      selectOptions {
        description
        label
        name
        value
      }
      type
      value
    }
  }
}
`

export const UPDATE_STOCK_FULFILLMENT = gql`
  mutation updateStockMappings ($slug: String!, $instanceNumber: Int!, $stockMappings: [StockMappingInput!]) {
    updateChannelInstanceStockMappings (slug: $slug, instanceNumber: $instanceNumber, stockMappings: $stockMappings) {
        id
        stockMappings {
          stockValues {
            stockField
            value
          }
        }
    }
  }
`

export const UPDATE_ITEM_SPECIFICS = gql`
  mutation UpdateEbayItemSpecifics($instanceNumber: Int!, $updateObject: itemSpecificsInput!) {
    updateEbayItemSpecifics (instanceNumber: $instanceNumber, updateObject: $updateObject ) {
      itemspecific {
        description
        isInline
        label
        name
      }
    }
  }
`
export const SYNC_CHANNELS = gql`
  query SyncChannels ($slug: String!, $instanceNumber: Int!) {
    ChannelInstance (slug: $slug, instanceNumber: $instanceNumber) {
      id
      syncChannels
    }
  }
`

export const REFRESH_FIELD = gql`
  query RefreshField ($slug: String!, $instanceNumber: Int!, $field: String) {
    ChannelInstance (slug: $slug, instanceNumber: $instanceNumber) {
      id
      refreshField(field: $field)
    }
  }
`

export const GET_MANAGED_PAYMENTS = gql`
  query GetManagedPayments ($slug: String!, $instanceNumber: Int!) {
    ChannelInstance (slug: $slug, instanceNumber: $instanceNumber) {
      id
      managedPayments
    }
  }
`

export const CHANGE_CHANNEL_STATUS = gql`
  mutation changeChannelStatus ($slug: String!, $instanceNumber: Int!, $changeTo: Boolean!) {
    changeChannelInstanceStatus (slug: $slug, instanceNumber: $instanceNumber, changeTo: $changeTo) {
      id
      enabled
    }
  }
`

export const UNINSTALL_CHANNEL = gql`
  query channelUninstall ($slug: String!, $queryParams: JSON!) {
    ChannelUninstall (slug: $slug, queryParams: $queryParams) {
      id
    }
  }
`

export const GET_CHANNEL_FIELDS = gql`
  query GetChannelInfo ($slug: String!, $organizationId: ID!, $instanceNumber: Int!) {
    # This is the number of options to select for each row
    OrganizationDetails(id: $organizationId) {
      id
      mappableProductFields {
        name
        label
        description
      }
    }
    # This is the number of rows to be mapped
    Channel (slug: $slug) {
      id
      mappableProductFields (instanceNumber: $instanceNumber) {
        name
        label
        description
      }
    }
    # It includes the value chosen for each row. There can be rows without selection
    ChannelInstance(instanceNumber: $instanceNumber, slug: $slug) {
      id
      fieldMappings {
        channelFieldName
        organizationFieldName
      }
    }
  }
`

export const UPDATE_CHANNEL_FIELDS = gql`
  mutation updateFieldMappings ($slug: String!, $instanceNumber: Int!, $fieldMappings: [FieldMappingInput!]) {
    updateChannelInstanceFieldMappings(
      slug: $slug,
      instanceNumber: $instanceNumber,
      fieldMappings: $fieldMappings) {
        id
        fieldMappings {
          channelFieldName
          organizationFieldName
        }
    }
  }
`

export const GET_CHANNEL_REPORT = gql`
  query GetChannelReport ($reportType: ReportType!, $slug: String!, $instanceNumber: Int!) {
    GetChannelReport (reportType: $reportType, slug: $slug, instanceNumber: $instanceNumber) {
      status
      fileName
      startDate
      endDate
      fileUrl
    }
  }
`

export const DELETE_CHANNEL_INSTANCE = gql`
  mutation deleteChannelInstance ($slug: String!, $instanceNumber: Int!) {
    deleteChannelInstance (slug: $slug, instanceNumber: $instanceNumber)
  }
`
