import { useState } from 'react'
import { useStores } from '../stores/RootStore'

const useMFA = () => {
  const { stores: { auth } } = useStores()
  const [loading, setLoading] = useState({
    verifying: false,
    associating: false,
    updating: false,
    sending: false
  })
  const [errors, setError] = useState({
    verifyingError: false,
    associatingError: false,
    updatingError: false,
    sendingError: false
  })
  const [success, setSuccess] = useState({
    verifyingSuccess: false,
    associatingSuccess: false,
    updatingSuccess: false,
    sendingSuccess: false
  })

  const associateSoftwareToken = async () => {
    setLoading({ ...loading, associating: true })
    try {
      setError({ ...errors, associatingError: false })
      const result = await auth.associateSoftwareToken()
      setLoading({ ...loading, associating: false })
      setSuccess({ ...success, associatingSuccess: true })
      return result
    } catch (error) {
      setError({ ...errors, associatingError: error })
      setLoading({ ...loading, associating: false })
      setSuccess({ ...success, associatingSuccess: false })
    }
  }

  const updatingMFA = async (enabled) => {
    setLoading({ ...loading, updating: true })
    try {
      setError({ ...errors, updatingError: false })
      const result = await auth.updateMFASetting(enabled)
      setLoading({ ...loading, updating: false })
      setSuccess({ ...success, updatingSuccess: true })
      return result
    } catch (error) {
      setError({ ...errors, updatingError: error })
      setLoading({ ...loading, updating: false })
      setSuccess({ ...success, updatingSuccess: false })
    }
  }

  const verifySoftwareToken = async (code) => {
    setLoading({ ...loading, verifying: true })
    try {
      setError({ ...errors, verifyingError: false })
      const result = await auth.verifySoftwareToken(code)
      setLoading({ ...loading, verifying: false })
      setSuccess({ ...success, verifyingSuccess: true })
      return result
    } catch (error) {
      setError({ ...errors, verifyingError: error })
      setLoading({ ...loading, verifying: false })
      setSuccess({ ...success, verifyingSuccess: false })
    }
  }

  const sendMfACode = async (code) => {
    setLoading({ ...loading, sending: true })
    try {
      setError({ ...errors, sendingError: false })
      const result = await auth.sendMFACode2(code)
      setLoading({ ...loading, sending: false })
      setSuccess({ ...success, sendingSuccess: true })
      return result
    } catch (error) {
      setError({ ...errors, sendingError: error })
      setLoading({ ...loading, sending: false })
      setSuccess({ ...success, sendingSuccess: false })
    }
  }

  return {
    associateSoftwareToken,
    updatingMFA,
    verifySoftwareToken,
    sendMfACode,
    loading,
    errors,
    success
  }
}

export default useMFA
