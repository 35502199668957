import React from 'react'
import copy from 'copy-to-clipboard'
import { Button, UncontrolledPopover, PopoverBody } from 'reactstrap'
import { FaCopy } from 'react-icons/fa'

/**
 * @param {{value: String, compact: Boolean, className: String, size: 'sm'|'md'|'lg'}} props
 * @returns
 */
export default function CopyToClipboard ({ value, compact = true, className = '', size = 'md', content, placement = 'right' }) {
  return (
    <>
      <Button
        outline
        size={size}
        id='PopoverFocus'
        alt='Copy to Clipboard'
        onClick={() => copy(value)}
        className={`copy-clipboard ${className}`}
      >
        <FaCopy className='mr-0' />{compact ? null : (content || ' Copy to Clipboard')}
      </Button>
      <UncontrolledPopover trigger='focus' placement={placement} target='PopoverFocus'>
        <PopoverBody>Copied!</PopoverBody>
      </UncontrolledPopover>
    </>
  )
}
export { CopyToClipboard }
