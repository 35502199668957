import React from 'react'
import moment from 'moment'
import { Magnifier } from './'
import { Badge } from 'reactstrap'
import { useParams } from 'react-router-dom'
import { FaExternalLinkAlt as ExternalLink } from 'react-icons/fa'

const todayBegin = `${moment().format('YYYY-MM-DD')}T00:00:00+00:00`
const todayEnd = `${moment().format('YYYY-MM-DD')}T23:59:59+00:00`

// All columns ids, based on the backend queried data
export const allColumnsIds = ['identifier', 'result', 'action', 'context', 'createdAt', 'id', 'type', 'role', 'retry', 'jobId', 'userId', 'method', 's3Path', 'release', 'message', 'warnings', 'instance', 'request', 'response', 'timestamp', 'fieldData', 'accountId', 'channelId', 'operation', 'requestId', 'serverName', 'integration', 'releaseType', 'truncatedFields']

// Columns that we don't whant to show on the UI. Only platform users will see 'serverName' and 'releaseType' columns
export const columnsToDelete = (isPlatformUser) => {
  const colsToDelete = ['s3Path', '__typename']
  if (!isPlatformUser) colsToDelete.push('serverName', 'releaseType')
  return colsToDelete
}

// Initial columns to show on the UI
export const initialColumnsShown = ['identifier', 'result', 'warnings', 'action', 'context', 'createdAt', 'type', 'method', 'operation', 'integration']

// Initial default logs filter
export const defaultLogsSearch = `filters={"filterType":"AND","filters":[{"key":"createdAt","opr":">","val":"${todayBegin}"},{"key":"createdAt","opr":"<","val":"${todayEnd}"}],"page":0,"size":10}&columns=${initialColumnsShown}`

// Columns to hide on "first view" of logs
export const initialHiddenColumns = ['id', 'role', 'retry', 'jobId', 'userId', 'release', 'message', 'instance', 'request', 'response', 'timestamp', 'fieldData', 'accountId', 'channelId', 'requestId', 'serverName', 'releaseType', 'truncatedFields']

// Rewrite the column order, no matter if the column is shown or not
export const columnTableOrder = ['expander', 'identifier', 'result', 'warnings', 'action', 'context', 'createdAt']

// Number of previous days for which records were saved. To be used for the selection of custom dates
export const savedLogsDays = 45

// Handles slug special cases
export const slugFormatter = s => {
  if (s === 'amzn') return 'amazon'
  return s
}

// Cell formatter
export const cellFormatter = ({ cell, handleAddFilter, targetOrganizationId }) => {
  const cellValue = cell?.value
  const columnId = cell?.column?.id
  const original = cell?.row?.original

  // Shows badges on results when results is !== success
  if (columnId === 'result') {
    if (cellValue !== 'success') {
      return (
        <td {...cell.getCellProps()}>
          <Badge color={`${cellValue === 'error' ? 'danger' : 'warning'}`}>
            {cell.render('Cell')}
          </Badge>
          <Magnifier filter={{ key: columnId, opr: '=', val: cellValue }} handleAddFilter={handleAddFilter} />
        </td>
      )
    }
    return (
      <td {...cell.getCellProps()}>
        {cell.render('Cell')}{' '}
        <Magnifier filter={{ key: columnId, opr: '=', val: cellValue }} handleAddFilter={handleAddFilter} />
      </td>
    )
  }

  // Shows context[instance] if instance > 0
  if (columnId === 'context') {
    return (
      <td {...cell.getCellProps()}>
        {cell.render('Cell')} {original?.instance > 0 && original?.instance}{' '}
        <Magnifier filter={{ key: columnId, opr: '=', val: cellValue }} handleAddFilter={handleAddFilter} columnId={columnId} original={original} />
      </td>
    )
  }

  // Add Magnifier glass icon to replicate filter
  if (columnId === 'action') {
    return (
      <td {...cell.getCellProps()}>
        {cell.render('Cell')}{' '}
        <Magnifier filter={{ key: columnId, opr: '=', val: cellValue }} handleAddFilter={handleAddFilter} />
      </td>
    )
  }

  // Add external link to see the log in details in a new tab
  if (columnId === 'identifier') {
    const params = useParams()
    const logID = original?.id
    const requestId = original?.requestId
    const { logIdentifier: logIdFromURL } = params || {}
    const isCurrentLog = logIdFromURL && logIdFromURL === logID // shown on specific log
    const columnsToShow = 'identifier,result,action,context,createdAt,type,method,operation,request,response'
    const relatedLogSearch = `filters={"filterType":"AND","filters":[{"key":"requestId","opr":"=","val":"${requestId}"}],"page":0,"size":10}&columns=${columnsToShow}`
    return (
      <td {...cell.getCellProps()}>
        {cell.render('Cell')}
        {isCurrentLog
          ? <Badge color='info' className='ml-2'>Current log</Badge>
          : (
            <a target='_blank' rel='noopener noreferrer' href={`/logs/${targetOrganizationId}/${logID}?${relatedLogSearch}`}>
              <ExternalLink size={12} className='ml-2 mb-1' />
            </a>
            )}
      </td>
    )
  }

  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
}

export const formatActiveFilters = ({ key, val, opr }) => {
  if (key === 'context') {
    if (opr === '!=') return <>channel not equals: {val}</>
    return <>channel: {slugFormatter(val)}</>
  }
  if (key === 'createdAt') {
    const sanitized = val?.split(' ')[0]
    const formatted = sanitized && moment(sanitized).format('MMM DD - HH:mm[hs]')
    if (opr === '>') return <>after: {formatted}</>
    if (opr === '<') return <>before: {formatted}</>
  }
  if (opr === '!=') return <>{key} not equals: {val}</>
  return <>{key}: {val}</>
}

export const addOrReplaceFilter = (currentFilters = [], newFilter) => {
  if (newFilter?.key === 'createdAt') {
    return [...currentFilters, newFilter]
  }

  const index = currentFilters.findIndex(filter => filter.key === newFilter.key)
  if (index > -1) {
    const newFilters = [...currentFilters]
    newFilters[index] = newFilter
    return newFilters
  } else {
    return [...currentFilters, newFilter]
  }
}
