const daysAgo = days => new Date(Date.now() - days * 24 * 60 * 60 * 1000)

export const usefulSearches = [
  {
    type: 'orders',
    label: 'Today\'s orders',
    query: `date:${new Date().toJSON().slice(0, 10)}` // 2023-06-20
  },
  {
    type: 'orders',
    label: 'Last 7 days orders',
    query: `date:>${daysAgo(6).toJSON().slice(0, 10)}` // 2023-06-14
  },
  {
    type: 'orders',
    label: 'Pending orders',
    query: 'status:=pending'
  },
  {
    type: 'products',
    label: 'Items out of stock',
    query: 'stock:=0'
  },
  {
    type: 'products',
    label: 'Active items',
    query: 'status:=publish'
  }
]

export const connectorOptions = [
  { value: 'AND', label: 'AND' },
  { value: 'OR', label: 'OR' }
]

export const keyOptions = {
  Products: [
    { value: 'id', label: 'id' },
    { value: 'sku', label: 'sku' },
    { value: 'guid', label: 'guid' },
    { value: 'title', label: 'title' },
    { value: 'stock', label: 'stock' },
    { value: 'total_stock', label: 'total stock' },
    { value: 'kitlinks', label: 'kit links' },
    { value: 'cost', label: 'cost' },
    { value: 'price', label: 'price' },
    { value: 'discountprice', label: 'discount price' },
    { value: 'msrp', label: 'msrp' },
    { value: 'brand', label: 'brand' },
    { value: 'size', label: 'size' },
    { value: 'color', label: 'color' },
    { value: 'style', label: 'style' },
    { value: 'condition', label: 'condition' },
    { value: 'notes', label: 'notes' },
    { value: 'updatenotes', label: 'internal notes' },
    { value: 'material', label: 'material' },
    { value: 'longdescription', label: 'long description' },
    { value: 'upc', label: 'upc' },
    { value: 'status', label: 'status' },
    { value: 'sku_type', label: 'sku type' },
    { value: 'datesold', label: 'date sold' },
    { value: 'iskit', label: 'is kit' },
    { value: 'mediax', label: 'media x' },
    { value: 'mediacount', label: 'media count' },
    { value: 'weight', label: 'weight' },
    { value: 'boxlength', label: 'box length' },
    { value: 'boxwidth', label: 'box width' },
    { value: 'boxheight', label: 'box height' },
    { value: 'boxweight', label: 'box weight' },
    { value: 'dimweight', label: 'dim weight' }
  ],
  Orders: [
    { value: 'oid', label: 'order id' },
    { value: 'channel', label: 'channel' },
    { value: 'instance', label: 'instance' },
    { value: 'items', label: 'items' },
    { value: 'date', label: 'date' },
    { value: 'dateutc', label: 'dateutc' },
    { value: 'dateupdated', label: 'date updated' },
    { value: 'dateupdatedutc', label: 'date updated utc' },
    { value: 'comments', label: 'comments' },
    { value: 'internalnotes', label: 'internal notes' },
    { value: 'status', label: 'status' },
    { value: 'transactionid', label: 'transaction id' },
    { value: 'correlationid', label: 'correlation id' },
    { value: 'paymentdate', label: 'payment date' },
    { value: 'paymentstatus', label: 'payment status' },
    { value: 'shippingcarrier', label: 'shipping carrier' },
    { value: 'shippingservice', label: 'shipping service' },
    { value: 'shippingstatus', label: 'shipping status' },
    { value: 'shiptracking', label: 'ship tracking' },
    { value: 'total', label: 'total' },
    { value: 'itemtotal', label: 'item total' },
    { value: 'shippingtotal', label: 'shipping total' },
    { value: 'handlingtotal', label: 'handling total' },
    { value: 'taxtotal', label: 'taxtotal' },
    { value: 'discounttotal', label: 'discount total' },
    { value: 'discountcode', label: 'discount code' },
    { value: 'currency', label: 'currency' },
    { value: 'refundid', label: 'refund id' },
    { value: 'refunddate', label: 'refund date' },
    { value: 'refunddetails', label: 'refund details' },
    { value: 'email', label: 'email' },
    { value: 'billingcountry', label: 'billing country' },
    { value: 'billingfirstname', label: 'billing firstname' },
    { value: 'billingmiddlename', label: 'billing middlename' },
    { value: 'billinglastname', label: 'billing lastname' },
    { value: 'billingstreet1', label: 'billing street1' },
    { value: 'billingstreet2', label: 'billing street2' },
    { value: 'billingstreet3', label: 'billing street3' },
    { value: 'billingcity', label: 'billing city' },
    { value: 'billingstateprovince', label: 'billing state province' },
    { value: 'billingpostalcode', label: 'billing postal code' },
    { value: 'billingphone', label: 'billing phone' },
    { value: 'billingphone2', label: 'billing phone2' },
    { value: 'shippingcountry', label: 'shipping country' },
    { value: 'shippingfirstname', label: 'shipping firstname' },
    { value: 'shippingmiddlename', label: 'shipping middlename' },
    { value: 'shippinglastname', label: 'shipping lastname' },
    { value: 'shippingstreet1', label: 'shipping street1' },
    { value: 'shippingstreet2', label: 'shipping street2' },
    { value: 'shippingstreet3', label: 'shipping street3' },
    { value: 'shippingcity', label: 'shipping city' },
    { value: 'shippingstateprovince', label: 'shipping state province' },
    { value: 'shippingpostalcode', label: 'shipping postal code' },
    { value: 'shippingphone', label: 'shipping phone' },
    { value: 'shippingphone2', label: 'shipping phone2' }
  ]
}

export const operatorOptions = [
  { value: ':=', label: 'is' },
  { value: ':-=', label: 'is not' },
  { value: ':', label: 'contains' },
  { value: ':>', label: 'is greater than' },
  { value: ':>=', label: 'is greater than or equal to' },
  { value: ':<', label: 'is lower than' },
  { value: ':<=', label: 'is lower than or equal to' },
  { value: ':^', label: 'starts with' },
  { value: ':$', label: 'ends with' }
]

export const initialFilter = { connector: connectorOptions[0], value: '', operator: operatorOptions[0], input: '' }

export const forceOr = () => ({ value: 'OR', label: 'OR' })

export const formatInput = (input, operator) => {
  const val = input?.includes(' ') ? `"${input}"` : input
  if (operator === ':$') {
    return `${val}$`
  } else {
    return val
  }
}

export const formatOperator = operator => {
  if (operator === ':$') {
    return ':'
  } else {
    return operator
  }
}
