import React, { useState } from 'react'
import Select from 'react-select'
import { Input } from 'reactstrap/lib'
import 'react-select/dist/react-select.css'
import { isAmazon } from 'suredone-common'
import Highlighter from 'react-highlight-words'

const customStyles = {
  fontWeight: 600,
  letterSpacing: '.3px'
}

const FieldSummary = ({ slug, field, searchTerms, parentCallback, orgMappableFields }) => {
  const { label, description, value } = field
  const { channelFieldName } = value || {}

  const showInput = isAmazon(slug) && field?.name?.includes('@unitOfMeasure')
  const [inputValue, setInputValue] = useState({ value: (channelFieldName || null) })
  const [selectedOption, setSelectedOption] = useState({
    value: (channelFieldName || null),
    label: <strong style={customStyles}>{channelFieldName || null}</strong>
  })

  // Format options for react-select from orgMappableFields array
  const options = (orgMappableFields || []).map(item => ({
    value: item.name,
    label: <><strong style={customStyles}>{item.name}</strong><br />{item.description}</>
  }))

  const handleChangeSelect = (event) => {
    setSelectedOption(event)
    parentCallback(event, field)
  }

  const handleChangeInput = e => {
    const value = e.target.value
    setInputValue({ value })
    parentCallback({ value }, field)
  }

  return (
    <div className='d-flex w-100 justify-content-between align-items-center flex-wrap'>
      <div className='col-12 col-sm-7 col-lg-8 px-0 pr-sm-3 pb-2 pb-sm-0'>
        <strong style={customStyles}>
          <Highlighter
            className=''
            highlightTag='mark'
            autoEscape
            searchWords={searchTerms || []}
            textToHighlight={label || 'Unnamed field'}
          /><br />
        </strong>
        {description}
      </div>
      <div className='col-12 col-sm-5 col-lg-4 px-0'>
        {showInput
          ? (
            <Input
              value={inputValue?.value}
              onChange={handleChangeInput}
            />
            )
          : (
            <Select
              placeholder=''
              options={options}
              value={selectedOption}
              onChange={handleChangeSelect}
            />
            )}
      </div>
    </div>
  )
}

export default FieldSummary
