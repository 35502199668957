/**
 * ActionButton
 *
 * Usage:
 *  <ActionButton loading icon reactStrapProps>
 *    {yourContent}
 *  </ActionButton>
 */

import React from 'react'
import { Button, Spinner } from 'reactstrap'

const ActionButton = (props) => {
  const propsCopy = Object.assign({}, props)
  const disabled = (props.loading || props.disabled) ? { disabled: true } : {}
  delete propsCopy.loading
  return (
    <Button {...propsCopy} {...disabled}>
      {props.loading
        ? <Spinner size='sm' className='mr-2' />
        : props.icon
          ? props.icon
          : null}
      {props.children}
    </Button>
  )
}

export { ActionButton }
