import React, { useState, useEffect, useMemo } from 'react'
import Debug from 'debug'
import { cleanPayload } from './utils'
import FieldSummary from './FieldSummary'
import { useStores } from '../../stores/RootStore'
import { Loadable, ErrorAlert } from '../../components'
import { SaveButton } from '../../components/SaveButton'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { ResourceList } from '../../components/SearchableLists'
import { GET_CHANNEL_FIELDS, UPDATE_CHANNEL_FIELDS } from './commonQueries'

import './ChannelManagement.css'

const debug = Debug('sd:ChannelManagement')

export const ChannelManagement = ({ slug, instanceNumber }) => {
  // Get organizationId from Auth
  const { stores: { auth } } = useStores()
  const { user } = auth || {}
  const { organizationId } = user || {}

  // Query stuff with slug, instanceNumber and organizationId
  const { loading, error, data } = useQuery(GET_CHANNEL_FIELDS, {
    variables: {
      slug,
      instanceNumber,
      organizationId
    }
  })

  // orgMappableFields -> options to select
  const { OrganizationDetails } = data || { OrganizationDetails: {} }
  const { mappableProductFields: orgMappableFields } = OrganizationDetails || []

  // channelMappableFields -> rows
  const { Channel } = data || { Channel: {} }
  const { mappableProductFields: channelMappableFields } = Channel || []

  // channelInstanceFieldMappings -> info about selected option per row
  const { ChannelInstance } = data || { ChannelInstance: {} }
  const { fieldMappings: channelInstanceFieldMappings } = ChannelInstance || []

  const [rows, setRows] = useState([])
  const [newChannelInstance, setNewChannelInstance] = useState()
  const [fieldsToRemoveValue, setFieldsToRemoveValue] = useState([])

  // Mutation stuff
  const [mutationError, setMutationError] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const [updateFields, { loading: mLoading, data: mData }] = useMutation(
    UPDATE_CHANNEL_FIELDS,
    {
      onCompleted: () => {
        setIsDirty(false)
      },
      onError: (err) => {
        debug('onError, e:', err)
        setMutationError(err || 'There was an error updating field mappings. Try again please.')
      }
    }
  )

  useEffect(() => {
    const rows = channelMappableFields
    const config = channelInstanceFieldMappings
    // Creates a copy of channelMappableFields but with formatted data
    const fullRows = (rows || []).map((i, idx) => ({
      ...i,
      value: config.find(c => c.organizationFieldName === i.name),
      key: (i.channelFieldName || i.name) + idx,
      generalIndex: idx
    }))
    setRows(fullRows)
  }, [channelMappableFields, channelInstanceFieldMappings])

  const handleChangedValue = (event, field) => {
    // Enable save button
    setIsDirty(true)
    setMutationError(false)

    if (!event?.value) {
      setFieldsToRemoveValue(prev => [...prev, field.name])
    } else {
      setFieldsToRemoveValue(prev => prev?.filter(i => i !== field.name))
    }

    const newConfig = {
      channelFieldName: (event && event.value) || null,
      organizationFieldName: field.name
    }

    // Updates the copy of rows with the new config
    rows[field.generalIndex].value = newConfig

    // Get rows with selected option only and format it to make the mutation
    const valuesToSaveOnly = rows
      .filter(i => (i.value && i.value.channelFieldName) ? i : null)
      .map(i => i.value)

    setNewChannelInstance(valuesToSaveOnly)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    let mergedOldAndNewConfigs
    if (newChannelInstance) {
      mergedOldAndNewConfigs = [...channelInstanceFieldMappings, ...newChannelInstance]
    }

    // Removes duplicated keys and keep the latest updated
    const filtered = cleanPayload(mergedOldAndNewConfigs, fieldsToRemoveValue)

    updateFields({
      variables: {
        slug,
        instanceNumber,
        fieldMappings: filtered
      }
    })
  }

  const memoizedList = useMemo(() => {
    return (
      <ResourceList
        className='channel-management'
        items={rows}
        searchableStrings={a => [a.organizationFieldName, a.label]}
        itemComponent={
          ({ item: field, searchTerms }) =>
            <li className='list-group-item-flush d-flex flex-sm-row flex-column list-group-item-action list-group-item'>
              <FieldSummary
                isListItem
                slug={slug}
                field={field}
                searchTerms={searchTerms}
                parentCallback={handleChangedValue}
                orgMappableFields={orgMappableFields}
              />
            </li>
        }
      />
    )
    // eslint-disable-next-line
  }, [rows])

  return (
    <>
      <Loadable
        inline
        error={error}
        loading={loading}
        style={{ textAlign: 'center' }}
        content={
          <form className='form p-1' onSubmit={handleSubmit}>
            {memoizedList}

            {mutationError && <ErrorAlert error={mutationError} />}

            <div className='d-flex justify-content-end'>
              <SaveButton
                type='submit'
                color='primary'
                loading={mLoading}
                success={mData ? 'true' : null}
                dirtysincelastsubmit={isDirty ? 'true' : null}
                disabled={!isDirty || newChannelInstance?.length < 1}
              />
            </div>
          </form>
        }
      />
    </>
  )
}

debug('loaded')
