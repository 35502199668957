import React, { useState } from 'react'
import { daysToSelect } from './utils'
import { ErrorsList } from './ErrorsList'
import { useQuery } from '@apollo/react-hooks'
import { useQueryParams } from '../../../hooks'
import { getAuthorizedChannels } from '../utils'
import { Loadable, Page } from '../../../components'
import { AVAILABLE_CHANNELS } from '../commonQueries'
import { Redirect, NavLink as RNavLink } from 'react-router-dom'
import { Card, CardBody, CardHeader, Nav, NavItem, NavLink } from 'reactstrap'
import './ChannelErrors.scss'

export const formatSlug = s => {
  if (s === 'amzn') return 'amazon'
  return s
}

export const ChannelErrors = ({ params }) => {
  const { match } = params
  const { slug, instanceNumber } = match?.params
  const { daysago: daysAgoFromURL, page } = useQueryParams()
  const [daysAgo, setDaysAgo] = useState(daysToSelect[daysAgoFromURL - 1] ? daysToSelect[daysAgoFromURL - 1] : daysToSelect[0])
  const [pageIndex, setPageIndex] = useState(Number(page) || 0)
  const { data, loading, error } = useQuery(AVAILABLE_CHANNELS)
  const { ChannelInstances } = data || {}

  const tabIsActive = (s, i) => s === slug && i === instanceNumber

  const authorizedChannels = ChannelInstances && getAuthorizedChannels(ChannelInstances)
  const firstAuthorizedChannel = authorizedChannels?.[0]
  const { instance: faInstance, channel: faChannel } = firstAuthorizedChannel || {}
  const availableTabs = authorizedChannels?.reduce((acc, curr) => {
    const { instance, channel: { slug, name }, customLabel } = curr
    const label = customLabel || `${name} ${instance || ''}`
    return [...acc, { instance, slug: formatSlug(slug), name, label }]
  }, [])

  if (!slug) {
    return <Redirect to={`/channels/errors/${formatSlug(faChannel?.slug)}/${faInstance}?daysago=1&page=1`} />
  }

  return (
    <>
      <Page
        title='Channels Errors'
        description='These reports are updated every 30 minutes'
        className='channel-errors mb-n3'
        back={{ to: '/dashboard', name: 'Dashboard' }}
      >
        <Card className='mt-2'>
          <Loadable
            inline
            error={error}
            loading={loading}
            style={{ padding: '1rem' }}
            content={
              <>
                <CardHeader>
                  <Nav tabs className='card-header-tabs'>
                    {availableTabs?.map(({ slug, instance, label }, idx) => (
                      <NavItem key={slug + instance + idx}>
                        <NavLink
                          tag={RNavLink}
                          active={tabIsActive(slug, instance)}
                          onClick={() => {
                            setDaysAgo(1)
                            setPageIndex(0)
                          }}
                          to={`/channels/errors/${slug}/${instance}?daysago=1&page=1`}
                        >
                          {label}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                </CardHeader>
                <CardBody>
                  <ErrorsList params={params} helpers={{ daysAgo, setDaysAgo, pageIndex, setPageIndex }} />
                </CardBody>
              </>
            }
          />
        </Card>
      </Page>
    </>
  )
}
