import React from 'react'
import { Label, Col, FormFeedback, FormGroup, Input } from 'reactstrap'
import { Field, Form } from 'react-final-form'
import { SaveButton } from '../SaveButton'
import ErrorAlert from '../ErrorAlert'
import { useMFA } from '../../hooks'
import { DIGITS } from 'suredone-common'

const MfaCodeForm = ({ handleSubmit, dirtySinceLastSubmit, customError, sending, success, pristine, valid }) => {
  return (
    <form className='auth-form pb-5' onSubmit={handleSubmit}>
      <Col>
        <Label for='code'>Enter the 6-digit security code from your authenticator app:</Label>
      </Col>
      <Col>
        <Field
          name='code'
          validate={(code) => {
            if (!code) return 'Required'
            if (!DIGITS.test(code) || code.length !== 6) return 'Password must contain only 6 digits.'
          }}
        >
          {({ input, meta }) => {
            return (
              <FormGroup>
                <Input
                  {...input}
                  type='text'
                  required
                  autoFocus
                  invalid={meta.touched && meta.invalid}
                />
                {meta.error && meta.touched && (
                  <FormFeedback>{meta.error}</FormFeedback>
                )}
              </FormGroup>
            )
          }}
        </Field>
        <SaveButton
          color='primary'
          type='submit'
          loading={sending}
          disabled={sending || pristine || !valid}
          // The following sintax {state ? 'true' : false} is to avoid console warnings
          success={success ? 'true' : null}
          dirtysincelastsubmit={dirtySinceLastSubmit ? 'true' : null}
        >
          {sending ? 'Verifying' : 'Verify code'}
        </SaveButton>
      </Col>
      {customError && !sending && <ErrorAlert error={customError} />}

    </form>
  )
}

const MFAInputCode = () => {
  const { sendMfACode, loading: { sending }, errors: { sendingError }, success: { sendingSuccess } } = useMFA()
  const onHandleSubmit = async (values) => {
    await sendMfACode(values.code)
  }
  return (
    <Form
      onSubmit={onHandleSubmit}
      sending={sending}
      customError={sendingError && sendingError.message}
      success={sendingSuccess}
    >
      {props => <MfaCodeForm {...props} />}

    </Form>
  )
}

export { MFAInputCode }
