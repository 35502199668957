import React from 'react'

import {
  FaRobot as Automations
  // FaDownload as Download,
  // FaList as List,
  // FaPlus as Plus
} from 'react-icons/fa'

import { SidebarSection } from '../../components'

const debug = require('debug')('sd:AutomationSidebar')

export function AutomationSidebar (props) {
  return (
    <SidebarSection
      title='Automations'
      to='/automations/installed'
      icon={<Automations />}
      scopes={['organization:automation:ui:read', 'organization:automation:ui:write']}
      // navLinks={[
      //   {
      //     title: 'Installed Automations',
      //     to: '/automations/installed',
      //     icon: <Download />,
      //     exact: true
      //   },
      //   {
      //     title: 'Published Automations',
      //     to: '/automations/published',
      //     icon: <List />
      //   },
      //   {
      //     title: 'Custom Automation',
      //     to: '/automations/new',
      //     icon: <Plus />
      //   }
      // ]}
      parentTracker={{ eventName: 'Automations: visit', data: { action: 'User clicks Automations at the sidebar' } }}
      {...props}
    />
  )
}

debug('loaded')
