import React from 'react'
import gql from 'graphql-tag'
import { useHistory } from 'react-router-dom'
import { Spinner, DropdownItem } from 'reactstrap'
import { ErrorModal } from './ErrorModal'
import { useMutation } from '@apollo/react-hooks'
import { useObserver } from 'mobx-react'
import { useStores } from '../stores/RootStore'
import { FaUserSecret as Sudo } from 'react-icons/fa'
import { ActionButton } from './ActionButton'
import Debug from 'debug'

const debug = Debug('sd:SudoButton')

const BECOME_USER = gql`
  mutation becomeUser($userId: ID!) {
    sudoBecomeUser(userId: $userId)
  }
`

const RESTORE_SELF = gql`
  mutation restoreSelf {
    sudoRestoreSelf
  }
`

export function SudoButton ({ userId }) {
  const { stores: { auth } } = useStores()
  const [sudoBecomeUser, { loading, error }] = useMutation(BECOME_USER, { onComplete: () => auth.refresh() })
  debug('error', error)
  return useObserver(() =>
    auth.permissions.hasScope('platform:allUsers:sudo')
      ? (
        <>
          <ActionButton
            className='mr-1'
            color='secondary'
            outline={loading || !userId || auth.user.id === userId}
            disabled={loading || !userId || auth.user.id === userId}
            loading={loading}
            icon={<Sudo />}
            onClick={async e => {
              e.preventDefault()
              await sudoBecomeUser({ variables: { userId } })
              await auth.refresh()
              window.location = '/automations' // to force Intercom reboot
            }}
          >
            Become user
            {error && <ErrorModal header='Error becoming user' error={error.message} />}
          </ActionButton>
        </>)
      : null
  )
}

export function RestoreSelf () {
  const history = useHistory()
  const { stores: { auth } } = useStores()
  const [sudoRestoreSelf, { loading, error }] = useMutation(RESTORE_SELF, {
    onComplete: () => {
      debug('onComplete')
    }
  })
  debug('error', error)
  const name = auth.user && auth.user.sudoer ? auth.user.sudoer.fullName() || auth.user.sudoer.primaryEmail : ''
  const existingId = auth.user && auth.user.id
  return useObserver(() =>
    <DropdownItem
      disabled={loading}
      color='danger'
      onClick={async e => {
        e.preventDefault()
        await sudoRestoreSelf()
        await auth.refresh()
        history.push(existingId ? `/admin/users/${existingId}/permissions` : '/admin/users')
      }}
    >
      {loading ? <Spinner size='sm' /> : <Sudo />} Become {name} again
      {error && <ErrorModal header='Error restoring self' error={error.message} />}
    </DropdownItem>
  )
}
