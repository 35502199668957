import React from 'react'
import { UsersSearch } from './UsersSearch'
import { useStores } from '../../stores/RootStore'

export const KeyboardLookup = () => {
  const { stores: { auth } } = useStores()
  const { user: { organizationId } } = auth || {}
  const canSearchUsers = auth.permissions.hasAnyScopeInOrganization(['platform:allUsers:list', 'platform:allOrganizations:list'], organizationId)

  if (!canSearchUsers) return ''

  return <UsersSearch />
}
