import React from 'react'
import { Col, Card, CardHeader, CardBody, CardFooter } from 'reactstrap'
import { FaBullhorn as Bullhorn, FaAngleRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { news } from '../../ProductUpdates/News'
import '../Dashboard.css'
import '../../ProductUpdates/ProductUpdates.css'

const News = ({ data }) => {
  const { newsTitle, amountOfNews } = data
  const slicedNews = news.slice(0, amountOfNews)

  return (
    <Col xs='12' xl='4' className='product-updates'>
      <Card className='news-card'>
        <CardHeader>
          <h3 className='card-title mb-0'><Bullhorn /> {newsTitle}</h3>
        </CardHeader>
        <CardBody className='p-3'>
          {slicedNews && slicedNews.map(({ id, date, title, content }) =>
            <article key={id} className='mb-4'>
              <div><strong>{title}</strong></div>
              <div className='text-muted mb-1'>
                {id} {date}
              </div>
              {content}
            </article>
          )}
        </CardBody>
        <CardFooter className='d-flex justify-content-center align-items-center px-3 py-2'>
          <Link to='/product-updates'>View all <FaAngleRight /></Link>
        </CardFooter>
      </Card>
    </Col>
  )
}

export { News }
