import React from 'react'
import { AuthPage } from '../Auth/AuthPage'
import { Button, Card, CardBody } from 'reactstrap'
import { launchContact } from '../../components/utils'

export const DeactivatedWall = () => {
  return (
    <AuthPage>
      <Card className='custom auth-form mx-auto mt-3 text-center' style={{ maxWidth: '500px' }}>
        <CardBody className='p-0'>
          <h5>Your account was deactivated</h5>
          <p>Want to activate it again? <Button color='link' className='px-1 px-0 py-0 mt-n1' onClick={launchContact}>Contact support</Button> </p>
        </CardBody>
      </Card>
    </AuthPage>
  )
}
