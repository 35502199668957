import { useEffect } from 'react'
const intervalToUpdateIntercom = 90 * 1000 // 90 seconds

/**
 * Will update Intercom every 'intervalToUpdateIntercom' seconds to look for new messages that should be displayed to the current user
 */
export const IntercomUpdates = () => {
  useEffect(() => {
    if (window) {
      const interval = setInterval(() => {
        window.Intercom('update')
      }, intervalToUpdateIntercom)
      return () => clearInterval(interval)
    }
  }, [])

  return null
}
