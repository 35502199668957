// Convert 1234567 into 1,234,567.00
export const withComma = num => Number(num).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })

// Sums up all the values of the [array] it receives in the [key] it receives
export const arrSum = (array, key) => (array || []).map(i => i[key]).reduce((a, b) => a + b, 0)

// Generates a left margin for graphs
export const generateMargin = (array) => {
  // Gets the biggest number of the [array] it receives
  const biggest = Math.ceil(Math.max.apply(Math, (array || []).map(function (o) { return o.value })))
  // If biggest === 1, rechart will render 0.25, 0.5 and 0.75 on Y axis so we force the number of digits to 3
  const numberOfDigits = biggest === 1 ? 3 : biggest.toString().length

  switch (numberOfDigits) {
    case 1:
      return -35
    case 2:
      return -30
    case 3:
      return -20
    case 4:
      return -15
    case 5:
      return -5
    case 6:
      return 0
    case 7:
      return 5
    default:
      return 10
  }
}
