import React from 'react'
import { InfoAlert } from './InfoAlert'
import { Card, CardBody } from 'reactstrap'
import { CodeBlock } from '../../components'
import { InstructionCollapse } from './InstructionCollapse'

const bundleCode = `<script type="text/javascript">
window.shopData = {
  suredoneID: yourSureDoneID,
  storefront: 'shopify|bigcommerce|storefront',
  instance: 2|3|4|5 // required for instances > 0
}
</script>
<script type="text/javascript" src="https://fitment.suredone.com/bundle.min.js"></script>`

const BundleDisclaimer = () => {
  return (
    <>
      <p className='mb-0'>The above will work as long as the <code>bundle</code> and <code>window.shopData</code> were already present on the page so if they are not, don't forget to add them:</p>
      <CodeBlock>{bundleCode}</CodeBlock>
      <b className='d-block mt-1'>Be careful and do not load the <code>bundle.min.js</code> more than once per page.</b>
    </>
  )
}

export const AdvanceCustomization = () => {
  return (
    <>
      <Card>
        <CardBody>
          <h2>Fitment Tags</h2>
          <p className='mb-2'>A set of <code>HTML</code> tags that allow you to get the most out of the SureFit app.</p>
          <InfoAlert>
            Advanced web development knowledge is required.<br />
            Be careful with the <code>bundle.min.js</code> and do not load it more than once per page.
          </InfoAlert>

          {/* Engaged Vehicle */}
          <InstructionCollapse id='tag-1' title='Engaged Vehicle'>
            <p>This tag improves the search experience by adding the Year Make and Model filtered by to the page title.</p>
            <img src='https://assets.suredone.com/688015/logos/engaged-vehicle.png' alt='Engaged Vehicle' />
            <p className='mt-3'>This example shows how to do it with Bigcommerce but if you use Shopify or another storefront you will have to do something similar.</p>
            <ol className='pl-3'>
              <li>
                You must edit the page you have previously created to display the fitment results.
                <img src='https://assets.suredone.com/688015/logos/engaged-1.png' alt='Engaged Vehicle 1' />
              </li>
              <li>
                Use the small button to include <code>HTML</code> in that page.
                <img src='https://assets.suredone.com/688015/logos/engaged-2.png' alt='Engaged Vehicle 2' />
              </li>
              <li>
                Add <code>{'<div id="sd-fitment-engaged-vehicle"></div>'}</code>.
                <img src='https://assets.suredone.com/688015/logos/engaged-3.png' alt='Engaged Vehicle 3' />
              </li>
              <li>All done 🎉</li>
            </ol>

            <h3 className='mt-4'>Custom Title</h3>
            <p className='mb-0'>If you need something more customized, for example <b>{'{Year Make Model} Parts & Accessories'}</b>, you can implement the tag <code>data-engaged-title="{'{YMM} Custom Title'}"</code> where <b>{'{YMM}'}</b> is wherever you want the searched vehicle to appear. Here are some examples:</p>
            <ul className='mb-3'>
              <li>To show <b>2020 Audi A1 Parts & Accessories</b>, implement <code>{'<div id="sd-fitment-engaged-vehicle" data-engaged-title="{YMM} Parts & Accessories"></div>'}</code></li>
              <li>To show <b>Parts for 2020 Audi A1:</b>, implement <code>{'<div id="sd-fitment-engaged-vehicle" data-engaged-title="Parts for {YMM}:"></div>'}</code></li>
            </ul>

            <BundleDisclaimer />
          </InstructionCollapse>

          {/* Fixed Initial Search  */}
          <InstructionCollapse id='tag-2' title='Fixed Initial Search'>
            <p>It is very useful when you want to display the Year Make Model selectors globally. This only displays the selectors to perform a search and be redirected to the results page.</p>
            <img src='https://assets.suredone.com/688015/logos/fixed-initial-search.png' alt='Fixed Initial Search' />

            <ol className='pl-3 my-3'>
              <li>
                Go to your theme and add <code>{'<div id="sd-fitment-fixed-initial-search"></div>'}</code> to the page where you want to display the selectors.
              </li>
              <li>
                All done 🎉 The <b>Year Make Model</b> selectors will appear wherever you put the tag:
                <img src='https://assets.suredone.com/688015/logos/fixed-1.png' alt='Fixed Initial Search 1' />
              </li>
            </ol>
            <BundleDisclaimer />
          </InstructionCollapse>

          {/* Search Modal  */}
          <InstructionCollapse id='tag-3' title='Search Modal'>
            <p>Very useful to open the <b>Year Make Model</b> selectors in places where there is not much space.<br />
              This tag will show a <u>Click to Search for a Vehicle</u> button that will open the <b>Year Make Model</b> selectors in a modal.
            </p>
            <img src='https://assets.suredone.com/688015/logos/search-modal.png' alt='Search Modal' />

            <ol className='pl-3 my-3'>
              <li>
                Go to your theme and add <code>{'<div id="sd-fitment-search-modal"></div>'}</code> to the page where you want to display the modal.
              </li>
              <li>
                All done 🎉 The tag will render the modal and the button that opens it:
                <img src='https://assets.suredone.com/688015/logos/search-modal-1.png' alt='Search Modal 1' />
              </li>
            </ol>
            <BundleDisclaimer />
          </InstructionCollapse>

          {/* Search With Go Button  */}
          <InstructionCollapse id='tag-4' title='Search With Go Button'>
            <p>It is useful when you want to display a <b>GO</b> button that replaces the default behavior of redirecting to the results page when model is selected.</p>
            <img src='https://assets.suredone.com/688015/logos/go-button.png' alt='Go Button' />

            <ol className='pl-3 my-3'>
              <li>
                Go to your theme and add <code>{'<div id="sd-fitment-search-with-go-button"></div>'}</code> to the page where you want to display the YMM selectors with the GO button.
              </li>
              <li>
                All done 🎉 The tag will render the <b>Year Make Model</b> selectors and <b>GO</b> button wherever you put it.
              </li>
            </ol>
            <BundleDisclaimer />

            <h2 className='mt-5'>Including it in the home page</h2>
            <p className='mb-0'>You may want to put it in the home page and for that you must use the theme editor to:</p>
            <ol className='pl-3'>
              <li>
                Delete the default drang & drop block (the one that contains the div with id <code>sd-root</code>)
              </li>
              <li>
                Drag & drop a <code>HTML</code> block with the into it (for Shopify or another storefront you will have to do something similar):
                <img src='https://assets.suredone.com/688015/logos/go-button-1.png' alt='Go Button 1' />
              </li>
              <li>
                Edit the <code>HTML</code> block just added to include the required code
                <CodeBlock className='my-2'>{`<div id="sd-fitment-search-with-go-button"></div>
  <script>
    window.shopData = {
      suredoneID: yourSureDoneID,
      storefront: 'shopify|bigcommerce|storefront',
      instance: 2|3|4|5 // required for instances > 0
    }
  </script>
  <script src="https://fitment.suredone.com/bundle.min.js"></script>`}
                </CodeBlock>
                <img src='https://assets.suredone.com/688015/logos/go-button-2.png' alt='Go Button 2' />
              </li>
              <li>All done 🎉</li>
            </ol>
          </InstructionCollapse>

          {/* Advanced Fits Table */}
          <InstructionCollapse id='tag-5' title='Advanced Fits Table'>
            <p>
              This tag will allow you to show more details in the Fits Table.<br />
              <b>Special data configuration is required so please contact SureDone support for this.</b>
            </p>
            <img src='https://assets.suredone.com/688015/logos/advanced-table.png' alt='Advanced Fits Table' />

            <ol className='pl-3 my-3'>
              <li>
                Go to your theme and add <code>{'<div id="sd-advanced-fits-table"></div>'}</code> to the page where you want to display the table (usually Product Page).
              </li>
              <li>
                All done 🎉 The tag will render the table:
                <img src='https://assets.suredone.com/688015/logos/advanced-table-1.png' alt='Advanced Fits Table 1' />
              </li>
            </ol>
            <BundleDisclaimer />

            <p className='mt-4 mb-0'>If no data is found for the advanced table, the basic table will be shown:</p>
            <img src='https://assets.suredone.com/688015/logos/advanced-table-2.png' alt='Advanced Fits Table 2' />

            <h2 className='mt-5'>Column modifiers</h2>
            <p>With the column modifiers you can hide and order the columns as you wish. You can even create labels so that <code>bodyNumDoors</code> is displayed as <code>Body Number Of Doors</code> for example. Let's look at each one in detail:</p>

            <u><h3 className='mt-4'>Hidding columns</h3></u>
            <p className='mb-2'>Just add <code>data-columns-hidden</code> to the <code>{'<div id="sd-advanced-fits-table">'}</code> tag. For example:</p>
            <CodeBlock>{'<div id="sd-advanced-fits-table" data-columns-hidden=\'AppFingerprint,Asset Name,Asset Order\'></div>'}</CodeBlock>
            <p className='mt-2'>Being <code>AppFingerprint</code>, <code>Asset Name</code> and <code>Asset Order</code> the columns you want to hide.</p>
            <p><b>How does it work?</b> Simply by adding the <code>hidden-col</code> class to all the cells that should not be displayed. The widget by default applies <code>display: none</code> and makes it not to be displayed but you can overwrite it to do with these <code>hidden-col</code> whatever you want.</p>

            <u><h3 className='mt-4'>Ordering columns</h3></u>
            <p className='mb-2'>The UI table respects the order of the headers present in the csv used. If you want to modify it you can simply add <code>data-columns-order</code> to the <code>{'<div id="sd-advanced-fits-table">'}</code> tag and this will override the order. For example:</p>
            <CodeBlock>{'<div id="sd-advanced-fits-table" data-columns-order=\'Engine,BodyType,Region\'></div>'}</CodeBlock>
            <p className='mt-2'>will show <code>Engine</code>, <code>BodyType</code> and <code>Region Name</code> as first columns. If there are more columns, they will be displayed in the order they were in.</p>
            <p><b>How does it work?</b> We simply take the columns the user specifies and place them first in that order. If there are more, it displays them in the order they were in. This allows that if you have 15 columns for example and you are only interested in the order of the first 5 columns, just specifying those 5 will be enough.</p>

            <u><h3 className='mt-4'>Labeling columns</h3></u>
            <p className='mb-2'>Just add <code>data-columns-labels</code> to the <code>{'<div id="sd-advanced-fits-table">'}</code> tag. For example:</p>
            <CodeBlock>{'<div id="sd-advanced-fits-table" data-columns-labels=\'{"BodyType": "Body Type","BodyNumDoors": "Body Number Of Doors"}\'></div>'}</CodeBlock>
            <p className='mt-2'>The above will override <code>BodyType</code> header with <code>Body Type</code>. The same for <code>BodyNumDoors</code>, showing <code>Body Number Of Doors</code>.</p>
            <p><b>Take into account that the value of <code>data-columns-labels</code> must be a valid stringified JSON.</b></p>

            <u><h3 className='mt-4'>Combinig the modifiers</h3></u>
            <p className='mb-2'>You can combine them as you wish. Here is an example of the 3 modifiers together:</p>
            <CodeBlock>{`<div id="sd-advanced-fits-table"
    data-columns-hidden='AppFingerprint,App,Order,Asset Name,Asset Order'
    data-columns-order='BodyType,Region'
    data-columns-labels='{"BodyType": "Body Type"}'
  ></div>`}
            </CodeBlock>

            <u><h3 className='mt-4'>Hiding a column when all values are empty</h3></u>
            <p className='mb-2'>It does not require a modifier but is a default feature of the widget. That is, if the column does not have at least one value, then the entire column is not displayed. For example:</p>
            <img src='https://assets.suredone.com/688015/logos/advanced-table-3.png' alt='Advanced Fits Table 3' />
            <p className='mt-2'><code>TVL</code> and <code>Gasoline</code> prevent <b>Submodel</b> and <b>Engine</b> columns from being hidden. But <b>Qualifiers</b> and <b>Position</b> will be completely hidden as they have no value.</p>
            <p><b>How does it work?</b> We simply add the <code>entire-col-empty</code> class to the necessary cells to prevent the entire column from being displayed. The widget by default applies <code>display: none</code> and makes it not to be displayed but you can overwrite it to do with these <code>entire-col-empty</code> whatever you want.</p>
          </InstructionCollapse>

          {/* My Garage */}
          <InstructionCollapse id='tag-6' title='My Garage'>
            <p>Very useful when you need to display <b>My Garage</b> in some part of the theme.</p>
            <img src='https://assets.suredone.com/688015/logos/my-garage-button.png' alt='My Garage' />

            <ol className='pl-3 my-3'>
              <li>
                Go to your theme and add <code>{'<div id="sd-fitment-my-garage"></div>'}</code> to the page or section where you want to display My Garage.
              </li>
              <li>
                All done 🎉 The tag will render it:
                <img src='https://assets.suredone.com/688015/logos/my-garage-button-1.png' alt='My Garage 1' />
              </li>
            </ol>
            <BundleDisclaimer />
          </InstructionCollapse>

          {/* Prevent Redirections */}
          <InstructionCollapse id='tag-7' title='Prevent redirects when applying a filter'>
            <p>
              If you implement the <b>Fixed Initial Search</b> or <b>Search With Go Button</b> tags but using the <code>data-category-product-pages-prevent-redirect="true"</code> attribute, when you filter by <b>year/make/model</b> you will not be redirected to the results page but you will refresh that page but with an engaged vehicle.<br />
              This way you would be applying a filter and you would still be on the product or category page:
            </p>
            <CodeBlock>
              {'<div id="sd-fitment-fixed-initial-search" data-category-product-pages-prevent-redirect="true"></div>'}
            </CodeBlock>
            <br />
            <BundleDisclaimer />
          </InstructionCollapse>

          {/* Apply Other filter by default */}
          <InstructionCollapse id='tag-8' title='Apply Other Filter by default'>
            <p>
              This is useful when you want to apply the <b>Other</b> filters by default:
            </p>

            <img src='https://assets.suredone.com/688015/logos/apply-other.png' alt='Apply Other Filter by defaul' />

            <p className='my-3'>This will work as long as you include the <code>apply-other-by-default="true"</code> attibute to a <code>div</code>. We recommend to add it to the <code>sd-root</code>, so:</p>

            <ol className='pl-3 my-3'>
              <li>
                Go to your theme and add <code>{'<div id="sd-root" apply-other-by-default="true"></div>'}</code> to the results page.
              </li>
              <li>
                All done 🎉 <b>Other</b> filter will be applied by default when a user performs a search.
              </li>
            </ol>

            <InfoAlert>
              <b>Note:</b><br />
              <b>Other</b> filter will be applied but will still be displayed in the UI. We recommend hiding it using CSS by simply doing:<br />
              <code>{'li.menu__item.sd-filter-Other { display: none }'}</code>
            </InfoAlert>
            <BundleDisclaimer />
          </InstructionCollapse>

          {/* Amount of Filters before the "View more" */}
          <InstructionCollapse id='tag-9' title='Amount of Filters before the "View more"'>
            <p>
              This is useful when you want to edit the amount of filters before the "View more" button (defaulted now to 10 filters):
            </p>

            <img src='https://assets.suredone.com/688015/logos/view more.png' style={{ maxWidth: '270px' }} alt='Amount of Filters before the "View more"' />

            <p className='my-3'>This will work as long as you include the <code>data-initial-filters="number"</code> attibute to a <code>div</code>. We recommend to add it to the <code>sd-root</code>, so:</p>

            <ol className='pl-3 my-3'>
              <li>
                Go to your theme and add <code>{'<div id="sd-root" data-initial-filters="15"></div>'}</code> (you can use any integer number).
              </li>
              <li>
                All done 🎉 The filters at the results page will now show 15 items before the <b>"View more"</b> button.
              </li>
            </ol>

            <BundleDisclaimer />
          </InstructionCollapse>

          {/* Getting Widget Data */}
          <InstructionCollapse id='tag-20' title='Getting Widget Data'>
            <p>Very useful when you need to get the data used by the widget. For example, what was the last <b>year/make/model</b> search performed.</p>
            <p>We expose these values in <code>window.sdFitmentData</code> so you can use it from there. You can check them out from your browser console:</p>

            <img src='https://assets.suredone.com/688015/logos/widget-data.png' alt='Getting Widget Data' />

            <InfoAlert>
              <b>Important:</b><br />
              The data of <code>window.sdFitmentData</code> varies and is not always the same. The entire object may not even exist, so use it with caution.
            </InfoAlert>

            <CodeBlock>
              {`window.sdFitmentData: {
  "sku": "1-5414",
  "lastYMMSearch": { "year": 2024, "make": "Ford", "model": "Ranger" },
  "fitsById": [
    {
      "Year": 2023,
      "Make": "Ford",
      "Model": "Ranger",
      "Notes": "Black",
      "Per Car": 1,
      "Mfr Label": "3 in. Round Step Nerf Bar",
      "BodyType": "Extended Cab Pickup"
    },
    {...}
  ]
}`}
            </CodeBlock>
          </InstructionCollapse>
        </CardBody>
      </Card>
    </>
  )
}
