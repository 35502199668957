import { useEffect } from 'react'
import { useLocation } from 'react-router'

const useScrollTo = () => {
  const hashId = useLocation().hash
  const hashArray = hashId.split('#')
  const id = hashArray.length > 0 ? hashArray[1] : ''
  useEffect(() => {
    if (id !== '') {
      document && document.getElementById(id) && document.getElementById(id).scrollIntoView()
    }
  }, [id])
}

export default useScrollTo
