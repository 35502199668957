/* eslint-disable */
import React from 'react'

/* Once edited, don't forget to update the value of termsOfServiceLastUpdate in /ui/server/src/config.js */
export const termsOfService = (
  <div>
    <ol type='1'>
      <li><span>DEFINITIONS</span>
        <ol type='a'>
          <li>“Account Tier” means the subscription plan level designated within your account settings, within your Statement of Work, designated when you signed up for SureDone Services, or designated when you have upgraded your SureDone Services. Your Account Tier defines allowances and restrictions on your account, including but not limited to number of orders, number of products, number of listings, selling revenue (GMV), bulk processing or API updates and types of support available to you.</li>
          <li>"Automation" means an automated task listed within the SureDone application on or under this page: https://app.suredone.com/automations/. This includes any installed automations, whether enabled or disabled.</li>
          <li>“Buyers” means individuals or companies that view or purchase products from SureDone Sellers.</li>
          <li>"Channels" means SureDone's integrated storefront, along with all third party technology that SureDone connects to for the purpose of listing products, product information, pricing, inventory levels or other information about Seller's products, whether for sale or not. Examples of channels include eBay, Amazon, Walmart, Etsy, Facebook, Google, Shopify, BigCommerce and Magento, but may include third parties full integrated within the SureDone Technology or through the SureDone automation engine or other data exchange method. Channels may also be used in the singular ("Channel") to represent any single one of these Channels.</li><li>“Content” means any information, text, graphics, videos, photos, product data or other materials uploaded, downloaded or appearing on the Services.</li>
          <li>“Free Tier” means an Account Tier with a recurring subscription charge in the amount of zero dollars ($0).</li>
          <li>“Seller” or “Sellers” means the individual or company, or plural, that is the user licensed to receive the SureDone Services, along with any related or non-related entities that have been provided with access to SureDone Services under the individual or company, or plural, license.</li>
          <li>“Services” means SureDone's various websites, APIs, SMS, email notifications, applications, buttons, widgets, videos, written content, and guides.</li>
          <li>“SureDone” means SureDone, Inc., and its parents, subsidiaries, affiliates, related companies, officers, directors, employees, agents, representatives, partners, and licensors.</li>
          <li>“Technology” means, collectively, the SureDone Platform and Services, Documentation, functionality, visual interfaces, URLs and integrations that enable Seller access to the SureDone Platform, analytics, transaction information, all content in, and work product resulting from, the Services, and any software or technology incorporated in or made available by SureDone through the SureDone Platform or Services, expressly excluding Product Information and Seller Marks unless otherwise stated.</li>
          <li>“Terms” means these Terms of Service, along with our privacy policy located at https://www.suredone.com/privacy and all statements of work (SOW), exhibits and invoices.</li>
        </ol>
      </li>
      <li><span>SERVICES AND TERMS</span>
        <ol type='a'>
          <li>SureDone is a Service with the primary purpose of facilitating the sales of goods to Buyers on online marketplaces, such as but not limited to eBay and Amazon, and via websites, such as but not limited to SureDone Storefronts, BigCommerce and Magento, by Sellers.</li>
          <li>Our Terms govern your access to and use of the Services and Content. Your access to and use of the Services is conditioned upon your acceptance of and compliance with these Terms. By accessing or using the Services you agree to be bound by these Terms.</li>
          <li>You may use the Services only if you are authorized as an individual or company representative to create a binding contract with SureDone and are not a person barred from receiving services under the laws of the United States or other applicable jurisdiction. If you are accepting these Terms and using the Services on behalf of yourself as an individual, a company, organization, government, or other legal entity, you represent and warrant that you are authorized to do so. You may use the Services only in compliance with these Terms and all applicable local, state, national, and international laws, rules and regulations.</li>
          <li>The Services that SureDone provides are always evolving and the form and nature of the Services that SureDone provides may change from time to time without prior notice to you. In addition, SureDone may generally stop, permanently or temporarily, providing the Services, or any features within the Services, to you or to users and may not be able to provide you with prior notice. We also retain the right to create limits on use and storage at our sole discretion at any time without prior notice to you.</li>
        </ol>
      </li>
      <li><span>LICENSE TO USE THE SERVICES</span>
        <ol type='a'>
          <li><u>General License.</u> Generally, SureDone gives Sellers a personal, worldwide, royalty-free, non-assignable and non-exclusive license to use the software that is provided to Seller by SureDone as part of the Services. This license is for the sole purpose of enabling Seller to use and enjoy the benefit of the Services as provided by SureDone, in the manner permitted by these Terms. </li>
          <li>This Section 2(b) defines the legal use of the Services including but not limited to the SureDone API, all updates, revisions, substitutions, and any copies of the SureDone API made by or for Seller. All rights not expressly granted to Seller are reserved by SureDone.
            <ol type='i'>
              <li><u>Rate Limits and Data Field Restrictions.</u>
                <ol type='1'>
                  <li>Seller agrees that there may be a rate limit on the quantity of information sent or received and speed of transmission or receipt when using our bulk, API or other Services, and there may be a restriction to the number of products within Seller's SureDone account, the number of listings Seller is allowed to make using Seller's SureDone account, the number of orders Seller is allowed to receive or process in Seller's SureDone account, the number of custom fields created within Seller's account, the number of sub-accounts or sub-users created using Seller's account, and the number of updates Seller's SureDone account can process through SureDone's bulk, API or other Service, and Seller agree that Seller shall comply with that rate limit and restrictions to products, listings, orders and updates at all times.</li>
                  <li>The rate limits and restrictions are described in the SureDone API Guide, available at <a href='https://www.suredone.com/guides/api/' title='SureDone API Guide' target='_bl[ank'>https://www.suredone.com/guides/api/</a>, or may be based on Seller's Account Tier. </li>
                  <li>Restrictions may be contained within the same API Guide or may be placed on Seller's account based on Seller's Account Tier.</li>
                  <li>These rate limits and restrictions are subject to change from time to time at SureDone's sole discretion, effective immediately upon posting. </li>
                  <li>SureDone may, in its sole discretion, limit the: (i) rate at which the service, or any subset of it, may be called by Seller, (ii) the amount of storage made available to each Seller account, and/or (iii) the length of individual content segments that may be uploaded to, or served from, the Service (all of the foregoing being forms of “Throttling”). </li>
                  <li>If Seller exceeds limits defined on their Account Tier, SureDone may restrict or throttle the Seller account.</li>
                  <li>SureDone may perform this Throttling globally across the entire SureDone API, per Seller, or on any other basis. </li>
                  <li>Seller will not take steps to circumvent any technical measures SureDone may put in place to enforce Throttling, and Seller understands and agrees that programmatic methods intended to subvert rate limiting (including maintaining a cache of the data) are considered a violation of these API Terms. </li>
                </ol>
              </li>
              <li><u>Modifications</u>. SureDone reserves the right to release subsequent versions of the SureDone API and to require Seller to obtain and use the most recent version. If a modification is unacceptable to Seller, Seller may cancel the Services by removing the SureDone API code from the Seller website, applications or other systems or programs accessing the SureDone API. If Seller continues to use the Services, Seller will be deemed to have accepted the modifications.</li>
              <li><u>Limitations and Conditions of the Services</u>. SureDone may use technology or other means to protect the SureDone API, protect our Sellers, or stop Sellers from breaching this Agreement. These means may include, for example, filtering to stop spam or increase security. These means may hinder or break Seller's use of the SureDone API, and Seller may not work around or attempt to thwart or disable any of these technical or other means.</li>
            </ol>
          </li>
          <li>Seller hereby assigns to SureDone all Intellectual Property rights in the Technology that Seller may have acquired by law or otherwise. Seller shall not challenge, directly or indirectly, any of SureDone's (or its licensors') rights to the Technology, as stated in the Agreement.</li>
          <li><u>Beta Software</u>. All new features, or enhancements to existing features, within the Service are considered "Beta Software" whether visually marked as Beta or not marked with any designation. Beta Software marked with a "Beta" designation is considered Beta Software while so marked and for a period of ninety (90) days after all Beta marks are removed. Any new feature or feature enhancement that is not visually marked as Beta is considered Beta Software for a period of ninety (90) days after being made available within the Service. Seller understands that any Beta Software, pre-release and beta versions of Technology, and any pre-release and beta features within generally available Technology, that we make available (collectively, “Beta Versions”) are still under development, may be inoperable or incomplete and are likely to contain more errors and bugs than generally available Technology and should be used at Seller's own risk, including but not limited to verifying Beta Versions are performing correctly in their function and immediately reporting any errors or issues to SureDone. SureDone assumes no liability, financial or otherwise, when Beta Versions are used by Sellers. SureDone makes no promises that any Beta Versions will ever be made generally available. In some circumstances, SureDone may charge a fee in order to allow you to use Beta Versions. All information regarding the characteristics, features or performance of any Beta Versions constitutes SureDone's Confidential Information.</li>
          <li><u>No-Charge Software</u>. SureDone may offer certain Technology to Sellers at no charge, including free accounts, trial use, and Beta Versions (collectively, “No-Charge Software”). Your use of No-Charge Software is subject to any additional terms that we specify and is only permitted during the License Term we designate (or, if not designated, until terminated in accordance with this Agreement). Except as otherwise set forth in this Section, the terms and conditions of this Agreement governing Technology fully apply to No-Charge Software. SureDone may terminate Seller's right to use No-Charge Software at any time and for any reason in SureDone's sole discretion, without liability to Seller. To the maximum extent permitted by applicable law, SureDone disclaims all obligations or liabilities with respect to No-Charge Software, including any Support and Maintenance, warranty, and indemnity obligations. NOTWITHSTANDING ANYTHING ELSE IN THIS AGREEMENT, SUREDONE'S MAXIMUM AGGREGATE LIABILITY TO SELLER IN RESPECT OF NO-CHARGE SOFTWARE SHALL NOT EXCEED $100 U.S.</li>
        </ol>
      </li>
      <li><span>ACCOUNTS</span>
        <ol type='a'>
          <li><u>Eligibility</u>
            <ol type='i'>
              <li>The Services are not intended for use by children under 13 years of age. If Seller is 13 - 18 years old, Seller shall use the Services only with the involvement and supervision of a parent or guardian.</li>
              <li>To use the SureDone API, Seller must have an account on the Services and keep Seller's account information up-to-date and accurate at all times, including a valid email address.</li>
              <li>To use the Services to sell items, Seller's SureDone account must be current and in good standing.</li>
              <li>Seller shall not be entitled to transfer and shall not attempt to transfer or sell your SureDone account or User ID to another party.</li>
              <li>Seller shall not use another Seller's account without permission.</li>
              <li>If Seller is registering as a business entity, Seller represents, warrants, and guarantees that the Seller representative has the authority to bind the entity to this Agreement.</li>
              <li>The Services are not available to temporarily or indefinitely suspended SureDone accounts; SureDone shall not be liable to Seller or any other party for any loss or damages arising from, or otherwise connected to, any such unavailability.</li>
              <li>SureDone reserves the right, in SureDone's sole and absolute discretion, to cancel unconfirmed or inactive accounts at any time.</li>
              <li>SureDone shall be entitled to refuse service, in SureDone's sole and absolute discretion, to anyone, for any reason, at any time.</li>
            </ol>
          </li>
          <li><u>Passwords</u>. Seller is responsible for safeguarding the password that Seller uses to access the Services and for any activities or actions under Seller's password. We encourage Sellers to use "strong" passwords (passwords that use a combination of upper and lower case letters, numbers and symbols) with their account. SureDone shall not be liable for any loss or damage arising from Seller's failure to comply with the above.</li>
        </ol>
      </li>
      <li><span>PRIVACY</span>
        <ol type='a'>
          <li>Any information that Seller provides to SureDone is subject to SureDone's Privacy Policy, located at https://www.suredone.com/privacy, which governs SureDone's collection and use of Seller's information. The Privacy Policy is incorporated into these Terms of Service as if fully set forth herein. In the event of any conflict between the Terms of Service and the Privacy Policy, the provisions of the Terms of Service shall supersede and control.</li>
          <li>Seller shall, and is hereby required, to establish, maintain, and enforce a privacy policy that complies with all applicable laws, rules and regulations including without limitation the treatment of all personal information in accordance with European Union and other non-U.S. applicable laws and governing authorities to the extent that Seller sells into international marketplaces or otherwise is subject to the laws of those authorities. Seller must secure Buyer information and not allow Buyer information to be disclosed except in accordance with Seller's privacy policy. Seller must establish security processes to protect personal information in accordance with applicable law and at least as restrictive as industry standards, but no less than reasonable care. Seller acknowledges that Seller's information and Seller's Buyers' information (personal or otherwise) may be transmitted to the United States or European Union and other non-U.S. jurisdictions as a result of SureDone providing the Services. In accessing the SureDone platform as permitted under the Terms, Seller must report any security breaches promptly to SureDone. Seller is responsible for any breaches of security that occur through Seller's access or login credentials of the SureDone platform.</li>
          <li>In cases where SureDone handles the personal information of California residents on Seller's behalf in connection with the Services, the parties will comply with the California Consumer Privacy Act of 2018, Cal. Civil Code § 1798.100 et seq., (“CCPA”) and the following terms will apply: (a) SureDone at all times under the Agreement is acting as Seller's Service Provider and has been engaged by Seller for the Business Purpose of providing the Services detailed in the Terms; (b) SureDone will not Sell Personal Information it handles in connection with the Services; (c) SureDone will not retain, use or disclose Personal Information for any purpose other than for the specific Business Purposes authorized by Seller; (d) SureDone will cooperate with Seller's reasonable requests related to individual requests involving Personal Information and comply with Seller's reasonable instructions as to the handling of that information to the extent required by applicable law. All capitalized terms in the foregoing sentence not otherwise defined in the Agreement shall have the meaning detailed in the CCPA.</li>
          <li>Seller grants SureDone permission to use the Seller trade names, trademarks, service marks, logos or other commercial or product designations (collectively, “Seller Materials”) in connection with SureDone Services, and materials and activities that promote SureDone's Services (collectively, “SureDone Materials”). This includes the worldwide right to use, copy, distribute, exhibit, translate, broadcast, transmit, perform, publish, display, alter and create derivative works from the SureDone Materials containing Seller Materials in any manner and in any media now or hereafter devised. SureDone shall have the right to edit, rearrange, vary, change, alter, modify, and delete Seller Materials in the SureDone Materials, so long as the use of Seller Materials is not misleading, disparaging or defamatory. SureDone is under no obligation to make use of or to provide compensation for any of the rights or permissions granted by this permission. SureDone shall be the exclusive owner of all right, title and interest, including copyright, in the SureDone Materials. Company hereby waives the benefits of any provision of law known as “droit moral” and/or “moral rights” or any similar law in any jurisdiction.</li>
        </ol>
      </li>
      <li><span>PAYMENT AND BILLING</span>
        <ol type='a'>
          <li><u>Fees</u>. Unless agreed upon in writing, Seller is responsible for paying monthly fees in advance of each month. Fees charged may change from time to time with one (1) month notice, posted on the SureDone site or sent in an electronic or written format, by SureDone. </li>
          <li><u>Billing Process</u>. The Services are available for a monthly, quarterly, yearly or multi-year license fee based on various factors including but not limited to a) the number of products managed through the Services, b) the number of listings Seller makes to connected services, c) the monetary volume of transactions completed through the Service per month, d) the number of orders completed per month, and/or e) the number of integrations or features enabled within the software component of the Services.</li>
          <li><u>Billing Cycle</u>. Seller's Billing Cycle initiates the day the account is provisioned.</li>
          <li><u>Authorization</u>. The Seller agrees to pay for the Services, and provides SureDone express authorization to charge all applicable fees to the Seller's payment provider at time of purchase and at each service interval. If the Seller's payment provider declines the charge, Seller expressly authorizes SureOne to charge any other payment provider they may have available for the Seller.</li>
          <li><u>Failure to Make Payment</u>. If payment is not current, SureDone may immediately cease to provide any and all services to the Seller. Any collection fees, attorney fees, court costs, or other costs incurred in collection of delinquent undisputed amounts shall be the responsibility of and paid for by Seller. </li>
          <li><u>Refunds</u>. Unless otherwise agreed upon in writing, the setup fee and monthly fee paid by Seller for use of the Services shall not be non-refundable, regardless of whether the Seller is terminated prior to the end of the then-current monthly billing period.</li>
          <li><u>Suitability</u>. By making the initial payment for service and using the Service, Seller acknowledges and agrees they have completed their due diligence for suitability to their requirements.</li>
          <li><u>Renewal</u>. Seller understands, acknowledges, and agrees that recurring charges will automatically renew using the Seller's current payment method. Seller EXPRESSLY CONSENTS to the automatic renewal and billing for the Services. If the current payment method is unsuccessful, Seller hereby authorizes SureDone to use an alternate payment type and consents to the initiation and collection of such charges. </li>
          <li><u>Changes</u>. SureDone reserves the right to change its fees or billing methods at any time. SureDone will provide timely notice to the affected Sellers of any such changes.</li>
          <li><u>Seller Responsibilities</u>. It is the Seller's responsibility to promptly provide SureDone with any contact or billing information changes or updates (including phone number, email address, credit card numbers, etc.). Account updates should be made online, via Seller's account, once Seller has logged onto the Services. SureDone does not validate all credit card information required by the Seller's payment provider to secure payment.</li>
          <li><u>Billing Disputes</u>. The Seller must notify SureDone about any billing problems or discrepancies within sixty (60) days after charges first appear on their Account statement. If it is not brought to SureDone's attention within sixty (60) days, Seller agrees to waive their right to dispute such problems or discrepancies. </li>
          <li><u>Change of Ownership</u>. The sale of a Seller's business or the change of ownership of a Seller's business does not discharge Seller from any payments required under these Terms.</li>
        </ol>
      </li>
      <li><span>LISTING, SELLING AND ORDERS</span>
        <ol type='a'>
          <li>All items offered through the Services must be for sale through a third-party solution including, but not limited to, eBay, Amazon, Walmart, BigCommerce or Shopify, or through the integrated SureDone storefront.</li>
          <li>By listing an item through the Services a Seller warrants that the Seller and all aspects of the item comply with SureDone's published policies. The Seller also warrants that he, she, or it may legally sell the item. </li>
          <li>Sellers shall be required to accurately describe their items, the item's condition and all terms of sale in their listings. A Seller's listing may only include text descriptions, graphics, pictures and other content relevant to the sale of that item. Each listing must accurately and completely describe the item(s) for sale in that listing. </li>
          <li>SureDone is not directly involved in the transaction between Buyers and Sellers. As a result, SureDone has no control over the quality, safety, morality or legality of any aspect of the items listed, the truth or accuracy of the listings, the ability of Sellers to sell items or the ability of Buyers to pay for items. SureDone does not pre-screen Sellers or Buyers or the content or information provided by Sellers or Buyers. SureDone cannot ensure that Sellers or Buyers will actually complete a transaction.</li>
          <li>SureDone does not transfer legal ownership of items from Sellers to Buyers. SureDone cannot guarantee the true identity, age, and nationality of a Seller or Buyer. SureDone encourages Sellers and Buyers to communicate directly with potential transaction partners through email or the tools available within the online marketplace Seller chooses to use in association with the Services. Sellers or Buyers may also wish to consider using a third-party escrow service or services that provide additional user verification.</li>
          <li>Sellers must outline their terms of sale for their SureDone listings. These terms may include, for example, shipping, returns, payment and selling terms. Sellers must create reasonable terms in good faith and must abide by such terms. All listing terms must comply with SureDone's Terms and policies. Sellers are responsible for enforcing their own reasonable policies. SureDone reserves the right to request that a Seller modify their policies.</li>
          <li>Sellers must comply with any terms and conditions, policies and/or privacy requirements placed on them by individual marketplaces, web commerce software or other systems using data stored within SureDone, and must also comply with any relevant laws or regulations by governmental or regulatory entities, including sales tax requirements. Failure to abide by these may result in immediate termination of Seller's license for the Services.</li>
          <li>Sellers are obligated to ship orders or otherwise complete the transactions with Buyers in a prompt manner and according to terms specified in their listings or by a marketplace, web commerce software or by SureDone, except when: (a) the Buyerfails to meet the terms of the Seller's listing (such as payment method), or (b) the Seller cannot authenticate the Buyer's identity. The Buyer is obligated to deliver appropriate payment for items purchased. The price stated in each listing description must be an accurate representation of the sale.</li>
        </ol>
      </li>
      <li><span>CONTENT ON THE SERVICES</span>
        <ol type='a'>
          <li>All Content, whether publicly posted or privately transmitted, is the sole responsibility of the Content originator. SureDone may or may not monitor or control the Content posted via the Services and cannot take responsibility for such Content. Any use or reliance on any Content or materials posted via the Services or obtained by Seller or Buyer through the Services is at Seller or Buyer's own risk.</li>
          <li>SureDone does not endorse, support, represent or guarantee the completeness, truthfulness, accuracy, or reliability of any Content or communications posted via the Services or endorse any opinions expressed via the Services. </li>
          <li>Buyer or Seller understand that by using the Services, they may be exposed to Content that might be offensive, harmful, inaccurate or otherwise inappropriate, or in some cases, postings that have been mislabeled or are otherwise deceptive. SureDone shall not be liable in any way or under any circumstance for any Content, including, but not limited to, any errors or omissions in any Content, or any loss or damage of any kind incurred as a result of the use of any Content posted, emailed, transmitted or otherwise made available via the Services or broadcast elsewhere.</li>
          <li>Seller Content shall not:
            <ol type='i'>
              <li>Be false, inaccurate, or misleading.</li>
              <li>Be fraudulent or involve the sale of illegal, counterfeit or stolen items.</li>
              <li>Infringe upon any third-party's copyright, patent, trademark, trade secret or other proprietary or intellectual property rights or rights of publicity or privacy.</li>
              <li>Violate these Terms, any SureDone policies or guidelines, or any applicable law, statute, ordinance or regulation (including, but not limited to, those governing export control, consumer protection, unfair competition, anti-discrimination, false advertising or price gouging).</li>
              <li>Contain items that have been identified by the U.S. Consumer Products Safety Commission (CPSC) as hazardous to consumers and therefore subject to a recall. </li>
              <li>Be defamatory, trade libelous, unlawfully threatening, unlawfully harassing, impersonate or intimidate any person (including SureDone staff or other users), or falsely state or otherwise misrepresent your affiliation with any person, through for example, the use of a similar email address, nickname, creation of a false account or any other method or device.</li>
              <li>Interfere with a Seller's business or listings</li>
              <li>Take any action that may undermine online reviews or feedback.</li>
            </ol>
          </li>
        </ol>
      </li>
      <li><span>SELLER RIGHTS</span>
        <ol type='a'>
          <li>Seller retains their rights to any Content they submit, post or display on or through the Services. By submitting, posting or displaying Content on or through the Services, Seller grants SureDone a worldwide, non-exclusive, royalty-free license (with the right to sublicense) to use, copy, reproduce, process, adapt, modify, publish, transmit, display and distribute such Content in any and all media or distribution methods (now known or later developed).</li>
          <li>Seller agrees that this license includes the right for SureDone to provide, promote, and improve the Services and to make Content submitted to or through the Services available to other companies, organizations or individuals who partner with SureDone for the syndication, broadcast, distribution or publication of such Content on other media and services, subject to SureDone's terms and conditions for such Content use.</li>
          <li>Additional uses by SureDone, or other companies, organizations or individuals who partner with SureDone, may be made with no compensation paid to Seller with respect to the Content that Seller submits, posts, transmits or otherwise makes available through the Services.</li>
          <li>SureDone may modify or adapt Seller's Content in order to transmit, display or distribute it over computer networks and in various media and/or make changes to Seller's Content as necessary to conform and adapt that Content to any requirements or limitations of any networks, devices, services or media.</li>
          <li>Seller is responsible for Seller's use of the Services, for any Content Seller provides, and for any consequences thereof, including the use of Seller's Content by other SureDone Sellers and SureDone third-party partners. Seller understands that their Content may be syndicated, broadcast, distributed, or published by SureDone partners and if Seller does not have the right to submit Content for such use, it may subject Seller to liability. SureDone will not be responsible or liable for any use of Seller Content by SureDone in accordance with these Terms. Seller represents and warrants that Seller has all the rights, power and authority necessary to grant the rights granted herein to any Content that Seller submits</li>
        </ol>
      </li>
      <li><span>SUREDONE RIGHTS</span>
        <ol type='a'>
          <li>All right, title, and interest in and to the Services (excluding Content provided by users) are and will remain the exclusive property of SureDone and its licensors.</li>
          <li>The Services are protected by copyright, trademark, and other laws of both the United States and foreign countries. </li>
          <li>Nothing in the Terms gives you a right to use the SureDone name or any of the SureDone trademarks, logos, domain names, and other distinctive brand features and/or variations and misspellings thereof except within listings to marketplaces or web commerce software, and on these locations solely to display a “Powered by SureDone” phrase along with the SureDone logo and optionally a link to the SureDone website. Any feedback, comments, or suggestions you may provide regarding SureDone, or the Services, is entirely voluntary and SureDone is free to use such feedback, comments or suggestions as SureDone sees fit and without any obligation to you.</li>
        </ol>
      </li>
      <li><span>RESTRICTIONS ON USE OF SERVICES</span>
        <ol type='a'>
          <li><u>Restrictions on Use</u>. You may not do any of the following while accessing or using the Services:
            <ol type='i'>
              <li>Access, tamper with, or use non-public areas of the Services, SureDone's computer systems, or the technical delivery systems of SureDone's providers.</li>
              <li>Probe, scan, or test the vulnerability of any system or network or breach or circumvent any security or authentication measures.</li>
              <li>Access or search or attempt to access or search the Services by any means (automated or otherwise) other than through our currently available, published interfaces that are provided by SureDone (and only pursuant to those terms and conditions), unless you have been specifically allowed to do so in a separate agreement with SureDone (NOTE: crawling the Services is permissible if done in accordance with the provisions of the robots.txt file, however, scraping the Services without the prior consent of SureDone is expressly prohibited).</li>
              <li>Forge any TCP/IP packet header or any part of the header information in any email or posting, or in any way use the Services to send altered, deceptive or false source-identifying information.</li>
              <li>Interfere with, or disrupt, (or attempt to do so), the access of any user, host or network, including, without limitation, sending a virus, overloading, flooding, spamming, mail-bombing the Services, or by scripting the creation of Content in such a manner as to interfere with or create an undue burden on the Services.</li>
              <li>Host images not part of a listing.</li>
              <li>Appear to create liability for SureDone or cause SureDone to lose (in whole or in part) the services of SureDone's ISPs or other suppliers.</li>
              <li>Link directly or indirectly, reference or contain descriptions of goods or services that are prohibited under this Agreement, or other policy or guideline documents as posted by SureDone.</li>
              <li>List any item for sale through the Services that, by paying to SureDone the listing fee or the final value fee, could cause SureDone to violate any applicable law, statute, ordinance or regulation, or that violates the Terms.</li>
            </ol>
          </li>
          <li>No rights to any source code are granted and Seller shall not itself, or through any third-party, reverse engineer, disassemble, decompile or otherwise attempt to derive source code or design information regarding any Technology and is not permitted to make any modifications to Technology. Seller may not remove or obscure any copyright, patent, trademark, trade secret or similar proprietary notice affixed to any Technology. Seller agrees that the underlying structure, sequence, organization and source code of the Technology are valuable trade secrets of SureDone and shall remain strictly confidential. Seller may not use the Technology for the purpose of bringing an Intellectual Property claim against SureDone or for the purpose of developing a product or service, or helping another develop a product or service, that is competitive with the Technology. </li>
        </ol>
      </li>
      <li><span>COPYRIGHT POLICY</span>
        <ol type='a'>
          <li>SureDone respects the intellectual property rights of others and requires users of the Services to do the same. SureDone will respond to notices of alleged copyright infringement that comply with applicable law and are properly provided to us. If you believe that your Content has been copied in a way that constitutes copyright infringement, please provide us with the following information:
            <ol type='i'>
              <li>Physical or electronic signature of the copyright owner or a person authorized to act on their behalf;</li>
              <li>Identification of the copyrighted work claimed to have been infringed;</li>
              <li>Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material;</li>
              <li>Your contact information, including your address, telephone number, and an email address;</li>
              <li>A statement by you that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and</li>
              <li>A statement that the information in the notification is accurate, and, under penalty of perjury, that you are authorized to act on behalf of the copyright owner.</li>
            </ol>
          </li>
          <li>SureDone reserves the right to remove Content alleged to be infringing without prior notice, at our sole discretion, and without liability to Seller or others. In appropriate circumstances, SureDone will also terminate a Seller's account due to copyright infringement. Our designated copyright agent for notice of alleged copyright infringement appearing on the Services is:<br /><br />
            SureDone, Inc. <br />
            Attn: Copyright Agent <br />
            115 E. 23rd St, FL 3 <br />
            New York, New York 10010 <br />
            Email: Support@SureDone.com
          </li>
        </ol>
      </li>
      <li><span>TERMINATION</span>
        <ol type='a'>
          <li>The Terms will continue to apply until terminated by either Seller or SureDone as follows.
            <ol type='i'>
              <li>Seller can cancel their account with 30 days prior notice by email to support@SureDone.com or by opening a ticket at https://support.suredone.com. Seller will receive an automated notice with a ticket ID in return. If Seller does not receive this automated notice, it is Seller's responsibility to follow up with SureDone to ensure receipt of Seller's cancellation request. All cancellation requests will be processed within five (5) business days. If a payment is due between the date of cancellation request and 30 days after the cancellation request, Seller will be responsible for this payment. Once the cancellation is processed, a confirmation email will be sent to the Seller's email account on record with SureDone. If Seller has a question about a cancellation, Seller should contact SureDone at support@SureDone.com. Seller shall remain liable for any payments still due under this agreement. </li>
              <li>SureDone may suspend or terminate Seller's account or cease providing Seller with all or part of the Services at any time for any reason, including, but not limited to, SureDone's reasonable suspicion or belief:
                <ol type='1'>
                  <li>Seller has violated these Terms or any SureDone policy or guideline, </li>
                  <li>Seller creates risk or possible legal exposure for SureDone; or </li>
                  <li>SureDone's provision of the Services to Seller is no longer commercially or financially viable. </li>
                </ol>
              </li>
              <li>SureDone will make reasonable efforts to notify Seller at the email address associated with Seller account or the next time Seller attempts to access their account.</li>
            </ol>
          </li>
          <li>If SureDone terminates a listing or Seller's account, if Seller closes their account, or if the payment of Seller's SureDone fees cannot be completed for any reason, Seller remains obligated to pay SureDone for all unpaid fees plus any penalties, if applicable. </li>
          <li>Upon termination, SureDone reserves the right, but is not obligated, to immediately delete Seller's Content from the Service and from any third-party system accessed using the Service.</li>
          <li>SureDone has the right to terminate this agreement when the Seller becomes insolvent, fails to pay its bills when due, makes
            an assignment for the benefit of creditors, goes out of business, or ceases production.
          </li>
        </ol>
      </li>
      <li><span>GENERAL DISCLAIMERS AND LIMITATIONS ON LIABILITY</span>
        <ol type='a'>
          <li>Please read this section carefully since it limits the liability of SureDone (collectively, the “SureDone Entities”). Each of the subsections below applies up to the maximum extent permitted under applicable law. Some jurisdictions do not allow the disclaimer of implied warranties or the limitation of liability in contracts, and as a result specific contents of this section may not apply to Seller. When a specific disclaimer or limitation is not permissible under local jurisdiction, they should be enforced to the maximum extent valid and enforceable. Nothing in this section is intended to limit any rights Seller may have which may not be lawfully limited.</li>
          <li>The Services Are Available “AS-IS.” Seller's access to and use of the Services or any Content are at Seller's own risk. Seller understands and agrees that the Services are provided to Seller on an "AS IS" and "AS AVAILABLE" basis.</li>
          <li><u>Limited Liability.</u> TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, SUREDONE MAKES NO WARRANTY AND DISCLAIMS ALL RESPONSIBILITY AND LIABILITY FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOOD-WILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM (i) SELLER ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICES; (ii) ANY CONDUCT OR CONTENT OF ANY THIRD-PARTY ON THE SERVICES, INCLUDING WITHOUT LIMITATION, ANY DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF OTHER USERS OR THIRD PARTIES; (iii) ANY CONTENT OBTAINED FROM THE SERVICES; OR (iv) UNAUTHORIZED ACCESS, USE OR ALTERATION OF SELLER TRANSMISSIONS OR CONTENT. IN ADDITION, SUREDONE MAKES NO WARRANTY AND DISCLAIMS ALL RESPONSIBILITY FOR: (i) THE COMPLETENESS, ACCURACY, AVAILABILITY, TIMELINESS, SECURITY OR RELIABILITY OF THE SERVICES OR ANY CONTENT; (ii) ANY HARM TO SELLER COMPUTER SYSTEMS, LOSS OF DATA, OR OTHER HARM THAT RESULTS FROM SELLER'S ACCESS TO OR USE OF THE SERVICES OR ANY CONTENT; (iii) THE DELETION OF, OR THE FAILURE TO STORE OR TRANSMIT, ANY CONTENT AND OTHER COMMUNICATIONS MAINTAINED BY THE SERVICES; AND (iv) WHETHER THE SERVICES WILL MEET SELLER'S REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE OR ERROR-FREE BASIS. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN OR TRANSMITTED ELECTRONICALLY, OBTAINED FROM SUREDONE OR THROUGH THE SERVICES, WILL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.<br /><br />
            IN NO EVENT SHALL THE AGGREGATE LIABILITY OF SUREDONE EXCEED THE GREATER OF ONE HUNDRED U.S. DOLLARS (U.S. $100.00) OR THE AMOUNT YOU PAID SUREDONE, IF ANY, IN THE SIX MONTHS PRIOR TO THE CLAIM FOR THE SERVICES GIVING RISE TO THE CLAIM.<br /><br />
            THE LIMITATIONS OF THIS SUBSECTION SHALL APPLY TO ANY THEORY OF LIABILITY, WHETHER BASED ON WARRANTY, CONTRACT, STATUTE, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, AND WHETHER OR NOT SUREDONE HAS BEEN INFORMED OF THE POSSIBILITY OF ANY SUCH DAMAGE, AND EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.
          </li>
          <li><u>Indemnification</u>. Seller shall indemnify, defend, and hold SureDone harmless from and against any and all claims, actions, suits, demands, assessments, or judgments asserted, and any and all losses, liabilities, damages, costs, and expenses (including, without limitation, attorneys fees, accounting fees, and investigation costs to the extent permitted by law) alleged or incurred arising out of or relating to any operations, acts, or omissions of the Seller or any of its employees, agents, and invitees in the exercise of the Seller's rights or the performance or observance of the Seller's obligations under this agreement. Prompt notice must be given of any claim, and the Seller will have control of any defense or settlement.</li>
        </ol>
      </li>
      <li><span>DISCLAIMERS AND LIMITATIONS ON LIABILITY REGARDING THIRD PARTIES</span>
        <ol type='a'>
          <li>SureDone may introduce Seller to third parties or Sellers may use third parties which may be Channels, services (i.e. website developers, template developers, others), software solutions (i.e. repricers, shipping and logistics software, web commerce software, others), suppliers or partners (i.e. distributors, manufacturers, warehouse companies, others) or other organizations. Whether this third party is paid directly by Seller or paid by SureDone, and whether or not this third party is directly connected to SureDone or data from this third party is used within SureDone or transmitted from or by SureDone using any method,
            <ol type='i'>
              <li>SureDone is not liable, financially or otherwise, for any commitments, promises, contracts or other agreements, whether in writing or otherwise, made by or to third parties.</li>
              <li>SureDone is not liable, financially or otherwise, for commitments made by third parties for entry into any third-party programs or for any services, discounts, waivers or other benefits of such programs or services, or any changes made to these programs or services, such as (but not limited to) Amazon white glove services, eBay seller scaling and other offerings.</li>
              <li>SureDone is not liable, financially or otherwise, for fees charged by third parties to Seller or Seller accounts. Any issues between Seller and third parties must be addressed directly with the third-party.</li>
              <li>SureDone is not liable, financially or otherwise, for the availability, timeliness or accuracy of third party data retrieved from, transmitted to or contained within SureDone, including but not limited to (1) Financial data such as, but not limited to, prices, costs, shipping costs, promotions, markups, markdowns, discounts, MAP pricing, sell prices, or any other financial data, (2) Any form of inventory data or product availability data, (3) Descriptive product content data such as, but not limited to, titles, descriptions, vehicle fitment information, images, categories, weights, sizes, colors or any other product content data, compliance with government or other laws or statutes, or compliance with marketplace or channel policies, (4) Order and Order tracking data, including, but not limited to, confirmation of order receipt, products ordered, purchasing information including, but not limited to, costs, shipping information, contact information, identifiers, order numbers, or shipment tracking information, or (5) Any other third party data used within SureDone, or received by or transmitted to a third party.</li>
              <li>SureDone is not liable, financially or otherwise, for changes to the format of data available from third parties or for changes to SureDone systms that may be required to maintain compatibility with third parties.</li>
              <li>SureDone is not liable, financially or otherwise, for the output of an Automation or decisions made within an Automation when that Automation is using third party data or accessing a third party system.
              </li><li>SureDone is not liable, financially or otherwise, for oversells, MAP violations, compliance violations, marketplace metric adjustments, channel or vendor or other suspensions, meeting channel or marketplace requirements, or any events, penalties or other results from the use of third party data or an Automation.</li>
            </ol>
          </li><li>The Services may contain links to third-party websites or resources. Seller acknowledges and agrees that SureDone shall not be liable or liable for: (i) the availability or accuracy of such websites or resources; or (ii) the content, products, or services on or available from such websites or resources. Links to such websites or resources do not imply any endorsement by SureDone of such websites or resources or the content, products, or services available from such websites or resources. Seller acknowledges sole responsibility for and assumes all risk arising from their use of any such websites or resources.</li>
          <li>SureDone shall not be liable for bugs in third-party software, delisting or listing errors due to third parties, or financial losses or business interruption due to actions or inactions of third parties. </li>
          <li>SureDone shall not be liable for recommending, specifying, installing, configuring, troubleshooting, identifying issues with, managing or optimizing the performance of, or any other action or concern of software or solutions of third parties. In some cases, SureDone may suggest the use of a specific software solution or other solution. It is the sole responsibility of the Seller to ensure the suggestion meets their needs and that third-party software or solutions are scaled, secured and configured correctly and appropriately to interface with SureDone.</li>
          <li>SureDone shall not be liable for the management of third-party service or consulting providers. Third-party service or consulting providers contracted by Seller will be considered equivalent to employees of Seller.</li>
          <li>At times, in order to solve potential problems or for other reasons, SureDone may require Seller to open a ticket or support request with a third-party. Seller agrees to open such tickets or support requests in a timely manner and will include SureDone in all communications with the third-party when possible.</li>
        </ol>
      </li>
      <li><span>USER DISPUTES</span>
        <ol type='a'>
          <li>In the event a dispute arises between Seller and another Seller or a third-party, SureDone encourages you to contact the user or third-party to resolve the dispute amicably.</li>
          <li>If a Buyer and Seller are unable to resolve a dispute resulting from a transaction that occurs through the Services, they may choose to enlist SureDone's mediation services in order to resolve the dispute. Cases are escalated for review and resolution by SureDone. SureDone provides mediation for the benefit of users. SureDone shall not have any obligation to resolve disputes between users or between users and third-parties; the decision to resolve any dispute shall be made in SureDone's sole and absolute discretion. SureDone shall be entitled to charge fees to perform any such dispute resolution. To the extent that SureDone attempts to resolve a dispute, SureDone will do so in good faith and based solely on SureDone's policies. SureDone will not make judgments regarding legal issues or claims. Seller may also report user-to-user disputes to your local law enforcement, postmaster general, or a certified mediation or arbitration entity, as applicable.</li>
          <li>Seller releases the SureDone Entities from any and all claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way connected with disputes with one or more Sellers, Buyers, or an outside party.</li>
        </ol>
      </li>
      <li><span>CALIFORNIA RESIDENTS</span>
        <ol type='a'>
          <li>Under California Civil Code Section 1789.3, California residents are entitled to the following specific consumer rights information:
            <ol type='i'>
              <li>Pricing Information. Current rates for our services may be obtained by calling us at 877-773-6755, or by sending an email to support@SureDone.com. SureDone reserves the right to change fees, surcharges, monthly or other periodic subscription fees or to institute new fees at any time as provided in this Agreement.</li>
              <li>Complaints. The Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs may be contacted in writing at 400 R Street, Suite 1080, Sacramento, CA 95814, or by telephone at (916) 445-1254 or (800) 952-5210.</li>
            </ol>
          </li>
        </ol>
      </li>
      <li><span>ONBOARD POLICIES</span>
        <ol type='a'>
          <li>Unless an onboard package is purchased, SureDone will only provide onboarding support through the avenues authorized in your Service tier. This may include one or more of the following: eLearning portal, SureDone discussion forums, SureDone office hours and ticket based support. SureDone support may choose to respond via a method not normally included within a tier but this does not signify that the Seller will continue to have access via this support method.</li>
          <li>If Seller has purchased an onboard package or has been provided with a free onboard package it may include, but is not limited to, a) a discovery and/or closing phone call regarding Seller's business and needs for using the Services, b) one on one time with a trained SureDone professional and/or c) additional Services such as data merging, custom automations, and eBay listing template conversion or creation done by a SureDone professional (collectively the “Onboard”). Not all onboard packages will include all of these services. The following are a set of policies designed to ensure the success of a new Seller Onboard that include guidelines and limitations (collectively “Onboard Policies”).
            <ol type='i'>
              <li>Seller must sign up for our online learning management system (https://elearn.suredone.com) and complete the available courses prior to scheduling their first call with SureDone team members.</li>
              <li>Generally Includes a discovery phone call with a SureDone professional discussing Seller business objectives and how the Services may be used to accomplish or accommodate these objectives.</li>
              <li>Generally Includes a check in and/or closing phone call with a SureDone professional discussing the recommendations offered in the discovery call and understanding what remaining education may be needed to make full use of the Services.</li>
              <li>Sellers may have limited access to one on one calls with SureDone personnel to discuss account aspects specific to the Seller. However, Sellers are expected to exhaust other avenues of support, such as office hours, elearning, and online guides and videos prior to scheduling a call. In addition, if calls are scheduled, Sellers are expected to be on the call at the designated scheduled time. If Sellers abuse call privileges then call privileges may be terminated by SureDone.</li>
            </ol>
          </li>
          <li>Once the Onboard is initiated, fees paid for the Onboard shall be non-refundable. Onboard fees will be charged prior to any work performed by SureDone personnel.</li>
          <li>If the Seller (a) does not make reasonable efforts to complete the onboard with 60 calendar days (exclusive of onboard delays of longer than three business days due to SureDone delays); (b) Does not complete applicable training; (c) Does not respond to SureDone questions in a timely manner; (d) Does not complete assigned tasks in a timely manner; or (e) Misses more than one scheduled appointment without reasonable notice, then the Onboard may be terminated at SureDone's discretion or additional fees may be charged.</li>
          <li>Unless otherwise approved by SureDone, Seller shall not have more than one designated individual participating in the Onboard process.</li>
          <li>Seller will be responsible for the payment of any SureDone Service fees during the Onboard.</li>
        </ol>
      </li>
      <li><span>GENERAL TERMS</span>
        <ol type='a'>
          <li><u>Governing Law; Disputes</u>. These Terms of Use shall be construed in accordance with and governed by the laws of the United States and the internal laws of the State of New York, without reference to their rules regarding conflicts of law. You irrevocably consent to the exclusive jurisdiction of the state or federal courts located in New York in all disputes arising out of or related to the use of the Site or Service. </li>
          <li>If you are a federal, state, or local government entity in the United States using the Services in your official capacity and legally unable to accept the controlling law, jurisdiction or venue clauses above, then those clauses do not apply to you. For such U.S. federal government entities, these Terms and any action related thereto will be governed by the laws of the United States of America (without reference to conflict of laws) and, in the absence of federal law and to the extent permitted under federal law, the laws of the State of New York (excluding choice of law).</li>
          <li>Any claim under these Terms, except for a financial dispute which shall be governed by paragraph 6.11, must be brought within one (1) year after the cause of action arises, or such claim or cause of action shall be barred. Claims made under the separate terms and conditions of purchase for goods and services are not subject to this limitation. No recovery may be sought or received for damages other than out-of-pocket expenses, except that the substantially prevailing party shall be entitled to reasonable costs and documented attorneys' fees. In the event of any controversy or dispute between SureDone and Seller arising out of or in connection with Seller's use of the Site, the parties shall attempt, promptly and in good faith, to resolve any such dispute by non-litigious means. If parties are unable to resolve any such dispute within thirty (30) days, then either party may submit such controversy or dispute to mediation within New York State. If parties are unable to resolve any such dispute via mediation within ninety (90) days, then parties agree the suit will be resolved by binding arbitration, rather than in court, under the American Arbitration Association's Rules for Arbitration of Consumer Related Disputes.</li>
          <li><u>Severability; Waiver</u>. The failure of SureDone to enforce any right or provision of these Terms will not be deemed a waiver of such right or provision. In the event that any provision of these Terms is held to be invalid or unenforceable, then that provision will be limited or eliminated to the minimum extent necessary to make it valid and enforceable, and the remaining provisions of these Terms will remain in full force and effect.</li>
          <li><u>Force Majeure</u>. SureDone shall not be held responsible for any delay or failure in performance of any part of this agreement to the extent such delay or failure is caused by fire, flood, explosion, war, embargo, government requirement, civil or military authority, act of God, pandemic or other similar causes beyond its control and without the fault or negligence of the delayed or non-performing party. The affected party will notify the other party in writing or electronic mail within ten (10) days after the beginning of any such cause that would affect its performance.</li>
          <li><u>Successors and Assigns</u>. This agreement shall be binding upon and inure to the benefit of the parties hereto and their respective heirs, legal or personal representatives, successors, and assigns.</li>
          <li><u>Survival</u>. All provisions that logically ought to survive termination of this agreement shall survive.</li>
          <li><u>No Agency</u>. No agency, partnership, joint venture, employee-employer or franchiser-franchisee relationship is intended or created by this Agreement.</li>
          <li><u>Entire Agreement</u>. These Terms and our Privacy Policy are the entire and exclusive agreement between SureDone and Seller regarding the Services (excluding any services for which Seller has a separate agreement with SureDone that is explicitly in addition to or explicitly in place of these Terms), and these Terms supercede and replace any prior agreements between SureDone and Seller regarding the Services. The terms of this Agreement shall control over any conflicting terms in any referenced agreement or document. Any specific right or remedy provided in this contract will not be exclusive but will be cumulative of all other rights and remedies.</li>
        </ol>
      </li>
    </ol>
    <p>We may revise these Terms from time to time. The most current version will always be at https://www.suredone.com/terms/. If the revision, in our sole discretion, is material, we will notify Seller via e-mail to the email associated with Seller account. By continuing to access or use the Services after those revisions become effective, Seller agrees to be bound by the revised Terms.</p>
    <p>These Services are operated and provided by SureDone, Inc., 115 E. 23rd St, 3rd Floor New York, New York 10010. If you have any questions about these Terms, please contact us.</p>
  </div>
)
