import React, { useState } from 'react'
import { useLocation } from 'react-router'
import { TablePicker } from '../../../components/TablePicker'
import { AuthLinkButton } from './AuthLinkButton'

const getNum = num => num > 0 ? num : ''

const SelectInstance = ({ instances }) => {
  const location = useLocation().search
  const [pickedItem, setPickedItem] = useState(instances[0].id)
  const selectedInstance = (instances || []).find(i => i.id === pickedItem)
  const { instance: instanceNumber, channel } = selectedInstance || {}
  const { name, slug } = channel || {}

  const items = instances.map(instance => {
    const { instance: number, channel } = instance
    return {
      ...instance,
      title: `${channel.name} ${getNum(number)}`,
      subTitle: `${channel.name} instance ${getNum(number)}`
    }
  })

  return (
    <>
      <TablePicker items={items} parentCallback={setPickedItem} pickedItem={pickedItem} />
      <div className='d-flex justify-content-end mt-4'>
        <AuthLinkButton
          href={`/auth/${slug}/${instanceNumber}${location}`}
          text={`Authorize ${name} ${getNum(instanceNumber)}`}
        />
      </div>
    </>
  )
}

export default SelectInstance
