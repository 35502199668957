import React from 'react'
import { Badge, Card, CardHeader, CardBody, CardFooter, Col, Table } from 'reactstrap'
import {
  FaCog as Settings,
  FaAngleRight,
  FaExchangeAlt as ChannelsIcon
} from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { Loadable } from '../../../components'
import { icons } from '../../Channel/ChannelsLogos'

import { useQuery, useApolloClient } from '@apollo/react-hooks'
import { slugToIcon, getLink, GET_CHANNEL } from '../../Channel'
import { AVAILABLE_CHANNELS } from '../../Channel/commonQueries'
import { GET_STOREFRONT } from '../../../components/commonQueries'
import { channelsWithNewUI } from 'suredone-common'

const Channels = ({ data: fromPropsData }) => {
  const { channelsTitle } = fromPropsData
  const { data: dataStorefront, loading: loadingStorefront, error: errorStorefront } = useQuery(GET_STOREFRONT)
  const { StorefrontStatus } = dataStorefront || {}
  const { live } = StorefrontStatus || {}
  const isStorefrontEnabled = live === 'on'

  const client = useApolloClient()
  const onCompleted = (result) => {
    const { ChannelInstances } = result || { ChannelInstances: [] }
    const { AvailableChannels } = result || { AvailableChannels: [] }
    AvailableChannels.forEach(channel => {
      const { slug, ...rest } = channel
      const data = {
        Channel: { slug, __typename: 'Channel', ...rest },
        ChannelInstances
      }
      client.writeQuery({
        query: GET_CHANNEL,
        variables: { slug },
        data
      })
    })
  }

  const { loading, error, data } = useQuery(AVAILABLE_CHANNELS, { onCompleted })
  const { ChannelInstances } = data || { ChannelInstances: [] }

  return (
    <Col xs='12' xl='4'>
      <Card className='channels-card'>
        <CardHeader>
          <h3 className='card-title mb-0'><ChannelsIcon /> {channelsTitle}</h3>
        </CardHeader>
        <CardBody className='p-3'>
          <Loadable
            inline
            loading={loading || loadingStorefront}
            error={error || errorStorefront}
            style={{ minHeight: '175px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            content={
              <Table responsive className='mb-0'>
                <tbody>
                  {ChannelInstances.map(({ id, instance, enabled, channel: { slug, name }, customLabel }) =>
                    <tr key={id}>
                      <td className='d-flex align-items-center justify-content-between px-0 py-2'>
                        <div className='d-flex align-items-center'>
                          <span className='mr-1'>{slugToIcon.get(slug) || icons[slug]}</span>
                          <span>{customLabel || name + (instance ? ` ${instance}` : '')}</span>
                          {!enabled ? <Badge color='danger' pill className='ml-2 custom-badge'>disabled</Badge> : null}
                        </div>
                        {channelsWithNewUI.includes(slug)
                          ? (
                            <Link to={getLink(slug, instance)} className='btn btn-secondary btn-xs ml-2'>
                              <Settings style={{ margin: 0, marginLeft: '2px' }} />
                            </Link>)
                          : (
                            <a href={getLink(slug, instance)} className='btn btn-secondary btn-xs ml-2'>
                              <Settings style={{ margin: 0, marginLeft: '2px' }} />
                            </a>)}
                      </td>
                    </tr>
                  )}
                  <tr key='storefront'>
                    <td className='d-flex align-items-center justify-content-between px-0 py-2'>
                      <div className='d-flex align-items-center'>
                        <span className='mr-1'>{icons.storefront}</span>
                        <span>SureDone Storefront </span>
                        {!isStorefrontEnabled ? <Badge color='danger' pill className='ml-2 custom-badge'>disabled</Badge> : null}
                      </div>
                      <a href={getLink('storefront', 0)} className='btn btn-secondary btn-xs ml-2'>
                        <Settings style={{ margin: 0, marginLeft: '2px' }} />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </Table>
            }
          />
        </CardBody>
        <CardFooter className='d-flex justify-content-center align-items-center px-3 py-2'>
          <Link to='/channels/add'>Authorize more channels <FaAngleRight /></Link>
        </CardFooter>
      </Card>
    </Col>
  )
}

export { Channels }
