import React from 'react'
import Debug from 'debug'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, ButtonGroup, Row, Col } from 'reactstrap'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { MFA_REQUIRED_ERROR } from 'suredone-common'
import { MFAEnableButton, MFAIntroText } from './MFAEnableButton'
import { FaSignOutAlt as LogOut, FaExclamationTriangle as Warning } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { useStores } from '../../stores/RootStore'

const debug = Debug('sd:MFARequiredWall')

const SHOW_MFA_REQUIRED_WALL = gql`
  query showMfaRequiredWall {
    showMfaRequiredWall
  }
`

export function MFARequiredWall (props) {
  const { error } = useQuery(SHOW_MFA_REQUIRED_WALL)
  const { stores: { auth } } = useStores()

  const errorCode = error?.networkError?.result?.code

  return (
    <Modal isOpen={errorCode === MFA_REQUIRED_ERROR.code} backdrop='static' keyboard={false} centered>
      <ModalHeader>
        <Row>
          <Col className='col-auto p-1 pl-2'>
            <Warning size={48} className='text-warning' />
          </Col>
          <Col auto>
            Your account requires two-factor authentication
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <MFAIntroText />
        <MFAEnableButton />
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button tag={Link} color='link' to='/' onClick={_ => auth.logOut()}>
            <LogOut /> Log Out
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  )
}

debug('loaded')
