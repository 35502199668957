import React, { useState } from 'react'
import { FormGroup, Input, Label, FormFeedback, UncontrolledAlert } from 'reactstrap'
import { Form, Field } from 'react-final-form'
import { ActionButton, ErrorAlert } from '../'
import { EMAIL } from 'suredone-common'
import { useMutation } from '@apollo/react-hooks'

const SendVerificationCodeFormComponent = (props) => {
  const { handleSubmit, loadings, customErrors, setCustomErrors, success, setSuccess, values, setEmail } = props
  return (
    <form onSubmit={handleSubmit}>
      <FormGroup>
        <Label>Enter the new email, we will send you a verification code</Label>
        <Field
          name='email'
          validate={email => { if (!EMAIL.test(email)) return 'Invalid email' }}
        >
          {({ input, meta }) => {
            return (
              <FormGroup>
                <Input
                  {...input}
                  type='email'
                  invalid={meta.touched && meta.invalid}
                  placeholder='Email'
                  onChange={(e) => {
                    input.onChange(e)
                    setEmail(e.target.value)
                  }}
                />
                {meta.error && meta.touched && <FormFeedback>{meta.error}</FormFeedback>}
                {customErrors && <ErrorAlert isOpen={customErrors} toggle={() => setCustomErrors(false)} error='An error ocurred. Please try again later.' />}
                {success && <UncontrolledAlert className='mt-2' color='success' toggle={() => setSuccess(false)}> The verification code was sent to <b>{values.email}</b> </UncontrolledAlert>}
                <ActionButton color='link' type='submit' disabled={loadings || meta.invalid || success} loading={loadings} className='text-primary pl-0'>
                  Send me a verification code
                </ActionButton>
              </FormGroup>
            )
          }}
        </Field>
      </FormGroup>
    </form>
  )
}

const SendVerificationCodeForm = ({ SendVerificationCodegql, mutationVariables, setEmail }) => {
  // Errors and success for send code to email
  const [sendVerificationCodeError, setSendVerificationCodeError] = useState(false)
  const [sendVerificationCodeSuccess, setSendVerificationCodeSuccess] = useState(false)
  const [sendVerificationCode, { loading: sendingVerificationCode }] = useMutation(SendVerificationCodegql,
    {
      onCompleted: (e) => {
        setSendVerificationCodeSuccess(true)
      },
      onError: (e) => {
        setSendVerificationCodeError(true)
      }
    }
  )
  return (
    <Form
      onSubmit={({ email }) => sendVerificationCode({ variables: { ...mutationVariables, email } })}
      customErrors={sendVerificationCodeError}
      setCustomErrors={setSendVerificationCodeError}
      success={sendVerificationCodeSuccess}
      setSuccess={setSendVerificationCodeSuccess}
      loadings={sendingVerificationCode}
      setEmail={setEmail}
    >
      {props => <SendVerificationCodeFormComponent {...props} />}
    </Form>
  )
}

export { SendVerificationCodeForm }
