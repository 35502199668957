import gql from 'graphql-tag'

export const GET_CHANNEL_INFO = gql`
  query GetChannelInfo ($slug: String!, $instanceNumber: Int!) {
    Channel (slug: $slug) {
      id
      name
      import_rate # products per minute
    }
    # 
    ChannelInstance(slug: $slug, instanceNumber: $instanceNumber) {
      id
      enabled
      authStatus
    }
    # 
    ChannelImport(slug: $slug, instanceNumber: $instanceNumber) {
      id
      tableId
      slug
      instance
      organizationId
      status
      importFileName
      importFileUrl
      creationDate
      creationFileDate
      loadingTable
      importType
      importAction
      estimatedTime
      loadedIntoSD
      legacyJobId
    }
  }
`

export const GET_IMPORT_FILE_TABLE = gql`
  query GetImportFileTable ($tableId: ID!, $start: Int!, $limit: Int!) {
    Table(tableId: $tableId) {
      id
      name
      description
      loading
      editable
      numRows
      columns {
        id
        name
      }
      loadError {
        message
        code
        statusCode
      }
    } 
    TableRows(tableId: $tableId, start: $start, limit: $limit) {
      id
      index
      data {
        columnId
        value
      }
    }
  }
`

export const GET_BULK_STATUS_SUMMARY = gql`
  query BulkStatusSummary ($jobId: String!, $updateAfterSeconds: Int) {
    BulkStatusSummary(jobId: $jobId, updateAfterSeconds: $updateAfterSeconds) {
      id
      legacyAccountId
      legacyJobId
      type
      status
      chunker
      database
      channel
      media
      finisher
      startTime
      lastUpdateTime
      endTime
      cancelRequested
      rowsProcessed
      rowsTotal
      rowsTotalMedia
      chunksProcessed
      chunksTotal
      chunksTotalMedia
      errors
    } 
  }
`

export const START_FULL_IMPORT = gql`
    mutation StartFullImport($slug: String!, $instanceNumber: Int!, $input: StartImportInput) {
      StartFullImport(slug: $slug, instanceNumber: $instanceNumber, input: $input) {
        id
        slug
        instance
        organizationId
        status
        creationDate
        creationFileDate
        loadingTable
        importType
        importAction
        estimatedTime
        importFileUrl
        importFileName 
      }
    }
  `

export const START_SAMPLE_IMPORT = gql`
    mutation StartSampleImport($slug: String!, $instanceNumber: Int!) {
      StartSampleImport(slug: $slug, instanceNumber: $instanceNumber) {
        id
        slug
        instance
        organizationId
        status
        creationDate
        creationFileDate
        loadingTable
        importType
        importAction
        estimatedTime
        importFileUrl
        importFileName 
      }
    }
  `

export const UPLOAD_IMPORT = gql`
    mutation UploadImport($id: ID!, $skipUpdate: [String]) {
      UploadImport(id: $id, skipUpdate: $skipUpdate) {
        id
        slug
        instance
        organizationId
        status
        creationDate
        creationFileDate
        loadingTable
        importType
        importAction
        estimatedTime
        loadedIntoSD
        legacyJobId
        uploadError
      }
    }
  `
