import gql from 'graphql-tag'

export const GET_SAVED_QUERIES = gql`
  query GetSavedQueries {
    GetSavedQueries {
      label
      query
      type
    }
  }
`
