import React from 'react'
import Avatar from 'react-avatar'
import Debug from 'debug'

const debug = Debug('sd:OrganizationAvatar')

export function OrganizationAvatar (props) {
  const { organization } = props
  const { id, name } = organization
  const reversedId = id.slice(5).split().reverse().join()// + id.slice(0, 5)
  // debug('revesedId', reversedId, primaryEmail)
  return (
    <Avatar
      {...props}
      round='5px'
      name={name}
      // email={primaryEmail}
      color={Avatar.getRandomColor(reversedId)}
    />
  )
}

debug('loaded')
