import React from 'react'
import { Link } from 'react-router-dom'
import { Notification } from './Notification'
import { launchContact } from '../utils'
import { Button } from 'reactstrap'

/**
 * Trial notification
 */
export const trialNotification = ({ boughtPlanButStillOnTrial, trialExpired, daysLeft, fourteenDaysReached }) => {
  const trialNotification = (
    <>
      Your {fourteenDaysReached ? 'premium' : 'unlimited'}{' '}
      {boughtPlanButStillOnTrial && <strong>trial</strong>}{' '}
      access{' '}
      {trialExpired
        ? <><strong>expired</strong> and you are now on the free <strong>Go! plan</strong>.</>
        : <strong>expires {daysLeft}.</strong>}
    </>
  )
  const webinaInfo = (
    <>
      Stuck? {' '}
      <a href='https://suredone.zoom.us/webinar/register/5716027987551/WN_d47o3tpXQny00czUqEoqEg' target='_blank' rel='noopener noreferrer'>
        Sign up for our demo webinar!
      </a>
    </>
  )
  const trialAction = { title: 'Upgrade plan', tag: 'link', link: '/settings/billing' }

  return (
    <Notification
      showBullet
      action={trialAction}
      message={trialNotification}
      extraMessage={webinaInfo}
    />
  )
}

/**
 * Stripe status notification
 * See https://stripe.com/docs/billing/subscriptions/overview#subscription-statuses
 */
export const stripeStatusNotification = ({ status }) => {
  // eslint-disable-next-line
  switch (status) {
    case 'past_due':
      return (
        <Notification color='danger'>
          <b>Your latest invoice is past due, there may be an issue with your <Link to='/settings/billing/payment'>payment method (click here)</Link>.</b>
        </Notification>
      )
    case 'unpaid':
      return (
        <Notification color='danger'>
          <b>Your <Link to='/settings/billing/invoices'>latest invoice</Link> has not been paid</b>
        </Notification>
      )
    case 'incomplete':
      return <Notification color='danger' message='Your subscription was created but the payment failed. Please contact support.' />
    case 'canceled':
      return <Notification color='danger' message='Your subscription has been canceled.' />
  }
}

/**
 * Token notifications helpers
 */
const generateMessage = (data) => data.length > 1 ? 'these channels' : 'this channel'
const generateLinks = (data) => data.map(({ channel: { slug }, instance }, idx) => {
  const isTheLast = data.length === idx + 1
  return (
    <span key={slug + idx}>
      <Link to={`/channels/${slug}/${instance}/authorization`} className='ml-1'>
        {slug}{instance > 0 && instance}
      </Link>{isTheLast ? '.' : ','}
    </span>
  )
})

/**
 * Close to expire token notification
 */
export const closeToExpireTokenNotification = ({ channelsCloseToExpire }) => {
  return (
    <Notification>
      <p>
        The Authorization Token of {generateMessage(channelsCloseToExpire)} is <strong>close to expiring</strong>: {generateLinks(channelsCloseToExpire)}
      </p>
    </Notification>
  )
}

/**
 * Already expired token notification
 */
export const alreadyExpiredTokenNotification = ({ channelsAlreadyExpired }) => {
  return (
    <Notification color='danger'>
      <p>
        The Authorization Token of {generateMessage(channelsAlreadyExpired)} is <strong>expired</strong>: {generateLinks(channelsAlreadyExpired)}
      </p>
    </Notification>
  )
}

/**
 * Link to new tab Notification
 */
export const isOnAnIframe = window?.location !== window?.parent?.location
export const openNewTabNotification = (to) => {
  return (
    <Notification color='info'>
      <p>
        In order to use this app fully, please open SureDone in another browser tab
        <a target='_blank' rel='noopener noreferrer' href={to} className='ml-1'>
          Click here
        </a>
      </p>
    </Notification>
  )
}

/**
 * The Account is on hold stripe plan
 */
export const onHoldNotification = () => {
  return (
    <Notification color='danger'>
      <p>
        Your account is ON HOLD - Please contact support
        <Button color='link' className='p-0 ml-1' onClick={launchContact}>
          Contact support
        </Button>
      </p>
    </Notification>
  )
}
