import React, { useState, useEffect } from 'react'
import { FacetSummary } from '..'
import { capitalize } from './SettingsContainer'
import { Card, Form, CardBody } from 'reactstrap'
import { MultiFacetsModal } from './MultiFacetsModal'
import { useStores } from '../../../stores/RootStore'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { ResourceList } from '../../../components/SearchableLists'
import { SaveButton, Loadable, ErrorAlert } from '../../../components'
import { GET_DEFAULT_FACETS, GET_AVAILABLE_FACETS, GET_ACTIVE_FACETS, SET_FACETS } from '../commonQueries'

export const CustomFacets = ({ slug, instance }) => {
  const context = slug + instance
  const { stores: { auth } } = useStores()
  const [facetsThatAreMultiple, setFacetsThatAreMultiple] = useState([])
  const [showMultiFacetsModal, setShowMultiFacetsModal] = useState(false)
  const toggleMultiFacetsModal = () => setShowMultiFacetsModal(!showMultiFacetsModal)
  const [isDirty, setIsDirty] = useState(false)
  const [success, setSuccess] = useState(false)
  const [rows, setRows] = useState()
  const { data: defaultFacetsData } = useQuery(GET_DEFAULT_FACETS) // Gets default facets (can't be removed)
  const { getDefaultFacetsObjects: defaultFacets } = defaultFacetsData || []
  const { data: allFacetsData, loading: allFacetsLoading } = useQuery(GET_AVAILABLE_FACETS) // Gets all posible facets (including the defaults)
  const { getAvailableFacetsObjects: allFacets } = allFacetsData || []
  const { data: ciData, loading: ciLoading } = useQuery(GET_ACTIVE_FACETS, { // Get active facets, set by the user previously
    fetchPolicy: 'network-only',
    variables: { slug, instanceNumber: Number(instance) }
  })
  const { getFacetsByChannelInstance: facetsByInstance } = ciData || {}
  const [updateActiveFacets, { loading: mLoading, error: mError }] = useMutation(SET_FACETS,
    { onCompleted: () => setSuccess(true) }
  )

  useEffect(() => {
    const fullRows = allFacets?.map((i, idx) => ({
      ...i,
      valueObject: {
        value: facetsByInstance?.includes(i.name)
      },
      key: i.name + idx,
      generalIndex: idx
    }))
    setRows(fullRows)
    // eslint-disable-next-line
  }, [allFacets, facetsByInstance])

  const handleChangeValue = (event, field) => {
    setIsDirty(true)
    setSuccess(false)
    const newConfig = {
      modified: true,
      value: event.target.checked
    }
    rows[field.generalIndex].valueObject = newConfig
  }

  const handleSubmit = e => {
    e.preventDefault()
    setIsDirty(false)
    toggleMultiFacetsModal()
  }

  const confirmSave = () => {
    toggleMultiFacetsModal()
    const facetsToSave = rows?.filter(({ valueObject: vo }) => vo?.value)?.map(i => i?.name)
    updateActiveFacets({
      variables: {
        slug,
        instanceNumber: Number(instance),
        newCustomFacetSet: facetsToSave,
        multiFacetsSet: facetsThatAreMultiple
      },
      onCompleted: () => {
        auth.refresh()
      }
    })
  }

  return (
    <>
      <Card>
        <CardBody>
          <h5>Custom facets for {capitalize(slug)} {Number(instance) ? instance : ''}</h5>
          <p>Your active facets are: <b>{defaultFacets?.join(', ')}{facetsByInstance?.length ? ',' : ''} {facetsByInstance?.join(', ')}</b></p>
          <Loadable
            inline
            style={{ padding: '1.5rem 1rem 1rem' }}
            loading={allFacetsLoading || ciLoading}
            content={
              <Form onSubmit={handleSubmit}>
                <ResourceList
                  className='channel-management'
                  items={rows}
                  searchableStrings={({ label, name }) => [label, name]}
                  itemComponent={
                  ({ item: field, searchTerms }) =>
                    <li className='list-group-item-flush d-flex flex-sm-row flex-column list-group-item-action list-group-item p-0'>
                      <FacetSummary
                        field={field}
                        context={context}
                        searchTerms={searchTerms}
                        defaultFacets={defaultFacets}
                        handleChange={handleChangeValue}
                      />
                    </li>
                }
                />
                {mError && <ErrorAlert error={mError} />}
                <div className='d-flex justify-content-end'>
                  <SaveButton
                    color='primary'
                    loading={mLoading}
                    disabled={!isDirty || mLoading}
                    success={success ? 'true' : null}
                    dirtysincelastsubmit={isDirty ? 'true' : null}
                  />
                </div>
              </Form>
          }
          />
        </CardBody>
      </Card>

      <MultiFacetsModal
        isOpen={showMultiFacetsModal}
        onAccept={e => confirmSave(e)}
        toggleMultiFacetsModal={toggleMultiFacetsModal}
        setFacetsThatAreMultiple={setFacetsThatAreMultiple}
        facetsToSave={rows?.filter(({ valueObject: vo }) => vo?.value)?.map(i => i?.name)}
      />
    </>
  )
}
