import React from 'react'
import { CustomFacets, ExternalChannels } from '..'

export const SettingsShopify = ({ routeProps }) => {
  const { match: { params: { instance } } } = routeProps

  return (
    <>
      <CustomFacets slug='shopify' instance={instance} />
      <ExternalChannels name='Shopify' href='https://shopify.com/login' />
    </>
  )
}
