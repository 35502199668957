import React from 'react'
import { Card, CardHeader, CardBody } from 'reactstrap'
import { Loadable } from '../../../components'
import { SDLineChart } from './SDLineChart'
import { withComma, arrSum, generateMargin } from './utils'

const ShippingTotal = ({ title, data, sampleData, useSample, loading, error }) => {
  const { GetDashboardData } = data || {}
  const { stotals } = GetDashboardData || {}
  const totals = arrSum(stotals, 'value')
  const mLeft = useSample ? -30 : generateMargin(stotals)

  return (
    <Card>
      <CardHeader className='d-flex justify-content-between'>
        <span>{title}</span>
        {totals > 0 && <h6 className='mb-0'><Loadable loading={loading} inline content={`$${withComma(totals)}`} /></h6>}
      </CardHeader>
      <CardBody className='pl-0'>
        <Loadable
          loading={loading}
          inline
          error={error}
          style={{ minHeight: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          content={<SDLineChart data={useSample ? sampleData.totalShipping : stotals} name='Shipping total' mLeft={mLeft} />}
        />
      </CardBody>
    </Card>
  )
}

export { ShippingTotal }
