import gql from 'graphql-tag'

export const GET_QUALIFICATIONS = gql`
  query GetQualifications($email: String!) {
    GetQualifications(email: $email) {
      email
      calendlyData
      userId
      firstName
      lastName
      company
    }
  }
`

export const FINISH_QUALIFICATIONS = gql`
  mutation FinishQualifications($qualifications: QualificationsInput) {
    FinishQualifications(qualifications: $qualifications)
  }
`

export const STORE_PAGE_1_QUALIFICATIONS = gql`
  mutation StorePage1Qualifications($qualifications: QualificationsInput) {
    StorePage1Qualifications(qualifications: $qualifications)
  }
`

export const UPDATE_QUALIFICATIONS = gql`
  mutation UpdateQualifications($qualifications: QualificationsInput) {
    UpdateQualifications(qualifications: $qualifications)
  }
`

export const CREATE_LEGACY_USER = gql`
  mutation CreateLegacyUser($qualifications: QualificationsInput) {
    CreateLegacyUser(qualifications: $qualifications)
  }
`

export const IS_ANY_SUPPORT_AVAILABLE = gql`
  query IsAnySupportAvailable {
    IsAnySupportAvailable
  }
`

export const SET_NEXT_HOUR_CALL = gql`
  mutation SetNextHourCall($qualifications: QualificationsInput) {
    SetNextHourCall(qualifications: $qualifications)
  }
`
