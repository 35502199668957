import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import { LoggedOutPage } from '../../components/LoggedOutPage'

export const AutomatedReferral = () => {
  return (
    <LoggedOutPage>
      <Card>
        <CardBody className='px-3 py-4'>
          <p className='m-0'>
            We don't think SureDone is a perfect fit for your business. If you'd like to try it out anyway, please click on the link below. <br />
            <Link to='/register' className='btn btn-primary mt-3'>
              Try Suredone
            </Link>
          </p>
        </CardBody>
      </Card>
    </LoggedOutPage>
  )
}
