import React, { useState } from 'react'
import { TablePicker } from '../../../../components'
import { AuthLinkButton } from '../AuthLinkButton'

export const SelectOnBoard = ({ slug, instanceNumber, commerceManagers }) => {
  const [pickedCommerce, setPickedCommerce] = useState(commerceManagers[0].page_id)
  const selectedCommerce = (commerceManagers || []).find(i => i.page_id === pickedCommerce)
  const { page_name: pageName, cms_id: cmsId } = selectedCommerce || {}
  const items = commerceManagers.map(cm => {
    const { page_id: pageId, page_name: pageName, page_category: pageCategory } = cm
    return {
      ...cm,
      id: pageId,
      title: `${pageName}`,
      subTitle: `${pageCategory}`
    }
  })

  return (
    <>
      <TablePicker items={items} parentCallback={setPickedCommerce} pickedItem={pickedCommerce} />
      <div className='d-flex justify-content-end mt-4'>
        <AuthLinkButton
          href={`/auth/${slug}/${instanceNumber}?cms_id=${cmsId}`}
          text={`Authorize ${pageName}`}
        />
      </div>
    </>
  )
}
