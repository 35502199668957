import React from 'react'
import { Link } from 'react-router-dom'
import { ActionButton } from './ActionButton'
import { FaEye as Eye } from 'react-icons/fa'
import { defaultLogsSearch } from '../scenes/Logs'
import { useStores } from '../stores/RootStore'

export const ViewLogs = ({ organizationId }) => {
  const { stores: { auth } } = useStores()
  const showButton = auth.permissions.hasScope('platform:allOrganizations:logs:read')

  if (showButton) {
    return (
      <ActionButton
        tag={Link}
        icon={<Eye />}
        className='mr-1'
        to={`/logs/${organizationId}?${defaultLogsSearch}`}
      >
        View logs
      </ActionButton>
    )
  } else return null
}
