import React from 'react'
import { Button } from 'reactstrap'

const successResults = ['success', 'complete', 'submitted']
const errorResults = ['error', 'failure']

const generateColor = result => {
  if (successResults.includes(result)) return 'success'
  if (errorResults.includes(result)) return 'danger'
  return 'warning'
}

export const Status = ({ data, setDetailedStatus, isFrame, organizationId }) => {
  const { lastLog } = data || {}
  const { _id: lsLogId, context, instance, result } = lastLog || {}

  const handleClick = () => setDetailedStatus(data)

  const trackEvent = () => {
    if (window.analytics) {
      const currentEvent = 'Status: Clicked'
      const currentHistory = window?.analyticsEventHistory || []
      const newHistory = [currentEvent, ...currentHistory].slice(0, 10)
      window.analyticsEventHistory = newHistory
      const fullFilled = {
        channel: `${context} ${instance > 0 && instance}`,
        action: 'User clicks on the status link',
        pathHistory: window?.analyticsPathHistory,
        priorPath: window?.analyticsPathHistory?.[0],
        eventHistory: newHistory,
        priorEvent: newHistory?.[1]
      }
      window.analytics.track(currentEvent, fullFilled)
    }
  }

  return !isFrame
    ? (
      <Button
        size='sm'
        className='status-card'
        color={generateColor(result)}
        onClick={() => handleClick()}
      >
        {context} {instance > 0 && instance}
      </Button>
      )
    : (
      <a
        target='_blank'
        rel='noopener noreferrer'
        onClick={() => trackEvent()}
        href={`/logs/${organizationId}/${lsLogId}`}
        className={`btn btn-sm btn-${generateColor(result)}`}
      >
        {context} {instance > 0 && instance}
      </a>
      )
}
