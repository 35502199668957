import React from 'react'
import { icons } from '../Channel/ChannelsLogos'

export const ChannelsList = ({ channels, clicked, wanted, currentSelected }) => {
  const handleChange = item => clicked(item)

  return (
    <div className='channels-list-wrapper'>
      {channels.map(({ name, supported, cIcon, label }) =>
        <div className='visual-picker m-0 p-0' key={name}>
          <input
            name={name}
            type='checkbox'
            id={`${wanted ? 'w-' : ''} ${name}`}
            checked={currentSelected?.some(selectedChannel => selectedChannel?.name === name)}
            onChange={e => handleChange({ wanted, name, supported, checked: e.target.checked })}
          />
          <label className='visual-picker-figure' htmlFor={`${wanted ? 'w-' : ''} ${name}`}>
            <span className='visual-picker-content p-0'>
              {cIcon ? icons[`c_${name}`] : icons[name]} {label || name}
            </span>
          </label>
        </div>
      )}
    </div>
  )
}
