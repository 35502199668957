import React from 'react'
import { InstructionCollapse } from './'

export const FitmentCustomFacets = () => {
  return (
    <InstructionCollapse id='CustomFacets' title='2. Customize your shop filters adding Custom Facets'>
      <ol className='pl-3'>
        <li>Log in on <a href='https://app.suredone.com' target='_blank' rel='noopener noreferrer'>SureDone app </a></li>
        <li>Select the channel you want to modify from the link in the left sidebar, under Fitment. <br />
          <img src='https://assets.mysuredone.com/688015/logos/fitment-advanced-CF-1.png' alt='SureDone Fitment sidebar' />
        </li>
        <li>You will see a list with all the fields that you can use as filters (Default filters can't be removed).
          <img src='https://assets.mysuredone.com/688015/logos/fitment-advanced-CF-2.png' alt='SureDone Fitment Facets list' />
        </li>
        <li>
          Select all the ones you want to show in your storefront. Your shop users will be able to use them to filter and search more precisely.
          <img src='https://assets.mysuredone.com/688015/logos/fitment-advanced-CF-3.png' alt='SureDone Fitment Facets list' />
        </li>
        <li> Once you have selected what you want, make sure you click the "Save changes" button.
        </li>
        <li>
          If all went well now you need to reload so new facets can be stored correctly. For that purpose you can either: <br />
          <ol type='a'>
            <li>Relist all your products.</li>
            <li>Ask support to reload your fitment data</li>
          </ol>
        </li>
        <li> Once above step finishes. You should see on your storefront filters your new custom facets. For example add box width as custom facets: <br />
          <img src='https://assets.mysuredone.com/688015/logos/fitment-advanced-CF-4.png' alt='Fitment filters with custom Box width facet' />
        </li>
      </ol>
    </InstructionCollapse>
  )
}
