import React from 'react'
import { FormGroup, Label, Col, FormText } from 'reactstrap'
import { ActionType, MappableSelectType, InputType, SelectMultiType, SelectType, TextType, ToggleType } from './ChannelSettingsTypes'

const showMappableProductFields = item => item?.selectOptions[0]?.value === 'List of mappable product fields'

const renderSwitch = (type, item, parentCallback, settings, slug, instanceNumber, amazonMappings) => {
  switch (type) {
    case 'TEXT':
      return <TextType item={item} />
    case 'INPUT':
      return <InputType data={{ item, parentCallback }} />
    case 'ACTION':
      return <ActionType data={{ item, parentCallback, slug, instanceNumber }} />
    case 'TOGGLE':
      return <ToggleType data={{ item, parentCallback }} />
    case 'SELECT':
      if (showMappableProductFields(item)) return <MappableSelectType data={{ item, parentCallback, settings, amazonMappings }} />
      return <SelectType data={{ item, parentCallback, settings }} />
    case 'SELECT_MULTI':
      return <SelectMultiType data={{ item, parentCallback, settings }} />
    default:
      return 'Undefined type'
  }
}

const ChannelSettingsItem = ({ item, parentCallback, settings, slug, instanceNumber, amazonMappings }) => {
  const { label, type, help_text: helpText } = item || {}

  return (
    <FormGroup row className={`setting-${label?.replaceAll(' ', '-')}`}>
      <Col sm={7} className='pl-0'>
        <Label xs={12} className='pb-0 px-2'>{label}</Label>
        {helpText && <FormText className='px-2' dangerouslySetInnerHTML={{ __html: helpText }} />}
      </Col>
      <Col sm={5}>
        {renderSwitch(type, item, parentCallback, settings, slug, instanceNumber, amazonMappings)}
      </Col>
    </FormGroup>
  )
}

export default ChannelSettingsItem
