import React, { useState } from 'react'
import { CardSubtitle, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Row } from 'reactstrap'
import Highlighter from 'react-highlight-words'
import { Link as RLink } from 'react-router-dom'
import { OrganizationAvatar } from '../../components'
import { FaEllipsisH as ThreeDots } from 'react-icons/fa'
// import './OrganizationSummary.css'
import Debug from 'debug'

const debug = Debug('sd:OrganizationSummary')

const OrganizationSummary = ({ organization, searchTerms, isListItem = false, to, logoUrl }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const { name, slug, email, legacyAccountId } = organization

  const toggle = () => setDropdownOpen(prevState => !prevState)

  return (
    <Row className='align-items-center'>
      <Col className='col-auto'>
        <a href={to}>
          <OrganizationAvatar size={48} organization={organization} />
        </a>
      </Col>
      <Col className='col-sm col-8 pr-0 pr-sm-12'>
        <h3 className='card-title'>
          <a href={to}>
            <Highlighter
              className=''
              highlightTag='mark'
              autoEscape
              searchWords={searchTerms || []}
              textToHighlight={name}
            />
          </a>
          &nbsp;{slug && <small class='text-muted'>{slug}</small>}
        </h3>
        <CardSubtitle className='text-muted'>
          <a href={to}>
            <Highlighter
              className='font-weight-light d-inline-block flex-grow-0 text-muted text-truncate'
              tag='h5'
              highlightTag='mark'
              autoEscape
              searchWords={searchTerms || []}
              textToHighlight={email || ''}
            />
          </a>
        </CardSubtitle>
        <span className='text-muted'> uid </span>
        &nbsp;
        <Highlighter
          highlightTag='mark'
          autoEscape
          searchWords={searchTerms || []}
          textToHighlight={legacyAccountId || ''}
        />
        &nbsp;
        &nbsp;
        <span className='text-muted'> acct</span>&nbsp; <Highlighter highlightTag='mark' autoEscape searchWords={searchTerms || []} textToHighlight={slug || ''} />
        <span className='text-muted'>
          &nbsp;(<Highlighter highlightTag='mark' autoEscape searchWords={searchTerms || []} textToHighlight={legacyAccountId || ''} />)
        </span>
      </Col>
      <Col className='d-none col-auto px-0 px-sm-3'>
        <Dropdown className='d-inline-block' isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle className='btn-icon' color='secondary'>
            <ThreeDots />
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem tag={RLink} color='link' to={`${to}/details`}>Details</DropdownItem>
            <DropdownItem tag={RLink} color='link' to={`${to}/permissions`}>Permissions</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </Col>
    </Row>
  )
}

export { OrganizationSummary }

debug('loaded')
