import React, { useState, useEffect } from 'react'
import Debug from 'debug'
import { ObjectId } from 'bson'
import Select from 'react-select'
import { useHistory } from 'react-router'
import { calculateRedirectUrl } from './utils'
import { useQualifications } from '../../hooks'
import { objectKeyMap } from '../../components/utils'
import { FaArrowRight as NextIcon } from 'react-icons/fa'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { snakeToCamel, dataP2, TalkNextHourModal } from '.'
import { ActionButton, ErrorAlert } from '../../components'
import { LoggedOutPage } from '../../components/LoggedOutPage'
import { Label, Input, Card, CardBody, Col, Row } from 'reactstrap'
import { CREATE_LEGACY_USER, FINISH_QUALIFICATIONS, IS_ANY_SUPPORT_AVAILABLE } from './commonQueries'
const debug = Debug('sd:Page2')

export const Page2 = () => {
  const history = useHistory()
  const { storedValue, setValue, cleanCurrentChannels, cleanWantedChannels } = useQualifications()
  const { actionsToDo, currentChannels, wantedChannels, isAFit, skuCount, gmvPerMonth, ordersPerMonth, primaryCategory, email, firstName, lastName } = storedValue
  const quizIsEmpty = actionsToDo?.length < 1 && currentChannels?.length < 1 && wantedChannels?.length < 1 && !gmvPerMonth && !ordersPerMonth && !primaryCategory && !skuCount
  const quiz2FullCompleted = !!gmvPerMonth && !!ordersPerMonth && !!primaryCategory && !!skuCount

  const [userSpecialCategory, setUserSpecialCategory] = useState()
  const [categoryIsOther, setCategoryIsOther] = useState(false)

  const [showModal, setShowModal] = useState(false)
  const { data: supportAvailable } = useQuery(IS_ANY_SUPPORT_AVAILABLE)
  const { IsAnySupportAvailable: shouldShowTalkModal } = supportAvailable || {}

  const handleChangeVolumeDolars = event => { setValue((storedValue) => ({ ...storedValue, gmvPerMonth: event.label })) }
  const handleChangeOrdersQuantity = event => { setValue((storedValue) => ({ ...storedValue, ordersPerMonth: event.label })) }
  const handleChangeSKU = event => { setValue((storedValue) => ({ ...storedValue, skuCount: event.label })) }
  const handleChangeCategory = event => {
    setCategoryIsOther(event.value === 'Other')
    setValue((storedValue) => ({ ...storedValue, primaryCategory: event?.label }))
  }

  const goBackPage = () => {
    // Clean input selection
    setValue((storedValue) => ({ ...storedValue, gmvPerMonth: undefined, ordersPerMonth: undefined, skuCount: undefined }))
    history.push('/q/1')
  }

  const [createLegacyUser, { loading: luLoading }] = useMutation(CREATE_LEGACY_USER, {
    onError: error => debug({ error })
  })

  const [storeQualifications, { loading, error }] = useMutation(FINISH_QUALIFICATIONS, {
    onCompleted: () => {
      if (!shouldShowTalkModal) history.push(calculateRedirectUrl(isAFit, storedValue.inPersonDemo))
    },
    onError: error => debug({ error })
  })

  const calcTotalPoints = () => {
    const p1 = dataP2.skuCount.find(data => data.label === skuCount)?.points || 0
    const p2 = dataP2.orderVolumeInDolars.find(data => data.label === gmvPerMonth)?.points || 0
    const p3 = dataP2.ordersPerMonthQuantity.find(data => data.label === ordersPerMonth)?.points || 0
    const p4 = dataP2.categoryOptions.find(data => data.label === primaryCategory)?.points || 0
    return p1 + p2 + p3 + p4
  }

  const submitQualifications = e => {
    e.preventDefault()
    setShowModal(true)

    if (window.analytics) {
      const currentEvent = 'Quiz: Business information'
      const currentHistory = window?.analyticsEventHistory || []
      const newHistory = [currentEvent, ...currentHistory].slice(0, 10)
      window.analyticsEventHistory = newHistory
      const fullFilled = {
        action: 'Clicks finish',
        email: storedValue?.email,
        pathHistory: window?.analyticsPathHistory,
        priorPath: window?.analyticsPathHistory?.[1],
        eventHistory: newHistory,
        priorEvent: newHistory?.[1]
      }
      window.analytics.track(currentEvent, fullFilled)
      debug({ currentEvent, fullFilled })
    }

    const newStoredValue = {}
    newStoredValue.totalPoints = calcTotalPoints()
    newStoredValue.inPersonDemo = newStoredValue.totalPoints >= 0 // all users will be gated
    if (categoryIsOther) newStoredValue.primaryCategory = `Other: ${userSpecialCategory}`
    const userId = new ObjectId().toString()

    setValue(storedValue => ({ ...storedValue, ...newStoredValue, userId }))
    const qualifications = objectKeyMap({
      ...storedValue,
      ...newStoredValue,
      currentChannels: cleanCurrentChannels(storedValue),
      wantedChannels: cleanWantedChannels(storedValue),
      email,
      firstName,
      lastName,
      userId
    }, snakeToCamel)
    // Avoids create the legacy user until the quiz is filled because the user will be redirected to complete the quiz
    if (!quizIsEmpty) {
      createLegacyUser({ variables: { qualifications } })
    }

    storeQualifications({ variables: { qualifications } })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    // eslint-disable-next-line
  }, [])

  return (
    <LoggedOutPage
      title='Almost done!'
      className='qualifications'
      description="We're just about ready to provision your account. All we need are answers to the questions below."
    >
      <Card className='mb-5'>
        <CardBody className='pt-0'>
          <Row>
            <Col xs={12} md={6} className='mt-4'>
              <Label tag='legend'>How many orders do you have in a month?</Label>
              {dataP2.ordersPerMonthQuantity.map((item, idx) => (
                <div key={idx} className='custom-control custom-radio mb-1'>
                  <Label style={{ cursor: 'pointer' }} className='mb-1'>
                    <Input
                      id={item.label}
                      type='radio'
                      name='orders-month-quantity'
                      onChange={() => handleChangeOrdersQuantity(item)}
                      className='custom-control-input'
                    />
                    <span className='custom-control-label'>{item.label}</span>
                  </Label>
                </div>
              ))}
            </Col>

            <Col xs={12} md={6} className='mt-4'>
              <Label tag='legend'>How many SKUs do you manage?</Label>
              {dataP2.skuCount.map((item, idx) => (
                <div key={idx} className='custom-control custom-radio mb-1'>
                  <Label style={{ cursor: 'pointer' }} className='mb-1'>
                    <Input
                      id={item.label}
                      type='radio'
                      name='sku-count'
                      className='custom-control-input'
                      onChange={() => handleChangeSKU(item)}
                    />
                    <span className='custom-control-label'>{item.label}</span>
                  </Label>
                </div>
              ))}
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6} className='mt-4'>
              <Label tag='legend'>What is your current monthly order volume?</Label>
              {dataP2.orderVolumeInDolars.map((item, idx) => (
                <div key={idx} className='custom-control custom-radio mb-1'>
                  <Label style={{ cursor: 'pointer' }} className='mb-1'>
                    <Input
                      id={item.label}
                      type='radio'
                      name='orders-volume-dolars'
                      onChange={() => handleChangeVolumeDolars(item)}
                      className='custom-control-input'
                    />
                    <span className='custom-control-label'>{item.label}</span>
                  </Label>
                </div>
              ))}
            </Col>

            <Col xs={12} md={6} className='mt-4'>
              <Label tag='legend'>What is the main category you sell in?</Label>
              <Select
                id='category'
                value={dataP2.categoryOptions.find(categories => categories.label === primaryCategory)}
                clearable={false}
                placeholder='Category'
                options={dataP2.categoryOptions}
                onChange={e => handleChangeCategory(e)}
              />

              {categoryIsOther &&
                <form onSubmit={e => submitQualifications(e)}>
                  <Input className='mt-2' autoFocus onChange={e => setUserSpecialCategory(e.target.value)} placeholder='Main category' autoCapitalize='off' />
                </form>}
            </Col>
          </Row>

          <div className='d-flex w-100 justify-content-end mt-4'>
            <ActionButton
              outline
              className='mr-2'
              onClick={() => goBackPage()}
            >
              Back
            </ActionButton>
            <ActionButton
              color='primary'
              className='continue'
              loading={loading || luLoading}
              onClick={e => submitQualifications(e)}
              disabled={!quiz2FullCompleted || (categoryIsOther && !userSpecialCategory)}
            >
              Finish
              <NextIcon className='mr-0 ml-1' />
            </ActionButton>
          </div>
          {error && <ErrorAlert error={error} />}

          {shouldShowTalkModal &&
            <TalkNextHourModal isOpen={showModal} isAFit={isAFit} inPersonDemo={storedValue.inPersonDemo} />}
        </CardBody>
      </Card>
    </LoggedOutPage>
  )
}
