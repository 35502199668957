import React, { useState } from 'react'
import Highlighter from 'react-highlight-words'
import { CustomInput, Badge } from 'reactstrap'

export const FacetSummary = ({ field, context, defaultFacets, searchTerms, handleChange }) => {
  const { name, label, valueObject, key } = field
  const isDefaultFacet = defaultFacets?.includes(name)
  const [checked, setChecked] = useState(valueObject?.value || isDefaultFacet)

  const handleLocalChange = (event) => {
    setChecked(event.target.checked)
    handleChange(event, field)
  }

  return (
    <label htmlFor={'field-' + context + key} className={`d-flex w-100 align-items-center px-3 py-2 mb-0 ${isDefaultFacet ? 'check-disabled' : ''}`} style={{ cursor: 'pointer' }}>
      <div className='pl-0 pr-1 disabled'>
        <CustomInput
          size='large'
          type='checkbox'
          checked={checked}
          id={'field-' + context + key}
          onChange={e => handleLocalChange(e)}
        />
      </div>
      <p className='mb-0'>
        <strong className='w-100 d-block'>
          <Highlighter
            autoEscape
            className='mr-2'
            highlightTag='mark'
            searchWords={searchTerms || []}
            textToHighlight={label || 'Unnamed field'}
          />
          {isDefaultFacet && <Badge color='secondary' style={{ fontSize: '60%', verticalAlign: 'text-bottom' }} pill>Default</Badge>}
        </strong>
      </p>
    </label>
  )
}
