import React, { useState } from 'react'
import Select from 'react-select'

export const MappableSelectType = ({ data }) => {
  const { item, parentCallback, amazonMappings } = data || {}
  const { value: { value } } = item

  const options = (amazonMappings || []).map(item => ({
    value: item.name,
    label: <><strong style={{ letterSpacing: '.3px', fontWeight: 400 }}>{item.name}</strong><br />{item.description}</>
  }))

  const [selectedOption, setSelectedOption] = useState({
    value: (value || null),
    label: <strong style={{ letterSpacing: '.3px', fontWeight: 400 }}>{value || null}</strong>
  })

  const handleChange = (event) => {
    setSelectedOption(event)
    parentCallback(event, item)
  }

  return (
    <Select
      options={options}
      value={selectedOption}
      onChange={handleChange}
      placeholder=''
    />
  )
}
