import React, { useState } from 'react'
import { FormGroup, Row, Col, Input, FormFeedback, Label } from 'reactstrap'
import Select from 'react-select'
import { Field } from 'react-final-form'
import { getCountriesList, getCountryByISO, getStateOrProvinceForUSandCA, getStateByISO, getTimezoneByCountryForSelect } from './utils'
import Debug from 'debug'
import { SaveButton } from './SaveButton'
import ErrorAlert from './ErrorAlert'

const debug = Debug('sd:AddressForm')

const AddressForm = ({ handleSubmit, submitting, pristine, valid, form, loading, hasValidationErrors, dirtySinceLastSubmit, success, customError, initialValues }) => {
  const [chosenCountry, setChosenCountry] = useState(initialValues && initialValues.country)
  const [timezone, setTimezone] = useState((initialValues && initialValues.country && getTimezoneByCountryForSelect(initialValues.country)) || '')

  return (
    <form className='form p-1' onSubmit={handleSubmit}>
      <Row>
        <Col lg={6}>
          <FormGroup>
            <Label tag='legend' for='country'>Country</Label>
            <div className='form-label-group select-with-label'>
              <Field
                name='country'
                validate={name => name ? undefined : 'Country is required'}
                // react-select needs value/label fields
                format={isoValue => isoValue ? ({ label: getCountryByISO(isoValue), isoValue }) : isoValue}
                // we pick the countryName
                parse={country => country && (country.isoValue)}
              >
                {({ input, meta }) => (
                  <>
                    <Select
                      {...input}
                      id='Country'
                      // Run the original onChange and do more stuff
                      onChange={event => {
                        input.onChange(event)
                        form.mutators.clearSelect('stateProvince')
                        form.mutators.clearSelect('timezone')
                        event && setChosenCountry(event.isoValue)
                        event && setTimezone(getTimezoneByCountryForSelect(event.isoValue))
                      }}
                      style={{ borderColor: meta.invalid && meta.touched ? '#dc3545' : '#aaa', height: '47px' }}
                      clearable={false}
                      options={getCountriesList()}
                      placeholder=''
                    />
                    <Label for='country'>Country</Label>
                    {meta.invalid && <div style={{ fontSize: '12px', color: '#dc3545' }}>Country is required</div>}
                  </>
                )}
              </Field>
            </div>
          </FormGroup>
          <FormGroup>
            <Label tag='legend'>Address</Label>
            <div className='form-label-group'>
              <Field
                name='street1'
                validate={street => street ? undefined : 'Street is required'}
              >
                {({ input, meta }) => (
                  <>
                    <Input {...input} id='street1' type='text' xvalid={meta.valid} invalid={!meta.pristine && meta.touched && meta.invalid} placeholder='Street' />
                    <Label for='street1'>Street</Label>
                    {meta.error && !meta.pristine && meta.touched && <FormFeedback>{meta.error}</FormFeedback>}
                  </>
                )}
              </Field>
            </div>
          </FormGroup>
          <FormGroup>
            <div className='form-label-group'>
              <Field
                name='street2'
              >
                {({ input, meta }) => (
                  <>
                    <Input {...input} id='street2' type='text' xvalid={meta.valid} invalid={!meta.pristine && meta.touched && meta.invalid} placeholder='Apt, unit, etc.' />
                    <Label for='street2'>Apt, unit, etc.</Label>
                  </>
                )}
              </Field>
            </div>
          </FormGroup>
          <FormGroup>
            <div className='form-label-group'>
              <Field
                name='city'
                validate={last => last ? undefined : 'City is required'}
              >
                {({ input, meta }) => (
                  <>
                    <Input {...input} id='city' type='text' xvalid={meta.valid} invalid={!meta.pristine && meta.touched && meta.invalid} placeholder='City' />
                    <Label for='city'>City</Label>
                    {meta.error && !meta.pristine && meta.touched && <FormFeedback>{meta.error}</FormFeedback>}
                  </>
                )}
              </Field>
            </div>
          </FormGroup>
          <FormGroup>
            <div className='form-label-group select-with-label'>
              {(chosenCountry === 'US' || chosenCountry === 'CA')
                ? (
                  <Field
                    name='stateProvince'
                    validate={name => name ? undefined : 'State/province is required'}
                    format={isoValue => isoValue ? ({ value: isoValue, label: (getStateByISO(isoValue) || {}).label }) : isoValue}
                    parse={state => state && (state.value)}
                  >
                    {({ input, meta }) => (
                      <>
                        <Select
                          {...input}
                          id='stateProvince'
                          clearable={false}
                          options={getStateOrProvinceForUSandCA(chosenCountry)}
                          placeholder=''
                          style={{ borderColor: meta.invalid ? '#dc3545' : '#aaa', height: '47px' }}
                        />
                        <Label for='stateProvince'>State/Province</Label>
                        {meta.invalid && <div style={{ fontSize: '12px', color: '#dc3545' }}>State/province is required</div>}
                      </>
                    )}
                  </Field>
                  )
                : (
                  <Field
                    name='stateProvince'
                  >
                    {({ input, meta }) => (
                      <div className='form-label-group'>
                        <Input {...input} type='text' placeholder='State/Province' />
                        <Label>State/Province</Label>
                      </div>
                    )}
                  </Field>
                  )}
            </div>
          </FormGroup>
          <FormGroup>
            <div className='form-label-group'>
              <Field
                name='postalCode'
                validate={postalCode => postalCode ? undefined : 'Postal code is required'}
              >
                {({ input, meta }) => (
                  <>
                    <Input {...input} id='postalcode' xvalid={meta.valid} invalid={!meta.pristine && meta.touched && meta.invalid} type='text' placeholder='Postal Code' />
                    <Label for='postalcode'>Postal Code</Label>
                    {meta.error && !meta.pristine && meta.touched && <FormFeedback>{meta.error}</FormFeedback>}
                  </>
                )}
              </Field>
            </div>
          </FormGroup>
          <FormGroup>
            <Label tag='legend' for='timezone'>Timezone</Label>
            <div className='form-label-group select-with-label'>
              <Field
                name='timezone'
                validate={name => name ? undefined : 'Timezone is required'}
                // react-select needs value/label fields
                format={timzone => timzone ? ({ value: timzone, label: timzone }) : timzone}
                // we pick the timezone ex: America/Chicago
                parse={timzone => timzone && (timzone.value)}
              >
                {({ input, meta }) => (
                  <>
                    <Select
                      {...input}
                      id='timezone'
                      // disabled={timzoneDisabled}
                      clearable={false}
                      options={timezone}
                      placeholder=''
                      style={{ borderColor: meta.invalid ? '#dc3545' : '#aaa', height: '47px' }}
                    />
                    <Label for='timezone'>Timezone</Label>
                    {meta.invalid && <div style={{ fontSize: '12px', color: '#dc3545' }}>Timezone is required</div>}
                  </>
                )}
              </Field>
            </div>
          </FormGroup>
          {customError && <ErrorAlert error={customError} />}
        </Col>
      </Row>
      <FormGroup className='float-right'>
        <SaveButton
          color='primary'
          className='mb-3'
          type='submit'
          loading={loading}
          disabled={submitting || pristine || !valid || loading}
          // The following sintax {state ? 'true' : false} is to avoid console warnings
          success={success ? 'true' : null}
          dirtysincelastsubmit={dirtySinceLastSubmit ? 'true' : null}
        />
      </FormGroup>
    </form>
  )
}
export { AddressForm }

debug('loaded')
