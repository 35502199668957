import React, { useState } from 'react'
import { Loadable } from '..'
import { User, Organization } from 'suredone-common'

import { useQuery } from '@apollo/react-hooks'
import { useHotkeys } from 'react-hotkeys-hook'
import { ResourceList, ResourceListItem } from '../SearchableLists'
import { UserSummary2 } from '../../scenes/UserManager/UserSummary2'
import { GET_ALL_USERS } from '../../scenes/UserManager/UserManager'
import { OrganizationSummary } from '../../scenes/OrganizationManager/OrganizationSummary'
import { GET_ALL_ORGANIZATIONS } from '../../scenes/OrganizationManager/OrganizationManager'
import { Modal, ModalHeader, ModalBody, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'

export const UsersSearch = () => {
  // Defines the shortcut
  useHotkeys('ctrl+enter', () => toggleModal())

  const [modal, setModal] = useState(false)
  const toggleModal = () => setModal(!modal)

  const [activeTab, setActiveTab] = useState(0)
  const toggleTab = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const availableTabs = [
    { label: 'Users' },
    { label: 'Organizations' }
  ]

  // Get all users
  const { loading: uLoading, error: uError, data: uData } = useQuery(GET_ALL_USERS)
  const usersResponse = (uData && uData.AllUsers) ? uData.AllUsers : []
  const users = usersResponse.map(({
    id, username, primaryEmail, roles, scopes, organizationId, legacyUserId, legacyAccountId, legacyAccountName, legacyPrimary, firstName, lastName
  }) => new User(
    id, username, primaryEmail, roles, scopes, organizationId, legacyUserId, legacyAccountId, legacyAccountName, '', legacyPrimary, firstName, lastName
  ))

  // Get all organizations
  const { loading: oLoading, error: oError, data: oData } = useQuery(GET_ALL_ORGANIZATIONS)
  const organizationsResponse = (oData && oData.AllOrganizations) ? oData.AllOrganizations : []
  const organizations = organizationsResponse.map(({
    id, name, slug, legacyAccountId, email, phone, address
  }) => new Organization({
    id, name, slug, legacyAccountId, email, phone, address
  }))

  return (
    <Modal isOpen={modal} size='lg' toggle={toggleModal}>
      <ModalHeader toggle={toggleModal} className='pb-0'>Search</ModalHeader>
      <ModalBody className='p-0'>
        <Nav tabs>
          {availableTabs.map((i, idx) =>
            <NavItem key={idx}>
              <NavLink
                style={{ cursor: 'pointer' }}
                onClick={() => toggleTab(idx)}
                className={activeTab === idx ? 'active' : ''}
              >
                {i.label}
              </NavLink>
            </NavItem>
          )}
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId={0}>
            <Loadable
              inline
              error={uError}
              loading={uLoading}
              style={{ padding: '1rem' }}
              content={
                <ResourceList
                  items={users}
                  itemPlural='users'
                  itemComponent={
                    ({ item: user, searchTerms }) =>
                      <ResourceListItem to={`/admin/users/${user.id}`} onClick={toggleModal}>
                        <UserSummary2 isListItem searchTerms={searchTerms} user={user} />
                      </ResourceListItem>
                  }
                  searchableStrings={u => [
                    u.lastName,
                    u.firstName,
                    u.primaryEmail,
                    u.legacyUserId,
                    u.legacyAccountId,
                    u.legacyAccountName,
                    [u.firstName, u.lastName].join(' ') // full name
                  ]}
                  className='user-list'
                  searchPlaceholder='Search users'
                />
              }
            />
          </TabPane>
          <TabPane tabId={1}>
            <Loadable
              loading={oLoading}
              error={oError}
              content={
                <ResourceList
                  items={organizations}
                  itemPlural='organizations'
                  itemComponent={
                    ({ item: organization, searchTerms }) =>
                      <ResourceListItem to={`/admin/organizations/${organization.id}/profile`} onClick={toggleModal}>
                        <OrganizationSummary isListItem searchTerms={searchTerms} organization={organization} />
                      </ResourceListItem>
                  }
                  searchableStrings={o => [
                    o.id,
                    o.name,
                    o.slug,
                    o.email,
                    o.phone,
                    o.timezone,
                    o.legacyAccountId
                  ]}
                  searchPlaceholder='Search organizations'
                  className='organization-list'
                />
              }
            />
          </TabPane>
        </TabContent>
      </ModalBody>
    </Modal>
  )
}
