import React from 'react'
import { Card, CardBody } from 'reactstrap'
import { FaExternalLinkAlt as ExternalIcon } from 'react-icons/fa'

const ExternalLink = ({ href, children }) => (
  <a target='_blank' rel='noopener noreferrer' href={href}>
    {children}
    <ExternalIcon size={11} className='ml-1 mb-1' />
  </a>
)

export const ExternalChannels = ({ name, href }) => {
  return (
    <Card>
      <CardBody>
        <ExternalLink href={href}>Go to {name}</ExternalLink>
      </CardBody>
    </Card>
  )
}
