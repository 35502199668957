import React from 'react'
import { Badge } from 'reactstrap'

const config = () => {
  const location = window.location.toString()
  if (location?.includes('localhost')) return ['danger', 'LOCAL']
  if (location?.includes('dev')) return ['warning', 'DEV']
  if (location?.includes('beta')) return ['success', 'BETA']
}

export const EnvBadge = () => {
  const [color, label] = config() || []

  return (
    <Badge
      color={color}
      style={{ fontSize: '8px', position: 'absolute', right: '-15px' }}
    >
      {label}
    </Badge>
  )
}
