export const launchContact = () => {
  const el = document.querySelector('.intercom-launcher')
  if (el && el.onclick) {
    el.onclick()
  } else if (el && el.click) {
    el.click()
  } else window.open('https://www.suredone.com/contact', '_blank')
}

export const confetti = {
  type: 'confetti',
  angle: 90,
  decay: 0.88,
  spread: 140,
  zIndex: 2000,
  lifetime: 250,
  elementSize: 8,
  elementCount: 30,
  startVelocity: 50,
  springAnimation: true
}
