import React from 'react'
import { Row, Col } from 'reactstrap'
import { EmptyState } from '../../components'
import { ProductCard, FitPagination } from '.'
import { people } from '../../img/illustrations'
import { useFitment } from '../../hooks/useFitment'

export const ResultGrid = () => {
  const { noLogs } = people
  const { results } = useFitment()
  const { raw: products, totalFacetedProducts: resultsCount } = results || {}
  const amountOfResults = resultsCount?.[0]?.total

  return (
    <EmptyState
      isEmpty={products?.length < 1}
      heading='No products'
      customImage={noLogs}
      content='There are no products for the current filters. Try editing them.'
    >
      <Row>
        <Col>
          {amountOfResults > 0 && <p><b>{amountOfResults}</b> items were found</p>}
        </Col>
      </Row>
      <Row>
        {amountOfResults > 0 && products.map(i => <ProductCard key={i._id} item={i} />)}
      </Row>
      <FitPagination />
    </EmptyState>
  )
}
