import React from 'react'
import { Card, CardBody, Col } from 'reactstrap'
import { Loadable, Pill, makeSearch } from '../../../components'
import { FaExternalLinkAlt as ExternalLink } from 'react-icons/all'

export const SavedSearches = ({ savedSearchesData }) => {
  const { savedSearches, loading, error } = savedSearchesData || {}

  if (!savedSearches?.length) return null

  return (
    <Loadable
      inline
      error={error}
      loading={loading}
      content={
        <Col>
          <Card className='h-100 mb-0'>
            <CardBody>
              <h6>Saved Searches</h6>
              <ul className='saved-searches pl-0 mb-0'>
                {savedSearches?.map(({ type, query, label }, idx) => (
                  <li
                    className='mb-2'
                    key={`${query}${idx}`}
                    style={{ listStyle: 'none' }}
                    onClick={() => makeSearch({ type, query })}
                  >
                    <Pill style={{ cursor: 'pointer' }}>
                      {label} <ExternalLink color='#b5b6ba' className='mr-0' size={10} />
                    </Pill>
                  </li>
                ))}
              </ul>
            </CardBody>
          </Card>
        </Col>
    }
    />
  )
}
