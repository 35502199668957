import React from 'react'
import { CodeBlock } from '../../../components'
import { InstructionCollapse } from '../InstructionCollapse'

const code = `.sd-myGarageButton-Aside, .sd-myGarageButton-initialSearch {
    display:none !important;
  }`

const code2 = `.sd-myGarageButton-Aside {
    display:none !important;
  }`

const code3 = `.sd-myGarageButton-initialSearch {
    display:none !important;
  }`

export const ShopifyHideMyVehicleButton = () => {
  return (
    <InstructionCollapse id='HideMVB' title='3. Hide My Garage button'>
      <ol className='pl-3'>
        <li>Edit your theme files</li>
        <li>Go to <code>assets {'>'} base.scss</code></li>
        <li>Add the following code:
          <CodeBlock language='css'>{code}</CodeBlock>
          This code will remove the button from all pages
          <img src='https://assets.mysuredone.com/688015/logos/fitment-advanced-shopify-HMG-1.png' alt='Shopify base.scss editor' />
        </li>
        <li> You may want to remove the button from only 1 place so you can do it as follows:
          <CodeBlock language='css'>{code2}</CodeBlock>
          This code will remove the button from filters
          <CodeBlock language='css'>{code3}</CodeBlock>
          This code will remove the button from the initial year make model dropdowns
        </li>
        <li>
          Click Save and wait until deployed
        </li>
        <li>
          <b>Results:</b> <br />
          Before
          <img src='https://assets.mysuredone.com/688015/logos/fitment-advanced-shopify-HMG-2.png' alt='Shopify before hiding button' />
          <img src='https://assets.mysuredone.com/688015/logos/fitment-advanced-shopify-HMG-4.png' alt='Shopify before hiding button' /> <br />
          After
          <img src='https://assets.mysuredone.com/688015/logos/fitment-advanced-shopify-HMG-3.png' alt='Shopify after hiding button' />
          <img src='https://assets.mysuredone.com/688015/logos/fitment-advanced-shopify-HMG-5.png' alt='Shopify after hiding button' />
        </li>
        <li>
          Click Save and wait until deployed
        </li>
      </ol>
    </InstructionCollapse>
  )
}
