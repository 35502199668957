import debug from 'debug'
import sanitizeFilename from 'sanitize-filename'

export const EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const DIGITS = /[0-9]+/
export const UPPER = /[A-Z]+/
export const LOWER = /[a-z]+/
export const PUNCT = /[^0-9A-z]+/
export const USERNAME = /(\+)/g
export const STARTS_WITH_NUMBER = /^\d/
export const ENDS_WITH_NUMBER = /\d$/

const reservedNames = [
  'suredone'
]

const checkReservedNames = (str) => {
  const splitted = str.split(/[.*+-/_]/)
  const match = splitted.map(name => reservedNames.indexOf(name))
  // Checks app-2020, beta+2020, mail.2020, etc.
  const invalid1 = (match || [])[0] >= 0
  // Checks app2020, beta2020, mail2020, etc.
  const invalid2 = reservedNames.filter(reserved => str.startsWith(reserved)).length > 0
  // Checks 12345
  const invalid3 = isNaN(str) ? null : true
  return invalid1 || invalid2 || invalid3 ? null : str
}

export const parseUsername = (username) => {
  // Trim whitespaces, lowercase it and replace middlespaces with '-'
  const step1 = username.trim().toLowerCase().replace(/ /g, '-')
  // Only allow 1 consecutive -
  // Removes consecutives '-' but allow a single '-' Ex: 'a-a--a---sa' -> 'a-aasa'
  const step2 = step1.replace(/-(-*-)/g, '')
  // Removes [^a-z0-9-+]
  const step3 = step2.replace('/[^a-z0-9-+]/', '')
  // Remove any '+' and suredone from username
  const step4 = (step3.replace(/(\+)/g, '')).replace('suredone', '')
  // trim - and . from beginning
  const step5 = step4.replace(/(^(\.|-)+)/, '')
  // trim '-' and '.' from the end
  const step6 = step5.replace(/((\.|-)+$)/, '')
  // Throws a form error if the name starts with one of the reserved names
  const step7 = checkReservedNames(step6)
  return step7
}

// In case it receive an object or array to make a Mongo query,
// it'll will strip out any keys that start with '$'
export const sanitize = (v) => {
  debug({ isObject: v instanceof Object })
  if (v instanceof Object) {
    for (const key in v) {
      if (/^\$/.test(key)) {
        delete v[key]
      } else {
        sanitize(v[key])
      }
    }
  }
  return v
}

/**
 * Node port of Helpers::makeSlug() from php
 * @param {String} input: arbitrary string
 * @returns {String} sanitized string
 */
export const makeSlug = (input) => {
  const result = input.replace(/[ÀÀÁÂÃÄÅ]/g, 'A')
    .replace(/[ÆǼ]/g, 'AE')
    .replace(/[Ĳ]/g, 'IJ')
    .replace(/[ÇĆĈĊČ]/g, 'C')
    .replace(/[ĚÈÉÊËĒĖĔĘ]/g, 'E')
    .replace(/[ÌÍÎÏĨĪĬĮİǏ]/g, 'I')
    .replace(/[ÐĎĐ]/g, 'D')
    .replace(/[ÑŃŅŇ]/g, 'N')
    .replace(/[ÒÓÔÕÖØŌŎŐƠǾǑ]/g, 'O')
    .replace(/[ÙÚÛÜŨŪŬŮŰŲǓǕǗǙǛƯ]/g, 'U')
    .replace(/[ÝŶŸ]/g, 'Y')
    .replace(/[ĜĞĠĢ]/g, 'G')
    .replace(/[ĤĦ]/g, 'H')
    .replace(/[Ĵ]/g, 'J')
    .replace(/[ßśŝşšſ]/g, 's')
    .replace(/[àáâãäåāăąǻǎ]/g, 'a')
    .replace(/[æǽ]/g, 'ae')
    .replace(/[çćĉċč]/g, 'c')
    .replace(/[èéêëęěēĕė]/g, 'e')
    .replace(/[ìíîïĩīĭįıǐ]/g, 'i')
    .replace(/[ñńņňŉ]/g, 'n')
    .replace(/[òóôõöøōŏőǒơǿ]/g, 'o')
    .replace(/[ùúûüũūŭůűųǔǖǘǚǜư]/g, 'u')
    .replace(/[ýÿŷ]/g, 'y')
    .replace(/[ďđ]/g, 'd')
    .replace(/[ĝğġģ]/g, 'g')
    .replace(/[ĥħ]/g, 'h')
    .replace(/[ĳ]/g, 'ij')
    .replace(/[ĵ]/g, 'j')
    .replace(/[Ķ]/g, 'K')
    .replace(/[ķ]/g, 'k')
    .replace(/[ĹĻĽĿ]/g, 'L')
    .replace(/[ĺļľŀŁł]/g, 'l')
    .replace(/[Œ]/g, 'OE')
    .replace(/[ŔŖŘ]/g, 'R')
    .replace(/[ŕŗř]/g, 'r')
    .replace(/[ŚŜŞŠ]/g, 'S')
    .replace(/[œ]/g, 'oe')
    .replace(/[ŢŤŦ]/g, 'T')
    .replace(/[ţťŧ]/g, 't')
    .replace(/[Ŵ]/g, 'W')
    .replace(/[ŵ]/g, 'w')
    .replace(/[ŹŻŽ]/g, 'Z')
    .replace(/[źżž]/g, 'z')
    .replace(/[ƒ]/g, 'f')
    .replace(/[ţťŧ]/g, 't')
    .trim()
    .toLowerCase()
    .replace(/[^a-z0-9-+]/g, '')
    .replace(/(--+)/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '')
  return sanitizeFilename(result)
}
