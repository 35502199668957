import React, { useState } from 'react'
import { CustomInput } from 'reactstrap'
import './ChannelToggleEnabled.css'
import { ErrorModal } from '../../components/ErrorModal'
import { useMutation } from '@apollo/react-hooks'
import { Loadable } from '../../components'
import { useStores } from '../../stores/RootStore'
import { CHANGE_CHANNEL_STATUS } from './commonQueries'

export default function ChannelToggleEnabled ({ id, slug, instanceNumber, active, disabled = false }) {
  const [showError, setShowError] = useState(false)
  const { stores: { auth } } = useStores()
  const [changeChannelStatus, { loading, error }] = useMutation(CHANGE_CHANNEL_STATUS, {
    onError: (e) => {
      setShowError(true)
    },
    onCompleted: (data) => {
      auth.refresh()
    }
  })

  const handleClickSwitch = e => {
    if (window.analytics) {
      const currentEvent = `${slug}${instanceNumber}: Enable Channel`
      const currentHistory = window?.analyticsEventHistory
      const newHistory = [currentEvent, ...currentHistory].slice(0, 10)
      window.analyticsEventHistory = newHistory
      const fullFilled = {
        action: 'User toggles to enable channel',
        instanceNumber,
        slug: `${slug}${instanceNumber > 0 ? instanceNumber : ''}`,
        pathHistory: window?.analyticsPathHistory,
        priorPath: window?.analyticsPathHistory?.[0],
        eventHistory: newHistory,
        priorEvent: newHistory?.[1]
      }
      window.analytics.track(currentEvent, fullFilled)
    }
    changeChannelStatus({ variables: { slug, instanceNumber, changeTo: e.target.checked } })
  }

  return (
    <Loadable
      inline
      loading={loading}
      content={
        <CustomInput
          className='channel-toggle'
          type='switch'
          size='large'
          disabled={disabled || loading}
          checked={active}
          onChange={e => {
            handleClickSwitch(e)
          }}
          id={'channel-' + id + '-activetoggle'}
        >
          {showError && <ErrorModal header={`Error ${active ? 'disabling' : 'enabling'} channel`} error={error.message} setErrorState={setShowError} />}
        </CustomInput>
      }
    />
  )
}
