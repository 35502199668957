import React from 'react'
import { Page } from '../../../components'
import { FaSearch as Magnifier } from 'react-icons/fa'
import { Builder, UsefulSearches, YourSearches, ReadTheDocs } from '.'
import './SearchBuilder.scss'
import { Col, Row } from 'reactstrap'

export const SearchBuilder = () => {
  return (
    <Page
      className='search-builder'
      back={{ to: '/dashboard', name: 'Dashboard' }}
      title={<><Magnifier size={22} style={{ marginTop: '-2px', marginRight: '12px' }} />Search</>}
    >
      <Builder />
      <YourSearches />
      <Row>
        <Col>
          <UsefulSearches />
        </Col>
        <Col>
          <ReadTheDocs />
        </Col>
      </Row>
    </Page>
  )
}
