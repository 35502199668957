import React from 'react'
import { STATUSES_LIMIT } from './ErrorsList'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import { FaAngleLeft as Prev, FaAngleRight as Next, FaAngleDoubleLeft as DPrev, FaAngleDoubleRight as DNext } from 'react-icons/fa'

export const StatusesPagination = ({ resultsCount, pageIndex, setPageIndex, updateUrlParams }) => {
  const pageSize = STATUSES_LIMIT
  const pageCount = Math.ceil(resultsCount / pageSize) || 1
  const canPreviousPage = pageIndex > 0
  const canNextPage = (pageIndex + 1) < pageCount
  const gotoPage = p => updatePageIndex(p)

  const updatePageIndex = p => {
    setPageIndex(p)
    updateUrlParams({ page: p })
  }

  return (
    pageCount > 1 &&
      <Pagination className='row justify-content-center mb-n3'>
        {pageCount > 3 && (
          <>
            <PaginationItem disabled={!canPreviousPage}><PaginationLink onClick={() => gotoPage(0)}><DPrev className='m-0' /></PaginationLink></PaginationItem>
            <PaginationItem disabled={!canPreviousPage}><PaginationLink onClick={() => gotoPage(pageIndex - 1)}><Prev className='m-0' /></PaginationLink></PaginationItem>
          </>
        )}
        {[-2, -1, 0, 1, 2]
          .filter(index => pageIndex + 1 + index > 0 && pageIndex + 1 + index <= pageCount)
          .map(index =>
            <PaginationItem key={pageIndex + 1 + index} active={index === 0}>
              <PaginationLink onClick={() => gotoPage(pageIndex + index)}>{pageIndex + 1 + index}</PaginationLink>
            </PaginationItem>
          )}
        {pageCount > 3 && (
          <>
            <PaginationItem disabled={!canNextPage}><PaginationLink onClick={() => gotoPage(pageIndex + 1)}><Next className='m-0' /></PaginationLink></PaginationItem>
            <PaginationItem disabled={!canNextPage}><PaginationLink onClick={() => gotoPage(pageCount - 1)}><DNext className='m-0' /></PaginationLink></PaginationItem>
          </>
        )}
      </Pagination>
  )
}
