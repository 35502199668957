import { getEmojiFlag, countries } from 'countries-list'
import cAndT from 'countries-and-timezones'

const { getTimezonesForCountry } = cAndT

const AvailablesISOCountry = ['US', 'CA']

const _getCountriesList = (countries) => {
  return Object.keys(countries).map(isoCountry => {
    const countryName = countries[isoCountry].name
    return { value: countryName, label: `${getEmojiFlag(isoCountry)} ${countryName}`, isoValue: isoCountry }
  })
}

const getCountriesList = () => _getCountriesList(countries)

const getCountryByISO = (isoCountry) => (countries[isoCountry] || {}).name

const getAvailablesCountries = () => _getCountriesList(AvailablesISOCountry)

const USStates = [
  {
    label: 'Alabama',
    value: 'AL'
  },
  {
    label: 'Alaska',
    value: 'AK'
  },
  {
    label: 'Arizona',
    value: 'AZ'
  },
  {
    label: 'Arkansas',
    value: 'AR'
  },
  {
    label: 'California',
    value: 'CA'
  },
  {
    label: 'Colorado',
    value: 'CO'
  },
  {
    label: 'Connecticut',
    value: 'CT'
  },
  {
    label: 'Delaware',
    value: 'DE'
  },
  {
    label: 'Florida',
    value: 'FL'
  },
  {
    label: 'Georgia',
    value: 'GA'
  },
  {
    label: 'Hawaii',
    value: 'HI'
  },
  {
    label: 'Idaho',
    value: 'ID'
  },
  {
    label: 'Illinois',
    value: 'IL'
  },
  {
    label: 'Indiana',
    value: 'IN'
  },
  {
    label: 'Iowa',
    value: 'IA'
  },
  {
    label: 'Kansas',
    value: 'KS'
  },
  {
    label: 'Kentucky',
    value: 'KY'
  },
  {
    label: 'Louisiana',
    value: 'LA'
  },
  {
    label: 'Maine',
    value: 'ME'
  },
  {
    label: 'Maryland',
    value: 'MD'
  },
  {
    label: 'Massachusetts',
    value: 'MA'
  },
  {
    label: 'Michigan',
    value: 'MI'
  },
  {
    label: 'Minnesota',
    value: 'MN'
  },
  {
    label: 'Mississippi',
    value: 'MS'
  },
  {
    label: 'Missouri',
    value: 'MO'
  },
  {
    label: 'Montana',
    value: 'MT'
  },
  {
    label: 'Nebraska',
    value: 'NE'
  },
  {
    label: 'Nevada',
    value: 'NV'
  },
  {
    label: 'New Hampshire',
    value: 'NH'
  },
  {
    label: 'New Jersey',
    value: 'NJ'
  },
  {
    label: 'New Mexico',
    value: 'NM'
  },
  {
    label: 'New York',
    value: 'NY'
  },
  {
    label: 'North Carolina',
    value: 'NC'
  },
  {
    label: 'North Dakota',
    value: 'ND'
  },
  {
    label: 'Ohio',
    value: 'OH'
  },
  {
    label: 'Oklahoma',
    value: 'OK'
  },
  {
    label: 'Oregon',
    value: 'OR'
  },
  {
    label: 'Pennsylvania',
    value: 'PA'
  },
  {
    label: 'Rhode Island',
    value: 'RI'
  },
  {
    label: 'South Carolina',
    value: 'SC'
  },
  {
    label: 'South Dakota',
    value: 'SD'
  },
  {
    label: 'Tennessee',
    value: 'TN'
  },
  {
    label: 'Texas',
    value: 'TX'
  },
  {
    label: 'Utah',
    value: 'UT'
  },
  {
    label: 'Vermont',
    value: 'VT'
  },
  {
    label: 'Virginia',
    value: 'VA'
  },
  {
    label: 'Washington',
    value: 'WA'
  },
  {
    label: 'West Virginia',
    value: 'WV'
  },
  {
    label: 'Wisconsin',
    value: 'WI'
  },
  {
    label: 'Wyoming',
    value: 'WY'
  }
]

const CAProvince = [
  { value: 'AB', label: 'Alberta' },
  { value: 'BC', label: 'British Columbia' },
  { value: 'MB', label: 'Manitoba' },
  { value: 'NB', label: 'New Brunswick' },
  { value: 'NL', label: 'Newfoundland and Labrador' },
  { value: 'NS', label: 'Nova Scotia' },
  { value: 'ON', label: 'Ontario' },
  { value: 'PE', label: 'Prince Edward Island' },
  { value: 'QC', label: 'Quebec' },
  { value: 'SK', label: 'Saskatchewan' }
]

const getStateOrProvinceForUSandCA = value => value === 'US' ? USStates : CAProvince

const getStateByISO = (isoState) => USStates.find(i => i.value === isoState) || CAProvince.find(i => i.value === isoState)

// TODO set Timezone as value label
const getTimezoneByCountryForSelect = country => {
  const isoCountry = country
  return getTimezonesForCountry(isoCountry).map(timezone => ({ value: timezone.name, label: `${timezone.name} UTC: ${timezone.utcOffsetStr}` }))
}

/**
 * Converts a date into the local time it receives
 * @param {Date} utcTime - ie: new Date()
 * @param {string} localToConvert - ie: 'America/New_York'
 */
const utcToLocalTime = (utcTime, localToConvert) => new Date(utcTime).toLocaleString('en-US', { timeZone: localToConvert })

export { getAvailablesCountries, getCountriesList, getStateOrProvinceForUSandCA, getTimezoneByCountryForSelect, getCountryByISO, getStateByISO, utcToLocalTime }
