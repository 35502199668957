/**
 * Utilities for normalizing import data from different sources.
 */

const formatDate = date => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' }
  return new Intl.DateTimeFormat('en-US', options).format(date)
}

export const ImportTypes = new Set([
  'Full',
  'Limited',
  'Sample'
])

export const ImportActions = new Set([
  'draft',
  'create',
  'update',
  'match'
])

export const SampleImportConfig = {
  importType: 'Sample',
  importAction: 'match'
}

export const validateImportType = (importType) => {
  if (!ImportTypes.has(importType)) throw new Error(`Invalid import type ${importType}`)
}

export const validateImportAction = (importAction) => {
  if (!ImportActions.has(importAction)) throw new Error(`Invalid import action ${importAction}`)
}

/**
 * Utility class for normalizing our concept of an import. All the data on this
 * class is expected to be present in the places we refer to imports.
 */
export class ChannelImport {
  /**
   * Constructs an import object.
   */
  constructor (
    id,
    slug,
    instance,
    organizationId,
    status,
    importFileName,
    creationDate,
    tableId,
    loadingTable,
    importType,
    importAction,
    creationFileDate,
    estimatedTime,
    importFileUrl,
    loadedIntoSD,
    legacyJobId
  ) {
    if (!id) throw new Error('Import missing id')
    validateImportType(importType)
    validateImportAction(importAction)
    this.id = id
    this.slug = slug
    this.instance = instance
    this.organizationId = organizationId
    this.status = status
    this.importFileName = importFileName
    this.creationDate = creationDate
    this.tableId = tableId
    this.loadingTable = loadingTable
    this.importType = importType
    this.importAction = importAction
    this.creationFileDate = creationFileDate
    this.estimatedTime = estimatedTime
    this.importFileUrl = importFileUrl
    this.loadedIntoSD = loadedIntoSD
    this.legacyJobId = legacyJobId
  }

  /**
   * Returns a user object constructed from a user MongoDB entry.
   */
  static fromMongo (data) {
    const {
      _id,
      slug,
      instance,
      organizationId,
      status,
      importFileName,
      creationDate,
      creationFileDate,
      tableId,
      loadingTable,
      importType,
      importAction,
      estimatedTime,
      importFileUrl,
      loadedIntoSD,
      legacyJobId
    } = data
    const id = _id.toString()
    return new ChannelImport(
      id,
      slug,
      instance,
      organizationId,
      status,
      importFileName,
      creationDate,
      tableId,
      loadingTable,
      importType,
      importAction,
      creationFileDate ? formatDate(creationFileDate) : creationFileDate,
      estimatedTime,
      importFileUrl,
      !!loadedIntoSD,
      legacyJobId
    )
  }
}
