import React from 'react'
import { useHistory } from 'react-router'
import { useLogs } from '../../hooks'
import { Filters, LogsTable } from '../../scenes/Logs'
import { people } from '../../img/illustrations'
import { EmptyState, Loadable } from '../../components'

const defaultFilter = requestId => [{ key: 'requestId', opr: '=', val: `${requestId}` }]

/**
 * @param {{
* parentLog: Log
* targetOrgData: Organization
* }} props
* @returns
*/
export const RelatedLogs = ({ parentLog, targetOrgData }) => {
  const { requestId, identifier } = parentLog

  const { noLogs } = people
  const history = useHistory()
  const targetOrganizationId = targetOrgData?.id

  const { data: relatedLogs, error, loading, called, activeFilters, activeColumns, refreshing, handleAddFilter, handleRemoveFilter, refreshLogs } = useLogs({ targetOrgData, defaultFilter: defaultFilter(requestId) })

  const { UserLogs } = relatedLogs || {}
  const { logs, count: logsCount } = UserLogs || {}

  if (!called) return null
  if (loading || refreshing) return <Loadable inline loading />
  if (logs?.length < 1) return <EmptyState isEmpty heading='No related logs' customImage={noLogs} content='There are no logs related to the current log.' />

  return (
    <Loadable
      inline
      error={error}
      content={
        <>
          <h3 className='mb-3'>Related Logs</h3>
          <Filters
            logs={logs}
            activeFilters={activeFilters}
            handleAddFilter={handleAddFilter}
            handleRemoveFilter={handleRemoveFilter}
            downloadButtonText='Download Related Logs'
            suggestions={[
              { key: 'requestId', opr: '=', val: requestId },
              { key: 'identifier', opr: '=', val: identifier }
            ]}
          />
          <LogsTable
            logs={logs}
            history={history}
            logsCount={logsCount}
            refreshLogs={refreshLogs}
            activeColumns={activeColumns}
            handleAddFilter={handleAddFilter}
            targetOrganizationId={targetOrganizationId}
          />
        </>
        }
    />
  )
}
