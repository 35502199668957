import React, { useState } from 'react'
import { Col, FormGroup, Input, Label, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import { Field } from 'react-final-form'
import { FaEye as ShowPass, FaEyeSlash as HidePass } from 'react-icons/fa'

const PasswordType = ({ data }) => {
  const { label, fieldName, disabled } = data
  const [showPass, setShowPass] = useState(false)
  return (
    <>
      <Col sm={7}>
        <Label for={fieldName}>{label}</Label>
      </Col>
      <Col sm={5}>
        <Field
          name={fieldName}
        // validate={name => name ? undefined : `${label} is required`}
        >
          {({ input, meta }) => {
            return (
              <FormGroup>
                <InputGroup>
                  <Input
                    {...input}
                    disabled={disabled}
                    style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                    type={`${showPass ? 'text' : 'password'}`}
                  />
                  <InputGroupAddon addonType='append'>
                    <InputGroupText onClick={() => setShowPass(!showPass)}>
                      {showPass ? <ShowPass /> : <HidePass />}
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            )
          }}
        </Field>
      </Col>
    </>
  )
}

export { PasswordType }
