import React, { useCallback, useEffect } from 'react'
import moment from 'moment'
import './Notifications.css'
import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import { GET_CUSTOMER } from '../../scenes/Billing/commonQueries'
import { AVAILABLE_CHANNELS, GET_AUTH_STATUS } from '../../scenes/Channel/commonQueries'
import {
  trialNotification,
  stripeStatusNotification,
  closeToExpireTokenNotification,
  alreadyExpiredTokenNotification,
  openNewTabNotification,
  isOnAnIframe,
  onHoldNotification
} from './utils'
import { useStores } from '../../stores/RootStore'
import { useLocation } from 'react-router'

export const Notifications = () => {
  // Stripe data stuff
  const { data: stripeCustomerData } = useQuery(GET_CUSTOMER)
  const { stores } = useStores()
  const { auth } = stores
  const location = useLocation()
  const { externalChannel } = auth || {}
  const { GetCustomer } = stripeCustomerData || {}
  const { subscription, price } = GetCustomer || {}
  const { trial_end: trialEnd, trial_start: trialStart, status } = subscription || {}
  const { id: priceId, unit_amount: unitAmount } = price || {}
  const trialExpired = moment().isAfter(moment.unix(trialEnd))
  const daysLeft = trialEnd && moment.unix(trialEnd).endOf('hour').fromNow()
  const fourteenDaysSinceStartedTrial = moment.unix(trialStart).add(14, 'days')
  const fourteenDaysReached = moment().isAfter(fourteenDaysSinceStartedTrial)
  const boughtPlanButStillOnTrial = status === 'trialing' && unitAmount / 100 <= 500

  // Prevent new users from seeing the trial notification to prevent them from deciding to wait to complete the purchase
  // but we keep the notification code in case it is needed in the future
  const shouldShowTrialNotification = false // trialEnd && status !== 'active'
  const shouldShowStripeStatusNotification = status && status !== 'active' && status !== 'trialing'

  // Token expiration stuff
  const { data: availableChannels } = useQuery(AVAILABLE_CHANNELS)
  const [getAuthStatus, { data: channelData }] = useLazyQuery(GET_AUTH_STATUS)

  const { ChannelInstances } = availableChannels || {}
  const channelsWithExpirationDate = ChannelInstances?.filter(i => i.dateOfTokenExp)
  const today = new Date()
  const day = 86400000 // 1 day in milliseconds
  const daysOffset = day * 3 // in milliseconds, the number of previous days needed to show notification that the token is close to expire
  const channelsAlreadyExpired = channelsWithExpirationDate?.filter(({ dateOfTokenExp }) => (new Date(dateOfTokenExp) - today) < 0)
  const channelsCloseToExpire = channelsWithExpirationDate?.filter(i => {
    const difference = new Date(i.dateOfTokenExp) - today
    return difference > 0 && difference < daysOffset
  })

  const shouldShowCloseToExpire = channelsCloseToExpire?.length > 0
  const shouldShowAlreadyExpired = channelsAlreadyExpired?.length > 0
  const shouldShowAccountOnHold = priceId === 'price_0PRgNouByytmL9TJzYFs0w7U' // ON HOLD stripe plan id

  // Calculate if shouldShowOpenNewTabNotification
  const getLatestChannelInstanceAuthStatus = useCallback(() => {
    const slug = externalChannel
    if (slug) {
      const instanceNumber = ChannelInstances?.reduce((acum, currentValue) => {
        return currentValue?.channel?.slug === slug && currentValue?.instance > acum ? currentValue?.instance : acum
      }, 0)
      getAuthStatus({ variables: { slug, instanceNumber } })
    }
  }, [ChannelInstances, getAuthStatus, externalChannel])

  useEffect(() => {
    if (isOnAnIframe && availableChannels) {
      getLatestChannelInstanceAuthStatus()
    }
  }, [availableChannels, getLatestChannelInstanceAuthStatus])

  const { ChannelInstance } = channelData || {}
  const { authStatus: authStatusOfIFrameChannel } = ChannelInstance || {}
  const shouldShowOpenNewTabNotification = isOnAnIframe && authStatusOfIFrameChannel === 'complete'

  return (
    <>
      {/* Token expired */}
      {shouldShowAlreadyExpired && alreadyExpiredTokenNotification({ channelsAlreadyExpired })}
      {/* Token close to expire */}
      {shouldShowCloseToExpire && closeToExpireTokenNotification({ channelsCloseToExpire })}
      {/* Stripe subscription statuses */}
      {shouldShowStripeStatusNotification && stripeStatusNotification({ status })}
      {/* Stripe trial notification */}
      {shouldShowTrialNotification && trialNotification(({ boughtPlanButStillOnTrial, trialExpired, daysLeft, fourteenDaysReached }))}
      {/* Open in new tab notification */}
      {shouldShowOpenNewTabNotification && openNewTabNotification(location.pathname)}
      {/* Account on hold */}
      {shouldShowAccountOnHold && onHoldNotification()}
    </>
  )
}
