import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { ErrorAlert, Loadable } from '../../../components'
import { MILLISECONDS_TO_REFRESH_BSS } from './ImportElements'
import { Alert, FormText, Progress, Spinner } from 'reactstrap'

const debug = require('debug')('sd:BulkProgress')

const isEmpty = obj => obj && Object.keys(obj).length === 0 && obj.constructor === Object

const limitValue = val => {
  if (val < 0) return 0
  if (val > 100) return 100
  return val
}

const humanize = (estimation, type) => {
  const humanized = estimation && moment.duration(estimation, 'seconds').humanize()
  if (typeof humanized === 'string') {
    const [amount, unit] = humanized?.split(' ')
    if (unit === 'minute') return '1 minute'
    if (type === 'remaining' && amount < 7) return 'about 5 minutes'
    if (type === 'remaining') return `about ${Math.ceil(amount / 10) * 10} minutes` // rounds to 10, 20, 30...
  }
  return humanized
}

const JobRow = ({ title, data }) => {
  const { chunkProgress, secondsRemaining, secondsElapsed } = data || {}
  const progressInPercentage = Math.round(100 * chunkProgress)
  const value = isEmpty(data) ? 0 : limitValue(progressInPercentage)

  return (
    <div className='mb-2'>
      <Progress className='my-1' value={value}>{title} | {value}%</Progress>
      <FormText className='d-flex flex-wrap justify-content-center mb-2 mt-0'>
        {secondsElapsed >= 0 ? <span className='mx-2'>Time elapsed: {humanize(secondsElapsed, 'elapsed')}</span> : ''}
        {secondsRemaining >= 0 ? <span className='mx-2'>Time remaining: {humanize(secondsRemaining, 'remaining')}</span> : ''}
      </FormText>
    </div>
  )
}

export const BulkProgress = ({ loadingBSSummary, errorBSSummary, dataBSSummary, avoidRefetchBSS }) => {
  const [now, setNow] = useState(new Date())
  const { BulkStatusSummary } = dataBSSummary || {}
  const { status: overallStatus, chunkProgress, secondsElapsed, secondsRemaining, chunker, database, channel, finisher } = BulkStatusSummary || {}
  const overallProgressInPercentage = Math.round(chunkProgress * 100)
  const overallValue = overallStatus === 'complete' ? 100 : overallProgressInPercentage
  const bulkCompleted = overallStatus === 'complete'
  const errorMessage = errorBSSummary?.message

  debug('______________________')
  debug('overallStatus:', overallStatus)
  debug('chunkProgress:', chunkProgress)
  debug('overallValue:', overallValue)
  debug('BulkStatusSummary', BulkStatusSummary)

  // Forces a re-render every MILLISECONDS_TO_REFRESH_BSS
  useEffect(() => {
    let timer
    if (overallStatus !== 'complete' && !avoidRefetchBSS) {
      timer = setInterval(() => {
        setNow(new Date())
      }, MILLISECONDS_TO_REFRESH_BSS)
    }
    return () => clearInterval(timer)
  }, [now, overallStatus, avoidRefetchBSS])

  // We have to wait for legacyJobId to be set before we get a correct response from BulkStatusSummary
  if (errorMessage?.includes('legacyJobId not set')) {
    return (
      <div className='mb-4'>
        <div className='mb-3'>
          <b className='d-block mb-2'>Bulk progress</b>
          <Alert color='info'><Spinner size='sm' className='mr-1' /> We're working on your upload</Alert>
        </div>
      </div>
    )
  }

  if (errorBSSummary || overallStatus === 'killed') return <ErrorAlert className='my-2' error={errorBSSummary || 'Something went wrong with your upload, please try again in a few minutes.'} />

  return (
    <div className='mb-4'>
      <div className='mb-3'>
        <b className='d-block'>Bulk progress</b>
        {dataBSSummary &&
          <Loadable
            inline
            loading={loadingBSSummary}
            style={{ margin: '0.5rem auto' }}
            content={(
              <>
                {overallValue
                  ? <Progress className='my-2' value={overallValue}>{overallValue}%</Progress>
                  : null}
                <FormText className='d-flex flex-wrap justify-content-center mb-4 mt-n1'>
                  {secondsElapsed >= 0 ? <span className='mx-2'>Time elapsed: {humanize(secondsElapsed, 'elapsed')}</span> : ''}
                  {secondsRemaining >= 0 ? <span className='mx-2'>Time remaining: {humanize(secondsRemaining, 'remaining')}</span> : ''}
                </FormText>
                {!bulkCompleted && (
                  <>
                    <JobRow title='Analyzing file' data={chunker} />
                    <JobRow title='Updating SureDone' data={database} />
                    <JobRow title='Updating Channels' data={channel} />
                    <JobRow title='Building result file' data={finisher} />
                  </>
                )}
              </>
            )}
          />}
      </div>
    </div>
  )
}
