import React from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import {
  FaChevronRight as R,
  FaChevronLeft as L
} from 'react-icons/fa'

function ResourceListPaginator ({ pageIndex, maxPageIndex, setPageIndex }) {
  if (maxPageIndex <= 0) return null
  return (
    <Pagination>
      {maxPageIndex + 1 > 5 && (
        <>
          <PaginationItem disabled={pageIndex === 0}>
            <PaginationLink onClick={e => {
              e.preventDefault()
              setPageIndex(0)
            }}
            ><L />
            </PaginationLink>
          </PaginationItem>

          {/*
          <PaginationItem disabled={pageIndex === 0}>
            <PaginationLink onClick={e => {
              e.preventDefault()
              const newPageIndex = pageIndex - 1
              setPageIndex(newPageIndex)
            }}
            ><L />
            </PaginationLink>
          </PaginationItem>
          */}
        </>
      )}

      {[-4, -3, -2, -1, 0, 1, 2, 3, 4].filter(
        index => pageIndex + index >= 0 && pageIndex + index <= maxPageIndex
      ).map(index => (
        <PaginationItem key={pageIndex + 1 + index} active={index === 0}>
          <PaginationLink onClick={e => {
            e.preventDefault()
            const newPageIndex = pageIndex + index
            setPageIndex(newPageIndex)
          }}
          >{pageIndex + 1 + index}
          </PaginationLink>
        </PaginationItem>
      ))}

      {maxPageIndex + 1 > 5 && (
        <>
          {/*
          <PaginationItem disabled={pageIndex === maxPageIndex}>
            <PaginationLink onClick={e => {
              e.preventDefault()
              const newPageIndex = pageIndex + 1
              setPageIndex(newPageIndex)
            }}
            ><R />
            </PaginationLink>
          </PaginationItem>
          */}

          <PaginationItem disabled={pageIndex === maxPageIndex}>
            <PaginationLink onClick={e => {
              e.preventDefault()
              const newPageIndex = maxPageIndex
              setPageIndex(newPageIndex)
            }}
            ><R />
            </PaginationLink>
          </PaginationItem>
        </>
      )}
    </Pagination>
  )
}

export { ResourceListPaginator }
