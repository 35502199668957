import React from 'react'
import { Link } from 'react-router-dom'
import { useObserver } from 'mobx-react'
import { EnvBadge, MainSearch } from '.'
import { UserDropdownMenuDesktop } from '../UserDropdownMenu'
import logo from '../../img/suredone-logo-gray-white-5000x1015.png'
// import { NotificationsToggler } from '../NotificationsDrawer/NotificationsToggler' – Uncomment when needed
import './MainNavBar.scss'

const debug = require('debug')('sd:Navbar')

export function SidebarToggle ({ sidebarOpen, setSidebarOpen }) {
  return useObserver(() =>
    <button
      type='button'
      data-toggle='aside-menu'
      aria-label='toggle aside menu'
      className='hamburger hamburger-squeeze mr-2 d-flex d-md-none'
      onClick={e => {
        e.preventDefault()
        setSidebarOpen(!sidebarOpen)
      }}
    >
      <span className='hamburger-box'> <span className='hamburger-inner' /> </span>
    </button>
  )
}

export function MainNavbar ({ sidebarOpen, setSidebarOpen = () => { }, setDrawerOpen }) {
  return useObserver(() =>
    <header className='app-header app-header-dark main-navbar'>
      <div className='top-bar'>
        <div className='top-bar-brand'>
          <SidebarToggle sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          <div className='m-1' style={{ position: 'relative' }}>
            <Link to='/dashboard'>
              <img src={logo} alt='SureDone Logo' className='img-fluid' style={{ maxHeight: '25px', width: '100% 0', paddingBottom: '0' }} />
            </Link>
            <EnvBadge />
          </div>
        </div>

        <div className='top-bar-list'>
          <div className='top-bar-item px-2 d-md-none d-lg-none d-xl-none'>
            <SidebarToggle sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          </div>
          <MainSearch />
          {/* <NotificationsToggler setDrawerOpen={setDrawerOpen} /> – Uncomment when needed */}
          <div className='ml-auto'>
            <UserDropdownMenuDesktop />
          </div>
        </div>
      </div>
    </header>
  )
}

debug('loaded')
