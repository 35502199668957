import React, { useState } from 'react'
import Select from 'react-select'
import { useChannelSettings } from '../../hooks'
import { useMutation } from '@apollo/react-hooks'
import { SaveButton } from '../../components/SaveButton'
import { FaTrash as DeleteIcon } from 'react-icons/fa'
import { UPDATE_CHANNEL_SETTINGS } from './commonQueries'
import { DefaultShipMappingTable } from './DefaultShipMappingTable'
import { ActionButton, ErrorAlert, Loadable } from '../../components'
import { Form, Label, Col, Row, Input, FormText, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const parseValue = set => {
  const val = set?.value?.value
  if (!val || !val[0]) return ''

  try {
    return JSON.parse(val)
  } catch (error) {
    return 'Error parsing value'
  }
}

const parseOptions = set => {
  const { selectOptions } = set || {}
  const { mappingValues } = selectOptions?.[0] || {}
  const keys = mappingValues && Object.keys(mappingValues)
  return keys?.map(k => ({ value: k, label: mappingValues[k] }))
}

const includeSlug = slug => {
  if (slug === 'ebay') {
    return 'ebay_'
  } else {
    return ''
  }
}

export const DefaultShipMapping = ({ slug, instanceNumber }) => {
  const [, { loading, error }, info, { getSet }] = useChannelSettings(slug, instanceNumber)
  let shipMapping
  if (slug === 'ebay') {
    shipMapping = info?.filter(i => i?.name === 'ebay_orders_import_shipping_carrier_mapping')?.[0]
  } else {
    shipMapping = getSet('orders_import_shipping_carrier_mapping')
  }
  const shipMappingValue = parseValue(shipMapping)
  const channelShippingOptions = parseOptions(shipMapping) // default, standard, priority, etc.

  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const toggleConfirmDeleteModal = () => setConfirmDeleteModal(prev => !prev)

  const [newValue, setNewValue] = useState({ channelShippingService: null, service: '', carrier: '' })
  const [isDirty, setIsDirty] = useState(false)
  const [mutationError, setMutationError] = useState(false)
  const [updateChannelSettings, { loading: mutationLoading, data: mutationData }] = useMutation(UPDATE_CHANNEL_SETTINGS, {
    onCompleted: () => {
      setIsDirty(false)
      setConfirmDeleteModal(false)
    },
    onError: (err) => {
      setMutationError(err || 'There was an error updating Default Ship Mapping settings. Try again please.')
    }
  })

  const handleNewValue = (key, event) => {
    let val
    if (key === 'channelShippingService') {
      val = event
    } else {
      val = event?.target?.value
    }
    setNewValue(p => ({ ...p, [key]: val }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const objToSave = {
      ...shipMappingValue,
      [newValue.channelShippingService.value]: `${newValue.carrier}${newValue.service ? `*${newValue.service}` : ''}`
    }
    const valuesToSave = {
      name: `${includeSlug(slug)}orders_import_shipping_carrier_mapping`,
      type: 'MAPPING',
      value: JSON.stringify(objToSave)
    }
    updateChannelSettings({ variables: { slug, instanceNumber, settings: valuesToSave } })
  }

  const deleteShippingServices = () => {
    updateChannelSettings({
      variables: {
        slug,
        instanceNumber,
        settings: {
          name: `${includeSlug(slug)}orders_import_shipping_carrier_mapping`,
          type: 'MAPPING',
          value: ''
        }
      }
    })
  }

  return (
    <Loadable
      inline
      loading={loading}
      error={error}
      style={{ textAlign: 'center' }}
      content={
        <Form onSubmit={handleSubmit} className='channel-settings'>
          <Row className='mb-3'>
            <Col xs={12}>
              <Label tag='legend' className='m-0'>Default Ship Mapping</Label>
              <FormText className='w-100 mt-0 mb-1'>
                Translate your channel order shipping services to preferred shipping carrier and service
              </FormText>
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={6} xl={4}>
              <DefaultShipMappingTable data={shipMappingValue} />
            </Col>
          </Row>

          <Row className='mt-4 mb-1'>
            <Col xs={12}>
              <Label tag='legend' className='m-0'>Add new value</Label>
              <FormText className='w-100 mt-0 mb-1'>
                Configure each Channel Shipping Service and save the changes
              </FormText>
            </Col>
          </Row>
          <Row className='mb-2'>
            <Col xs={12} md={6} lg={4} xl={3}>
              <Select
                clearable={false}
                options={channelShippingOptions}
                placeholder='Channel Shipping Service'
                value={newValue?.channelShippingService}
                onChange={e => handleNewValue('channelShippingService', e)}
              />
            </Col>
          </Row>
          <Row className='mb-2'>
            <Col xs={12} md={6} lg={4} xl={3}>
              <Input
                placeholder='Carrier'
                onChange={e => handleNewValue('carrier', e)}
                disabled={!newValue?.channelShippingService?.value}
              />
            </Col>
          </Row>
          <Row className='mb-2'>
            <Col xs={12} md={6} lg={4} xl={3}>
              <Input
                placeholder='Service'
                disabled={!newValue?.carrier}
                onChange={e => handleNewValue('service', e)}
              />
            </Col>
          </Row>

          {shipMappingValue && (
            <>
              <Row className='mt-4 mb-2'>
                <Col xs={12}>
                  <Label tag='legend' className='m-0'>Delete values</Label>
                </Col>
              </Row>
              <Row className='mb-2'>
                <Col>
                  <ActionButton
                    outline
                    color='danger'
                    icon={<DeleteIcon className='m-0' />}
                    onClick={() => toggleConfirmDeleteModal()}
                  >
                    <span className='ml-2'>
                      Delete All Shipping Services
                    </span>
                  </ActionButton>
                </Col>
              </Row>
            </>
          )}

          {mutationError && <ErrorAlert error={mutationError} />}

          <div className='d-flex justify-content-end'>
            <SaveButton
              type='submit'
              color='primary'
              loading={mutationLoading}
              success={mutationData ? 'true' : null}
              dirtysincelastsubmit={isDirty ? 'true' : null}
              disabled={!newValue.channelShippingService?.value || !newValue?.carrier}
            >
              Save Default Ship Mapping
            </SaveButton>
          </div>

          {/* Delete All Shipping Services confirmation modal */}
          <Modal isOpen={confirmDeleteModal} toggle={toggleConfirmDeleteModal}>
            <ModalHeader toggle={toggleConfirmDeleteModal}>Deleteing Shipping Services</ModalHeader>
            <ModalBody>
              You are about to delete all your Shipping Services. Are you sure?
            </ModalBody>
            <ModalFooter>
              <ActionButton onClick={toggleConfirmDeleteModal}>
                Cancel
              </ActionButton>
              <ActionButton color='danger' onClick={deleteShippingServices}>
                Delete All Shipping Services
              </ActionButton>
            </ModalFooter>
          </Modal>
        </Form>
      }
    />
  )
}
