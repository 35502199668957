import { useState, useEffect, useRef } from 'react'
/**
 * Debounce setting a value
 * @param {{shopUrl, clientId, clientSecret}} objectValue
 * @param delay
 * @returns {[string, fn, fn]}
 */
export const useAuthDebounce = (objectValue, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(objectValue)
  const handler = useRef(null)
  const canceller = () => {
    clearTimeout(handler.current)
  }
  useEffect(() => {
    if (objectValue && Object.keys(objectValue).length === 0 && objectValue.constructor === Object) return
    handler.current = setTimeout(() => {
      setDebouncedValue(objectValue)
    }, delay)
    return canceller
  }, [objectValue, objectValue.shopUrl, objectValue.clientId, objectValue.clientSecret, delay])
  return [debouncedValue, canceller, setDebouncedValue]
}
