/**
 * This component will show a modal with a disclaimer text and an input where
 * the user has to type 'Accept' to enable the button to perform the desired action.
 *
 * Usage:
 *  <DisclaimerModal
 *    header      = String | React.ReactHTML
 *    content     = String | React.ReactHTML
 *    actionLabel = String
 *    isOpen      = Boolean
 *    onAccept    = Function - Executed once the user clicks the accept modal button
 *    toggleModal = Function - Open/close the modal
 *  />
 */
import React, { useState } from 'react'
import { ActionButton } from '.'
import { Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap'
import './DisclaimerModal.scss'

/**
 * @param {{
 *  header: String | React.ReactHTML,
 *  content: String | React.ReactHTML,
 *  actionLabel: String
 *  isOpen: boolean,
 *  italic: boolean,
 *  color: string,
 *  onAccept: (e: React.MouseEvent<HTMLButtonElement>) => void,
 *  toggleModal: Function,
 * }} props
 * @returns {React.ReactHTML}
 */
export const DisclaimerModal = ({ header, content, actionLabel, isOpen, italic, color, toggleModal, onAccept }) => {
  const [inputValue, setInputValue] = useState('')

  const handleAccept = e => {
    onAccept(e)
    toggleModal()
  }

  return (
    <Modal centered isOpen={isOpen} toggle={toggleModal} scrollable onClosed={() => setInputValue('')} className='disclaimer-modal'>
      <ModalHeader toggle={toggleModal} className='pb-2'>{header || 'Limitation of Liability'}</ModalHeader>
      <ModalBody>
        <p className={`d-block mt-1 ${italic ? 'mb-4 pl-2 pr-3 font-italic' : 'mb-0'}`} style={{ maxHeight: '180px', overflow: 'auto' }}>
          {content || 'SureDone is not responsible for this action, please be careful.'}
        </p>
        <p className='mb-1'>
          Please type <b>Accept</b> to confirm.
        </p>
        <Input
          value={inputValue}
          placeholder='Accept'
          onChange={e => setInputValue(e.target.value)}
        />
      </ModalBody>
      <ModalFooter>
        <ActionButton onClick={toggleModal}>
          Cancel
        </ActionButton>
        <ActionButton
          color={color || 'primary'}
          onClick={e => handleAccept(e)}
          disabled={inputValue?.toLocaleLowerCase() !== 'accept'}
        >
          {actionLabel || 'Accept'}
        </ActionButton>
      </ModalFooter>
    </Modal>
  )
}
