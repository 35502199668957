import React from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'

export function Step ({ to, name, icon, desktopPrefix, desktopSuffix, enableClick = true, isActive = false, success }) {
  const match = useRouteMatch(to)
  const active = !!match || isActive
  return (
    <li
      className={'step' + (active ? ' active' : '') + (success ? ' success' : '')}
      style={!enableClick ? { pointerEvents: 'none' } : {}}
    >
      <NavLink
        to={to}
        className='step-trigger'
        aria-selected={active ? 'true' : ''}
      >
        <span className='d-none d-md-inline'>{desktopPrefix}</span>
        <span>{name}</span>
        <span className='d-none d-md-inline'>{desktopSuffix}</span>
      </NavLink>
    </li>
  )
}

export function Steps ({ children, className = '' }) {
  return (
    <div className={`steps steps- ${className}`} role='tablist'>
      <ul>
        {children}
      </ul>
    </div>
  )
}
