import React from 'react'
import { CodeBlock } from '../../../components'
import { InstructionCollapse } from '../InstructionCollapse'

const code = `.sd-myGarageButton-Aside, .sd-myGarageButton-initialSearch {
    display:none !important;
  }`

const code2 = `.sd-myGarageButton-Aside {
    display:none !important;
  }`

const code3 = `.sd-myGarageButton-initialSearch {
    display:none !important;
  }`

export const BigcommerceHideMyVehicleButton = () => {
  return (
    <InstructionCollapse id='HideMVB' title='3. Hide My Garage button'>
      <ol className='pl-3'>
        <li>Edit your theme files</li>
        <li>Go to <code>assets {'>'} scss {'>'} custom.scss</code></li>
        <li>Add the following code:
          <CodeBlock language='css' className='mb-0'>{code}</CodeBlock>
          This code will remove the button from all pages
          <img src='https://assets.mysuredone.com/688015/logos/fitment-advanced-bigcommerce-HMG-1.png' alt='Bigcommerce custom.scss editor' />
        </li>
        <li> You may want to remove the button from only 1 place so you can do it as follows:
          <CodeBlock language='css' className='mb-0'>{code2}</CodeBlock>
          This code will remove the button from filters <br />
          <CodeBlock language='css' className='mb-0'>{code3}</CodeBlock>
          This code will remove the button from the initial year make model dropdowns
        </li>
        <li>
          Click Save and wait until deployed
        </li>
        <li>
          <b>Results:</b> <br />
          Before
          <img src='https://assets.mysuredone.com/688015/logos/fitment-advanced-bigcommerce-HMG-2.png' alt='Bigcommerce before hiding button' />
          <img src='https://assets.mysuredone.com/688015/logos/fitment-advanced-bigcommerce-HMG-4.png' alt='Bigcommerce before hiding button' /><br />
          After
          <img src='https://assets.mysuredone.com/688015/logos/fitment-advanced-bigcommerce-HMG-3.png' alt='Bigcommerce after hiding button' />
          <img src='https://assets.mysuredone.com/688015/logos/fitment-advanced-bigcommerce-HMG-5.png' alt='Bigcommerce after hiding button' />
        </li>
        <li>
          Click Save and wait until deployed
        </li>
      </ol>
    </InstructionCollapse>
  )
}
