import React from 'react'
import { Card, CardBody } from 'reactstrap'
import { LoggedOutPage } from '../../components/LoggedOutPage'

export const PersonReferral = () => {
  return (
    <LoggedOutPage>
      <Card>
        <CardBody className='px-3 py-4'>
          <p className='m-0'>
            We don't think SureDone is a perfect fit for your business. However, I'd like to personally recommend a solution that will work for you, keep an eye out for an email from me. <br /><br />
            Sincerely, Jason Nadaf.
          </p>
        </CardBody>
      </Card>
    </LoggedOutPage>
  )
}
