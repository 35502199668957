/**
 * This component displays a list of similar objects. The main purpose of a
 * resource list is to help a user find one of these objects and either take
 * quick actions on it or navigate to a full page representation of it.
 *
 * Props documentation:
 *
 * <ResourceList
 *   items
 *     A list of items to display
 *   itemComponent
 *     A react component that renders each item.
 *     Props passed to itemComponent:
 *      - item - The original item you passed in in the `items` prop above
 *      - searchTerms - A array of strings from the user's normalized search
 *        searchQuery. Use this array to power search highlighting.
 *     Your component should render an instance of <ResourceListItem>.
 *   itemPlural
 *     The plural name for an item, for example "automations".
 *   disableSearch
 *     Disables the normal search control shown above the resources.
 *   searchableStrings
 *     A function that takes an item and returns an array of strings that
 *     should be included when searching the list.
 *     Example: item => [item.name, item.description, ...item.tags]
 *   searchPlaceholder
 *     Placeholder text to appear in the search box.
 *     Defaults to "Search ${itemPlural}".
 *   defaultQuery
 *     Query to include when component is first rendered.
 *   disablePagination
 *     Disables the pagination component beneath the resources.
 *   itemsPerPage
 *     Number of items per page in the built-in pagination system.
 *   defaultPage
 *     One-based index of the page to be loaded when component is first
 *     rendered. Note, user can change this explicitly or by typing in the
 *     search searchQuery box.
 *   className
 *     The class name for the <ResourceList> component itself (for styling).
 * />
 *
 * <ResourceListItem
 *   to
 *     A relative url path to the full page representation of the item.
 *     Clicking on the item navigates here.
 *   onClick
 *     Just passes through the onClick handler in case you don't want to
 *     use the `to` property. Calling e.preventDefault() here intercepts click.
 *   disabled
 *     Set this boolean to show the item in a disabled style and prevent the
 *     user from clicking on it.
 * >
 *   Any content you want to render inside the list item can go here.
 * </ResourceListItem>
 *
 */

import React, { useState } from 'react'
import { Input, InputGroup, InputGroupText, Row, Col } from 'reactstrap'
import { FaSearch as Search } from 'react-icons/fa'
import { ResourceListPaginator, stringMatchesTokens, scoreString, tokenizeQuery } from './'
import './ResourceList.css'
import Debug from 'debug'
const debug = Debug('sd:ResourceList')

//
// Internal components
//

function ResourceFilter ({
  searchQuery,
  setSearchQuery,
  searchPlaceholder,
  itemPlural,
  items,
  displayItems,
  searchedItems,
  pageIndex,
  itemsPerPage,
  sortDropdown
}) {
  const startItem = pageIndex * itemsPerPage + 1
  const endItem = Math.min((pageIndex + 1) * itemsPerPage, searchedItems.length)
  return (
    <>
      <Row className='mb-2'>
        <Col>
          <InputGroup>
            <InputGroupText>
              <Search />
            </InputGroupText>
            <Input
              type='search'
              name='search'
              value={searchQuery}
              placeholder={searchPlaceholder}
              onChange={e => setSearchQuery(e.target.value)}
            />
          </InputGroup>
        </Col>
        {sortDropdown &&
          <Col className='col-auto d-none d-sm-flex'>
            {sortDropdown}
          </Col>}
      </Row>
      <Row className='mb-2'>
        <Col>
          {displayItems.length
            ? (
              <span>
                Showing {searchedItems.length > itemsPerPage ? `${startItem}-${endItem} ` : ''}
                {searchQuery ? `${searchedItems.length > itemsPerPage ? 'of ' : ''} ${searchedItems.length} matching ${itemPlural} ` : ''}
                {searchQuery ? 'out' : ''} of {items.length} {searchQuery ? 'total' : itemPlural}.
              </span>)
            : 'No results.'}
        </Col>
      </Row>
    </>
  )
}

function ResourceListItems ({ items, searchQuery, searchTerms, itemComponent: ItemComponent }) {
  if (!items.length) return null
  return items.map((item, i) =>
    <ItemComponent item={item} key={i} searchQuery={searchQuery || ''} searchTerms={searchTerms} />
  )
}

const UserResourceList = ({
  // Items
  items = [],
  itemComponent,
  itemPlural = 'items',

  // Search
  searchableStrings = () => { },
  searchPlaceholder = 'Search items',
  defaultQuery = '',
  disableSearch = false,

  // Pagination
  itemsPerPage = 10,
  defaultPage = 1,
  disablePagination = false,
  paginationTop = false, // undocumented paginator at top

  // Meta
  className = ''
}) => {
  // Page stuff
  const sanitizedItemsPerPage = Math.max(1, itemsPerPage)
  const maxPages = Math.ceil(items.length / sanitizedItemsPerPage)
  const sanitizedDefaultPage = Math.max(1, Math.min(defaultPage, maxPages))
  const [pageIndex, setPageIndex] = useState(sanitizedDefaultPage - 1)

  // Search stuff
  const [searchQuery, setSearchQuery] = useState(defaultQuery)
  debug('searchQuery', searchQuery)
  const searchTerms = tokenizeQuery(searchQuery)
  const resetPageIndexAndSetSearchQuery = searchQuery => {
    // Whenever the user changes the searchQuery, we should set our pageIndex to 0
    setPageIndex(0)
    setSearchQuery(searchQuery)
  }

  // Item stuff
  const searchedItems = items
    // Filter based on any match
    .filter(item => {
      const strings = searchableStrings(item)
      return strings.some(s => stringMatchesTokens(s, searchTerms))
    })
    // Then do more expensive scoring
    .map(item => {
      const strings = searchableStrings(item)
      const score = strings
        .map(s => scoreString(s, searchTerms))
        .reduce((a, b) => a + b, 0)
      return Object.assign(item, { ResourceList_search: { score, strings, searchTerms } })
    })
    // And sort on the result
    .sort((a, b) => {
      return b.ResourceList_search.score - a.ResourceList_search.score
    })
  debug('searchedItems', searchedItems)

  const searchedMaxPages = Math.ceil(searchedItems.length / sanitizedItemsPerPage)
  const displayItems = searchedItems.slice(pageIndex * sanitizedItemsPerPage, (pageIndex + 1) * sanitizedItemsPerPage)

  return (
    <div className='section-block'>
      {!disableSearch && (
        <ResourceFilter
          searchQuery={searchQuery}
          setSearchQuery={resetPageIndexAndSetSearchQuery}
          searchPlaceholder={searchPlaceholder}
          items={items}
          itemPlural={itemPlural}
          displayItems={displayItems}
          searchedItems={searchedItems}
          pageIndex={pageIndex}
          itemsPerPage={itemsPerPage}
        />
      )}
      {!disablePagination && paginationTop && (
        <ResourceListPaginator
          pageIndex={pageIndex}
          maxPageIndex={searchedMaxPages - 1}
          setPageIndex={setPageIndex}
        />
      )}
      <ResourceListItems
        items={displayItems}
        searchQuery={searchQuery}
        searchTerms={searchTerms}
        itemComponent={itemComponent}
      />
      {!disablePagination && (
        <div className='d-flex justify-content-center m-3'>
          <ResourceListPaginator
            pageIndex={pageIndex}
            maxPageIndex={searchedMaxPages - 1}
            setPageIndex={setPageIndex}
          />
        </div>
      )}
    </div>
  )
}

export { UserResourceList }
debug('loaded')
