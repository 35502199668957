import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Col, Row } from 'reactstrap'
import { useStores } from '../../stores/RootStore'
import { ActionButton, ErrorModal, Loadable, Page } from '../../components'
import { ResourceList, ResourceListItem } from '../../components/SearchableLists'
import { ChannelSummary, AddChannelModal } from './'
import useCreateChannel from '../../hooks/useCreateChannel'
import { isNewUIHandlingAddChannel, getLink } from './utils'

const debug = require('debug')('sd:ChannelsAvailableList')

function ChannelListItem ({ item: channel, searchTerms, channelInstances }) {
  const { stores: { auth } } = useStores()
  const { slug, name: channelName } = channel || {}
  const [addNewChannel, { loading: newChannelLoading, error: errorAddNewChannel }] = useCreateChannel(slug)

  const generateButton = slug => {
    const hasUnauthorizedInstanceForThisChannel = channelInstances?.find(({ channel, authStatus }) => channel.slug === slug && authStatus !== 'complete')

    const handleClickAddChannel = (slug, addChannel) => {
      const currentEvent = 'Channel: Add'
      const currentHistory = window?.analyticsEventHistory
      const newHistory = [currentEvent, ...currentHistory].slice(0, 10)
      window.analyticsEventHistory = newHistory
      const fullFilled = {
        channel: channelName,
        pathHistory: window?.analyticsPathHistory,
        priorPath: window?.analyticsPathHistory?.[0],
        eventHistory: newHistory,
        priorEvent: newHistory?.[1]
      }
      if (window.analytics && !addChannel) {
        window.analytics.track(currentEvent, {
          action: 'User clicks add channel on the channel list and the popup modal is shown',
          ...fullFilled
        })
      }
      if (addChannel) {
        window.analytics.track(currentEvent, {
          action: 'User clicks add channel on the channel list',
          ...fullFilled
        })
        addNewChannel()
      }
    }

    // Shows a button which open a modal to decide between authorize the channel or add a new instance of it
    if (hasUnauthorizedInstanceForThisChannel) {
      return (
        <AddChannelModal
          slug={slug}
          channelName={channelName}
          addNewChannel={addNewChannel}
          channelInstances={channelInstances}
          newChannelLoading={newChannelLoading}
          parentCallback={() => handleClickAddChannel(slug, false)}
        />
      )
    }

    // Adds a new instance of this channel when click
    return (
      <ActionButton
        color='success'
        loading={newChannelLoading}
        onClick={() => handleClickAddChannel(slug, 'addChannel')}
      >
        Add Channel
      </ActionButton>
    )
  }

  const errorMessage = errorAddNewChannel?.message || errorAddNewChannel
  const refreshErrors = ['not extensible', '500 - undefined']

  if (refreshErrors.some(e => errorMessage?.includes(e))) {
    auth.refresh()
  }

  return (
    <Row className='w-100'>
      <ChannelSummary isPublished isListItem channel={channel} searchTerms={searchTerms} />
      <Col xs={12} sm='auto' className='list-group-item-figure justify-content-center border-0'>
        {isNewUIHandlingAddChannel(slug)
          ? generateButton(slug)
          : <a href={getLink(slug, 0)} className='btn btn-success'>Add channel</a>}
      </Col>
      {errorAddNewChannel && <ErrorModal header='Error adding new channel' error={errorAddNewChannel} />}
    </Row>
  )
}

const AVAILABLE_CHANNELS = gql`
  query ChannelData {
    AvailableChannels {
      id
      slug
      name
      logoUrl
      description
      readme
    }
    ChannelInstances {
      id
      instance
      authStatus
      channel {
        id
        slug
      }
    }
  }
`

export function ChannelsAvailableList () {
  const { loading, error, data } = useQuery(AVAILABLE_CHANNELS)
  const { ChannelInstances: channelInstances } = data || {}
  const items = data
    ? [...new Set([...(data.AvailableChannels || []), ...(data.InstalledChannels || [])])]
    : []

  const orderToShow = ['amzn', 'ebay', 'bigcommerce', 'shopify', 'miva', 'facebook', 'google', 'walmart', 'etsy', 'magento', 'magentotwo', 'skuvault']
  const sortedItems = items.sort((a, b) => orderToShow.indexOf(a.slug) - orderToShow.indexOf(b.slug))

  return (
    <Page
      title='Add Channel'
      description={
        <span>
          You can setup channels on your account to manage bi-directional product and order management.
          Click on the channel to see more details and instructions on how to set it up.
        </span>
      }
    >
      <Loadable
        loading={loading}
        error={error}
        content={
          <ResourceList
            items={sortedItems}
            itemsPerPage={15}
            itemPlural='channels'
            itemComponent={({ item, searchTerms }) =>
              <ResourceListItem hasLink={false}>
                <ChannelListItem
                  item={item}
                  searchTerms={searchTerms}
                  channelInstances={channelInstances}
                />
              </ResourceListItem>}
            searchableStrings={item => [item.name, item.description, item.vendor, item.id]}
          />
        }
      />
    </Page>
  )
}

debug('loaded')
