import React from 'react'
import { ActionButton } from '../../../components'
import { Modal, ModalHeader, ModalBody, ModalFooter, Label, Input } from 'reactstrap'

export const MultiFacetsModal = ({ facetsToSave, setFacetsThatAreMultiple, isOpen, toggleMultiFacetsModal, onAccept, loading }) => {
  const handleCheckboxes = (facet, checked) => {
    if (checked) {
      setFacetsThatAreMultiple(prev => [...prev, facet])
    } else {
      setFacetsThatAreMultiple(prev => prev.filter(i => i !== facet))
    }
  }

  const handleAccept = () => {
    onAccept()
  }

  return (
    <Modal centered isOpen={isOpen} toggle={toggleMultiFacetsModal} scrollable className='confirmation-modal' onClosed={() => setFacetsThatAreMultiple([])}>
      <ModalHeader toggle={toggleMultiFacetsModal} className='pb-2'>Multiple Custom Facets</ModalHeader>
      <ModalBody>
        {facetsToSave?.length > 0 && (
          <>
            <p className='mb-2'>
              You are saving the following attributes as custom facets, <b>are any of them multiple</b>? – If you are not sure, simply do not check any field and save the custom facets.
            </p>

            <u style={{ listStyle: 'none', textDecoration: 'none' }}>
              {facetsToSave.map(facet => (
                <li key={facet}>
                  <Label check className='custom-control custom-checkbox mb-0' for={facet} style={{ cursor: 'pointer' }}>
                    <Input
                      id={facet}
                      type='checkbox'
                      className='custom-control-input'
                      onChange={e => handleCheckboxes(facet, e.target.checked)}
                    />
                    <span className='custom-control-label'>{facet}</span>
                  </Label>
                </li>))}
            </u>

            <p className='mb-0 mt-2'>
              <b>To take action, the value of the multiple custom facets must be star delimited, e.g. <code>bedsize: "Short*Medium*Long"</code>.</b>
            </p>

            <p className='text-muted small mt-3'>
              A custom facet is multiple when your products can have one or more values for the same facet. If, for example, the <b>Bed Size</b> of one of your products can be <b>Short</b> and <b>Long</b> at the same time, then <b>Bed Size is a multiple facet</b>.
            </p>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <ActionButton onClick={toggleMultiFacetsModal}>
          Cancel
        </ActionButton>
        <ActionButton
          color='primary'
          loading={loading}
          onClick={e => handleAccept(e)}
        >
          Save Custom Facets
        </ActionButton>
      </ModalFooter>
    </Modal>
  )
}
