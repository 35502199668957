import React, { useState } from 'react'
import { Col, FormGroup, Input, Label, Row } from 'reactstrap'
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import gql from 'graphql-tag'
import { useLazyQueryAsPromise } from '../hooks/useLazyQueryAsPromise'
import Debug from 'debug'

const debug = Debug('sd:ImageFileUploader')

const GET_UPLOAD_PARAMS = gql`
  query GetUploadParams ($timestamp: String, $filename: String) {
    GetUploadParams(timestamp: $timestamp, filename: $filename) {
      fields {
        Key
        Acl
        Expires
        CacheControl
        ContentType
        Bucket
        Policy
        Signature
        XAmzAlgorithm
        XAmzCredential
        XAmzSecurityToken
        XAmzDate
      }
      url
    }
  }
  `

function checkImageExists (imageUrl, callBack) {
  const imageData = new window.Image()
  imageData.onload = () => callBack(true)
  imageData.onerror = () => callBack(false)
  imageData.src = imageUrl
}

const ImageFileUploader = (props) => {
  const { validFormats, maxFiles, acceptsUrl, currentURL, parentCallback } = props
  const [disabled, setDisabled] = useState('')
  const [validURL, setValidURL] = useState()
  const [fileValue, setFileValue] = useState('')

  // Lazyquery get all S3 upload params
  const [query] = useLazyQueryAsPromise(GET_UPLOAD_PARAMS)

  // This function is executed by Dropzone right before upload, it returns all stuff to upload the image
  const getUploadParamsFromS3 = async (props) => {
    const timestamp = new Date().getTime().toString()
    const { meta: { name } } = props
    try {
      const logoDisplayDomain = window.serverConfig.logoDisplayDomain
      const { data } = await query({ timestamp, filename: name })
      const { GetUploadParams } = data || {}
      debug({ GetUploadParams })
      const { fields, url } = GetUploadParams
      const { Key, Acl, Expires, CacheControl, ContentType, Bucket, Policy, Signature, XAmzAlgorithm, XAmzSecurityToken, XAmzCredential, XAmzDate } = fields
      const mapFields = {
        Key,
        Acl,
        Expires,
        'Cache-Control': CacheControl,
        'Content-Type': ContentType,
        Bucket,
        Policy,
        'X-Amz-Signature': Signature,
        'X-Amz-Algorithm': XAmzAlgorithm,
        'X-Amz-Credential': XAmzCredential,
        'X-Amz-Date': XAmzDate
      }
      if (XAmzSecurityToken) mapFields['X-Amz-Security-Token'] = XAmzSecurityToken
      const info = { withDropzone: true, fileUrl: `https://${logoDisplayDomain}/${Key}` }
      setFileValue(info)
      parentCallback(info)
      return { fields: mapFields, meta: { fileUrl: url }, url }
    } catch (e) {
      debug('Uploading error:', e)
    }
  }

  const handleChangeDropzone = ({ meta, file }, status) => {
    if (status === 'removed') {
      setFileValue({ ...fileValue, fileUrl: '' })
      parentCallback(({ ...fileValue, fileUrl: '' }))
    }
    return (
      (status !== 'removed' && status !== 'rejected_file_type')
        ? (setDisabled('input'))
        : setDisabled('')
    )
  }

  const handleChangeInput = (e) => {
    const value = e.target.value
    value ? setDisabled('dropzone') : setDisabled('')
    const info = { withDropzone: false, fileUrl: value }
    checkImageExists(value, function (existsImage) {
      if (existsImage) {
        const validFormats = ['jpg', 'png', 'gif']
        const actualFormat = info.fileUrl.split('.').pop()
        const formatIsValid = validFormats.includes(actualFormat)
        const errorMessage = `Sorry, ${actualFormat} is not a valid format. Only ${validFormats.map(i => ` ${i}`)} are valid.`
        if (formatIsValid) {
          setValidURL(true)
          setFileValue(info)
          parentCallback(info)
        } else {
          setValidURL(false)
          setFileValue({ ...info, error: errorMessage })
          parentCallback({ ...info, error: errorMessage })
        }
      } else {
        setValidURL(false)
        setFileValue({ ...info, error: 'The image doesn\'t exist' })
        parentCallback({ ...info, error: 'The image doesn\'t exist' })
      }
    })
  }

  const _dropzone = (
    <Dropzone
      getUploadParams={getUploadParamsFromS3}
      onChangeStatus={handleChangeDropzone}
      inputContent={(files, extra) => (extra.reject ? '.jpg .png or .gif formats only' : 'Drop files or click to upload.')}
      accept={validFormats}
      inputWithFilesContent={null}
      maxFiles={maxFiles}
      disabled={disabled === 'dropzone'}
      styles={{
        dropzone: { minHeight: 80, border: '2px dashed rgba(34,34,48,.1)', overflow: 'auto' },
        preview: { padding: '10px', minHeight: '78px', borderBottom: 0 },
        previewImage: { maxHeight: 60 },
        dropzoneReject: { borderColor: 'red', backgroundColor: '#fff5f5' },
        inputLabel: (files, extra) => (extra.reject
          ? {
              color: 'rgb(136, 140, 155)',
              fontSize: '16px',
              fontWeight: 'normal',
              marginBottom: 0,
              padding: '10px'
            }
          : { color: '#888c9b', fontSize: '16px', fontWeight: 'normal', marginBottom: 0, padding: '10px' })
      }}
    />
  )

  const hasAnError = !validURL && fileValue && fileValue.withDropzone !== true && fileValue.fileUrl !== ''
  const _input = (
    <FormGroup>
      <Label tag='legend' for='URL'>From URL</Label>
      <Input
        disabled={disabled === 'input'}
        type='text'
        placeholder={currentURL || 'Logo\'s URL'}
        onChange={e => handleChangeInput(e)}
        className={hasAnError ? 'is-invalid' : null}
      />
      {hasAnError && <div className='invalid-feedback' style={{ display: 'block' }}>{fileValue.error}</div>}
    </FormGroup>
  )

  return (
    acceptsUrl
      ? (
        <Row className='justify-content-between py-2'>
          <Col md='5'>{_dropzone}</Col>
          <Col md='1' className='d-flex justify-content-center align-items-center'>or</Col>
          <Col md='6'>{_input}</Col>
        </Row>)
      : _dropzone
  )
}

export { ImageFileUploader }
