import React from 'react'
import { InstructionCollapse } from './InstructionCollapse'

export const FitmentFAQ = () => {
  return (
    <>
      <h2 className='mb-4'>Frequently Asked Questions</h2>

      <InstructionCollapse id='faq-2' title='Product configurations'>
        <ul className='pl-3'>
          <li>
            <h3>Fitment EPIDs</h3>
            <ul>
              <li>Send in: <code>ebayepid</code></li>
              <li>
                It must be a list of eBay epids in numerical form, separated by <code>*</code><br />
                <div style={{ maxWidth: '600px', padding: ' 5px 0' }}>
                  <img src='https://assets.suredone.com/688015/logos/fitment-epids.png' alt='Fitment EPIDs' /><br />
                </div>
                <a href='https://support.suredone.com/support/solutions/articles/1000215453-bulk-fitment' target='_blank' rel='noopener noreferrer'>How to upload Fitment in Bulk to eBay</a>
              </li>
              <li>Products must be listed in the channel in which you are using SureFit as well.</li>
            </ul>
          </li>
          <li>
            <h3>Fitment Type</h3>
            <ul>
              <li>Send in: <code>fitmenttype</code></li>
              <li>Possible values:
                <ul>
                  <li><code>MVL:</code> sets the fitment for vehicles and powersports.</li>
                  <li><code>MML:</code> sets the fitment for motorcycles and powersports.</li>
                  <li><code>UNIVERSAL</code>: sets universal fitment.</li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </InstructionCollapse>
      <InstructionCollapse id='faq-1' title='How do Universal Products interact with the SureFit plugin'>
        <p className='m-0'>
          If a product is <code>UNIVERSAL</code> it will be displayed by the <i>SureFit</i> app even if it has not been configured as a Fitment product.
        </p>
      </InstructionCollapse>
    </>
  )
}
