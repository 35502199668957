import gql from 'graphql-tag'

export const GRAPHS_DATA = gql`
query ChannelData($from: String, $to: String) {
  GetDashboardData (from: $from, to: $to) {
    ototals {
      value
      date
      label
      legend
    }
    itotals {
        value
        date
        label
        legend
      }
    stotals {
        value
        date
        label
        legend
      }
    xtotals {
      value
      date
      label
      legend
    }
    orders {
      value
      date
      label
      legend
    }
    shipcosttotals {
      value
      date
      label
      legend
    }
  }
}
`

export const AVAILABLE_CHANNELS = gql`
  query ChannelData {
    ChannelInstances {
      id
      channel {
        id
        slug
        name
      }
      instance
      enabled
      authStatus
    }
  }
`

export const GET_ONE_ORDER = gql`
query OneOrder {
  GetOneOrder 
}
`

export const GET_AMOUNT_OF_PRODUCTS = gql`
query AmountOfProducts {
  GetAmountOfProducts 
}
`

export const GET_AMOUNT_OF_IMPORTS = gql`
query GetAmountOfImports {
  GetAmountOfImports 
}
`

export const GET_ONBOARD_DATA = gql`
query OnboardData {
  OnboardData
}
`

export const UPDATE_ONBOARD_DATA = gql`
mutation updateOnboardData($input: JSON) {
  UpdateOnboardData(input: $input)
}
`
