/* eslint-disable camelcase */
import React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { useDebounce } from '../../../../../hooks'
import { Alert } from 'reactstrap'

const SHOP_ALREADY_AUTHORIZED = gql`
query ShopifyAlreadyAuthorized($shop: String!) {
  ShopifyAlreadyAuthorized(shop: $shop)
}
`

export const AuthorizedShopifyStores = ({ values }) => {
  const [debouncedShop] = useDebounce(values?.shop, 400)
  const { data } = useQuery(SHOP_ALREADY_AUTHORIZED, {
    skip: !debouncedShop,
    variables: { shop: debouncedShop }
  })
  const { shop, instance, authorized } = data?.ShopifyAlreadyAuthorized || {}

  if (!authorized) return null

  return (
    <Alert color='info'>
      The shop has already been authorized for instance {instance}.
      You can try another shop or log in to {shop} and uninstall the SureDone app there.
    </Alert>
  )
}
