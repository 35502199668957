import React from 'react'
import './VisualPicker.css'

export const VisualPicker = props => {
  const { id, name, className = '', isSelected, children, parentCallback } = props
  return (
    <div className={`visual-picker has-peek w-100 pb-0 my-2 ${className} ${isSelected && 'active'}`} onClick={() => parentCallback(id)}>
      <input type='radio' id={id} name={name} />
      <label className='visual-picker-figure w-100 h-100 my-0' htmlFor={id}>
        <span className='visual-picker-content text-left w-100 p-0'>
          {children}
        </span>
      </label>
    </div>
  )
}
