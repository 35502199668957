export const sampleData = {
  totalSales: [
    {
      label: '2020-02-04',
      ototal: 173,
      itotal: 170,
      legend: 'Order total',
      legend1: 'Item total'
    },
    {
      label: '2020-02-05',
      ototal: 184,
      itotal: 180,
      legend: 'Order total',
      legend1: 'Item total'
    },
    {
      label: '2020-02-06',
      ototal: 650,
      itotal: 640,
      legend: 'Order total',
      legend1: 'Item total'
    },
    {
      label: '2020-02-08',
      ototal: 340,
      itotal: 300,
      legend: 'Order total',
      legend1: 'Item total'
    },
    {
      label: '2020-02-11',
      ototal: 847,
      itotal: 780,
      legend: 'Order total',
      legend1: 'Item total'
    },
    {
      label: '2020-04-01',
      ototal: 615,
      itotal: 615,
      legend: 'Order total',
      legend1: 'Item total'
    },
    {
      label: '2020-05-08',
      ototal: 820,
      itotal: 820,
      legend: 'Order total',
      legend1: 'Item total'
    },
    {
      label: '2020-06-22',
      ototal: 1346,
      itotal: 1220,
      legend: 'Order total',
      legend1: 'Item total'
    },
    {
      label: '2020-07-09',
      ototal: 1342,
      itotal: 1342,
      legend: 'Order total',
      legend1: 'Item total'
    }
  ],
  totalOrders: [
    {
      value: 1,
      label: '2020-02-04',
      legend: 'Orders total'
    },
    {
      value: 2,
      label: '2020-02-05',
      legend: 'Orders total'
    },
    {
      value: 1,
      label: '2020-02-06',
      legend: 'Orders total'
    },
    {
      value: 1,
      label: '2020-02-08',
      legend: 'Orders total'
    },
    {
      value: 3,
      label: '2020-02-11',
      legend: 'Orders total'
    },
    {
      value: 1,
      label: '2020-04-01',
      legend: 'Orders total'
    },
    {
      value: 1,
      label: '2020-05-08',
      legend: 'Orders total'
    },
    {
      value: 3,
      label: '2020-06-22',
      legend: 'Orders total'
    },
    {
      value: 4,
      label: '2020-07-09',
      legend: 'Orders total'
    }
  ],
  totalShipping: [
    {
      value: 0,
      label: '2020-02-04',
      legend: 'Shipping total'
    },
    {
      value: 28,
      label: '2020-02-05',
      legend: 'Shipping total'
    },
    {
      value: 8,
      label: '2020-02-06',
      legend: 'Shipping total'
    },
    {
      value: 10,
      label: '2020-02-08',
      legend: 'Shipping total'
    },
    {
      value: 22,
      label: '2020-02-11',
      legend: 'Shipping total'
    },
    {
      value: 16,
      label: '2020-04-01',
      legend: 'Shipping total'
    },
    {
      value: 0,
      label: '2020-05-08',
      legend: 'Shipping total'
    },
    {
      value: 64,
      label: '2020-06-22',
      legend: 'Shipping total'
    },
    {
      value: 52,
      label: '2020-07-09',
      legend: 'Shipping total'
    }
  ],
  totalTax: [
    {
      value: 0,
      label: '2020-02-04',
      legend: 'Tax total'
    },
    {
      value: 30,
      label: '2020-02-05',
      legend: 'Tax total'
    },
    {
      value: 8,
      label: '2020-02-06',
      legend: 'Tax total'
    },
    {
      value: 10,
      label: '2020-02-08',
      legend: 'Tax total'
    },
    {
      value: 34,
      label: '2020-02-11',
      legend: 'Tax total'
    },
    {
      value: 12,
      label: '2020-04-01',
      legend: 'Tax total'
    },
    {
      value: 0,
      label: '2020-05-08',
      legend: 'Tax total'
    },
    {
      value: 106,
      label: '2020-06-22',
      legend: 'Tax total'
    },
    {
      value: 90,
      label: '2020-07-09',
      legend: 'Tax total'
    }
  ]
}
