import { useQuery } from '@apollo/react-hooks'
import { GET_CUSTOMER } from '../scenes/Billing/commonQueries'

export const HotjarUpdates = () => {
  const { data: stripeCustomerData } = useQuery(GET_CUSTOMER)
  const { GetCustomer } = stripeCustomerData || {}
  const { isLegacyCustomer, subscription } = GetCustomer || {}
  const { status } = subscription || {}

  // Add trial tag to hotjar
  if (status && status === 'trialing') window.hj('tagRecording', ['trial'])

  // if (options.site_plan !== 'SAAS-PLAN') ==> isLegacyCustomer === true
  // so with this in mind we'll know if the user have been created since we allowed self-signup
  const isNewUser = isLegacyCustomer === null
  if (isNewUser) window.hj('tagRecording', ['newuser'])

  return null
}
