import React from 'react'
import { NavLink as RNavLink, useRouteMatch } from 'react-router-dom'
import { Card, CardBody, NavLink } from 'reactstrap'

import Debug from 'debug'
const debug = Debug('sd:InternalSidebar')

const InternalSidebar = ({ headerTitle, availableTabs }) => {
  const match = useRouteMatch()
  const { url } = match
  return (
    <Card fluid>
      <h6 className='card-header'>
        {headerTitle}
      </h6>
      <CardBody className='px-0 py-0'>
        <div class='nav nav-tabs flex-column border-0'>
          {availableTabs.map(tab => (<NavLink key={tab} to={`${url}/${tab.toLowerCase()}`} tag={RNavLink}>{tab}</NavLink>))}
        </div>
      </CardBody>
    </Card>

  )
}

export { InternalSidebar }

debug('loaded')
