import React from 'react'
import { InfoAlert } from '../InfoAlert'
import { CodeBlock } from '../../../components'
import { InstructionCollapse } from '../InstructionCollapse'

const code = `{{#if product.sku}}
    <script>
      if (!window.shopData) window.shopData = { }
      if (!window.shopData.storefront) window.shopData.storefront = "bigcommerce"
      if (!window.shopData.suredoneID) window.shopData.suredoneID = SUREDONEID
      if (!window.sdFitmentData) window.sdFitmentData = { }
      window.sdFitmentData.sku = "{{ product.sku }}"
    </script>
  {{/if}}
  <script src="https://fitment.suredone.com/bundle.min.js"></script>`

export const BigcommerceAddFYV = () => {
  return (
    <InstructionCollapse id='AddFYV' title='5. Add Fits Your Vehicle and Year Make Model table to the product page'>
      <InfoAlert>
        If you use this tag on a <b>product with variations</b>, take into account that for fitment to be displayed on the product page without using the year/make/model search, a <b>default variation</b> must be selected in the shared options.
      </InfoAlert>
      <ol className='pl-3'>
        <li>Edit your theme files</li>
        <li>Go to <code>templates {'>'} pages {'>'} product.html</code></li>
        <li>Above the line <code>{'{{/partial}}'}</code> paste the following code: <br />
          <CodeBlock>{code}</CodeBlock>
          Please <b style={{ margin: '8px 0', display: 'inline-block' }}>replace SUREDONEID with your SureDone id</b>.<br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/fitment-advanced-bigcommerce-1.png' alt='Bigcommerce advance editor' />
        </li>
        <li>Now we need to add the root of the <strong>fitment table</strong> and <strong>fit your vehicle</strong> where you want to show each component.</li>
        <li>
          Go to <code>templates {'>'} components {'>'} products {'>'} product-view.html</code>
        </li>
        <li>
          We want to show Fits your vehicle right below the product details
          So at the end of <code>{'<section class="productView-details">'}</code> paste following code: <br />
          <CodeBlock language='html'>{'<div id="sd-fitment-fyv-root"></div>'}</CodeBlock>
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/fitment-advanced-bigcommerce-2.png' alt='Bigcommerce advance editor' />
        </li>
        <li>
          We want to show the table at the bottom of product view so on the next line of closing tag of <code>{'<div class="productView">'}</code> paste following code: <br />
          <CodeBlock language='html'>{'<div id="sd-fitment-chart-root"></div>'}</CodeBlock><br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/fitment-advanced-bigcommerce-3.png' alt='Bigcommerce advance editor' />
        </li>
        <li>Click on save all files and go back to bigcommerce dashboard Theme: Storefront {'>'} Theme</li>
        <li>On the copied theme, go to 3 dots and click on apply</li>
      </ol>
    </InstructionCollapse>
  )
}
