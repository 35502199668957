import React from 'react'
import { CartesianGrid, Legend, LineChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { withComma } from './utils'

const SDLineChart = ({ data, lines, name, mLeft }) => {
  const CustomTooltip = (props) => {
    const { active, payload, label } = props
    return (
      active &&
        <div className='custom-tooltip'>
          {payload && payload[0] && <p className='value-0'>{`${payload[0].payload.legend}: $${withComma(payload[0].value)}`}</p>}
          {payload && payload[1] && <p className='value-1'>{`${payload[1].payload.legend1}: $${withComma(payload[1].value)}`}</p>}
          <p className='label text-muted'>{`${label}`}</p>
        </div>
    )
  }

  return (
    <div style={{ width: '100%', height: 300 }}>
      <ResponsiveContainer>
        <LineChart
          data={data}
          margin={{ left: mLeft, right: 5, top: 5 }}
        >
          {lines || <Line name={name} type='monotone' dataKey='value' stroke='#336db0' />}
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='label' />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

export { SDLineChart }
