import { useStores } from '../../stores/RootStore'

const useRegister = () => {
  const { stores: { auth } } = useStores()
  const { user } = auth || {}
  const { firstName, lastName } = user || {}

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  return {
    firstName,
    lastName,
    timezone
  }
}

export { useRegister }
