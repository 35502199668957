import React from 'react'
import Highlighter from 'react-highlight-words'
import { Col } from 'reactstrap'
import { Link } from 'react-router-dom'
import { logos } from './ChannelsLogos'

import './ChannelSummary.css'

export function ChannelSummary ({ channel = {}, searchTerms, isListItem = false, isPublished = false, className, to = '' }) {
  const { name, description, slug } = channel
  return (
    <>
      <Col xs={12} sm={3} className='automation-summary list-group-item-figure justify-content-center p-2'>
        {logos[slug]}
      </Col>
      <Col xs={12} className='list-group-item-body text-center text-sm-left p-2'>
        <div className='d-sm-flex justify-content-sm-between align-items-sm-center'>
          <div className='team'>
            <h4 className='list-group-item-title'>
              <b style={{ fontWeight: 600 }}>
                <Highlighter
                  className=''
                  tag={Link}
                  to={to}
                  highlightTag='mark'
                  autoEscape
                  searchWords={searchTerms || []}
                  textToHighlight={name || 'Unnamed Channel'}
                />
              </b>
            </h4>
          </div>
        </div>
        <div>
          <Highlighter
            highlightTag='mark'
            autoEscape
            searchWords={searchTerms || []}
            textToHighlight={description || ''}
          />
        </div>
      </Col>
    </>
  )
}
