import React from 'react'
import { TextType, InputType, SelectType, ToggleType, PasswordType, CheckboxType, SelectMultiType } from '.'

const renderSwitch = (data, extraData) => {
  const { type } = data
  switch (type) {
    case 'TEXT':
      return <TextType data={data} />
    case 'INPUT':
      return <InputType data={data} />
    case 'TOGGLE':
      return <ToggleType data={data} />
    case 'SELECT':
      return <SelectType data={data} />
    case 'SELECT_MULTI':
      return <SelectMultiType data={data} extraData={extraData} />
    case 'PASSWORD':
      return <PasswordType data={data} />
    case 'CHECKBOX':
      return <CheckboxType data={data} />
    default:
      return 'Undefined type'
  }
}

export { renderSwitch }
