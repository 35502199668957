import React from 'react'
import moment from 'moment'
import { useQuery } from '@apollo/react-hooks'
import { UncontrolledAlert } from 'reactstrap/lib'
import { FaFileCsv as CSVicon } from 'react-icons/all'
import { GET_CHANNEL_REPORT } from './commonQueries'
import { GET_CHANNEL_INFO } from './ChannelImportPage/graphqlCommon'
import { Loadable, ActionButton, EmptyState } from '../../components'
import { Card, CardHeader, CardBody, Row, Col, Spinner } from 'reactstrap'

const CompletedReportUI = ({ report, isLatest }) => {
  const { fileName, startDate, endDate, fileUrl } = report || {}
  return (
    <Row>
      <Col xs={7}>
        <ul className='m-0 p-0' style={{ listStyle: 'none' }}>
          <li><b>Started:</b> <span>{moment(Number(startDate)).utc().format('MMMM D, h:mma')}</span></li>
          <li><b>Finished:</b> <span>{moment(Number(endDate)).utc().format('MMMM D, h:mma')}</span></li>
          <li><b>File:</b> <span>{fileName}</span></li>
        </ul>
      </Col>
      <Col xs={5} className='d-flex justify-content-end'>
        <ActionButton
          tag='a'
          href={fileUrl}
          color='primary'
          className='mt-3'
          outline={!isLatest}
        >
          <CSVicon /> Download {isLatest ? 'Latest' : ''} Report
        </ActionButton>
      </Col>
    </Row>
  )
}

export const ChannelReport = ({ reportType, slug, instanceNumber, title }) => {
  const { loading: ciLoading, data: ciData } = useQuery(GET_CHANNEL_INFO, { variables: { slug, instanceNumber } })
  const { ChannelInstance } = ciData || {}
  const { enabled: channelIsEnabled, authStatus: channelAuthStatus } = ChannelInstance || {}

  const { loading, error, data } = useQuery(GET_CHANNEL_REPORT, { variables: { reportType, slug, instanceNumber } })
  const { GetChannelReport: reports } = data || {}
  const [lastReport, ...restOfReports] = reports || []

  return (
    <Loadable
      inline
      error={error}
      style={{ padding: '1rem' }}
      loading={loading || ciLoading}
      content={
        <EmptyState
          isEmpty={!channelIsEnabled || channelAuthStatus !== 'complete'}
          heading='No Reports'
          content='You can not generate reports until the integration is authorized and enabled'
        >
          <UncontrolledAlert color='info'>
            This report runs every day at midnight UTC
          </UncontrolledAlert>
          <Card>
            <CardHeader className='d-flex align-items-center'>
              <h5 className='m-0 mr-2'>{title}</h5>
            </CardHeader>
            <CardBody>
              {!lastReport && <p className='mb-0'><Spinner size='sm' className='mr-1 text-muted' /> There are no reports yet, we're working on a new one.</p>}
              {lastReport && <CompletedReportUI report={lastReport} isLatest />}
              {restOfReports?.length
                ? restOfReports?.length && (
                  <>
                    <hr className='my-2' />
                    {restOfReports.map(report => (
                      <React.Fragment key={report.startDate}>
                        <CompletedReportUI report={report} />
                        <hr className='my-2' />
                      </React.Fragment>
                    )
                    )}
                  </>
                )
                : null}
            </CardBody>
          </Card>
        </EmptyState>
        }
    />
  )
}
