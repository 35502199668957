import React from 'react'
import { Breadcrumb, BreadcrumbItem, Col, Row } from 'reactstrap'
import { useQuery } from '@apollo/react-hooks'
import { Organization } from 'suredone-common'
import { NavLink as RNavLink } from 'react-router-dom'
import { CoverTabs, OrganizationAvatar, Loadable, ViewLogs } from './'
import { FaAngleLeft as Back } from 'react-icons/fa'
import { GET_ORGANIZATION_DETAILS } from '../scenes/OrganizationManager/commonQueries'

/**
 * Looper cover element for organizations (https://www.uselooper.com/page-team-projects.html)
 *
 * Usage:
 *  <organizationCover organization={organization} />
 */

export function OrganizationCover ({ organizationId, availableTabs, back }) {
  const { loading, error, data } = useQuery(GET_ORGANIZATION_DETAILS, {
    variables: { id: organizationId }
  })
  const { OrganizationDetails } = data || { OrganizationDetails: {} }

  const {
    name,
    bio,
    slug,
    legacyAccountId,
    email,
    phone,
    address
  } = OrganizationDetails

  const organization = new Organization({
    id: organizationId,
    name,
    slug,
    legacyAccountId,
    email,
    phone,
    address,
    bio
  })

  return (
    <Loadable
      loading={loading}
      error={error}
      content={
        <>
          <header className='page-cover'>
            <div className='cover-controls cover-controls-bottom'>
              <ViewLogs organizationId={organizationId} />
            </div>
            {back && (
              <div className='d-flex justify-content-between align-items-center'>
                <Breadcrumb>
                  <BreadcrumbItem active tag={RNavLink} to={back.to}><Back />{back.name}</BreadcrumbItem>
                </Breadcrumb>
              </div>
            )}
            <Row className='text-center text-sm-left'>
              <Col className='col-sm-auto col-12 mb-2'>
                <OrganizationAvatar size='5rem' organization={organization} />
              </Col>
              <Col>
                <h1 className='page-title'>
                  {organization.name}
                </h1>
                <p className='text-muted'>
                  {organization.bio}
                </p>
              </Col>
            </Row>
          </header>
          {availableTabs && <CoverTabs availableTabs={availableTabs} />}
        </>
      }
    />
  )
}
