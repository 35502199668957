import React from 'react'
import { Breadcrumb, BreadcrumbItem, Row, Col } from 'reactstrap'
import { NavLink as RNavLink } from 'react-router-dom'
import { FaAngleLeft as Back } from 'react-icons/fa'

export function Page ({ title, logo, description, actions, back, children, cover, className = '' }) {
  return (
    <div className={`page ${className}`}>
      {cover}
      <div className='page-inner'>
        <header className='page-title-bar'>
          {back && (
            <div className='d-flex justify-content-between align-items-center'>
              <Breadcrumb>
                <BreadcrumbItem active tag={RNavLink} to={back.to}><Back />{back.name}</BreadcrumbItem>
              </Breadcrumb>
            </div>
          )}
          <div className='content-wrapper d-md-flex justify-content-between'>
            {(title || logo) && (
              <Row className='text-center text-sm-left'>
                {logo && <Col sm='auto' size='12' className='mb-2'> {logo} </Col>}
                {/*
                <Col style={{minWidth: '20em'}}>
                */}
                <Col className='title-description-wrapper'>
                  <h1 className='page-title mr-sm-auto'> {title} </h1>
                  {description && <p className='text-muted page-description mb-2'> {description} </p>}
                </Col>
              </Row>
            )}
            {actions && <div className='text-center' style={{ minWidth: '11em' }}> {actions} </div>}
          </div>
        </header>
        <div className='page-section'>
          {children}
        </div>
      </div>
    </div>
  )
}
