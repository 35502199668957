import React from 'react'
import { configData } from 'suredone-common'
import { UncontrolledAlert } from 'reactstrap'

const RefresehdAlert = ({ refreshedSettings }) => {
  return (
    <UncontrolledAlert color='info'>
      <p className='m-0'>The following settings have been refreshed:</p>
      <p className='m-0'>
        <b>{refreshedSettings.join(', ')}</b>
      </p>
    </UncontrolledAlert>
  )
}

export const ShowRefreshedSettings = ({ slug }) => {
  const showRefreshedIn = ['shopify', 'bigcommerce']
  if (!showRefreshedIn.includes(slug)) return null

  const dynamicLabels = []
  const settings = configData?.[slug]?.default?._system?.register_settings?.sets || {}

  for (const key in settings) {
    if (settings[key]?.dynamic === true) {
      dynamicLabels.push(settings[key]?.label)
    }
  }

  return <RefresehdAlert refreshedSettings={dynamicLabels} />
}
