import { useEffect, useRef, useState } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'
import gql from 'graphql-tag'
import { useChannelQueryParams } from './'
import { GET_AUTH_STATUS } from '../scenes/Channel'
const debug = require('debug')('sd:useAuthChannel')

const POST_AUTH_COMPLETE = gql`
mutation channelInstanceAuthPost($slug: String!, $instanceNumber: Int!, $stepNumber: Int!, $data:[PostData]!) {
  channelInstanceAuthPost(slug: $slug, instanceNumber: $instanceNumber, stepNumber: $stepNumber, data: $data) {
    id
    authStatus
    shopifyRedirectURL
  }
}
`
const GET_CHANNEL_CACHED_INSTANCE = gql`
query getCachedChannelInstance($slug: String!) {
  Channel(slug: $slug) {
    id
    cachedChannelInstance
  }
}
`

const useAuthChannel = (slug, instanceNumber) => {
  const [url, setUrl] = useState(`/channels/${slug}/0/authorization`)
  const [finalInstanceNumber, setFinalInstanceNumber] = useState(undefined)
  const { loading: loadingCached } = useQuery(GET_CHANNEL_CACHED_INSTANCE, {
    variables: { slug },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (Object.keys(data).length > 0) {
        const cachedChannelInstance = data?.Channel?.cachedChannelInstance[slug]
        // If no instanceNumber on route param and no cached instance number, then default as 0
        if (instanceNumber >= 0) {
          setFinalInstanceNumber(parseInt(instanceNumber))
          setUrl(`/channels/${slug}/${parseInt(instanceNumber)}/authorization`)
          return
        }
        if (instanceNumber === undefined && cachedChannelInstance) {
          setFinalInstanceNumber(cachedChannelInstance)
          setUrl(`/channels/${slug}/${cachedChannelInstance}/authorization`)
          return
        }
        setFinalInstanceNumber(0)
      }
    },
    onError: (error) => {
      debug({ error })
    }
  })

  const [params, stepNumber, authError] = useChannelQueryParams(slug)
  const dataRef = useRef()
  const history = useHistory()
  const [channelInstanceAuthPost, { loading, data, error }] = useMutation(POST_AUTH_COMPLETE,
    {
      onCompleted: (data) => {
        if (data.channelInstanceAuthPost.shopifyRedirectURL) {
          window.location.replace(data.channelInstanceAuthPost.shopifyRedirectURL)
        } else {
          history.push(url)
        }
      },
      update (cache, { data: { channelInstanceAuthPost } }) {
        // Avoid re-querying again in wizard
        const { id, authStatus } = channelInstanceAuthPost || {}
        cache?.writeQuery({
          query: GET_AUTH_STATUS,
          variables: { slug, instanceNumber: finalInstanceNumber },
          data: { ChannelInstance: { id, authStatus, __typename: 'ChannelInstance' } }
        })
      },
      onError: (error) => {
        history.push({
          pathname: url,
          search: `?error=${error.message}`
        })
      }
    }
  )
  useEffect(() => {
    dataRef.current = [params, stepNumber, authError]
  }, [params, authError, stepNumber])

  // DO NOT ADD params, authError TO DEPENDENCY ARRAY, CAUSE INFINITE RE-RENDER!!!!!
  useEffect(() => {
    const paramsAux = dataRef.current[0]
    const stepNumberAux = dataRef.current[1]
    const errorAux = dataRef.current[2]
    // If permission denied, incorrect params or some error from parsing redirect to wizard
    // TODO ADD QUERY PARAMS FOR ERROR HANDLER
    if (Object.keys(errorAux).length > 0) {
      history.push({
        pathname: url,
        search: `?error=${errorAux.message}`
      })
    }
    // If all it's OK, make POST
    if (Object.keys(errorAux).length === 0 && Object.keys(paramsAux).length !== 0 && !loadingCached && finalInstanceNumber !== undefined) {
      // Create array of PostData needed for graphql Schema
      const data = Object.keys(paramsAux).map(param => ({ name: `${param}`, value: paramsAux[param] }))
      channelInstanceAuthPost({ variables: { slug, instanceNumber: finalInstanceNumber, stepNumber: stepNumberAux.stepNumber, data } })
    }
  }, [channelInstanceAuthPost, slug, history, finalInstanceNumber, url, loadingCached])

  return [authError, { loading: (loading || loadingCached), data, error }]
}

export default useAuthChannel

debug('loaded')
