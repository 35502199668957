import React, { useState, useEffect } from 'react'
import { FitSelect } from '.'
import { useYmm } from '../../hooks'
import { useHistory } from 'react-router'
import { Card, CardBody } from 'reactstrap'
import { useStores } from '../../stores/RootStore'
import { people } from '../../img/illustrations'
import { FaSearch as Search } from 'react-icons/fa'
import { ActionButton, EmptyState, Page } from '../../components'

export const InitialSearch = () => {
  const { stores } = useStores()
  const { auth: { user } } = stores
  const { legacyAccountId } = user
  const history = useHistory()
  const { fitmentVehicles } = people
  const [selected, setSelected] = useState({})
  const [suredoneId, setSuredoneId] = useState(Number(legacyAccountId))
  const { data, loading, error } = useYmm(suredoneId, 'suredone', undefined, selected?.year?.value, selected?.make?.value)
  const { loadingYears, loadingMakes, loadingModels } = loading
  const { years, makes, models } = data

  const handleChange = e => {
    const { type } = e || {}
    setSelected(prev => ({ ...prev, [type]: e }))
    if (type === 'year') {
      setSelected(prev => ({ ...prev, make: undefined, model: undefined }))
    }
    if (type === 'make') {
      setSelected(prev => ({ ...prev, model: undefined }))
    }
  }

  // Redirects once user select model
  useEffect(() => {
    const { year, make, model } = selected || {}
    if (model) history.push(`/fitment/result?filters={"year":"${year.value}","make":"${make.value}","model":"${model.value}","page":0}`)
  }, [selected, history])

  return (
    <Page
      title='Find your part'
      className='initial-search'
    >
      <Card className='mb-0'>
        <CardBody className='py-4'>
          <form className='mb-4'>
            <FitSelect
              autoFocus
              id='year'
              label='Year'
              loading={loadingYears}
              disabled={loadingYears || !years}
              placeholder='Select year'
              value={selected.year}
              onChange={handleChange}
              options={years}
            />
            <FitSelect
              id='make'
              label='Make'
              loading={loadingMakes}
              value={selected.make}
              onChange={handleChange}
              disabled={loadingMakes || !makes}
              options={makes}
              placeholder='Select make'
            />
            <FitSelect
              id='model'
              label='Model'
              loading={loadingModels}
              value={selected.model}
              onChange={handleChange}
              disabled={!models || loadingModels}
              options={models}
              placeholder='Select model'
            />
            <ActionButton
              color='primary'
              disabled={!selected.model}
              icon={<Search size={18} className='m-0 d-flex align-items-center' />}
            />
          </form>

          {(years?.length === 0 || error) &&
            <div className='no-data text-center text-muted'>
              <p>It looks like you don't have fitment data yet.</p><br />
              <p>Would you like to <ActionButton color='primary' size='sm' onClick={() => setSuredoneId(688015)}>use data from a demo user</ActionButton> instead?</p>
            </div>}

          <EmptyState isEmpty customImage={fitmentVehicles} />
        </CardBody>
      </Card>
    </Page>
  )
}
