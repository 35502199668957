import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap'
import './WizardInfoModal.scss'
import { Link } from 'react-router-dom'
import { useStores } from '../../stores/RootStore'
import { YoutubeTutorial } from './YoutubeTutorial'
import { ActionButton } from '../../components/ActionButton'
import { launchContact } from '../../components/utils'

const Step1 = ({ showStep, enabled, modalShowCloseOnError, toggle, closeModal }) => {
  const takeMeToMyDashboard = () => {
    const element = document.getElementById('take-me-to-my-dashboard')
    if (window.analytics) {
      const currentEvent = 'Registration: Video'
      const currentHistory = window?.analyticsEventHistory
      const newHistory = [currentEvent, ...currentHistory].slice(0, 10)
      window.analyticsEventHistory = newHistory
      const fullFilled = {
        action: 'Take me to my dashboard',
        description: 'User request dashboard access after the account is created',
        pathHistory: window?.analyticsPathHistory,
        priorPath: window?.analyticsPathHistory?.[0],
        eventHistory: newHistory,
        priorEvent: newHistory?.[1]
      }
      window.analytics.trackLink(element, currentEvent, fullFilled)
    }
    toggle()
  }

  return (
    <div className={showStep === 1 ? ' active' : 'hide'}>
      <h2 className='text-center'>Welcome to SureDone!</h2>
      <p>
        In a moment, you'll have access to the best tools for scaling e-commerce operations. Watch the 60 second video below to see your next steps!
      </p>
      <YoutubeTutorial />
      <ModalFooter className='d-flex flex-wrap justify-content-center px-0'>
        {/* Show the user the option to go to dashbaoard anyway as the error may not have affected the registration */}
        {modalShowCloseOnError && (
          <>
            <ActionButton tag={Link} color='primary' to='/dashboard'>
              Finish registration
            </ActionButton>
            {/* It also shows the option to contact support, in case the registration really failed */}
            <p className='d-flex w-100 justify-content-center align-items-center mt-3 mb-0'>
              Any problem? {' '}
              <Button color='link' className='p-0 ml-1' onClick={() => launchContact()}>
                Contact support
              </Button>
            </p>
          </>
        )}
        {!modalShowCloseOnError &&
          <ActionButton
            color='primary'
            type='submit'
            disabled={!enabled}
            loading={!enabled}
            id='take-me-to-my-dashboard'
            onClick={() => takeMeToMyDashboard()}
          >
            {!enabled ? 'We are creating your account' : 'Finish registration'}
          </ActionButton>}
      </ModalFooter>
    </div>
  )
}

const WizardModalBody = ({ modalUserActionsEnabled, toggle, modalShowCloseOnError, closeModal }) => {
  const { stores } = useStores()
  const { auth } = stores || {}
  const [showStep, setShowStep] = useState(1)
  const nextStep = () => setShowStep(showStep + 1)
  return (
    <div className='wizard-button' role='tablist'>
      <Step1
        showStep={showStep}
        nextStep={nextStep}
        enabled={modalUserActionsEnabled}
        modalShowCloseOnError={modalShowCloseOnError}
        closeModal={closeModal}
        toggle={async () => {
          toggle()
          await auth.refresh()
        }}
      />
    </div>

  )
}

const WizardInfoModal = ({ modalState, setModalState, modalUserActionsEnabled, modalShowCloseOnError }) => {
  const toggle = () => setModalState(!modalState)
  const closeModal = () => setModalState(false)

  return (
    <Modal isOpen={modalState} toggle={toggle} backdrop='static' keyboard={false} size='md' className='modal-dialog-centered'>
      {modalShowCloseOnError && <ModalHeader style={{ zIndex: 5 }} toggle={toggle} className='pb-0 mb-n5' />}
      <ModalBody className='pt-4'>
        <WizardModalBody modalUserActionsEnabled={modalUserActionsEnabled} toggle={toggle} closeModal={closeModal} modalShowCloseOnError={modalShowCloseOnError} />
      </ModalBody>
    </Modal>
  )
}

export { WizardInfoModal }
