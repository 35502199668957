import React from 'react'
import { Badge } from 'reactstrap'
import Highlighter from 'react-highlight-words'
import { ActionButton } from '../../../components'
import { FaExternalLinkAlt as ExternalLink } from 'react-icons/fa'

const Highligt = ({ field, searchTerms }) => <Highlighter autoEscape highlightTag='mark' searchWords={searchTerms || []} textToHighlight={field || ''} />

export const ErrorSummary = ({ item, searchTerms, toggleModal, setModalContent }) => {
  const { id, result, product, media, identifier, title, message, errors, log } = item || {}

  return (
    <li key={id} className='d-flex align-items-center justify-content-between w-100 p-2 border-bottom' style={{ gap: '1rem' }}>
      <span style={{ width: '50px' }} className='media'>
        <img src={media} />
      </span>
      <a className='identifier' href={product} target='_blank' rel='noopener noreferrer'>
        <Highligt field={identifier} searchTerms={searchTerms} />
      </a>
      <span style={{ width: '60px' }} className='result'>
        <Badge color={result === 'warning' ? 'warning' : 'danger'}>
          <Highligt field={result} searchTerms={searchTerms} />
        </Badge>
      </span>
      <span style={{ width: '15%' }} className='title d-none d-xl-flex'>
        <Highligt field={title} searchTerms={searchTerms} />
      </span>
      <span style={{ width: '20%' }} className='message'>
        <Highligt field={message} searchTerms={searchTerms} />
      </span>
      <span style={{ width: '20%' }} className='errors d-none d-lg-inline'>
        {errors?.count === 1 && <Highligt field={errors?.content} searchTerms={searchTerms} />}
        {errors?.count > 1 && (
          <>
            <Highligt field={errors?.content[0]} searchTerms={searchTerms} />
            <ActionButton
              color='link'
              onClick={() => {
                setModalContent({
                  header: identifier,
                  body: errors?.content?.map((i, idx) => (
                    <React.Fragment key={idx}>
                      <p>{i}</p>
                      <hr />
                    </React.Fragment>
                  ))
                })
                toggleModal()
              }}
            >
              See all errors ({errors?.count})
            </ActionButton>
          </>
        )}
      </span>
      <a style={{ width: '100px' }} className='view-log btn btn-outline-primary' href={log} target='_blank' rel='noopener noreferrer'>
        View log <ExternalLink size={10} className='mr-0' style={{ marginTop: '-2px' }} />
      </a>
    </li>
  )
}
