import React from 'react'
import { CodeBlock } from '../../../components'
import { InstructionCollapse } from '../InstructionCollapse'

const code = `{{#if category.id}}
    <script>
      if (!window.shopData) window.shopData = {}
      if (!window.shopData.storefront) window.shopData.storefront = "bigcommerce"
      if (!window.shopData.suredoneID) window.shopData.suredoneID = SUREDONEID
      if (!window.sdFitmentData) window.sdFitmentData = {}
      window.sdFitmentData.category = "{{category.id}}"
      window.sdFitmentData.categoryName = "{{category.name}}"
    </script>
  {{/if}}
  <div id="sd-fitment-categories-root"></div>`

export const BigcommerceCategorySubFilter = () => {
  return (
    <InstructionCollapse id='CategoriesSubFilter' title='4. Add Fitment Filter to Category Page'>
      <ol className='pl-3'>
        <li>Edit your theme files</li>
        <li>Go to <code>templates {'>'} pages {'>'} category.html</code></li>
        <li>We need to hide Bigcommerce category page in order to replace it with fitment category page.
          So search for the html tag that takes care of that and add to the parent <code>div</code> an id equals to <b>SD-CollectionPage</b>. You also have to add <b>style="display: none;"</b> so our widget can find and show it.
          <img src='https://assets.mysuredone.com/688015/logos/fitment-advanced-bigcommerce-CSF-DIV-1.png' alt='Bigcommerce parent div' />
          <img src='https://assets.mysuredone.com/688015/logos/fitment-advanced-bigcommerce-CSF-DIV-2.png' alt='Bigcommerce parent div2' />
        </li>
        <li>
          Now we need to add a widget point of mount. For that add following code on <code>categories.html</code> right above the <code>div</code> of step 2:
          <CodeBlock>{code}</CodeBlock>
          Please <b style={{ margin: '8px 0', display: 'inline-block' }}>replace SUREDONEID with your SureDone id</b>.<br />
          <img src='https://assets.mysuredone.com/688015/logos/fitment-advanced-bigcommerce-CSF-DIV-3.png' alt='Bigcommerce point of mount' />
        </li>
        <li>Lastly we need to load the widget on this page so paste following code: <code>{'<script src="https://fitment.suredone.com/bundle.min.js"></script>'}</code><br />
          <img src='https://assets.mysuredone.com/688015/logos/fitment-advanced-bigcommerce-CSF-DIV-4.png' alt='Bigcommerce bundle load' />
          <img src='https://assets.mysuredone.com/688015/logos/fitment-advanced-bigcommerce-CSF-DIV-5.png' alt='Bigcommerce Category fitment result' />
        </li>
      </ol>
    </InstructionCollapse>
  )
}
