import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useStores } from '../../../stores/RootStore'
import fitmentPage from '../../../img/fitmentPage.png'
import { CodeBlock, CopyToClipboard, Page } from '../../../components'
import { AiOutlineInfoCircle as Info } from 'react-icons/ai'
import fitmentEditHeader from '../../../img/fitmentEditHeader.png'
import fitmentResultPage from '../../../img/fitmentResultPage.png'
import fitmentEdittingHeader from '../../../img/fitmentEdittingHeader.png'
import { Card, CardBody, Input, Label, FormFeedback, UncontrolledTooltip } from 'reactstrap'

const reg = /^#([0-9a-f]{3}){1,2}$/i // is hexadecimal code
const isHexColor = c => reg.test(c)

const code = (legacyAccountId, primaryColor, useDemoUser) => {
  return `<div id='sd-root'></div>
  <script>
      window.shopData = {
        suredoneID: ${useDemoUser ? 688015 : Number(legacyAccountId)},
        storefront: 'storefront',
        primary_color: '${isHexColor(primaryColor) ? primaryColor : '#000000'}'
      }
  </script>
  <script src='https://fitment.suredone.com/bundle.min.js'></script>`
}

export const SettingsSureDone = () => {
  const { stores } = useStores()
  const { auth } = stores
  const { user } = auth || {}
  const { legacyAccountId } = user || {}
  const [primaryColor, setPrimaryColor] = useState()
  const [useDemoUser, setUseDemoUser] = useState(false)

  const handleColor = e => setPrimaryColor(e?.target?.value)
  const handleDemo = e => setUseDemoUser(e?.target?.checked)

  return (
    <Page
      className='fitment-settings'
      title='SureDone Storefront Fitment Settings'
      description='These settings configure the fitment module that will be displayed on your SureDone storefront'
    >
      <Card>
        <CardBody className='py-4'>
          <h5 className='mb-3'>Widget configuration</h5>
          <h6>Primary color <Info id='primary-color' size={15} /></h6>
          <UncontrolledTooltip placement='right' target='primary-color'>
            Used to match the SureDone Fitment block styles with your storefront theme
          </UncontrolledTooltip>
          <Input value={primaryColor} onChange={e => handleColor(e)} placeholder='#000000' />
          {primaryColor && !isHexColor(primaryColor) && <FormFeedback style={{ display: 'block' }}>Please enter a valid hexadecimal code</FormFeedback>}<br />

          <h6>Demo user <Info id='demo-user' size={15} /></h6>
          <UncontrolledTooltip placement='right' target='demo-user'>
            Used to test the widget with demo user data
          </UncontrolledTooltip>
          <Label
            style={{ cursor: 'pointer' }}
            className='custom-control custom-checkbox d-flex'
          >
            <Input
              type='checkbox'
              onChange={e => handleDemo(e)}
              className='custom-control-input'
            />
            <span className='custom-control-label'>
              Use demo user data
            </span>
          </Label>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <h5 className='mb-3'>How to use it</h5>
          <ol style={{ paddingLeft: '15px' }}>
            <li>
              Go to <a href='/#!/pages' target='_blank' rel='noopener noreferrer'>Pages</a> and click on the <i>+ New Page</i> button.
            </li>
            <li>
              Configure the new page as following:
              <ul style={{ paddingLeft: '15px' }}>
                <li>
                  <u>Store URI</u>: needs to be <b>index</b>
                </li>
                <li>
                  <u>Title</u>: needs to be <b>fitment</b>
                </li>
                <li>
                  <u>Name</u>: be sure to click on <i>Edit</i> and then type <b>fitment</b>
                  <img src={fitmentPage} alt='Fitment page settings' style={{ width: '100%', padding: '5px 0' }} />
                </li>
                <li>
                  <u>Page Body</u>: Copy and paste there the following code:
                  <div style={{ position: 'relative', marginTop: '0.5rem' }}>
                    <CodeBlock language='html'>{code(legacyAccountId, primaryColor, useDemoUser)}</CodeBlock>
                    <CopyToClipboard value={code(legacyAccountId, primaryColor, useDemoUser)} />
                  </div>
                </li>
              </ul>
            </li>
            After saving the settings the url should look like <i>https://example.com/<b>fitment</b>/</i>
            <li>
              Create a new page as in step 2 but this time configure it as follows:
              <ul style={{ paddingLeft: '15px' }}>
                <li>
                  <u>Store URI</u>: needs to be <b>result</b>
                </li>
                <li>
                  <u>Title</u>: needs to be <b>result</b>
                </li>
                <li>
                  <u>Name</u>: select <b>fitment</b> (created in previous step).
                  <img src={fitmentResultPage} alt='Fitment Result page settings' style={{ width: '100%', padding: '5px 0' }} />
                </li>
                <li>
                  <u>Page Body</u>: Copy and paste the same code you used in step 2.
                </li>
              </ul>
            </li>
            After saving the settings the url should look like <i>https://example.com/pages/<b>fitment/result</b>/</i>
            <li>
              All done, you can now go to https://example.com/fitment/ and see the storefront widget running! <span role='img' aria-label='confetti'>🎉</span>
            </li>
          </ol>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <h5 className='mb-3'>Adding the Fitment widget to the home page</h5>
          <ol style={{ paddingLeft: '15px' }}>
            <li>
              Go to <Link to='/settings/templates'>Templates</Link> and edit the <b>header.htm</b> file of the template your storefront is using.
              <img src={fitmentEditHeader} alt='Edit header file' style={{ width: '100%', padding: '5px 0' }} />
            </li>
            <li>
              At the end of the header div paste the following code:
              <div style={{ position: 'relative', marginTop: '0.5rem' }}>
                <CodeBlock language='html'>{`{% if data.name != 'fitment' %}\n${code(legacyAccountId, primaryColor, useDemoUser)}\n{% endif %}`}</CodeBlock>
                <CopyToClipboard value={`{% if data.name != 'fitment' %}\n${code(legacyAccountId, primaryColor, useDemoUser)}\n{% endif %}`} />
              </div>
              <br />
              Like this:
              <img src={fitmentEdittingHeader} alt='Edit header file' style={{ width: '100%', padding: '5px 0' }} />
            </li>
            <li>Click on <b>Save changes</b> and you're done! <span role='img' aria-label='confetti'>🎉</span></li>
          </ol>
          <p style={{ margin: '20px 0 0' }}>Any doubts about how to use the templates? <a target='_blank' rel='noopener noreferrer' href='https://support.suredone.com/support/solutions/articles/1000312195-using-the-suredone-template-editor'>Check the docs</a>.</p>
        </CardBody>
      </Card>
    </Page>
  )
}
