import React from 'react'
import { ErrorAlert } from '../../components'
import { UncontrolledAlert } from 'reactstrap'
import { useQuery } from '@apollo/react-hooks'
import { SYNC_CHANNELS } from './commonQueries'

export const SyncChannels = ({ slug, instanceNumber }) => {
  const { loading, error, data } = useQuery(SYNC_CHANNELS, { variables: { slug, instanceNumber } })
  const { ChannelInstance } = data || {}
  const { syncData } = ChannelInstance || {}

  if (error) return <ErrorAlert error={error} />
  if (!loading && syncData) return <UncontrolledAlert color='info'>{syncData}</UncontrolledAlert>
  return null
}
