import React, { useState } from 'react'
import { Status } from './Status'
import { Card, CardBody } from 'reactstrap'
import { useQuery } from '@apollo/react-hooks'
import { GET_STATUSES } from './commonQueries'
import { useRouteMatch } from 'react-router-dom'
import { Loadable, Page } from '../../components'
import { useStores } from '../../stores/RootStore'
import { FaExternalLinkAlt as ExternalLink } from 'react-icons/fa'

export const StatusesList = ({ isFrame }) => {
  const { stores: { auth } } = useStores()
  const { user } = auth || {}
  const { organizationId } = user || {}
  const { params } = useRouteMatch()
  const urlIdentifier = params?.identifier
  const { loading, error, data } = useQuery(GET_STATUSES, {
    variables: {
      // targetOrganizationId,
      filter: { identifier: urlIdentifier }
    }
  })
  const { getLogStatusesByFilter } = data || {}
  const { statuses } = getLogStatusesByFilter || {}
  const [detailedStatus, setDetailedStatus] = useState()
  const { _id, lastLog, lastError, lastErrorFullLog } = detailedStatus || {}
  const { context: lsContext, instance: lsInstance, created_at: lsCreatedAt, result: lsResult, type: lsType, _id: lsLogId } = lastLog || {}
  const { context: leContext, instance: leInstance, created_at: leCreatedAt, result: leResult, type: leType, _id: leLogId } = lastError || {}
  const { action: lefAction, message: lefMessage, integration: lefIntegration, method: lefMethod, role: lefRole } = lastErrorFullLog || {}
  const { identifier, context, type, instance } = _id || {}

  return (
    <Page
      className='statuses'
    >
      <Card className='mb-0'>
        <CardBody>
          {statuses?.length < 1
            ? <h6>No statuses</h6>
            : (
              <>
                <h6 className='d-inline-flex'>Statuses</h6>
                <Loadable
                  inline
                  error={error}
                  loading={loading}
                  content={
                    <>
                      <section className='statuses-wrapper'>
                        {statuses?.map((item, idx) =>
                          <Status
                            key={idx}
                            data={item}
                            isFrame={isFrame}
                            organizationId={organizationId}
                            setDetailedStatus={setDetailedStatus}
                          />
                        )}
                      </section>
                    </>
                  }
                />
                {detailedStatus && !isFrame &&
                  <Card className='my-3 status-details'>
                    <CardBody>
                      <h6>{identifier}</h6>
                      <span>Type: {type}</span><br />
                      <span>Result: {lsResult}</span><br />
                      <span>Channel: {context} {instance > 0 && instance}</span>
                      {lastError && (
                        <>
                          <span className='mt-2 d-block'>Last error:</span>
                          <ul className='mb-0'>
                            <li>result: {leResult}</li>
                            <li>type: {leType}</li>
                            {lefAction && <li>action: {lefAction}</li>}
                            {lefMethod && <li>method: {lefMethod}</li>}
                            {lefRole && <li>role: {lefRole}</li>}
                            {lefIntegration && <li>integration: {lefIntegration}</li>}
                            <li>channel: {leContext} {leInstance > 0 && leInstance}</li>
                            {lefMessage && <li>message: {lefMessage?.message || '-'}</li>}
                            <li>created at: {leCreatedAt}</li>
                            <li>
                              <a target='_blank' rel='noopener noreferrer' href={`/logs/${organizationId}/${leLogId}`}>
                                view full log <ExternalLink size={10} className='mx-1' />
                              </a>
                            </li>
                          </ul>
                        </>
                      )}
                      {lastLog && (
                        <>
                          <span className='mt-2 d-block'>Last status:</span>
                          <ul className='mb-0'>
                            <li>result: {lsResult}</li>
                            <li>type: {lsType}</li>
                            <li>channel: {lsContext} {lsInstance > 0 && lsInstance}</li>
                            <li>created at: {lsCreatedAt}</li>
                            <li>
                              <a target='_blank' rel='noopener noreferrer' href={`/logs/${organizationId}/${lsLogId}`}>
                                view full log <ExternalLink size={10} className='mx-1' />
                              </a>
                            </li>
                          </ul>
                        </>
                      )}
                    </CardBody>
                  </Card>}
              </>
              )}
        </CardBody>
      </Card>
    </Page>
  )
}
