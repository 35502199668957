import React, { useState } from 'react'
import { Col, Row } from 'reactstrap'
import { VerticalSteps } from './VerticalSteps'
import { renderSwitch, DownloadButton } from './ImportElements'
import { useUploadImport } from '../../../hooks/useUploadImport'
import { useBulkStatusSummary } from '../../../hooks/useBulkStatusSummary'

/**
 * The APPROVED status is no longer used, an import is now automatically approved when it's ready to review
 * but we must support old imports that may have been approved but not loaded into the account.
 * @param {{customProps: object, setShowReward: function}} props
 * @returns {React.ReactHTML}
 */
export const ApprovedContent = ({ customProps, setShowReward }) => {
  const { id: channelImportId, status, importFileUrl, loadedIntoSD, slug, instance, legacyJobId } = customProps || {}
  const [avoidRefetchBSS, setAvoidRefetchBSS] = useState()

  const [getBulkStatusSummary, { data: dataBSSummary, loading: loadingBSSummary, error: errorBSSummary, called: calledBSSummary }] = useBulkStatusSummary({ legacyJobId, avoidRefetchBSS, setAvoidRefetchBSS })

  const [uploadImport, { loading: loadingUpload, error: errorUpload }] = useUploadImport({ channelImportId, typeOfImport: null, skipUpdateFields: [], legacyJobId, avoidRefetchBSS, getBulkStatusSummary })

  const { BulkStatusSummary } = dataBSSummary || {}
  const { status: bulkStatus } = BulkStatusSummary || {}
  const uploadStarted = calledBSSummary || loadingBSSummary

  return (
    <VerticalSteps importStatus={status} bulkStatus={bulkStatus}>
      <Row className='align-items-end'>
        <Col xs={12} sm={6} className='text-center p-2'>
          {renderSwitch({ calledBSSummary, bulkStatus, channelImportId, setShowReward, slug, instance, loadingBSSummary, errorBSSummary, dataBSSummary, avoidRefetchBSS, uploadImport, uploadStarted, loadingUpload, errorUpload, loadedIntoSD })}
        </Col>
        <Col xs={12} sm={6} className='text-center p-2'>
          <DownloadButton importFileUrl={importFileUrl} slug={slug} instance={instance} />
        </Col>
      </Row>
    </VerticalSteps>
  )
}
