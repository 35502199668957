import gql from 'graphql-tag'

// This query gets the data for the user. Don't mix with authStore (that one has cognito data)
export const SET_MFA_SETTING = gql`
mutation setMFASetting($mfaEnabled: Boolean!) {
  setMFASetting(mfaEnabled: $mfaEnabled) {
    id
    mfaEnabled
  }
}
`
