import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

const useQueryParams = () => {
  const location = useLocation().search
  const query = useMemo(() => new URLSearchParams(location), [location])
  // convention queries with arrays (scope[] = 1 && scope[] = 2), ',' separated (facebook), '+' separated (bigcommerce) transform into comma separeted
  const calcParams = (query) => {
    const object = {}
    for (const [k, v] of query.entries()) {
      if (k === 'error') {
        // removing () that causes errors
        object[k] = v.replace(/[()]/g, '')
        return object
      }
      if (k in object) {
        // Bigcommerce sends scope data in 2 ways when comming from bc, as '+' separeted
        // When auth from sd array way
        // way 1: scope%5B%5D=store_channel_listings&scope%5B%5D=store_channel_settings&scope%5B%5D=store_v2_default&scope%5B%5D=store_v2_information_read_only&scope%5B%5D=store_v2_orders&scope%5B%5D=store_v2_products&scope%5B%5D=users_basic_information
        // way 2: scope=store_channel_listings+store_channel_settings+store_v2_default+store_v2_information_read_only+store_v2_orders+store_v2_products+users_basic_information
        // facebook as granted_scopes =a,b. So decided to conver to commas
        object[k] = `${object[k]},${v}`
      } else {
        object[k] = v.split(' ').join(',')
      }
    }
    return object
  }
  const paramsObject = useMemo(() => calcParams(query), [query])

  return paramsObject
}

export default useQueryParams
