import React from 'react'
import { Link } from 'react-router-dom'
import { AuthPage } from '../Auth/AuthPage'
import { useQuery } from '@apollo/react-hooks'
import { assets } from '../../img/illustrations'
import { ScheduledEvent } from './ScheduledEvent'
import { useStores } from '../../stores/RootStore'
import { FaSignOutAlt as LogOut } from 'react-icons/fa'
import { Button, Row, Col, Card, CardBody } from 'reactstrap'
import { GET_ORGANIZATION_DETAILS } from '../OrganizationManager/commonQueries'
import './OnDemo.scss'

export const OnDemo = ({ orgData, organizationId }) => {
  const { email } = orgData || {}
  const { stores: { auth } } = useStores()
  const { theSalvationArmy, gizmo } = assets

  // Refetchs organization data every 10 seconds to check demoWall flag updates
  useQuery(GET_ORGANIZATION_DETAILS, {
    variables: { id: organizationId },
    pollInterval: 10000
  })

  return (
    <AuthPage>
      <Card className='custom auth-form mx-auto mt-3 on-demo'>
        <CardBody className='p-0'>
          <Row>
            <Col xs={12} md={6} className='l-wrapper d-flex flex-wrap align-items-start flex-column px-4 px-lg-5'>
              <h1 style={{ fontSize: '1.2rem' }}>Your account has been successfully created!</h1>
              <p className='mt-2 mb-3'>Once we have introduced you to our application, you will be able to make full use of it.</p>
              <ScheduledEvent email={email} />
            </Col>

            <Col xs={12} md={6} className='r-wrapper d-flex flex-wrap align-items-center px-4 px-lg-5'>
              <div className='testimonials d-flex flex-column'>
                <Card className='shadow-lg rounded-lg'>
                  <Row>
                    <Col xs={12} sm={4} className='d-flex justify-content-center align-items-center pt-sm-4 pb-sm-4 pl-sm-4 p-0 mt-4 mt-sm-0'>
                      {theSalvationArmy}
                    </Col>
                    <Col xs={12} sm={8} className='p-4'>
                      <blockquote className='blockquote text-left border-0 p-0 m-0'>
                        <p>SureDone is the best choice for selling on eBay, Amazon, and a custom tailored storefront! As an enterprise customer, they've lived up to my expectations in every way.</p>
                        <footer className='blockquote-footer'><cite title='Salvation Army'>Salvation Army</cite>
                        </footer>
                      </blockquote>
                    </Col>
                  </Row>
                </Card>

                <Card className='shadow-lg rounded-lg'>
                  <Row>
                    <Col xs={12} sm={4} className='d-flex justify-content-center align-items-center pt-sm-4 pb-sm-4 pl-sm-4 p-0 mt-4 mt-sm-0'>
                      {gizmo}
                    </Col>
                    <Col xs={12} sm={8} className='p-4'>
                      <blockquote className='blockquote text-left border-0 p-0 m-0'>
                        <p>SureDone was the only vendor that could offer a customizable solution to fit our needs. Its feature-rich tool set enables us to feed multiple warehouse locations to our one unified eBay marketplace making SureDone our eCommerce platform of choice.</p>
                        <footer className='blockquote-footer'><cite title='Gizmo Trader'>Gizmo Trader</cite>
                        </footer>
                      </blockquote>
                    </Col>
                  </Row>
                </Card>
              </div>
            </Col>

            <Col xs={12} className='mt-5 mb-n5 text-center'>
              <Button tag={Link} color='link' to='/' className='mb-3' onClick={_ => auth.logOut()}>
                <LogOut /> Log Out
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </AuthPage>
  )
}
