import React from 'react'
import { usefulSearches } from './utils'
import { Card, CardBody } from 'reactstrap'
import { Pill, makeSearch } from '../../../components'
import { FaExternalLinkAlt as ExternalLink } from 'react-icons/all'

export const UsefulSearches = () => {
  return (
    <Card>
      <CardBody>
        <h5 className='mb-3'>Useful Searches</h5>
        <ul className='useful-searches mb-0 pl-0'>
          {usefulSearches?.map(({ type, query, label }, idx) => (
            <li
              className='mb-2'
              key={`${query}${idx}`}
              style={{ listStyle: 'none' }}
              onClick={() => makeSearch({ type, query })}
            >
              <Pill style={{ cursor: 'pointer' }}>
                {label} <ExternalLink color='#b5b6ba' className='mr-0' size={10} />
              </Pill>
            </li>
          ))}
        </ul>
      </CardBody>
    </Card>
  )
}
