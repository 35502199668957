import React from 'react'
import YouTube from 'react-youtube'

const YoutubeTutorial = () => {
  const videoId = 'eJYcSknQd4I'
  const videoOpts = {
    height: '315',
    width: '100%',
    playerVars: {
      autoplay: 1,
      enablejsapi: 1
    }
  }

  const onReadyHandler = (e) => {
    if (window.analytics && window.analytics.plugins) {
      const ytAnalytics = new window.analytics.plugins.YouTubeAnalytics(e.target, window.serverConfig.segmentYoutubeAPIKeyPlugin)
      ytAnalytics.initialize()
    }
  }

  return (
    <YouTube
      videoId={videoId} // defaults -> null
      id='SuredoneTutorial' // defaults -> null
      opts={videoOpts} // defaults -> {}
      onReady={onReadyHandler}
    />

  )
}

export { YoutubeTutorial }
