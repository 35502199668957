import { useMutation } from '@apollo/react-hooks'
import { decideIfSendSkipUpdate } from '../scenes/Channel/ChannelImportPage/ImportElements'
import { UPLOAD_IMPORT } from '../scenes/Channel/ChannelImportPage/graphqlCommon'

export const useUploadImport = ({ channelImportId, typeOfImport, skipUpdateFields, legacyJobId, avoidRefetchBSS, getBulkStatusSummary }) => {
  const [uploadImport, { data, loading, error }] = useMutation(
    UPLOAD_IMPORT,
    {
      variables: {
        id: channelImportId,
        skipUpdate: decideIfSendSkipUpdate(typeOfImport, skipUpdateFields)
      },
      onCompleted: () => {
        if (legacyJobId && !avoidRefetchBSS) { getBulkStatusSummary() }
      }
    }
  )

  return [uploadImport, { data, loading, error }]
}
