import React, { useState } from 'react'
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import { FormGroup, Label, Col } from 'reactstrap'

export const StockSettingItem = ({ item, stockFields, parentCallback }) => {
  const { label, value } = item || {}
  const options = (stockFields || []).map(i => ({
    value: i,
    label: i
  }))

  const [selectedOption, setSelectedOption] = useState({
    value,
    label: value
  })

  const handleChange = (event) => {
    setSelectedOption(event)
    parentCallback(event, item)
  }

  return (
    <FormGroup row className='align-items-center'>
      <Label xs={6}>{label}</Label>
      <Col xs={6}>
        <Select
          options={options}
          clearable={false}
          value={selectedOption}
          onChange={handleChange}
          placeholder=''
        />
      </Col>
    </FormGroup>
  )
}
