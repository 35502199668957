/*
 * Shows a pogress bar at the top of the page based on user's scroll
 * Usage: <ScrollingProgressBar />
 */

import React, { useState, useEffect } from 'react'
import './ScrollingProgressBar.scss'

export const ScrollingProgressBar = () => {
  const [scroll, setScroll] = useState(0)

  useEffect(() => {
    const progressBarHandler = () => {
      const totalScroll = document.documentElement.scrollTop
      const windowHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight
      const scroll = totalScroll / windowHeight
      setScroll(scroll)
    }
    window.addEventListener('scroll', progressBarHandler)
    return () => window.removeEventListener('scroll', progressBarHandler)
  })

  return (
    <div id='progressBarContainer'>
      <div id='progressBar' style={{ transform: `scale(${scroll}, 1)` }} />
    </div>
  )
}
