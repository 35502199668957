import React from 'react'
import { Col, Label } from 'reactstrap'

const TextType = ({ data }) => {
  const { label, fieldName, description, value } = data
  return (
    value &&
      <>
        <Col sm={7}>
          <Label tag={`${description && 'legend'}`} className={`${description && 'mb-0'}`} for={fieldName}>{label}</Label>
          {description && <p>{description}</p>}
        </Col>
        <Col sm={5}>
          <p className='mb-0 mt-2'>{value}</p>
        </Col>
      </>
  )
}

export { TextType }
