import React from 'react'
import { Card, CardBody } from 'reactstrap'
import { EmptyState } from '../../../components'
import { people } from '../../../img/illustrations'

const actions = [
  { label: 'Fill out the onboarding questionnaire', link: 'https://www.cognitoforms.com/suredone1/suredonestatementofwork' },
  { label: 'Add new users', link: 'https://app.suredone.com/settings/organization/members' },
  { label: 'Update your company information and logo', link: 'https://app.suredone.com/settings/organization/profile' },
  { label: 'Sign up for our support portal', link: 'https://support.suredone.com' },
  { label: 'Sign up for elearning', link: 'https://elearn.suredone.com' }
]

const daysThreshold = 30
const actionsStartDate = 1715731200 // May 15, 2024 00:00:00 AM
const milliSecondsInADay = 24 * 60 * 60 * 1000

const has30DaysPassed = (creationDateInMilliseconds) => {
  const currentTimestamp = Date.now()
  const diffInMilliseconds = Math.abs(currentTimestamp - creationDateInMilliseconds)
  const diffInDays = diffInMilliseconds / milliSecondsInADay
  return diffInDays >= daysThreshold
}

export const OnboardingActions = ({ orgData }) => {
  const { createDate: orgCreationDate } = orgData || {}
  const showActions = (orgCreationDate > actionsStartDate) && !has30DaysPassed(orgCreationDate * 1000)

  return (
    <EmptyState
      isEmpty={!showActions}
      content='All tasks were completed'
      customImage={people.completedTODOs}
    >
      {actions.map(({ label, link }) => (
        <Card className='mb-2' key={link}>
          <CardBody className='p-2 d-flex justify-content-between align-items-center'>
            {label}
            <a
              href={link}
              target='_blank'
              rel='noopener noreferrer'
              className='btn btn-secondary btn-xs ml-2'
            >
              Go
            </a>
          </CardBody>
        </Card>
      ))}
    </EmptyState>
  )
}
