import React from 'react'
import { InstructionCollapse } from '../InstructionCollapse'

export const BigcommerceEditThemeFile = () => {
  return (
    <InstructionCollapse id='ThemeFiles' title='1. How to edit Theme Files'>
      <ol className='pl-3'>
        <li>On bigcommerce dashboard go to: Storefront {'>'} Themes</li>
        <li>Click on Advanced</li>
        <li>Make a copy of your current theme so you can edit it (if you already have a copy of it, skip this step)</li>
        <li>Click on the 3 dots of the resulting theme (the copy you just created) and go to Edit Theme Files</li>
        <li>The Bigcommerce theme editor should be opened</li>
      </ol>
    </InstructionCollapse>
  )
}
