import React from 'react'
import { MobXProviderContext } from 'mobx-react'

import AuthStore from './AuthStore'
// import ApiStore from './ApiStore'
// import GraphQLStore from './GraphQLStore'
// import UserStore from './UserStore'

const debug = require('debug')('sd:RootStore')

export default class RootStore {
  constructor () {
    this.auth = new AuthStore(this)
    // this.api = new ApiStore(this)
    // this.graphql = new GraphQLStore(this)
    // this.user = new UserStore(this)
    this.init()
  }

  async init () {
    await this.auth.init()
  }
}

export function useStores () {
  return React.useContext(MobXProviderContext)
}

debug('loaded')
