/**
 * <AsyncSelect />
 *
 * It should be used when the options to provide to <Select /> that we always use are not available on component did mount
 * since they depend on an asynchronous call based on an action performed by the user.
 *
 * Example of usage: /scenes/Channel/Walmart/WalmartCategoryFilter.js
 *
 * Usage:
 *     <AsyncSelect
 *       loading        = Boolean
 *       placeholder    = String -> Shown when there is no value
 *       value          = String -> Input's value where the user can type to make a search
 *       options        = Array -> [{ value: String, label: String, helpText: String}]
 *       setValue       = Function -> value setter, used to make queries to retrieve the data
 *       onClickOption  = Function -> Triggered when user click on some displayed option
 *       onInputChange  = Function -> Triggered when user changes the Select's input
 *  />
 */

import React, { useRef, useEffect } from 'react'
import { Input, Spinner } from 'reactstrap'
import './AsyncSelect.scss'

export const AsyncSelect = ({ onInputChange = () => { }, onClickOption = () => { }, value, setValue, options, loading, ...props }) => {
  const asyncSelect = useRef(null)

  const onBlur = () => asyncSelect.current.classList.remove('hide-focus')

  const onChange = e => {
    onInputChange(e.target.value)
    asyncSelect.current.classList.add('hide-focus')
    asyncSelect.current.classList.add('show-options')
  }

  const handleClickOption = (i, idx) => {
    setValue(i?.value)
    onClickOption(i, idx)
    asyncSelect.current.classList.remove('show-options')
  }

  const handleClickOutside = e => {
    if (!asyncSelect.current.contains(e.target)) {
      asyncSelect.current.classList.remove('hide-focus')
      asyncSelect.current.classList.remove('show-options')
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  })

  const renderSwith = () => {
    if (loading) {
      return (
        <li className='text-center'>
          <Spinner size='sm' color='secondary' />
        </li>
      )
    }

    if (options?.length > 0) {
      return options.map((i, idx) =>
        <li key={idx} onClick={() => handleClickOption(i, idx)} className='d-flex flex-wrap'>
          {i?.label}
          {i?.helpText && (
            <span
              className='text-muted w-100'
              style={{ fontSize: '.85rem' }}
            >
              {i.helpText}
            </span>
          )}
        </li>
      )
    }

    return <li className='disabled'>No results found</li>
  }

  return (
    <div className='async-select-wrapper' ref={asyncSelect}>
      <Input
        {...props}
        value={value}
        onBlur={onBlur}
        onChange={e => onChange(e)}
      />

      <ul>
        {renderSwith()}
      </ul>
    </div>
  )
}
