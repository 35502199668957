import React from 'react'
import { Page1, Page2 } from './'
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom'
import './Qualifications.scss'

export const Qualifications = () => {
  const { path } = useRouteMatch()

  const routes = [
    {
      name: 'Qualification 1',
      path: `${path}/1`,
      render: () => <Page1 />
    },
    {
      name: 'Qualification 2',
      path: `${path}/2`,
      render: () => <Page2 />
    }
  ]

  return (
    <Switch>
      <Route path={`${path}`}>
        <Switch>
          {routes.map((routeProps, index) => (
            <Route key={index} {...routeProps} />
          ))}
          <Redirect to='/q/1' />
        </Switch>
      </Route>
    </Switch>
  )
}
