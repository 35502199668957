export const maxHeightModifiers = {
  setMaxHeight: {
    enabled: true,
    order: 890,
    fn: (data) => {
      return {
        ...data,
        styles: {
          ...data.styles,
          maxHeight: 500,
          overflow: 'auto'
        }
      }
    }
  }
}

export const makeSearch = q => {
  const { type, query } = q || {}
  const appEndpoint = window.serverConfig.legacyAppEndpoint
  const searchUrl = `${appEndpoint}/#!/${type.toLowerCase()}?q=${encodeURIComponent(query)}`
  window.open(searchUrl, '_blank')
}
