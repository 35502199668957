import gql from 'graphql-tag'

export const GET_REGISTER_DATA = gql`
query OrganizationDetails($id: ID!) {
  OrganizationDetails(id: $id) {
    ... on Organization {
      id
      name
      email
      phone
      lastStepCompleted
      primaryContactFirstName
      primaryContactLastName
      subscribeToNewsletter
      timezone
      address {
        street1
        street2
        city
        stateProvince
        postalCode
        country
      }
    }
  }
}
`
