import auth01 from './auth01.png'

export const stepInstructions = {
  Permissions: {
    instructions: {
      stepInstructions: [
        {
          title: 'Login with eBay & Grant Application Access',
          description: 'Click "Authorize eBay" below to start the authorization process. Grant application access to SureDone to enable management of your listings and orders.',
          imgSrc: auth01,
          imgAlt: 'Login with eBay and Grant Application Access'
        }
      ]
    }
  },
  OAuth: {
    instructions: {
      stepInstructions: [
        {
          title: 'Authorize eBay via OAuth',
          description: 'The integration requires you authorize a second time to support functionality provided by OAuth. Please go through the authorization process again.',
          imgAlt: 'Authorize eBay via OAuth'
        }
      ]
    }
  }
}
